import React, { ReactNode } from 'react';
import { Collapse } from 'antd';
import classNames from 'classnames';
import { DoubleArrowsIcon } from 'assets/icons';

import './statisticCollapse.less';

const { Panel } = Collapse;

interface StatisticCollapsePropsI {
  panelName: ReactNode;
  className?: string;
  children: ReactNode;
  defaultExpanded?: boolean;
}

const CustomExpandIcon = ({ panelProps }: any) => {
  if (panelProps.isActive) {
    return (
      <div className="statistic-collapse__expand-active">
        <DoubleArrowsIcon />
      </div>
    );
  }
  return (
    <div className="statistic-collapse__expand">
      <DoubleArrowsIcon />
    </div>
  );
};

const StatisticCollapse = ({
  children,
  panelName,
  className,
  defaultExpanded,
}: StatisticCollapsePropsI) => (
  <Collapse
    className={classNames('statistic-collapse', className)}
    defaultActiveKey={defaultExpanded ? 'one' : 'none'}
    expandIconPosition="end"
    expandIcon={(panelProps) => <CustomExpandIcon panelProps={panelProps} />}
    ghost
  >
    <Panel header={panelName} key="one">
      {children}
    </Panel>
  </Collapse>
);

export default StatisticCollapse;
