import classNames from 'classnames';
import { TableProps } from 'antd/lib/table';
import { Table, Divider, Pagination, Card, Select } from 'antd';

import './table.less';

interface TableWrapperPropsI extends TableProps<any> {
  total?: number;
  limit?: number;
  pageSizeOptions?: number[];
  pageSizeSubject?: string;
  currentPage?: number;
  fetchMore?: (page: number, total?: number) => void;
  setRecLimit?: (value: number | undefined) => void;
}

const { Option } = Select;

const TableWrapper = (props: TableWrapperPropsI) => {
  const {
    className,
    total,
    limit,
    pageSizeOptions,
    pageSizeSubject,
    currentPage,
    fetchMore,
    onRow,
    setRecLimit,
  } = props;

  const refetch = (page: number, total: number) => {
    if (fetchMore) {
      fetchMore(page, total);
    }
  };

  return (
    <Card className="table-card" size="small" bordered={false}>
      <Table
        pagination={false}
        showSorterTooltip={false}
        {...props}
        scroll={{ x: true }}
        className={classNames('table-card__table', className, {
          'table-card__table--pointer': onRow,
        })}
      />
      {total && limit ? (
        <div className="table-card__pagination-block">
          <Divider className="table-card__divider" />

          {pageSizeOptions && (
            <div className="table-card__pagesizer-container">
              <span>Show</span>
              <Select
                onChange={setRecLimit && ((e) => setRecLimit(e))}
                defaultValue={limit}
                value={limit}
                className="table-card__pagesizer"
              >
                {pageSizeOptions.map((item: number) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              {pageSizeSubject && <span>{pageSizeSubject.toLocaleLowerCase()}</span>}
            </div>
          )}

          <Pagination
            className="table-card__pagination"
            current={currentPage}
            defaultCurrent={1}
            total={total}
            pageSize={limit}
            onChange={(e) => refetch(e, total)}
            showSizeChanger={false}
            locale={{ items_per_page: '' }}
            onShowSizeChange={setRecLimit && ((e, a) => setRecLimit(a))}
            responsive
          />
        </div>
      ) : null}
    </Card>
  );
};

export default TableWrapper;
