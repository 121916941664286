import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

const SelectWrapper = (props: SelectProps<any>) => {
  const { loading, ...selectProps } = props;

  if (loading) {
    return (
      <Select
        virtual={false}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        {...selectProps}
        loading
        disabled
        value={null}
      />
    );
  }

  return (
    <Select
      virtual={false}
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      {...selectProps}
    />
  );
};

export default SelectWrapper;
