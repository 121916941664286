import React, { Suspense } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { client } from 'api/client';
import { Preloader } from 'components';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider, TranslateProvider } from 'contexts';
import { BrowserRouter as Router } from 'react-router-dom';

import 'moment/locale/en-gb';
import locale from 'antd/es/locale/en_GB';
import moment from 'moment';

import { ConfigProvider } from 'antd';

import App from './App';

moment.locale('en-gb', {
  week: {
    dow: 1,
  },
});

Sentry.init({
  dsn: 'https://55d760b033ae41e3af0980531a4774b1@o403321.ingest.sentry.io/5519593',
  integrations: [
    new Sentry.Integrations.GlobalHandlers({
      onunhandledrejection: false,
      onerror: false,
    }),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV || 'local',
});

const container = document.getElementById('root');

if (container) {
  ReactDOMClient.createRoot(container).render(
    <Suspense fallback={<Preloader />}>
      <ApolloProvider client={client}>
        <Router>
          <AuthProvider>
            <TranslateProvider>
              <ConfigProvider locale={locale}>
                <App />
              </ConfigProvider>
            </TranslateProvider>
          </AuthProvider>
        </Router>
      </ApolloProvider>
    </Suspense>,
  );
}
