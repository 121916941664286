import { Descriptions } from 'antd';

import './description.less';

const Description = (props: any) => {
  const { className } = props;
  return (
    <Descriptions
      colon={false}
      size="small"
      {...props}
      className={className ? `${className} description` : 'description'}
    />
  );
};

export default Description;
