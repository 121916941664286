import { Table } from 'antd';
import { TableProps } from 'antd/es/table';

import './statisticTable.less';

const StatisticTable = (props: TableProps<any>) => (
  <Table
    className="statistic-table"
    rowKey="_id"
    size="small"
    pagination={false}
    showSorterTooltip={false}
    scroll={{ x: true }}
    {...props}
  />
);

export default StatisticTable;
