import React, { ReactNode } from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import './modalWindow.less';

const ModalWindow = ({
  cancelText = <FormattedMessage id="button.Cancel" />,
  okText = <FormattedMessage id="button.Create" />,
  okType,
  children,
  className,
  disableConfirm,
  visible,
  ...props
}: ModalWindowPropsI) => (
  <Modal
    className={`ModalWindow ${className}`}
    width={360}
    centered
    destroyOnClose
    okType={okType}
    okButtonProps={{
      type: 'primary',
      disabled: disableConfirm,
    }}
    cancelText={cancelText}
    okText={okText}
    open={visible}
    {...props}
  >
    {children}
  </Modal>
);

interface ModalWindowPropsI {
  visible: boolean;
  title: string | ReactNode;
  onOk: () => void;
  onCancel: () => void;
  disableConfirm?: boolean;
  cancelText?: string | ReactNode;
  okText?: string | ReactNode;
  okType?: 'primary' | 'danger';
  confirmLoading?: boolean;
  children?: ReactNode;
  className?: string;
}

export default ModalWindow;
