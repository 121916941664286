import { intl } from 'contexts';
import { Notification } from 'components';

import { api } from './interceptors';

const downloadInvoice = async (orderId: string, invoiceId: string) => {
  try {
    const response = await api.get(
      `/app/orders/invoice-pdf/${orderId}/${invoiceId}`,
      {
        responseType: 'blob',
      }
    );
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `invoice_${orderId}_${invoiceId}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    Notification({
      type: 'error',
      title: intl.formatMessage({ id: 'notification.error' }),
      message: intl.formatMessage({ id: 'notification.orderStatus' }),
    });
    throw error;
  }
};

export { downloadInvoice };
