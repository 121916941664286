import React, { useRef, RefObject } from 'react';
import moment from 'moment';
import { NoteFragment } from 'api';
import { Title } from 'components';
import classNames from 'classnames';
import { Stub } from 'assets/images';
import { useIntl } from 'react-intl';
import { usePermission } from 'hooks';
import { SendIcon } from 'assets/icons';
import { FormInstance } from 'antd/lib/form';
import { Card, Form, Input, Button, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import './notes.less';

const { TextArea } = Input;
const { Item: FormItem } = Form;

interface NotesPropsI {
  total?: number;
  action: string;
  loading?: boolean;
  className?: string;
  form: FormInstance;
  notes: NoteFragment[] | null;
  fetchMore: () => void;
  onSend: (
    values: { content: string },
    containerRef: RefObject<HTMLDivElement>
  ) => void;
}

const Notes = ({
  form,
  notes,
  total,
  onSend,
  action,
  loading,
  className,
  fetchMore,
}: NotesPropsI) => {
  const { formatMessage } = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);

  const { isPermitted: isPermittedCreate } = usePermission(action);

  const clearErrors = () => form.setFields([{ name: 'content', errors: [] }]);

  return (
    <Card className={classNames('notes', className)} bordered={false}>
      <Title size="small">{formatMessage({ id: 'Notes' })}</Title>

      {!notes ? <Spin className="notes__preloader" /> : null}

      {notes && notes.length === 0 ? (
        <div className="notes__stub">
          <Stub />
          <p className="notes__caption">
            {formatMessage({ id: 'text.noNotes' })}
          </p>
        </div>
      ) : null}

      {notes && notes.length !== 0 ? (
        <div id="notesItems" className="notes__items" ref={containerRef}>
          <InfiniteScroll
            className="notes__infinite-scroll"
            dataLength={notes.length}
            next={fetchMore}
            style={{ overflowX: 'hidden' }}
            inverse
            hasMore={!!total && notes.length < total}
            loader={<Spin />}
            scrollableTarget="notesItems"
          >
            {notes.map(({ _id, author, content, createdAt }: NoteFragment) => {
              const authorName = author?.fullName;

              return (
                <div key={_id} className="notes__item">
                  <div className="notes__title">
                    <p className="notes__author">{authorName}</p>
                    <p className="notes__time">
                      {moment(createdAt).format('DD/MM/YYYY hh.mm a')}
                    </p>
                  </div>
                  <div className="notes__content">{content}</div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      ) : null}

      {isPermittedCreate && (
        <Form
          className="notes__form"
          form={form}
          onFinish={({ content }: { content: string }) =>
            onSend({ content: content.trim() }, containerRef)
          }
          preserve={false}
          requiredMark={false}
          validateTrigger="onSubmit"
          onValuesChange={clearErrors}
        >
          <FormItem
            className="notes__input"
            name="content"
            rules={[
              {
                type: 'string',
                max: 3000,
                message: formatMessage(
                  { id: 'validate.maxString' },
                  { max: 3000 }
                ),
              },
              {
                whitespace: true,
                message: formatMessage({ id: 'validate.empty' }),
              },
              {
                required: true,
                message: formatMessage({ id: 'validate.require' }),
              },
            ]}
          >
            <TextArea
              placeholder={formatMessage({
                id: 'placeholder.Type your note here',
              })}
              onBlur={clearErrors}
              autoSize={{ minRows: 1, maxRows: 5 }}
            />
          </FormItem>
          <Form.Item shouldUpdate className="submit">
            {() => {
               const { content } = form.getFieldsValue(['content']);
               const formIsComplete = content && content.length > 0;
               return (
                <Button
                className="notes__send"
                type="primary"
                htmlType="submit"
                onClick={(e) => {
                  e.preventDefault();
                  form.submit();
                }}
                icon={<SendIcon />}
                loading={loading}
                disabled={!formIsComplete}
              />
              );
            }}
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};

export default Notes;
