import React, { RefObject, useEffect, useState } from 'react';
import { Form } from 'antd';

import {
  NoteEntityType,
  NoteFragment,
  RbacResourceName,
  useCreateNoteMutation,
  useGetNotesQuery,
} from 'api';
import { useErrorsContext } from 'contexts';
import { Notes } from 'components';

const { useForm } = Form;

const NotesWrapper = ({
  id,
  actionResourceName,
  entityType = NoteEntityType.Order,
}: {
  id: string;
  actionResourceName: RbacResourceName;
  entityType?: NoteEntityType.Order | NoteEntityType.B2BOrder;
}) => {
  const { setErrors } = useErrorsContext();

  const [beforeId, setBeforeId] = useState<string | null>();
  const [notes, setNotes] = useState<NoteFragment[] | null>(null);

  const [form] = useForm();

  const { data: notesData } = useGetNotesQuery({
    variables: {
      filter: {
        entityType,
        entityId: id,
        beforeId,
      },
      limit: 10,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (notesData) {
      const newNotes = notesData?.getNotes?.nodes;
      setNotes((notes) => {
        if (notes) {
          return [...notes, ...newNotes];
        }

        return newNotes;
      });
    }
  }, [notesData]);

  const notesTotal = notesData?.getNotes?.count;

  const [createNote, { loading: createNoteLoading }] = useCreateNoteMutation();

  const handleFetchMore = () =>
    setBeforeId(notes ? notes[notes.length - 1]?._id : null);

  const handleSend = async (
    { content }: { content: string },
    containerRef: RefObject<HTMLDivElement>
  ) => {
    const { data } = await createNote({
      variables: {
        input: {
          content,
          entityId: id,
          entityType,
        },
      },
    });

    const newNote = data?.createNote?.node;
    const { status, errors } = data?.createNote || {};
    const success = status === 'SUCCESS';

    if (success && newNote) {
      setNotes((notes) => {
        if (notes) {
          return [newNote, ...notes];
        }

        return [newNote];
      });
      containerRef.current?.scrollTo(0, 0);
      return form.resetFields();
    }

    setErrors(errors);
  };

  return (
    <Notes
      action={actionResourceName}
      form={form}
      notes={notes}
      total={notesTotal}
      onSend={handleSend}
      fetchMore={handleFetchMore}
      loading={createNoteLoading}
      className="contract-status__notes"
    />
  );
};

export default NotesWrapper;
