import React, { useContext, createContext, useState, useEffect } from 'react';
import { capitalize } from 'utils';
import { MutationError } from 'api';
import { useIntl } from 'react-intl';
import { DeleteEntityModal, Notification } from 'components';

const initialValues = {
  errors: [],
  setErrors: (errors?: MutationError[] | null) => {},
};

const errorsContext = createContext(initialValues);

const { Provider } = errorsContext;

const ErrorsProvider = (props: any) => {
  const [errors, setErrors] = useState<MutationError[]>();
  const [modalState, setModalState] = useState(false);

  const { formatMessage } = useIntl();

  const toggleDeleteModal = () => setModalState((prev) => !prev);

  useEffect(() => {
    if (!errors || errors.length === 0) {
      return;
    }

    const hasDeleteEntityError = errors.find(
      ({ code }: { code: string }) => code === 'NODE_HAS_EXTERNAL_REFERENCE'
    );

    if (hasDeleteEntityError) {
      toggleDeleteModal();
    }

    if (!hasDeleteEntityError) {
      const prettifyError = (message: string) =>
        capitalize(message.toLocaleLowerCase().split('_').join(' '));

      errors.forEach(({ code }: { code: string }) => {
        const error = formatMessage({ id: `error.${code}` });
        Notification({
          type: 'error',
          title: formatMessage({ id: 'notification.error' }),
          message:
            error !== `error.${code}`
              ? prettifyError(error)
              : prettifyError(code),
        });
      });
    }
  }, [errors, formatMessage]);

  return (
    <>
      <Provider value={{ errors, setErrors }} {...props} />
      <DeleteEntityModal
        visible={modalState}
        toggleDeleteModal={toggleDeleteModal}
      />
    </>
  );
};

const useErrorsContext = () => useContext(errorsContext);

export { ErrorsProvider, errorsContext, useErrorsContext };
