import { RbacResourceName } from 'api';
import { checkPermission } from 'utils';
import { useUserContext } from 'contexts';

const possibleRotes = [
  {
    route: '/dashboard',
    action: RbacResourceName.DashboardView,
  },
  {
    route: '/users',
    action: RbacResourceName.TeamMemberView,
  },
  {
    route: '/teams',
    action: RbacResourceName.TeamView,
  },
  {
    route: '/projects',
    action: RbacResourceName.ProjectView,
  },
  {
    route: '/products',
    action: RbacResourceName.ProductView,
  },
  {
    route: '/bundles',
    action: RbacResourceName.ProductBundleView,
  },
  {
    route: '/customers',
    action: RbacResourceName.CustomerView,
  },
  {
    route: '/orders',
    action: RbacResourceName.OrderView,
  },
  {
    route: '/shipping',
    action: RbacResourceName.OrderShipmentView,
  },
  {
    route: '/licenses',
    action: [
      RbacResourceName.OrderLicenseView,
      RbacResourceName.BatchLicenseView,
    ],
  },
  {
    route: '/soSafe',
    action: [
      RbacResourceName.OrderExternalUsersView,
      RbacResourceName.BatchExternalUsersView,
    ],
  },
  {
    route: '/partner-stores',
    action: RbacResourceName.PartnerStoreView,
  },
  {
    route: '/batches',
    action: RbacResourceName.LicenseBatchView,
  },
];

const useInitialRoute = () => {
  const { permissionsList } = useUserContext();

  const initialRoute = possibleRotes.reduce((redirect, accessRotes) => {
    if (redirect) {
      return redirect;
    }

    const { action, route } = accessRotes;

    const hasAccess = checkPermission({
      rules: permissionsList,
      action,
    });

    return hasAccess ? route : redirect;
  }, '');

  return { initialRoute };
};

export { useInitialRoute };
