import { intl } from 'contexts';
import { compressImage } from 'utils';
import { Notification } from 'components';

import { api } from './interceptors';

const uploadImages = async (productId: string, fileList: any) => {
  try {
    const compressedList = await fileList.map(async (item: File) => {
      const compressedImage = await compressImage(item);
      return compressedImage;
    });
    const formData = new FormData();
    for await (const file of compressedList) {
      formData.append('files', file, file.name);
    }
    formData.append('productId', productId);

    const response = await api.patch('/app/product/image', formData);
    return response;
  } catch (error) {
    Notification({
      type: 'error',
      title: intl.formatMessage({ id: 'notification.error' }),
      message: intl.formatMessage({ id: 'notification.uploadImageError' }),
    });
    throw error;
  }
};

const deleteImages = async (productId: string, urls: string[]) => {
  try {
    const response = await api.delete('/app/product/image', {
      data: {
        productId,
        urls,
      },
    });

    return response;
  } catch (error) {
    Notification({
      type: 'error',
      title: intl.formatMessage({ id: 'notification.error' }),
      message: intl.formatMessage({ id: 'notification.deleteImageError' }),
    });
    throw error;
  }
};

export { uploadImages, deleteImages };
