import { useIntl } from 'react-intl';
import { Tag } from 'components';
import { ShippingStatus } from 'api';

const ShippingStatusTag = ({ status }: { status: ShippingStatus }) => {
  const { formatMessage } = useIntl();
  if (status === ShippingStatus.Needed) {
    return <Tag color="primary">{formatMessage({ id: 'Needed' })}</Tag>;
  }
  if (status === ShippingStatus.NotNeeded) {
    return <Tag>{formatMessage({ id: 'Not Needed' })}</Tag>;
  }
  return <Tag color="success">{formatMessage({ id: 'Sent' })}</Tag>;
};

export default ShippingStatusTag;
