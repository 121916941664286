import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { InfoIcon } from 'assets/icons';

import './statisticHeader.less';

interface StatisticHeaderPropsI {
  title: ReactNode;
  valueName: string;
  tooltip: boolean;
  tooltipText: string;
  value: number | string;
  secondValueName?: string;
  secondValue?: number | string;
}

const StatisticHeader = ({
  title,
  value,
  valueName,
  secondValue,
  tooltip,
  tooltipText,
  secondValueName,
}: StatisticHeaderPropsI) => (
  <div className="statistic-header">
    {/* Title with tooltip */}
    <div className="statistic-header__title">
      {title}{' '}
      {tooltip && (
        <Tooltip title={tooltipText} trigger="click">
          <InfoIcon className="statistic-header__icon" />
        </Tooltip>
      )}
    </div>

    {/* First value */}
    <div className="statistic-header__values">
      <div className="statistic-header__info">
        <div className="statistic-header__value-name">{valueName}</div>
        <div className="statistic-header__value">{value}</div>
      </div>

      {/* Second value */}
      {secondValueName && secondValue && (
        <div className="statistic-header__info">
          <div className="statistic-header__value-name">{secondValueName}</div>
          <div className="statistic-header__value">{secondValue}</div>
        </div>
      )}
    </div>
  </div>
);

export default StatisticHeader;
