import { useState } from 'react';

const useFilters = (resetPagination?: () => void, initialValue?: any) => {
  const [filterBy, changeFilterBy] = useState<any>(initialValue);

  const reset = () => (resetPagination ? resetPagination() : null);

  const setFilterBy = (filteredBy: any) => {
    changeFilterBy(filteredBy);
    reset();
  };

  return [filterBy, setFilterBy];
};

export { useFilters };
