import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

const useNow = (format?: string) => {
  const getNow = useCallback(() => moment().format(format), [format]);

  const [now, setNow] = useState(getNow);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(getNow);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [getNow]);

  return now;
};

export { useNow };
