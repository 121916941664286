import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Input, Row, Col, Select, Divider, Checkbox, Radio } from 'antd';
import {
  ProductFragment,
  ProductStatus,
  useGetProductsQuery,
  useGetProductUnitsQuery,
  useGetProjectsQuery,
} from 'api';
import { FormInstance } from 'antd/lib/form';
import { NumberInput } from 'components';

import './addonForm.less';

const { Item: FormItem } = Form;
const { Group: RadioGroup } = Radio;

interface AddonFormPropsI {
  product?: ProductFragment | null;
  initialValues?: {
    projectId?: string | null;
    isActive?: boolean | null;
    isCreditCheckRequired?: boolean | null;
    addonName?: string | null;
    price?: number | null;
    vatRate?: number | null;
    isInStok?: boolean | null;
    isPersonIdRequired?: boolean | null;
    canBeTerminated?: boolean | null;
    isStockable?: boolean | null;
    isSubscription?: boolean | null;
    unitId?: string | null;
    licenseDurationMonths?: number | null;
  };
  form: FormInstance;
  fromProductsList?: boolean;
}

const AddonForm = ({ product, initialValues, form, fromProductsList }: AddonFormPropsI) => {
  const [projectValue, setProjectValue] = useState('');

  const { formatMessage } = useIntl();

  const { data: unitsData } = useGetProductUnitsQuery();
  const { data: projectsData } = useGetProjectsQuery({
    skip: !fromProductsList,
  });
  const { data: getProductsData } = useGetProductsQuery({
    variables: {
      filter: {
        statusEq: ProductStatus.Active,
        projectIdIn: [projectValue],
      },
    },
    skip: projectValue === '',
  });

  const handleProjectChange = () => {
    const projectId = form.getFieldValue('projectId');
    setProjectValue(projectId);
    form.resetFields(['productId']);
  };

  const { name: projectName = '', _id: projectId = '' } = product?.project || {};
  const units = unitsData?.getProductUnits?.nodes || [];
  const projects = projectsData?.getProjects.nodes || [];
  const formattedProjectOptions = projects.map(({ _id, name }) => ({
    value: _id,
    label: name,
  }));
  const projectOptions = fromProductsList
    ? formattedProjectOptions
    : [
        {
          label: projectName,
          value: projectId,
        },
      ];
  const products = getProductsData?.getProducts?.nodes || [];
  const productsOptions = products.map(({ _id, name }) => ({
    value: _id,
    label: name,
  }));

  const handlePriceChange = useCallback(() => {
    const vat = form.getFieldValue('vatRate');
    const price = form.getFieldValue('price');

    if (!price) {
      return form.setFieldsValue({ priceIncl: '' });
    }

    if (!vat) {
      return form.setFieldsValue({ priceIncl: price });
    }

    return form.setFieldsValue({
      priceIncl: Number(((price * (vat + 100)) / 100).toFixed(2)),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handlePriceChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePriceVATChange = useCallback(() => {
    const vat = form.getFieldValue('vatRate');
    const priceIncl = form.getFieldValue('priceIncl');

    if (!vat) {
      return form.setFieldsValue({ price: priceIncl });
    }

    return form.setFieldsValue({
      price: Number(((priceIncl * 100) / (vat + 100)).toFixed(2)),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      className="addon-form"
      form={form}
      layout="vertical"
      preserve={false}
      requiredMark={false}
      initialValues={initialValues}
    >
      <Row gutter={5}>
        {/* Addon name */}
        <Col span={fromProductsList ? 24 : 12}>
          <FormItem
            name="addonName"
            label={formatMessage({ id: 'Addon name' })}
            rules={[
              {
                type: 'string',
                max: 50,
                message: formatMessage({ id: 'validate.maxString' }, { max: 50 }),
              },
              { whitespace: true, required: true },
            ]}
          >
            <Input
              placeholder={formatMessage({
                id: 'placeholder.Type product name',
              })}
            />
          </FormItem>
        </Col>

        {/* Project */}
        <Col span={12}>
          <FormItem
            name="projectId"
            label={formatMessage({ id: 'Select Project' })}
            rules={[{ required: true }]}
          >
            <Select
              disabled={!fromProductsList}
              placeholder={formatMessage({
                id: 'placeholder.Select project',
              })}
              options={projectOptions}
              onChange={handleProjectChange}
            />
          </FormItem>
        </Col>

        {/* Product */}
        {fromProductsList && (
          <Col span={12}>
            <FormItem
              name="productId"
              label={formatMessage({ id: 'Select product' })}
              rules={[{ required: true }]}
            >
              <Select
                disabled={!projectValue}
                placeholder={formatMessage({
                  id: 'placeholder.Select product',
                })}
                options={productsOptions}
              />
            </FormItem>
          </Col>
        )}
      </Row>

      <Row gutter={5}>
        {/* Price excl. VAT */}
        <Col span={8}>
          <FormItem
            name="price"
            label={formatMessage({ id: 'Price excl. VAT(kr)' })}
            rules={[{ required: true }]}
          >
            <NumberInput
              min={1}
              precision={2}
              onChange={handlePriceChange}
              placeholder={formatMessage({
                id: 'placeholder.Type price excl. VAT',
              })}
            />
          </FormItem>
        </Col>

        {/* VAT rate */}
        <Col span={8}>
          <FormItem
            name="vatRate"
            label={formatMessage({ id: 'VAT rate(%)' })}
            rules={[{ required: true }]}
            validateFirst
          >
            <NumberInput
              min={0}
              onChange={handlePriceChange}
              placeholder={formatMessage({ id: 'placeholder.Type VAT rate' })}
            />
          </FormItem>
        </Col>

        {/* Price incl. VAT */}
        <Col span={8}>
          <FormItem
            name="priceIncl"
            label={formatMessage({ id: 'Price incl. VAT(kr)' })}
            rules={[{ required: true }]}
          >
            <NumberInput
              min={1}
              precision={2}
              onChange={handlePriceVATChange}
              placeholder={formatMessage({
                id: 'Price incl. VAT',
              })}
            />
          </FormItem>
        </Col>
      </Row>

      <Divider className="addon-form__divider" />

      {/* Form checkboxes */}
      <div className="addon-form__checkboxes">
        {/* Is credit check required checkbox */}
        <FormItem
          className="addon-form__checkbox"
          name="isCreditCheckRequired"
          valuePropName="checked"
        >
          <Checkbox className="no-wrap">{formatMessage({ id: 'Credit check required' })}</Checkbox>
        </FormItem>

        {/* Is active checkbox */}
        <FormItem className="addon-form__checkbox" name="isActive" valuePropName="checked">
          <Checkbox className="no-wrap">{formatMessage({ id: 'Is active' })}</Checkbox>
        </FormItem>

        {/* Is active checkbox */}
        <FormItem
          className="addon-form__checkbox"
          name="isPersonIdRequired"
          valuePropName="checked"
        >
          <Checkbox className="no-wrap">{formatMessage({ id: 'Personnummer required' })}</Checkbox>
        </FormItem>

        {/* Is subscription checkbox */}
        <FormItem className="addon-form__checkbox" name="isSubscription" valuePropName="checked">
          <Checkbox className="no-wrap">{formatMessage({ id: 'Is subscription' })}</Checkbox>
        </FormItem>

        {/* Can be terminated checkbox */}
        <FormItem shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const isSubscription = getFieldValue('isSubscription');

            return (
              isSubscription && (
                <FormItem
                  className="addon-form__checkbox"
                  name="canBeTerminated"
                  valuePropName="checked"
                >
                  <Checkbox className="no-wrap">
                    {formatMessage({ id: 'Can be terminated' })}
                  </Checkbox>
                </FormItem>
              )
            );
          }}
        </FormItem>
      </div>

      <FormItem shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const isSubscription = getFieldValue('isSubscription');

          return (
            !isSubscription && (
              <FormItem
                name="unitId"
                label={formatMessage({ id: 'Unit' })}
                rules={[{ required: true }]}
              >
                <RadioGroup>
                  {units.map(({ _id, title }: { _id: string; title: string }) => (
                    <Radio key={_id} value={_id}>
                      {title}
                    </Radio>
                  ))}
                </RadioGroup>
              </FormItem>
            )
          );
        }}
      </FormItem>

      {/* Stockable */}
      <FormItem
        name="isStockable"
        label={formatMessage({ id: 'Stockable product' })}
        rules={[{ required: true }]}
      >
        <RadioGroup>
          <Radio value>{formatMessage({ id: 'Yes' })}</Radio>
          <Radio value={false}>{formatMessage({ id: 'No' })}</Radio>
        </RadioGroup>
      </FormItem>

      {/* Is stock */}
      <FormItem
        name="isInStok"
        label={formatMessage({ id: 'Is it in stock' })}
        rules={[{ required: true }]}
      >
        <RadioGroup>
          <Radio value>{formatMessage({ id: 'Yes' })}</Radio>
          <Radio value={false}>{formatMessage({ id: 'No' })}</Radio>
        </RadioGroup>
      </FormItem>
    </Form>
  );
};

export default AddonForm;
