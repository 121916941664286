import { ReactNode } from 'react';
import { notification } from 'antd';

interface NotificationPropsI {
  type: 'success' | 'warning' | 'error' | 'info';
  title?: string | ReactNode;
  message: string | ReactNode;
  duration?: number;
}

const Notification = ({ type, title, message, duration }: NotificationPropsI) => {
  notification[type]({
    message: title,
    description: message,
    placement: 'topRight',
    duration,
  });
};

export default Notification;
