import { useState, useEffect, useCallback } from 'react';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { useB2COrdersFilterContext } from 'contexts';

const useB2cOrdersPagination = (isPramsInPath = true) => {
  const navigate = useNavigate();

  const { localRecordsLimit } = useB2COrdersFilterContext();
  const { pathname, search } = useLocation();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(localRecordsLimit || 10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    if (localRecordsLimit) {
      const { page } = queryString.parse(search);
      setLimit(localRecordsLimit);
      if (page) {
        const res =
          Number(page) <= Math.floor(totalRecords / localRecordsLimit)
            ? Number(page)
            : Math.floor(totalRecords / localRecordsLimit);
        if (res < 1) {
          fetchMore(1);
        } else {
          fetchMore(res);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localRecordsLimit]);

  const fetchMore = useCallback(
    (page: number) => {
      const resPage = totalRecords && limit > totalRecords ? 1 : page;
      const resSkip =
        totalRecords && limit > totalRecords ? 0 : (page - 1) * limit;

      setCurrentPage(resPage);
      setSkip(resSkip);

      if (isPramsInPath) {
        const queryParams = queryString.parse(search);
        const newParams = { ...queryParams, page: resPage };

        if (page > 1) {
          return navigate(`${pathname}?${queryString.stringify(newParams)}`);
        }

        return navigate(pathname);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPramsInPath, pathname, search],
  );

  const setTotalForPagination = (totalInput: number) => {
    if (totalInput) {
      setTotalRecords(totalInput);
    }
  };

  const setPaginationCurrentPage = (current: number) => {
    setCurrentPage(current);
  };

  const resetPagination = () => {
    setSkip(0);
    setCurrentPage(1);
    if (search !== '') {
      navigate(pathname);
    }
  };

  useEffect(() => {
    const { page } = queryString.parse(search);
    if (page) {
      fetchMore(Number(page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore, search]);

  return {
    limit,
    skip,
    currentPage,
    fetchMore,
    resetPagination,
    setPaginationCurrentPage,
    setTotalForPagination,
  };
};

export { useB2cOrdersPagination };
