import React, { createContext, useContext } from 'react';
import { useMeQuery, TeamFragment } from 'api';

interface UserContextI {
  userId?: string;
  userName?: string;
  userRoleName?: string;
  userProjectId?: string;
  userIsSuperAdmin?: boolean;
  permissionsList?: string[];
  userSalesChannelId?: string;
  userCanSeeOtherSearchHistory?: boolean;
  userTeam?: TeamFragment;
  onlyTeamData?: boolean;
}

const userContext = createContext<UserContextI>({});

const { Provider } = userContext;

const UserProvider = (props: any) => {
  const { data: meData, loading: meDataLoading } = useMeQuery({
    fetchPolicy: 'no-cache',
  });

  const userId = meData?.me?._id;
  const userName = meData?.me?.fullName;
  const userRoleName = meData?.me?.role?.name;
  const userIsSuperAdmin = meData?.me?.role?.isSuper;
  const permissionsList = meData?.me?.role?.permissionIds;
  const userProjectId = meData?.me?.projects[0]?.projectId;
  const userSalesChannelId = meData?.me?.projects[0]?.salesChannelId;
  const userCanSeeOtherSearchHistory =
    meData?.me?.role?.canSeeOtherSearchHistory;
  const userTeam = meData?.me?.team;
  const onlyTeamData = Boolean(meData?.me.role.onlyTeamData);

  if (meDataLoading) {
    return null;
  }

  return (
    <Provider
      value={{
        userId,
        userName,
        userRoleName,
        userProjectId,
        permissionsList,
        userIsSuperAdmin,
        userSalesChannelId,
        userCanSeeOtherSearchHistory,
        userTeam,
        onlyTeamData,
      }}
      {...props}
    />
  );
};

const useUserContext = () => useContext(userContext);

export { UserProvider, userContext, useUserContext };
