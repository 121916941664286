import { useEffect, useState } from 'react';
import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { transformDateEnd, transformDateStart } from 'utils';

interface LicenseRangeDateI {
  licenseStartDateFrom?: Moment | null;
  licenseStartDateTo?: Moment | null;
  licenseEndDateFrom?: Moment | null;
  licenseEndDateTo?: Moment | null;
}

const useLicenseRangeFilter = (resetPagination?: () => void) => {
  const [rangeDates, changeRangeDates] = useState<
    Moment[] | RangeValue<Moment> | null
  >(null);
  const [rangeType, setRangeType] = useState<string>('end');
  const [licenseRangeFilter, setLicenseRangeFilter] = useState<
    LicenseRangeDateI
  >({});

  const reset = () => (resetPagination ? resetPagination() : null);

  const setRangeDates = (dates: Moment[] | RangeValue<Moment> | null) => {
    changeRangeDates(dates);

    if (!dates) {
      setLicenseRangeFilter({});
      return reset();
    }

    const [startDate, endDate] = dates;

    if (startDate && endDate) {
      const dayStart = transformDateStart(startDate);
      const dayEnd = transformDateEnd(endDate);

      reset();

      switch (rangeType) {
        case 'end':
        default:
          return setLicenseRangeFilter({
            licenseEndDateFrom: dayStart,
            licenseEndDateTo: dayEnd,
          });
        case 'start':
          return setLicenseRangeFilter({
            licenseStartDateFrom: dayStart,
            licenseStartDateTo: dayEnd,
          });
        case 'duration':
          return setLicenseRangeFilter({
            licenseStartDateFrom: dayStart,
            licenseEndDateTo: dayEnd,
          });
      }
    }
  };

  useEffect(() => {
    if (rangeDates) {
      setRangeDates(rangeDates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeType]);

  return { licenseRangeFilter, setRangeDates, setRangeType };
};

export { useLicenseRangeFilter };
