import { useState, useEffect, useCallback } from 'react';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { useB2COrdersFilterContext } from 'contexts';

const usePagination = (isPramsInPath = true) => {
  const { orderType, localRecordsLimit } = useB2COrdersFilterContext();

  const [limit, setLimit] = useState(() =>
    orderType === 'B2C' && localRecordsLimit && localRecordsLimit > 10 ? localRecordsLimit : 10,
  );
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [skip, setSkip] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchMore = useCallback(
    (page: number) => {
      setSkip((page - 1) * limit);
      setCurrentPage(page);

      if (isPramsInPath) {
        const queryParams = queryString.parse(search);
        const newParams = { ...queryParams, page };
        if (page > 1) {
          return navigate(`${pathname}?${queryString.stringify(newParams)}`);
        }

        return navigate(pathname);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPramsInPath, pathname, search],
  );

  const resetPagination = () => {
    setSkip(0);
    setCurrentPage(1);
    if (search !== '') {
      navigate(pathname);
    }
  };

  const onShowSizeChange = (value?: number) => {
    if (value) {
      setLimit(value);
      resetPagination();
    }
  };

  useEffect(() => {
    const { page } = queryString.parse(search);

    if (page) {
      fetchMore(+page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore, search]);

  return {
    limit,
    skip,
    currentPage,
    fetchMore,
    resetPagination,
    onShowSizeChange,
  };
};

export { usePagination };
