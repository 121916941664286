import React, { useMemo } from 'react';
import {
  RoleFragment,
  TeamFragment,
  CommissionStatus,
  CommissionFragment,
  TeamMemberFragment,
  SalesChannelFragment,
  useUpdateCommissionMutation,
} from 'api';
import { Form, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { useErrorsContext } from 'contexts';
import { Title, CommissionForm } from 'components';

const { useForm } = Form;

interface EditCommissionModalPropsI {
  visible?: boolean;
  refetch: () => void;
  onCancel: () => void;
  teams: TeamFragment[];
  roles: RoleFragment[];
  commission?: CommissionFragment;
  teamMembers: TeamMemberFragment[];
  salesChannels: SalesChannelFragment[];
}

interface EditCommissionFormValuesI {
  qty?: number;
  size?: number;
  teamId?: string | null;
  roleId?: string | null;
  teamMemberId?: string | null;
  salesChannelId?: string | null;
}

const EditCommissionModal = ({
  teams,
  roles,
  visible,
  refetch,
  onCancel,
  commission,
  teamMembers,
  salesChannels,
}: EditCommissionModalPropsI) => {
  const [form] = useForm();

  const { setErrors } = useErrorsContext();

  const { formatMessage } = useIntl();

  const [updateCommission, { loading }] = useUpdateCommissionMutation({
    onCompleted({ updateCommission }) {
      const { status, errors } = updateCommission || {};
      const success = status === 'SUCCESS';

      if (success) {
        refetch();
        return onCancel();
      }

      setErrors(errors);
    },
  });

  const {
    _id = '',
    qty,
    size,
    teamId,
    roleId,
    teamMemberId,
    salesChannelId,
  } = commission || {};

  const initialValues = useMemo(() => {
    if (teamMemberId) {
      return {
        commissionType: 'byUser',
        qty,
        size,
        teamMemberId,
      };
    }

    if (roleId) {
      return {
        commissionType: 'byRole',
        qty,
        size,
        roleId,
        salesChannelId,
      };
    }

    return {
      commissionType: 'byTeam',
      qty,
      size,
      teamId,
      salesChannelId,
    };
  }, [qty, roleId, salesChannelId, size, teamId, teamMemberId]);

  const handleSubmit = ({
    qty,
    size = 0,
    teamId = null,
    roleId = null,
    teamMemberId = null,
    salesChannelId = null,
  }: EditCommissionFormValuesI) => {
    updateCommission({
      variables: {
        input: {
          _id,
          qty: qty || 0,
          size,
          teamId,
          roleId,
          teamMemberId,
          salesChannelId,
          status: CommissionStatus.Active,
        },
      },
    });
  };

  return (
    <Modal
      className="edit-commission-modal"
      width={360}
      open={visible}
      title={
        <Title size="middle">{formatMessage({ id: 'Edit commission' })}</Title>
      }
      okText={formatMessage({ id: 'button.Save' })}
      cancelText={formatMessage({ id: 'button.Cancel' })}
      onCancel={onCancel}
      onOk={() => form.validateFields().then(handleSubmit)}
      confirmLoading={loading}
      destroyOnClose
    >
      <CommissionForm
        form={form}
        teams={teams}
        roles={roles}
        teamMembers={teamMembers}
        salesChannels={salesChannels}
        initialValues={initialValues}
      />
    </Modal>
  );
};

export default EditCommissionModal;
