import { RbacResourceName } from 'api';
import {
  UsersIcon,
  TeamsIcon,
  OrdersIcon,
  SoSafeIcon,
  BatchesIcon,
  BundlesIcon,
  ProjectsIcon,
  ProductsIcon,
  PartnersIcon,
  LicensesIcon,
  ShippingIcon,
  DashboardIcon,
  CustomersIcon,
  PlusIcon,
} from 'assets/icons';

export const myTeamRouteConfig = {
  name: 'My team',
  route: '/my-team',
  action: RbacResourceName.TeamView,
  icon: TeamsIcon,
};

export const navigation = [
  [
    {
      name: 'New order',
      route: '/orders/create',
      action: [RbacResourceName.OrderView, RbacResourceName.B2BOrderView],
      icon: PlusIcon,
    },
  ],
  [
    {
      name: 'Dashboard',
      route: '/dashboard',
      action: RbacResourceName.DashboardView,
      icon: DashboardIcon,
    },
  ],
  [
    {
      name: 'System users',
      route: '/users',
      action: RbacResourceName.TeamMemberView,
      icon: UsersIcon,
    },
    {
      name: 'Teams',
      route: '/teams',
      action: RbacResourceName.TeamView,
      icon: TeamsIcon,
    },
  ],
  [
    {
      name: 'Projects',
      route: '/projects',
      action: RbacResourceName.ProjectView,
      icon: ProjectsIcon,
    },
    {
      name: 'Products',
      route: '/products',
      action: RbacResourceName.ProductView,
      icon: ProductsIcon,
    },
    {
      name: 'Bundles',
      route: '/bundles',
      action: RbacResourceName.ProductBundleView,
      icon: BundlesIcon,
    },
  ],
  [
    {
      name: 'Customers',
      route: '/customers',
      action: RbacResourceName.CustomerView,
      icon: CustomersIcon,
    },
    {
      name: 'Orders',
      route: '/orders',
      action: [RbacResourceName.OrderView, RbacResourceName.B2BOrderView],
      icon: OrdersIcon,
    },
    {
      name: 'Shipping',
      route: '/shipping',
      action: [
        RbacResourceName.OrderShipmentView,
        RbacResourceName.B2BOrderShipmentView,
      ],
      icon: ShippingIcon,
    },
  ],
  [
    {
      name: 'Licenses',
      route: '/licenses',
      action: [
        RbacResourceName.OrderLicenseView,
        RbacResourceName.BatchLicenseView,
      ],
      icon: LicensesIcon,
    },
    {
      name: 'SoSafe users',
      route: '/soSafe',
      action: [
        RbacResourceName.OrderExternalUsersView,
        RbacResourceName.BatchExternalUsersView,
      ],
      icon: SoSafeIcon,
    },
  ],
  [
    {
      name: 'Partner stores',
      route: '/partner-stores',
      action: RbacResourceName.PartnerStoreView,
      icon: PartnersIcon,
    },
    {
      name: 'Batches',
      route: '/batches',
      action: RbacResourceName.LicenseBatchView,
      icon: BatchesIcon,
    },
  ],
];
