import { useState } from 'react';
import { useDebounce } from './useDebounce';

const useSearch = (resetPagination?: () => void) => {
  const [valueToSearch, setValueToSearch] = useState<string>();
  const searchValue = useDebounce(valueToSearch);

  const reset = () => (resetPagination ? resetPagination() : null);

  const setSearchValue = (value: string) => {
    reset();
    setValueToSearch(value);
  };

  return [searchValue, setSearchValue];
};

export { useSearch };
