import { useIntl } from 'react-intl';
import { LicenseStatus as Status } from 'api';

import './LicenseStatusTag.less';

const LicenseStatusTag = ({ status }: { status?: string }) => {
  const { formatMessage } = useIntl();

  if (status === Status.Active) {
    return (
      <div className="license-status license-status--active">{formatMessage({ id: 'Active' })}</div>
    );
  }

  if (status === Status.Regretted) {
    return (
      <div className="license-status license-status--regretted">
        {formatMessage({ id: 'Regretted' })}
      </div>
    );
  }
  if (status === Status.Expired) {
    return (
      <div className="license-status license-status--expired">
        {formatMessage({ id: 'Expired' })}
      </div>
    );
  }

  return (
    <div className="license-status license-status--terminated">
      {formatMessage({ id: 'Terminated' })}
    </div>
  );
};

export default LicenseStatusTag;
