const getToken = (type?: string): string => {
  const auth = localStorage.getItem('auth');
  if (!auth) {
    return '';
  }
  const {
    accessToken: { token },
    refreshToken: { token: refresh },
  } = JSON.parse(auth);

  return type === 'refresh' ? JSON.stringify(refresh) : token;
};

const fetchRefreshToken = (): Promise<Response> =>
  fetch(process.env.REACT_APP_GRAPHQL || '', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      query: `mutation {
					refreshToken(input: {refreshToken: ${
            getToken('refresh') || JSON.stringify('')
          }}){
						status
						tokens {
							accessToken {
								expiresIn
								token
							}
							refreshToken {
								expiresIn
								token
							}
						}
					}
				}
				`,
    }),
  });

const logOut = (): void => {
  localStorage.removeItem('auth');
  localStorage.removeItem('dashboardFilters');
  window.location.href = '/login';
};

export { getToken, fetchRefreshToken, logOut };
