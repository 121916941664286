import React, { useMemo } from 'react';
import {
  ProductAddonStatus,
  ProductAddonFragment,
  useUpdateProductAddonMutation,
} from 'api';
import { Form, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { useErrorsContext } from 'contexts';
import { Title, AddonForm } from 'components';

const { useForm } = Form;

interface EditAddonModalPropsI {
  visible?: boolean;
  refetch: () => void;
  onCancel: () => void;
  addon?: ProductAddonFragment;
}

interface EditProductAddonValuesI {
  addonName?: string;
  price?: number;
  unitId?: string;
  vatRate?: number;
  isActive?: boolean;
  isPersonIdRequired?: boolean;
  isInStok?: boolean;
  freeMonths?: number;
  description?: string;
  isStockable?: boolean;
  hasFreeMonths?: boolean;
  isSubscription?: boolean;
  canBeTerminated?: boolean;
  licenseDurationMonths?: number;
  isCreditCheckRequired?: boolean;
}

const EditAddonModal = ({
  visible,
  refetch,
  onCancel,
  addon,
}: EditAddonModalPropsI) => {
  const [form] = useForm();

  const { setErrors } = useErrorsContext();

  const { formatMessage } = useIntl();

  const [updateProductAddon, { loading }] = useUpdateProductAddonMutation({
    onCompleted({ updateProductAddon }) {
      const { status, errors } = updateProductAddon || {};
      const success = status === 'SUCCESS';

      if (success) {
        refetch();
        return onCancel();
      }

      setErrors(errors);
    },
  });

  const {
    _id = '',
    status = '',
    name = '',
    isSubscription,
    ...rest
  } = addon || {};

  const initialValues = useMemo(
    () => ({
      ...rest,
      addonName: name,
      isActive: status === ProductAddonStatus.Active,
      isSubscription,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addon],
  );

  const handleSubmit = ({
    unitId,
    addonName,
    canBeTerminated,
    isActive,
    isCreditCheckRequired,
    isInStok,
    isStockable,
    isSubscription,
    price,
    vatRate,
    isPersonIdRequired,
  }: EditProductAddonValuesI) => {
    updateProductAddon({
      variables: {
        input: {
          _id,
          name: addonName,
          price,
          vatRate,
          isInStok,
          isPersonIdRequired,
          canBeTerminated,
          isStockable,
          isSubscription,
          unitId,
          // TODO: remove when BE ready
          licenseDurationMonths: 1,
          freeMonths: 0,
          isCreditCheckRequired,
          status: isActive
            ? ProductAddonStatus.Active
            : ProductAddonStatus.Inactive,
        },
      },
    });
  };

  return (
    <Modal
      className="edit-addon-modal"
      width={460}
      open={visible}
      title={<Title size="middle">{formatMessage({ id: 'Edit addon' })}</Title>}
      okText={formatMessage({ id: 'button.Save' })}
      cancelText={formatMessage({ id: 'button.Cancel' })}
      onCancel={onCancel}
      onOk={() => form.validateFields().then(handleSubmit)}
      confirmLoading={loading}
      destroyOnClose
    >
      <AddonForm
        form={form}
        product={addon?.product}
        initialValues={initialValues}
      />
    </Modal>
  );
};

export default EditAddonModal;
