/* eslint-disable no-new */
import { intl } from 'contexts';
import Compressor from 'compressorjs';
import { Notification } from 'components';

const fileSizeLimit = (limitMb: number, file: File) => {
  const { name: fileName, size } = file;

  const isLimit = size / 1024 / 1024 < limitMb;

  if (!isLimit) {
    Notification({
      type: 'error',
      title: intl.formatMessage({ id: 'notification.error' }),
      message: intl.formatMessage(
        { id: 'notification.sizeLimitError' },
        { fileName, limitMb }
      ),
    });
  }

  return isLimit;
};

const compressImage = (file: File) =>
  new Promise((resolve) => {
    new Compressor(file, {
      quality: 0.7,
      maxWidth: 160,
      maxHeight: 160,
      success(result) {
        resolve(result);
      },
      error() {
        Notification({
          type: 'error',
          title: intl.formatMessage({ id: 'notification.error' }),
          message: intl.formatMessage({ id: 'notification.noCompressed' }),
        });
      },
    });
  });

export { fileSizeLimit, compressImage };
