import { RoleFragment, TeamFragment, TeamMemberFragment, SalesChannelFragment } from 'api';
import { Form, Row, Col } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { NumberInput, Select, Option } from 'components';

const { Item: FormItem } = Form;

const commissionTypeOptions = [
  { value: 'byTeam', label: <FormattedMessage id="By team" /> },
  { value: 'byRole', label: <FormattedMessage id="By role" /> },
  { value: 'byUser', label: <FormattedMessage id="By user" /> },
];

interface CommissionFormPropsI {
  teams: TeamFragment[];
  roles: RoleFragment[];
  teamMembers: TeamMemberFragment[];
  salesChannels: SalesChannelFragment[];
  form: FormInstance;
  initialValues?: {
    commissionType?: string;
    size?: number;
    qty?: number;
    teamId?: string | null;
    roleId?: string | null;
    teamMemberId?: string | null;
    salesChannelId?: string | null;
  };
}

const CommissionForm = ({
  form,
  teams,
  roles,
  teamMembers,
  initialValues,
  salesChannels,
}: CommissionFormPropsI) => {
  const { formatMessage } = useIntl();

  return (
    <Form
      className="commission-form"
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
    >
      <FormItem
        name="commissionType"
        label={formatMessage({ id: 'Commission type' })}
        rules={[{ required: true }]}
      >
        <Select options={commissionTypeOptions} />
      </FormItem>

      <FormItem shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const commissionType = getFieldValue('commissionType');

          if (commissionType === 'byTeam') {
            return (
              <FormItem
                name="teamId"
                label={formatMessage({ id: 'Team' })}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={formatMessage({
                    id: 'placeholder.Select team',
                  })}
                  allowClear
                >
                  {teams.map(({ _id, name }) => (
                    <Option key={_id} value={_id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            );
          }

          if (commissionType === 'byRole') {
            return (
              <FormItem
                name="roleId"
                label={formatMessage({ id: 'Role' })}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={formatMessage({
                    id: 'placeholder.Select role',
                  })}
                  allowClear
                >
                  {roles.map(({ _id, name }) => (
                    <Option key={_id} value={_id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            );
          }

          if (commissionType === 'byUser') {
            return (
              <FormItem
                name="teamMemberId"
                label={formatMessage({ id: 'User' })}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={formatMessage({
                    id: 'placeholder.Select user',
                  })}
                  optionFilterProp="children"
                  showSearch
                  allowClear
                >
                  {teamMembers.map(({ _id, fullName }) => (
                    <Option key={_id} value={_id}>
                      {fullName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            );
          }
        }}
      </FormItem>

      <Row gutter={5}>
        <Col span={12}>
          <FormItem name="qty" label={formatMessage({ id: 'Quantity' })}>
            <NumberInput min={1} placeholder={formatMessage({ id: 'placeholder.Type quantity' })} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name="size"
            label={formatMessage({ id: 'Commission(kr)' })}
            rules={[{ required: true }]}
          >
            <NumberInput
              min={1}
              precision={2}
              placeholder={formatMessage({ id: 'placeholder.Type commission' })}
            />
          </FormItem>
        </Col>
      </Row>

      <FormItem shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const commissionType = getFieldValue('commissionType');

          return commissionType !== 'byUser' ? (
            <FormItem name="salesChannelId" label={formatMessage({ id: 'Sales channel' })}>
              <Select
                placeholder={formatMessage({
                  id: 'placeholder.Select sales channel',
                })}
                allowClear
              >
                {salesChannels.map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          ) : null;
        }}
      </FormItem>
    </Form>
  );
};

export default CommissionForm;
