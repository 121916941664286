import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { BatchFragment } from 'api';
import { defaultDateFormat } from 'utils';
import { Column, Table } from 'components';
import { DownloadIcon } from 'assets/icons';
import { LoadingOutlined } from '@ant-design/icons';

import './batchesTable.less';

interface BatchesTablePropsI {
  limit: number;
  total?: number;
  loading: boolean;
  currentPage: number;
  batches: BatchFragment[];
  hasPartnerStore?: boolean;
  fetchMore: (page: number) => void;
  onChange: (pagination: any, filters: any, sorter: any) => void;
}

const BatchesTable = ({
  total,
  limit,
  loading,
  batches,
  onChange,
  fetchMore,
  currentPage,
  hasPartnerStore,
}: BatchesTablePropsI) => {
  const { formatMessage } = useIntl();

  return (
    <div className="batches-table">
      <Table
        dataSource={batches}
        currentPage={currentPage}
        total={total}
        limit={limit}
        fetchMore={fetchMore}
        rowKey="_id"
        onChange={onChange}
        loading={loading}
      >
        <Column title={formatMessage({ id: 'Batch ID' })} dataIndex="_id" />
        <Column
          title={formatMessage({ id: 'Creation date' })}
          dataIndex="createdAt"
          render={(createdAt: Date) => defaultDateFormat(createdAt)}
          sorter
        />
        <Column
          title={formatMessage({ id: 'Duration' })}
          dataIndex="duration"
          render={(duration: number) => formatMessage({ id: 'monthDuration' }, { duration })}
          sorter
        />
        {hasPartnerStore && (
          <Column
            title={formatMessage({ id: 'Partner store' })}
            dataIndex="partnerStore"
            render={({ name }: { name: string }) => name}
          />
        )}
        <Column
          title={formatMessage({ id: 'Licenses' })}
          dataIndex="licenseCount"
          className="batches-table__bold"
          render={(
            licenseCount: string,
            { activatedLicenseCount }: { activatedLicenseCount: string },
          ) => `${activatedLicenseCount}/${licenseCount}`}
          sorter
        />
        <Column
          title="PDF"
          dataIndex="zipReady"
          align="center"
          render={(zipReady: boolean, { zipLink }: { zipLink: string }) => {
            if (zipReady) {
              return <Button type="link" href={zipLink} icon={<DownloadIcon />} download />;
            }

            return <LoadingOutlined className="batches-table__loading" />;
          }}
        />
      </Table>
    </div>
  );
};

export default BatchesTable;
