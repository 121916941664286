import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { ProductStatus, useGetProductsQuery, useGetPartnerStoresQuery } from 'api';
import { useIntl } from 'react-intl';
import { FormInstance } from 'antd/lib/form';
import { Modal, Form, Row, Col } from 'antd';
import { Title, NumberInput, Option, Select } from 'components';

const { Item: FormItem } = Form;

interface GenerateBatchesModalPropsI {
  visible?: boolean;
  loading?: boolean;
  form: FormInstance;
  initialValues?: {
    partnerStoreId: string;
  };
  onCancel: () => void;
  onSubmit: (values: any) => void;
}

const GenerateBatchesModal = ({
  form,
  visible,
  loading,
  initialValues,
  onCancel,
  onSubmit,
}: GenerateBatchesModalPropsI) => {
  const [projectId, setProjectId] = useState<string>();

  const { formatMessage } = useIntl();

  const { data: getPartnerStoresData } = useGetPartnerStoresQuery({
    skip: !visible,
  });
  const { data: getProductsData } = useGetProductsQuery({
    variables: {
      filter: {
        statusEq: ProductStatus.Active,
        projectIdIn: projectId ? [projectId] : null,
      },
    },
    skip: !visible,
  });

  const partnerStores = useMemo(
    () => getPartnerStoresData?.getPartnerStores?.nodes || [],
    [getPartnerStoresData?.getPartnerStores?.nodes],
  );
  const products = getProductsData?.getProducts?.nodes || [];

  const handlePartnerStoreChange = useCallback(
    (partnerStoreId: string) => {
      const partnerStore = partnerStores.find(({ _id }: { _id: string }) => partnerStoreId === _id);

      form.resetFields(['productId']);

      setProjectId(partnerStore?.projectId);
    },
    [form, partnerStores],
  );

  useEffect(() => {
    if (initialValues?.partnerStoreId) {
      handlePartnerStoreChange(initialValues?.partnerStoreId);
    }
  }, [handlePartnerStoreChange, initialValues]);

  return (
    <Modal
      className="generate-batches-modal"
      width={360}
      open={visible}
      title={<Title size="middle">{formatMessage({ id: 'Generate batch' })}</Title>}
      okText={formatMessage({ id: 'button.Create' })}
      cancelText={formatMessage({ id: 'button.Cancel' })}
      onCancel={onCancel}
      onOk={() => form.validateFields().then(onSubmit)}
      confirmLoading={loading}
      destroyOnClose
    >
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        requiredMark={false}
        preserve={false}
      >
        <Row gutter={5}>
          <Col span={12}>
            <FormItem
              name="partnerStoreId"
              label={formatMessage({ id: 'Partner store name' })}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={formatMessage({
                  id: 'placeholder.Select partner store',
                })}
                onChange={handlePartnerStoreChange}
                disabled={!!initialValues}
                allowClear
              >
                {partnerStores.map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              noStyle
              shouldUpdate={(prev, curr) => prev.partnerStoreId !== curr.partnerStoreId}
            >
              {({ getFieldValue }) => (
                <FormItem
                  name="productId"
                  label={formatMessage({ id: 'Product' })}
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder={formatMessage({
                      id: 'placeholder.Select product',
                    })}
                    disabled={!getFieldValue('partnerStoreId')}
                    allowClear
                  >
                    {products.map(({ _id, name }) => (
                      <Option key={_id} value={_id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={5}>
          <Col span={12}>
            <FormItem
              name="duration"
              label={formatMessage({ id: 'Duration(month)' })}
              rules={[
                {
                  required: true,
                },
                {
                  type: 'number',
                  max: 99,
                  message: formatMessage({ id: 'validate.maxDuration' }, { max: 99 }),
                },
              ]}
            >
              <NumberInput
                min={1}
                placeholder={formatMessage({ id: 'placeholder.Type duration' })}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name="licenseCount"
              label={formatMessage({ id: 'Quantity of licenses' })}
              rules={[
                {
                  required: true,
                },
                {
                  type: 'number',
                  max: 1000,
                  message: formatMessage({ id: 'validate.maxQty' }, { max: 1000 }),
                },
              ]}
            >
              <NumberInput
                min={1}
                placeholder={formatMessage({ id: 'placeholder.Type quantity' })}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default GenerateBatchesModal;
