import React, { createContext, useContext, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { OrderFragment } from 'api';

const INVOICING_FREQUENCY_OPTIONS = [
  { value: 1, label: <FormattedMessage id="Once per month" /> },
  { value: 3, label: <FormattedMessage id="Once per quarter" /> },
];

const INVOICING_TYPE_OPTIONS = [
  { value: 'EMAIL', label: <FormattedMessage id="E-mail" /> },
  { value: 'PRINTOUT', label: <FormattedMessage id="Printout" /> },
];

interface OrderContextI {
  clientInfo?: any;
  clientInfoEntered?: any;
  onChangeClientInfoEntered: (name?: string, value?: string) => void | {};
  onAddNewItemCb: (props: any) => void | {};
  children?: ReactNode;
  updateBundleProductAddons: (id: string, data: CheckboxValueType[], i: number) => void | {};
  updateProductAddons: (id: string, data: CheckboxValueType[]) => void | {};
  form?: any;
  order?: OrderFragment | null;
}

const initialContext: OrderContextI = {
  clientInfo: {
    zipCode: '',
    address: ' ',
    personId: '',
    lastName: '',
    fullName: '',
    firstName: '',
    allFields: [],
  },
  clientInfoEntered: {
    coAddress: '',
    diffInvAddress: false,
    email: '',
    invoicingAddress: '',
    invoicingFrequency: INVOICING_FREQUENCY_OPTIONS[0].value,
    invoicingType: INVOICING_TYPE_OPTIONS[0].value,
    invoicingZip: '',
    phoneNumber: '',
    creditStatus: null,
    creditCheckCode: null,
    customerId: null,
    winbackDuration: null,
    winbackPrice: '',
  },
  order: null,
  onChangeClientInfoEntered: (name?: string, value?: any) => {},
  onAddNewItemCb: (props: any) => {},
  form: { setFieldsValue: (data: any) => {} },
  updateBundleProductAddons: (id: string, data: CheckboxValueType[], i: number) => {},
  updateProductAddons: (id: string, data: CheckboxValueType[]) => {},
};

const orderContext = createContext(initialContext);

const { Provider } = orderContext;

const OrderProvider = ({
  clientInfo,
  clientInfoEntered,
  onChangeClientInfoEntered,
  onAddNewItemCb,
  updateProductAddons,
  updateBundleProductAddons,
  form,
  order,
  ...props
}: OrderContextI) => {
  const value = {
    clientInfo,
    clientInfoEntered,
    onChangeClientInfoEntered,
    onAddNewItemCb,
    updateProductAddons,
    updateBundleProductAddons,
    form,
    order,
  };

  return <Provider value={value} {...props} />;
};

const useOrderContext = () => useContext(orderContext);

export {
  OrderProvider,
  orderContext,
  useOrderContext,
  INVOICING_FREQUENCY_OPTIONS,
  INVOICING_TYPE_OPTIONS,
};
