interface DashboardFilters {
  filterByTeam: null | string;
  filterByUser: null | string;
  filterByRole: null | string;
  filterByProject: null | string;
  filterByChannel: null | string;
  period: string;
}

export function getIsFiltersChanged({
  period,
  filterByTeam,
  filterByUser,
  filterByRole,
  filterByProject,
  filterByChannel,
}: DashboardFilters) {
  if (period !== 'today') {
    return true;
  }

  const isNilFilters = [
    filterByChannel,
    filterByProject,
    filterByRole,
    filterByTeam,
    filterByUser,
  ].every((filter) => filter === null);

  if (!isNilFilters) {
    return true;
  }

  return false;
}
