import { Table } from 'components';
import { ProjectFragment } from 'api';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import './projectsTable.less';
import { Links } from 'settings';

const columns = [
  {
    title: <FormattedMessage id="ID" />,
    dataIndex: '_id',
  },
  {
    title: <FormattedMessage id="Name" />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: <FormattedMessage id="QTY Products" />,
    dataIndex: 'productsCount',
    sorter: true,
  },
  {
    title: <FormattedMessage id="Description" />,
    dataIndex: 'description',
    className: 'projects-table__description',
  },
];

interface ProjectsTablePropsI {
  limit: number;
  total?: number;
  loading: boolean;
  currentPage: number;
  projects: ProjectFragment[];
  fetchMore: (page: number) => void;
  onChange: (pagination: any, filters: any, sorter: any) => void;
}

const ProjectsTable = ({
  total,
  limit,
  loading,
  projects,
  onChange,
  fetchMore,
  currentPage,
}: ProjectsTablePropsI) => {
  const navigate = useNavigate();

  const handleProjectView = (id: string | number) =>
    navigate(generatePath(Links.projects.details, { id }));

  return (
    <div className="projects-table">
      <Table
        dataSource={projects}
        currentPage={currentPage}
        total={total}
        limit={limit}
        fetchMore={fetchMore}
        columns={columns}
        rowKey="_id"
        onRow={({ _id }) => ({
          onClick: () => handleProjectView(_id),
        })}
        onChange={onChange}
        loading={loading}
      />
    </div>
  );
};

export default ProjectsTable;
