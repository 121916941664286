import { Button, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { WarningMan } from 'assets/images';

import './deleteEntityModal.less';

interface DeleteEntityModalPropsI {
  visible?: boolean;
  toggleDeleteModal: () => void;
}

const DeleteEntityModal = ({ visible, toggleDeleteModal }: DeleteEntityModalPropsI) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      className="delete-entity-modal"
      width={360}
      open={visible}
      footer={false}
      onCancel={toggleDeleteModal}
      onOk={toggleDeleteModal}
      destroyOnClose
    >
      <div className="delete-entity-modal__content">
        <WarningMan className="delete-entity-modal__image" />
        <h1 className="delete-entity-modal__title">
          {formatMessage({ id: 'Entity cannot be deleted' })}
        </h1>
        <p className="delete-entity-modal__text">{formatMessage({ id: 'delete.entity.text' })}</p>

        <Button type="primary" onClick={toggleDeleteModal}>
          {formatMessage({ id: 'button.Delete modal' })}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteEntityModal;
