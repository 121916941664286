import React, { ChangeEvent } from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';

import './searchInput.less';

interface SearchInputPropsI {
  className?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}

const SearchInput = ({
  onChange,
  className,
  placeholder,
}: SearchInputPropsI) => {
  const { formatMessage } = useIntl();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <Input
      className={classNames('search-input', className)}
      size="large"
      placeholder={placeholder || formatMessage({ id: 'Search' })}
      onChange={handleChange}
      suffix={<SearchOutlined className="search-input__icon" />}
    />
  );
};

export default SearchInput;
