import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { calculatePercent, transformNumber } from 'utils';

import './statisticNumbers.less';

interface StatisticNumbersPropsI {
  withCounts?: boolean;
  total: number;
  regertQaRevenueData?: number;
  regertCustomerRevenueData?: number;
  regertCustomerArpRevenueData?: number;
  underRegretPeriodRevenue?: number;
  guarantedRevenue?: number;
  underRegretPerionRevenue?: number;
  regretted: number;
  className?: string;
  guaranteed: number;
  regrettedByQA: number;
  underRegretPeriod: number;
  regrettedByCustomer: number;
  regrettedByCustomerArp: number;
}

const StatisticNumbers = ({
  withCounts,
  regertQaRevenueData,
  regertCustomerRevenueData,
  regertCustomerArpRevenueData,
  guarantedRevenue,
  underRegretPerionRevenue,
  className,
  regretted,
  guaranteed,
  regrettedByQA,
  underRegretPeriod,
  regrettedByCustomer,
  regrettedByCustomerArp,
}: StatisticNumbersPropsI) => {
  const { formatMessage } = useIntl();

  const fullTotal =
    Number(regrettedByCustomer) +
    Number(regrettedByCustomerArp) +
    regrettedByQA +
    Number(guaranteed) +
    Number(underRegretPeriod);

  const regrettedPercent = calculatePercent(regretted, fullTotal);
  const guaranteedPercent = calculatePercent(guaranteed, fullTotal);
  const regrettedByQAPercent = calculatePercent(regrettedByQA, fullTotal);

  const underRegretPeriodPercent = calculatePercent(underRegretPeriod, fullTotal);
  const regrettedByCustomerPercent = calculatePercent(regrettedByCustomer, fullTotal);
  const regrettedByCustomerArpPercent = calculatePercent(regrettedByCustomerArp, fullTotal);

  const totalRegretsRevenue = () => {
    let result = 0;
    if (regertQaRevenueData) {
      result += regertQaRevenueData;
    }
    if (regertCustomerArpRevenueData) {
      result += regertCustomerArpRevenueData;
    }
    if (regertCustomerRevenueData) {
      result += regertCustomerRevenueData;
    }
    return result;
  };
  return (
    <div className={classNames('statistic-numbers', className)}>
      {/* Guaranteed info */}
      <div className="statistic-numbers__colored-items">
        <div className="statistic-numbers__item statistic-numbers__item--green">
          <div className="statistic-numbers__container">
            <div className="statistic-numbers__title">{formatMessage({ id: 'Guaranteed' })}</div>
            <div className="statistic-numbers__info">
              {withCounts ? (
                <>
                  <span className="statistic-numbers__value">{guaranteed}</span>
                  <span className="statistic-numbers__percent">({guaranteedPercent})</span>
                  <span className="statistic-numbers__cost-value">
                    {transformNumber(guarantedRevenue || 0)} kr
                  </span>
                </>
              ) : (
                <>
                  <span className="statistic-numbers__value">{transformNumber(guaranteed)} kr</span>
                  <span className="statistic-numbers__percent">({guaranteedPercent})</span>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Under regret period info */}
        <div className="statistic-numbers__item statistic-numbers__item--yellow">
          <div className="statistic-numbers__container">
            <div className="statistic-numbers__title">
              {formatMessage({ id: 'Under regret period' })}
            </div>
            <div className="statistic-numbers__info">
              {withCounts ? (
                <>
                  <span className="statistic-numbers__value">{underRegretPeriod}</span>
                  <span className="statistic-numbers__percent">({underRegretPeriodPercent})</span>
                  <span className="statistic-numbers__cost-value">
                    {transformNumber(underRegretPerionRevenue || 0)} kr
                  </span>
                </>
              ) : (
                <>
                  <span className="statistic-numbers__value">
                    {transformNumber(underRegretPeriod)} kr
                  </span>
                  <span className="statistic-numbers__percent">({underRegretPeriodPercent})</span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="statistic-numbers__item statistic-numbers__item--red">
          <div className="statistic-numbers__container">
            <div className="statistic-numbers__title">{formatMessage({ id: 'Regretted' })}</div>
            <div className="statistic-numbers__info">
              {withCounts ? (
                <>
                  <span className="statistic-numbers__value">
                    {regrettedByCustomer + regrettedByQA + regrettedByCustomerArp}
                  </span>
                  <span className="statistic-numbers__percent">({regrettedPercent})</span>
                  <span className="statistic-numbers__cost-value">
                    {transformNumber(totalRegretsRevenue())} kr
                  </span>
                </>
              ) : (
                <>
                  <span className="statistic-numbers__value">
                    {regrettedByCustomer + regrettedByQA + regrettedByCustomerArp} kr
                  </span>
                  <span className="statistic-numbers__percent">({regrettedPercent})</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="statistic-numbers__regrets-block">
        <div className="statistic-numbers__regrets-titles">
          {withCounts ? (
            <>
              {formatMessage({ id: 'By customer' })}{' '}
              <span>
                {regrettedByCustomer}{' '}
                <span className="statistic-numbers__percent">({regrettedByCustomerPercent})</span>
              </span>
              <span className="statistic-numbers__cost-value">
                {transformNumber(regertCustomerRevenueData || 0)} kr
              </span>
            </>
          ) : (
            <>
              {formatMessage({ id: 'By customer' })}{' '}
              <span>
                {transformNumber(regrettedByCustomer)} kr ({regrettedByCustomerPercent})
              </span>
            </>
          )}
        </div>
        <div className="statistic-numbers__regrets-titles">
          {withCounts ? (
            <>
              {formatMessage({ id: 'By QA' })}{' '}
              <span>
                {regrettedByQA}{' '}
                <span className="statistic-numbers__percent">({regrettedByQAPercent})</span>
              </span>
              <span className="statistic-numbers__cost-value">
                {transformNumber(regertQaRevenueData || 0)} kr
              </span>
            </>
          ) : (
            <>
              {formatMessage({ id: 'By QA' })}{' '}
              <span>
                {transformNumber(regrettedByQA)} kr ({regrettedByQAPercent})
              </span>
            </>
          )}
        </div>
        <div className="statistic-numbers__regrets-titles">
          {withCounts ? (
            <>
              {formatMessage({ id: 'After 14 days' })}{' '}
              <span>
                {regrettedByCustomerArp}{' '}
                <span className="statistic-numbers__percent">
                  ({regrettedByCustomerArpPercent})
                </span>
              </span>
              <span className="statistic-numbers__cost-value">
                {transformNumber(regertCustomerArpRevenueData || 0)} kr
              </span>
            </>
          ) : (
            <>
              {formatMessage({ id: 'After 14 days' })}{' '}
              <span>
                {transformNumber(regrettedByCustomerArp)} kr ({regrettedByCustomerArpPercent})
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatisticNumbers;
