import {
  FinanceSystem,
  GetOrdersForOrdersListQuery,
  OrderContractStatus,
  OrderFragment,
  RbacResourceName,
} from 'api';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  Table,
  OrderStatusTag,
  ContractStatusTag,
  Column,
  FinanceSystemBadge,
  RegretPeriodBadge,
} from 'components';
import moment from 'moment';

import './ordersTable.less';
import { usePermission } from 'hooks';
import { Typography } from 'antd';
import { Links } from 'settings';

const { Text } = Typography;

interface OrdersTablePropsI {
  limit: number;
  total?: number;
  loading: boolean;
  currentPage: number;
  pageSizeOptions?: number[];
  orders: GetOrdersForOrdersListQuery['getOrdersForOrdersList']['nodes'];
  hasClientName?: boolean;
  fetchMore: (page: number, total?: number) => void;
  onChange: (pagination: any, filters: any, sorter: any) => void;
  setLimit?: (value: number | undefined) => void;
}

export const renderProducts = ({
  bundles,
  items,
  upgrades,
}: {
  bundles: {
    productBundle: { name: string };
    items: {
      productAddons: { productAddon: { name: string } }[];
    }[];
  }[];
  items: {
    qty: number;
    product: { name: string };
    productAddons: { productAddon: { name: string } }[];
  }[];
  upgrades: {
    productAddons: { productAddon: { name: string } }[];
  }[];
}) => {
  const namesCollection: { [name: string]: number } = {};
  const addName = (name: string, qty = 1) => {
    if (namesCollection[name]) {
      namesCollection[name] += qty;
    } else {
      namesCollection[name] = qty;
    }
  };

  if (items) {
    for (const item of items) {
      addName(item.product.name, item.qty);

      for (const { productAddon } of item.productAddons) {
        addName(productAddon.name);
      }
    }
  }

  if (bundles) {
    for (const bundle of bundles) {
      addName(bundle.productBundle.name);

      for (const item of bundle.items) {
        for (const { productAddon } of item.productAddons) {
          addName(productAddon.name);
        }
      }
    }
  }

  if (upgrades) {
    for (const upgrade of upgrades) {
      for (const { productAddon } of upgrade.productAddons) {
        addName(productAddon.name);
      }
    }
  }

  const resultArray = [];
  /* eslint-disable-next-line guard-for-in */
  for (const name in namesCollection) {
    const qty = namesCollection[name];
    resultArray.push(qty === 1 ? name : `${qty} x ${name}`);
  }

  return resultArray.join(', ');
};

const renderTotal = (fullTotalWithAddons: number | undefined) => {
  if (fullTotalWithAddons) {
    return (
      <div className="orders-table__price">
        <div className="orders-table__total">{fullTotalWithAddons} kr</div>
      </div>
    );
  }
  return '-';
};

const renderOrderSignDate = (date: string, order: OrderFragment) => {
  if (date && order.contractStatus === OrderContractStatus.Signed) {
    return moment(date).format('DD MMM HH:mm');
  }
  return '-';
};

const OrdersTable = ({
  total,
  limit,
  orders,
  loading,
  onChange,
  fetchMore,
  setLimit,
  currentPage,
  hasClientName,
  pageSizeOptions,
}: OrdersTablePropsI) => {
  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const { isPermitted: isViewFinanceSystemPermitted } = usePermission(
    RbacResourceName.FinanceSystemView,
  );

  const handleOrderView = (id: string | number) =>
    navigate(generatePath(Links.orders.details, { id: String(id) }));

  return (
    <div className="orders-table">
      <Table
        dataSource={orders}
        currentPage={currentPage}
        total={total}
        limit={limit}
        pageSizeOptions={pageSizeOptions}
        pageSizeSubject={formatMessage({ id: 'Orders' })}
        fetchMore={fetchMore}
        setRecLimit={setLimit}
        rowKey="_id"
        onRow={({ _id }) => ({
          onClick: () => handleOrderView(_id),
        })}
        onChange={onChange}
        loading={loading}
      >
        <Column width={90} title="ID" dataIndex="_id" />
        {isViewFinanceSystemPermitted && (
          <Column
            width={100}
            title={formatMessage({ id: 'Invoicing' })}
            dataIndex="financeSystem"
            render={(financeSystem: FinanceSystem, order: OrderFragment) => (
              <div>
                <FinanceSystemBadge type={financeSystem} status={order?.financeSystemSyncStatus} />
              </div>
            )}
          />
        )}
        <Column
          width={90}
          title="Sold by"
          className="no-wrap"
          dataIndex={['assignee', 'fullName']}
          render={(name: string) => {
            const nameParts = name.split(' ');
            if (nameParts && nameParts.length > 1) {
              return (
                <span>
                  {nameParts[0]} {nameParts[nameParts.length - 1].charAt(0)}.
                </span>
              );
            }
            if (nameParts && nameParts.length === 1) {
              return <span>{nameParts[0]}</span>;
            }
            return <span>-</span>;
          }}
        />
        <Column
          title={formatMessage({ id: 'Channel' })}
          dataIndex="salesChannel"
          width={100}
          render={({ name }) => (
            <Text style={{ maxWidth: '100px' }} ellipsis={{ tooltip: name }}>
              {name}
            </Text>
          )}
        />
        {hasClientName && (
          <Column
            title={formatMessage({ id: 'Customer' })}
            dataIndex="fullName"
            // sorter
            render={(fullname: string, order: OrderFragment) => (
              <div className="customer-wraper">
                <span
                  className={order?.customer?._id ? 'customer-link' : 'customer-link-dissabled'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (order?.customer?._id) {
                      navigate(`/customers/view/${order?.customer?._id}`);
                    }
                  }}
                >
                  {fullname}
                </span>
                <span>{order?.personId ? order?.personId.substring(0, 8) : ''}</span>
              </div>
            )}
          />
        )}
        <Column
          title={formatMessage({ id: 'Signed' })}
          dataIndex="contractSignedDate"
          className="no-wrap"
          width={150}
          render={(contractSignedDate: string | undefined | null, order: OrderFragment) =>
            contractSignedDate ? renderOrderSignDate(contractSignedDate, order) : '-'
          }
        />
        <Column
          title={formatMessage({ id: 'Regret period' })}
          dataIndex="isRegrettableDate"
          width={250}
          className="no-wrap"
          render={(isRegrettableDate: string, order: OrderFragment) => (
            <RegretPeriodBadge
              dateString={isRegrettableDate}
              contractStatus={order.contractStatus}
              isRegrettable={order.isRegrettable}
            />
          )}
        />
        <Column title={formatMessage({ id: 'Products' })} render={renderProducts} />
        <Column
          title={formatMessage({ id: 'Total' })}
          dataIndex="fullTotalWithAddons"
          className="no-wrap"
          render={(fullTotalWithAddons: number, order: OrderFragment) =>
            renderTotal(order?.fullTotalWithAddons)
          }
          sorter={(a, b) => Number(a) - Number(b)}
        />
        <Column
          width={100}
          align="center"
          title={formatMessage({ id: 'Contract' })}
          dataIndex="contractStatus"
          render={(contractStatus: string) => <ContractStatusTag status={contractStatus} />}
        />
        <Column
          width={80}
          align="center"
          title={formatMessage({ id: 'QA' })}
          dataIndex="status"
          render={(status: string, order: OrderFragment) => (
            <OrderStatusTag
              isDeleted={order?.isDeleted}
              isWinBack={Boolean(order?.isWinBack)}
              status={order?.status}
              contractStatus={order?.contractStatus}
            />
          )}
        />
      </Table>
    </div>
  );
};

export default OrdersTable;
