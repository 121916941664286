import generatePicker, {
  PickerBaseProps,
  PickerDateProps,
  PickerTimeProps,
} from 'antd/es/date-picker/generatePicker';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

export type DatePickerProps =
  | PickerBaseProps<Moment>
  | PickerDateProps<Moment>
  | PickerTimeProps<Moment>;

export const DatePicker = generatePicker<Moment>(momentGenerateConfig);
