import { Title } from 'components/index';
import { Button } from 'antd';

import './alertSuccess.less';

interface AlertSuccessPropsI {
  IconNode?: any;
  title?: string;
  subtitle?: string;
  description?: string;
  actionLinkUrl?: string;
  actionLinkText?: string;
}

const AlertSuccess = ({
  IconNode,
  title,
  subtitle,
  description,
  actionLinkUrl,
  actionLinkText,
}: AlertSuccessPropsI) => {
  const onClickActionButton = () => {
    window.open(actionLinkUrl, '_blank');
  };

  return (
    <div className="alert-success">
      {IconNode && <IconNode className="alert-success__icon" />}

      <div className="alert-success__content">
        <Title size="small">{title}</Title>

        {subtitle && <p className="alert-success__subtitle">{subtitle}</p>}

        {description && <p className="alert-success__description">{description}</p>}

        {actionLinkText && (
          <Button className="alert-success__action" type="link" onClick={onClickActionButton}>
            {actionLinkText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default AlertSuccess;
