import React, { ReactNode } from 'react';
import { Space } from 'antd';
import classNames from 'classnames';

import './filtersRow.less';

interface FiltersRowPropsI {
  left?: ReactNode;
  right?: ReactNode;
  className?: string;
  searchItem?: ReactNode;
}

const FiltersRow = ({ className, left, right }: FiltersRowPropsI) => (
  <div className={classNames('filters-row', className)}>
    {left && <Space className="filters-row__left">{left}</Space>}
    {right && <Space className="filters-row__right">{right}</Space>}
  </div>
);

export default FiltersRow;
