import {
  Observable,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  DefaultOptions,
  createHttpLink,
} from '@apollo/client';
import { intl } from 'contexts';
import jwtDecode from 'jwt-decode';
import { getFreshTokens } from 'api';
import { Notification } from 'components';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getToken, fetchRefreshToken, logOut } from 'utils';
import { TokenRefreshLink } from 'apollo-link-token-refresh';

const errorLink = onError(
  ({ operation, graphQLErrors, networkError, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.message === 'Unauthorized') {
          // if user Unauthorized (token's time has expired)
          if (!getToken()) {
            logOut();
          } else {
            return new Observable((observer) => {
              getFreshTokens().then((status) => {
                if (status === 'SUCCESS') {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  // refetch the same request that was failed
                  forward(operation).subscribe(subscriber);
                }
              });
            });
          }
        }
        Notification({
          type: 'error',
          title: intl.formatMessage({ id: 'notification.error' }),
          message: intl.formatMessage(
            { id: 'notification.errorWithMessage' },
            { message: err.message }
          ),
        });
      }
    }

    if (networkError) {
      if (operation?.operationName === 'CheckSignInStatus') {
        return;
      }
      return Notification({
        type: 'error',
        title: intl.formatMessage({ id: 'notification.error' }),
        message: intl.formatMessage({ id: 'notification.errorOccurred' }),
      });
    }
  }
);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL,
});

const refreshToken = new TokenRefreshLink<{ tokens: any; status: string }>({
  accessTokenField: 'refreshToken',
  isTokenValidOrUndefined: (): boolean => {
    // return true if there is no token
    if (!getToken()) {
      return true;
    }
    // return false if token is invalid
    const { exp }: any = jwtDecode(getToken());
    if (Date.now() > exp * 1000) {
      return false;
    }

    // return true if token is still valid
    return true;
  },
  fetchAccessToken: () => fetchRefreshToken(),
  handleFetch: (data) => {
    if (data.status === 'SUCCESS') {
      localStorage.setItem('auth', JSON.stringify(data?.tokens));
    } else {
      logOut();
    }
  },

  handleError: (err) => {
    // For some case, if there will be an error
    logOut();
    // full control over handling token fetch Error
    Notification({
      type: 'error',
      title: intl.formatMessage({ id: 'notification.warning' }),
      message: intl.formatMessage({ id: 'notification.sessionError' }),
    });
  },
});

const authLink = setContext((_, { headers }): any => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, refreshToken, authLink, httpLink]),
  connectToDevTools: process.env.REACT_APP_ENV !== 'production',
  defaultOptions,
});

export { client };
