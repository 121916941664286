import { Switch as SwitchAntd } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';

import './switch.less';

interface SwitchPropsI {
  isChecked?: boolean;
  disabled?: boolean;
  onChange?: SwitchChangeEventHandler;
}

const Switch = ({ isChecked, onChange, disabled }: SwitchPropsI) => (
  <div className="switch-wrapper">
    <SwitchAntd className="switch" disabled={disabled} checked={isChecked} onChange={onChange} />
  </div>
);

export default Switch;
