import React, { createContext, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { B2BOrderContextI } from './b2bOrderContext.interfaces';

export const B2B_INVOICING_FREQUENCY_OPTIONS = [
  { value: 3, label: <FormattedMessage id="3 months" /> },
  { value: 6, label: <FormattedMessage id="6 months" /> },
  { value: 12, label: <FormattedMessage id="12 months" /> },
  { value: 0, label: <FormattedMessage id="Pay the full amount" /> },
];

const initialContext: B2BOrderContextI = {
  companyInfoEntered: {},
  onChangeCompanyInfoEntered: (name: string, value: any) => undefined,
  itemInfoEntered: {},
  onChangeItemInfoEntered: (name: string, value: any) => undefined,
};
export const b2bOrderContext = createContext(initialContext);

const { Provider } = b2bOrderContext;

type B2BOrderProviderArgs = Omit<
  B2BOrderContextI,
  'onChangeCompanyInfoEntered' | 'onChangeItemInfoEntered'
> &
  Partial<
    Pick<
      B2BOrderContextI,
      'onChangeCompanyInfoEntered' | 'onChangeItemInfoEntered'
    >
  >;

export const B2BOrderProvider = ({
  companyInfo,
  companyInfoEntered,
  onChangeCompanyInfoEntered = () => ({}),
  itemInfoEntered,
  onChangeItemInfoEntered = () => ({}),
  ...props
}: B2BOrderProviderArgs) => {
  const value = {
    companyInfo,
    companyInfoEntered,
    onChangeCompanyInfoEntered,
    itemInfoEntered,
    onChangeItemInfoEntered,
  };

  return <Provider value={value} {...props} />;
};

export const useB2BOrderContext = () => useContext(b2bOrderContext);
