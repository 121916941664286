import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Select } from 'components';

import './filter.less';

interface FilterPropsI {
  withSearch?: boolean;
  defaultValue?: string | number;
  value?: any;
  className?: string;
  children?: ReactNode;
  options?: { label: ReactNode; value: any }[];
  bordered?: boolean;
  disabled?: boolean;
  placeholder?: string;
  allowClear?: boolean;
  onChange?: (value: any) => void;
  onClear?: () => void;
}

const Filter = ({
  withSearch,
  options,
  onChange,
  onClear,
  children,
  bordered,
  disabled,
  className,
  allowClear,
  placeholder,
  defaultValue,
  value,
}: FilterPropsI) => (
  <Select
    value={value}
    defaultValue={defaultValue}
    onClear={onClear}
    className={classNames('filter', className)}
    size="large"
    showSearch={withSearch || false}
    placeholder={placeholder}
    onChange={onChange}
    options={options}
    allowClear={allowClear}
    disabled={disabled}
    bordered={bordered}
    optionFilterProp="children"
    filterOption={(input, option) =>
      (option!.name as string).toLowerCase().includes(input.toLowerCase())
    }
  >
    {children}
  </Select>
);

export default Filter;
