import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './shadowCard.less';

interface ShadowCardPropsI {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

const ShadowCard = ({ className, children, onClick }: ShadowCardPropsI) => (
  <div
    onClick={() => (onClick ? onClick() : undefined)}
    className={classNames('shadow-card', className)}
  >
    {children}
  </div>
);

export default ShadowCard;
