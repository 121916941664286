import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
}

export interface AdditionalField {
  __typename?: 'AdditionalField';
  name: Scalars['String'];
  value: Scalars['String'];
}

export interface AddonCommission {
  __typename?: 'AddonCommission';
  _id: Scalars['ID'];
  author: TeamMember;
  authorId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  productAddon: ProductAddon;
  productAddonId: Scalars['ID'];
  qty: Scalars['Int'];
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['ID']>;
  salesChannel?: Maybe<SalesChannel>;
  salesChannelId?: Maybe<Scalars['ID']>;
  size: Scalars['Float'];
  status: AddonCommissionStatus;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']>;
  teamMember?: Maybe<TeamMember>;
  teamMemberId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
}

export enum AddonCommissionStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface AddonCommissionsFilterInput {
  authorLike?: InputMaybe<Scalars['String']>;
  productAddonIdEq?: InputMaybe<Scalars['ID']>;
  roleIdIn?: InputMaybe<Array<Scalars['ID']>>;
  salesChannelIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamMemberIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamMemberLike?: InputMaybe<Scalars['String']>;
}

export enum AddonCommissionsOrderBy {
  CreatedAt = 'createdAt',
  Qty = 'qty',
  Size = 'size',
}

export interface ApproveB2BOrderInput {
  _id: Scalars['ID'];
}

export interface ApproveB2BOrderPayload {
  __typename?: 'ApproveB2BOrderPayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<Order>;
  status: MutationStatus;
}

export interface ApproveOrderInput {
  _id: Scalars['ID'];
}

export interface ApproveOrderPayload {
  __typename?: 'ApproveOrderPayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<Order>;
  status: MutationStatus;
}

export interface AuthToken {
  __typename?: 'AuthToken';
  expiresIn: Scalars['Int'];
  token: Scalars['String'];
}

export interface AuthTokens {
  __typename?: 'AuthTokens';
  accessToken: AuthToken;
  refreshToken: AuthToken;
}

export interface B2BContractStatusHistoryItem {
  __typename?: 'B2BContractStatusHistoryItem';
  createdAt: Scalars['DateTime'];
  orderId: Scalars['ID'];
  scriveContractId: Scalars['ID'];
  scriveContractStatus?: Maybe<Scalars['String']>;
  status: ContractStatus;
}

export interface B2BCustomer {
  __typename?: 'B2BCustomer';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  creditCheckCode: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  lastAssignee: TeamMember;
  lastAssigneeId: Scalars['ID'];
  orgId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
}

export enum B2BCustomersOrderBy {
  CreatedAt = 'createdAt',
  FullName = 'fullName',
}

export interface B2BOrder {
  __typename?: 'B2BOrder';
  _id: Scalars['ID'];
  assignee: TeamMember;
  assigneeId: Scalars['ID'];
  coAddress?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  createdAt: Scalars['DateTime'];
  creditCheckCode: Scalars['Int'];
  customer?: Maybe<B2BCustomer>;
  customerId?: Maybe<Scalars['ID']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  discountAmount: Scalars['Float'];
  email: Scalars['String'];
  invoicingAddress?: Maybe<Scalars['String']>;
  invoicingFrequency: Scalars['Int'];
  invoicingZip?: Maybe<Scalars['String']>;
  isCreditCheckReceived: Scalars['Boolean'];
  isUnsigned: Scalars['Boolean'];
  items: Array<B2BOrderItem>;
  monthlyPricePerAllLicenses: Scalars['Float'];
  monthlyPricePerOneLicense: Scalars['Float'];
  monthlyTotal: Scalars['Float'];
  orgId: Scalars['String'];
  phoneNumber: Scalars['String'];
  project: Project;
  projectId: Scalars['ID'];
  resolvedBy?: Maybe<TeamMember>;
  resolvedById?: Maybe<Scalars['ID']>;
  scriveContractId?: Maybe<Scalars['String']>;
  scriveContractStatus: ScriveContractStatus;
  shippingStatus: ShippingStatus;
  status: B2BOrderStatus;
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
}

export interface B2BOrderItem {
  __typename?: 'B2BOrderItem';
  discountAmount: Scalars['Float'];
  duration: Scalars['Int'];
  monthlyPricePerAllLicenses: Scalars['Float'];
  monthlyPricePerOneLicense: Scalars['Float'];
  productId: Scalars['ID'];
  qty: Scalars['Int'];
}

export interface B2BOrderItemInput {
  discountAmount?: InputMaybe<Scalars['Float']>;
  duration: Scalars['Int'];
  monthlyPricePerAllLicenses: Scalars['Float'];
  monthlyPricePerOneLicense: Scalars['Float'];
  productId: Scalars['ID'];
  qty: Scalars['Int'];
}

export interface B2BOrderShipmentStatusUpdateInput {
  orderIds: Array<Scalars['ID']>;
  shippingStatus: ShippingStatus;
}

export interface B2BOrderShipmentStatusUpdatePayload {
  __typename?: 'B2BOrderShipmentStatusUpdatePayload';
  errors?: Maybe<Array<MutationError>>;
  status: MutationStatus;
}

export enum B2BOrderStatus {
  Approved = 'APPROVED',
  Deleted = 'DELETED',
  Ended = 'ENDED',
  New = 'NEW',
  Regretted = 'REGRETTED',
}

export interface B2BOrderStatusHistoryItem {
  __typename?: 'B2BOrderStatusHistoryItem';
  byTeamMember: TeamMember;
  byTeamMemberId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  orderId: Scalars['ID'];
  status: B2BOrderStatus;
}

export enum B2BOrdersOrderBy {
  CompanyName = 'companyName',
  CreatedAt = 'createdAt',
  Subtotal = 'subtotal',
}

export interface B2BSearchHistoryItem {
  __typename?: 'B2BSearchHistoryItem';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  orgId: Scalars['String'];
  teamMember: TeamMember;
  teamMemberId: Scalars['ID'];
}

export enum B2BSearchHistoryOrderBy {
  CreatedAt = 'createdAt',
}

export interface BundleCommission {
  __typename?: 'BundleCommission';
  _id: Scalars['ID'];
  author: TeamMember;
  authorId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  productBundle: ProductBundle;
  productBundleId: Scalars['ID'];
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['ID']>;
  salesChannel?: Maybe<SalesChannel>;
  salesChannelId?: Maybe<Scalars['ID']>;
  size: Scalars['Float'];
  status: BundleCommissionStatus;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']>;
  teamMember?: Maybe<TeamMember>;
  teamMemberId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
}

export enum BundleCommissionStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface BundleCommissionsFilterInput {
  authorLike?: InputMaybe<Scalars['String']>;
  productBundleIdEq?: InputMaybe<Scalars['ID']>;
  roleIdIn?: InputMaybe<Array<Scalars['ID']>>;
  salesChannelIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamMemberIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamMemberLike?: InputMaybe<Scalars['String']>;
}

export enum BundleCommissionsOrderBy {
  CreatedAt = 'createdAt',
  Qty = 'qty',
  Size = 'size',
}

export enum CancelArvatoSubscriptionType {
  CancelAllUnpaid = 'CANCEL_ALL_UNPAID',
  CancelRemaining = 'CANCEL_REMAINING',
}

export enum ChangesLogAction {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

export enum ChangesLogEntityType {
  Addon = 'ADDON',
  Bundle = 'BUNDLE',
  Product = 'PRODUCT',
  Project = 'PROJECT',
  Role = 'ROLE',
  SalesChannel = 'SALES_CHANNEL',
  TeamMember = 'TEAM_MEMBER',
}

export enum ChangesLogInitiator {
  FrotnoxCron = 'FROTNOX_CRON',
  TeamMember = 'TEAM_MEMBER',
}

export interface ChangesLogItem {
  __typename?: 'ChangesLogItem';
  _id: Scalars['ID'];
  action: ChangesLogAction;
  createdAt: Scalars['DateTime'];
  entityId: Scalars['Int'];
  entityType: ChangesLogEntityType;
}

export interface ChangesLogsFilterInput {
  actionEq?: InputMaybe<ChangesLogAction>;
  entityIdEq?: InputMaybe<Scalars['ID']>;
  entityTypeEq?: InputMaybe<ChangesLogEntityType>;
  idEq?: InputMaybe<Scalars['ID']>;
}

export enum ChangesLogsOrderBy {
  CreatedAt = 'createdAt',
}

export interface CheckSignInStatusInput {
  sessionId: Scalars['String'];
}

export interface CheckSignInStatusPayload {
  __typename?: 'CheckSignInStatusPayload';
  status: MutationStatus;
  tokens?: Maybe<AuthTokens>;
}

export interface Commission {
  __typename?: 'Commission';
  _id: Scalars['ID'];
  author: TeamMember;
  authorId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  isDeletable: Scalars['Boolean'];
  product: Product;
  productId: Scalars['ID'];
  qty: Scalars['Int'];
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['ID']>;
  salesChannel?: Maybe<SalesChannel>;
  salesChannelId?: Maybe<Scalars['ID']>;
  size: Scalars['Float'];
  status: CommissionStatus;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']>;
  teamMember?: Maybe<TeamMember>;
  teamMemberId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
}

export enum CommissionStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface CommissionsFilterInput {
  applicableForTeamMemberId?: InputMaybe<Scalars['ID']>;
  authorLike?: InputMaybe<Scalars['String']>;
  productIdEq?: InputMaybe<Scalars['ID']>;
  roleIdIn?: InputMaybe<Array<Scalars['ID']>>;
  salesChannelIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamMemberIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamMemberLike?: InputMaybe<Scalars['String']>;
}

export enum CommissionsOrderBy {
  CreatedAt = 'createdAt',
  Qty = 'qty',
  Size = 'size',
}

export interface CompanyData {
  __typename?: 'CompanyData';
  allFields: Array<AdditionalField>;
  fullName?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
}

export enum ContractStatus {
  Canceled = 'CANCELED',
  Ended = 'ENDED',
  Error = 'ERROR',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Sent = 'SENT',
  Signed = 'SIGNED',
}

export interface ContractStatusHistoryItem {
  __typename?: 'ContractStatusHistoryItem';
  createdAt: Scalars['DateTime'];
  orderId: Scalars['ID'];
  scriveContractId: Scalars['ID'];
  scriveContractStatus?: Maybe<Scalars['String']>;
  status: ContractStatus;
}

export interface CreateAddonCommissionInput {
  productAddonId: Scalars['ID'];
  qty?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['ID']>;
  salesChannelId?: InputMaybe<Scalars['ID']>;
  size: Scalars['Float'];
  status?: InputMaybe<AddonCommissionStatus>;
  teamId?: InputMaybe<Scalars['ID']>;
  teamMemberId?: InputMaybe<Scalars['ID']>;
}

export interface CreateAddonCommissionPayload {
  __typename?: 'CreateAddonCommissionPayload';
  errors: Array<MutationError>;
  node?: Maybe<AddonCommission>;
  status: MutationStatus;
}

export interface CreateB2BOrderInput {
  coAddress?: InputMaybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  deliveryAddress: Scalars['String'];
  email: Scalars['String'];
  invoicingAddress?: InputMaybe<Scalars['String']>;
  invoicingFrequency: Scalars['Int'];
  invoicingZip?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<B2BOrderItemInput>>;
  orgId: Scalars['String'];
  phoneNumber: Scalars['String'];
  projectId: Scalars['ID'];
  sendContract?: InputMaybe<Scalars['Boolean']>;
  shippingStatus?: InputMaybe<ShippingStatus>;
}

export interface CreateB2BOrderPayload {
  __typename?: 'CreateB2BOrderPayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<B2BOrder>;
  status: MutationStatus;
}

export interface CreateBundleCommissionInput {
  productBundleId: Scalars['ID'];
  roleId?: InputMaybe<Scalars['ID']>;
  salesChannelId?: InputMaybe<Scalars['ID']>;
  size: Scalars['Float'];
  status?: InputMaybe<BundleCommissionStatus>;
  teamId?: InputMaybe<Scalars['ID']>;
  teamMemberId?: InputMaybe<Scalars['ID']>;
}

export interface CreateBundleCommissionPayload {
  __typename?: 'CreateBundleCommissionPayload';
  errors: Array<MutationError>;
  node?: Maybe<BundleCommission>;
  status: MutationStatus;
}

export interface CreateCommissionInput {
  productId: Scalars['ID'];
  qty?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['ID']>;
  salesChannelId?: InputMaybe<Scalars['ID']>;
  size: Scalars['Float'];
  status?: InputMaybe<CommissionStatus>;
  teamId?: InputMaybe<Scalars['ID']>;
  teamMemberId?: InputMaybe<Scalars['ID']>;
}

export interface CreateCommissionPayload {
  __typename?: 'CreateCommissionPayload';
  errors: Array<MutationError>;
  node?: Maybe<Commission>;
  status: MutationStatus;
}

export interface CreateLicenseBatchInput {
  duration?: InputMaybe<Scalars['Int']>;
  licenseCount?: InputMaybe<Scalars['Int']>;
  partnerStoreId: Scalars['ID'];
  productId: Scalars['ID'];
}

export interface CreateLicenseBatchPayload {
  __typename?: 'CreateLicenseBatchPayload';
  errors: Array<MutationError>;
  node?: Maybe<LicenseBatch>;
  status: MutationStatus;
}

export interface CreateNoteInput {
  content: Scalars['String'];
  entityId: Scalars['ID'];
  entityType: NoteEntityType;
}

export interface CreateNotePayload {
  __typename?: 'CreateNotePayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<Note>;
  status: MutationStatus;
}

export interface CreateOrderInput {
  bundles?: InputMaybe<Array<OrderBundleInput>>;
  coAddress?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  invoicingAddress?: InputMaybe<Scalars['String']>;
  invoicingFrequency: Scalars['Int'];
  invoicingType?: InputMaybe<InvoicingType>;
  invoicingZip?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<OrderItemInput>>;
  personId: Scalars['String'];
  phoneNumber: Scalars['String'];
  projectId: Scalars['ID'];
  salesChannelId?: InputMaybe<Scalars['ID']>;
  sendContract?: InputMaybe<Scalars['Boolean']>;
  shippingStatus?: InputMaybe<ShippingStatus>;
  upgrades?: InputMaybe<Array<OrderUpgradeInput>>;
}

export interface CreateOrderPayload {
  __typename?: 'CreateOrderPayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<Order>;
  status: MutationStatus;
}

export interface CreatePartnerStoreInput {
  address?: InputMaybe<Scalars['String']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  linkToSite?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
}

export interface CreatePartnerStorePayload {
  __typename?: 'CreatePartnerStorePayload';
  errors: Array<MutationError>;
  node?: Maybe<PartnerStore>;
  status: MutationStatus;
}

export interface CreateProductAddonInput {
  canBeTerminated?: InputMaybe<Scalars['Boolean']>;
  freeMonths: Scalars['Int'];
  isCreditCheckRequired?: InputMaybe<Scalars['Boolean']>;
  isInStok?: InputMaybe<Scalars['Boolean']>;
  isPersonIdRequired?: InputMaybe<Scalars['Boolean']>;
  isStockable?: InputMaybe<Scalars['Boolean']>;
  isSubscription: Scalars['Boolean'];
  licenseDurationMonths?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  productId: Scalars['ID'];
  status: ProductAddonStatus;
  unitId?: InputMaybe<Scalars['ID']>;
  vatRate: Scalars['Float'];
}

export interface CreateProductAddonPayload {
  __typename?: 'CreateProductAddonPayload';
  errors: Array<MutationError>;
  node?: Maybe<ProductAddon>;
  status: MutationStatus;
}

export interface CreateProductBundleInput {
  name: Scalars['String'];
  nameInContract?: InputMaybe<Scalars['String']>;
  price: Scalars['Float'];
  productIds: Array<ProductBundleToProductInput>;
  projectId: Scalars['ID'];
  status: ProductStatus;
  vatRate: Scalars['Float'];
}

export interface CreateProductBundlePayload {
  __typename?: 'CreateProductBundlePayload';
  errors: Array<MutationError>;
  node?: Maybe<ProductBundle>;
  status: MutationStatus;
}

export interface CreateProductDiscountInput {
  discount: Scalars['Float'];
  productId: Scalars['ID'];
  qty: Scalars['Int'];
  roleId?: InputMaybe<Scalars['ID']>;
  salesChannelId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<ProductDiscountStatus>;
  teamId?: InputMaybe<Scalars['ID']>;
  teamMemberId?: InputMaybe<Scalars['ID']>;
}

export interface CreateProductDiscountPayload {
  __typename?: 'CreateProductDiscountPayload';
  errors: Array<MutationError>;
  node?: Maybe<ProductDiscount>;
  status: MutationStatus;
}

export interface CreateProductInput {
  canBeTerminated?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  freeMonths: Scalars['Int'];
  isCreditCheckRequired?: InputMaybe<Scalars['Boolean']>;
  isInStok?: InputMaybe<Scalars['Boolean']>;
  isPersonIdRequired?: InputMaybe<Scalars['Boolean']>;
  isStockable?: InputMaybe<Scalars['Boolean']>;
  isSubscription: Scalars['Boolean'];
  licenseDurationMonths?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  nameInContract?: InputMaybe<Scalars['String']>;
  price: Scalars['Float'];
  projectId: Scalars['ID'];
  status: ProductStatus;
  unitId?: InputMaybe<Scalars['ID']>;
  vatRate: Scalars['Float'];
}

export interface CreateProductPayload {
  __typename?: 'CreateProductPayload';
  errors: Array<MutationError>;
  node?: Maybe<Product>;
  status: MutationStatus;
}

export interface CreateProjectInput {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}

export interface CreateProjectPayload {
  __typename?: 'CreateProjectPayload';
  errors: Array<MutationError>;
  node?: Maybe<Project>;
  status: MutationStatus;
}

export interface CreateRelationInput {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  status?: InputMaybe<RelationStatus>;
}

export interface CreateRelationPayload {
  __typename?: 'CreateRelationPayload';
  errors: Array<MutationError>;
  node?: Maybe<Relation>;
  status: MutationStatus;
}

export interface CreateRoleInput {
  b2bSearchRestriction?: InputMaybe<Scalars['Int']>;
  canSeeOtherB2BSearchHistory?: InputMaybe<Scalars['Boolean']>;
  canSeeOtherSearchHistory?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  isSuper?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  onlyOwnAndChildData?: InputMaybe<Scalars['Boolean']>;
  onlySalesChannelData?: InputMaybe<Scalars['Boolean']>;
  onlyTeamData?: InputMaybe<Scalars['Boolean']>;
  parentRoleId?: InputMaybe<Scalars['ID']>;
  permissionIds?: InputMaybe<Array<RbacResourceName>>;
  searchRestriction?: InputMaybe<Scalars['Int']>;
  teamId: Scalars['ID'];
}

export interface CreateRolePayload {
  __typename?: 'CreateRolePayload';
  errors: Array<MutationError>;
  node?: Maybe<Role>;
  status: MutationStatus;
}

export interface CreateSalesChannelInput {
  description?: InputMaybe<Scalars['String']>;
  financeSystem?: InputMaybe<FinanceSystem>;
  name?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  status?: InputMaybe<SalesChannelStatus>;
}

export interface CreateSalesChannelPayload {
  __typename?: 'CreateSalesChannelPayload';
  errors: Array<MutationError>;
  node?: Maybe<SalesChannel>;
  status: MutationStatus;
}

export interface CreateTeamInput {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projectIds: Array<Scalars['ID']>;
}

export interface CreateTeamMemberInput {
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  parentTeamMemberId?: InputMaybe<Scalars['ID']>;
  personId: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  projects?: InputMaybe<Array<TeamMemberProjectInput>>;
  roleId: Scalars['ID'];
  teamId: Scalars['ID'];
}

export interface CreateTeamMemberPayload {
  __typename?: 'CreateTeamMemberPayload';
  errors: Array<MutationError>;
  node?: Maybe<TeamMember>;
  status: MutationStatus;
}

export interface CreateTeamPayload {
  __typename?: 'CreateTeamPayload';
  errors: Array<MutationError>;
  node?: Maybe<Team>;
  status: MutationStatus;
}

export interface CreateWinBackInput {
  bundles?: InputMaybe<Array<OrderBundleInput>>;
  coAddress?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  invoicingAddress?: InputMaybe<Scalars['String']>;
  invoicingFrequency: Scalars['Int'];
  invoicingType?: InputMaybe<InvoicingType>;
  invoicingZip?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<OrderItemInput>>;
  phoneNumber: Scalars['String'];
  projectId: Scalars['ID'];
  salesChannelId?: InputMaybe<Scalars['ID']>;
  shippingStatus?: InputMaybe<ShippingStatus>;
  sourceOrderId: Scalars['ID'];
  upgrades?: InputMaybe<Array<OrderUpgradeInput>>;
  winBackDuration: Scalars['Int'];
  winBackPrice: Scalars['Float'];
}

export interface CreateWinBackPayload {
  __typename?: 'CreateWinBackPayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<Order>;
  status: MutationStatus;
}

export interface Customer {
  __typename?: 'Customer';
  _id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  coAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creditCheckCode: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  lastAssignee: TeamMember;
  lastAssigneeId: Scalars['ID'];
  notes: GetNotesPayload;
  personId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  salesChannel: SalesChannel;
  salesChannelId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  zipCode?: Maybe<Scalars['String']>;
}

export interface CustomerNotesArgs {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}

export enum CustomersOrderBy {
  CreatedAt = 'createdAt',
  FullName = 'fullName',
}

export interface DashboardFilterInput {
  assigneeIdIn?: InputMaybe<Array<Scalars['ID']>>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  projectIdIn?: InputMaybe<Array<Scalars['ID']>>;
  roleIdIn?: InputMaybe<Array<Scalars['ID']>>;
  salesChannelIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamIdIn?: InputMaybe<Array<Scalars['ID']>>;
}

export interface DeleteAddonCommissionInput {
  _id: Scalars['ID'];
}

export interface DeleteAddonCommissionPayload {
  __typename?: 'DeleteAddonCommissionPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteB2BOrderInput {
  _id: Scalars['ID'];
}

export interface DeleteB2BOrderPayload {
  __typename?: 'DeleteB2BOrderPayload';
  errors?: Maybe<Array<MutationError>>;
  status: MutationStatus;
}

export interface DeleteB2BUnsignedOrderInput {
  _id: Scalars['ID'];
}

export interface DeleteB2BUnsignedOrderPayload {
  __typename?: 'DeleteB2BUnsignedOrderPayload';
  errors?: Maybe<Array<MutationError>>;
  status: MutationStatus;
}

export interface DeleteBundleCommissionInput {
  _id: Scalars['ID'];
}

export interface DeleteBundleCommissionPayload {
  __typename?: 'DeleteBundleCommissionPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteCommissionInput {
  _id: Scalars['ID'];
}

export interface DeleteCommissionPayload {
  __typename?: 'DeleteCommissionPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteOrderInput {
  _id: Scalars['ID'];
  arvatoCancelRemainingNum?: InputMaybe<Scalars['Int']>;
  arvatoCancelType?: InputMaybe<CancelArvatoSubscriptionType>;
}

export interface DeleteOrderPayload {
  __typename?: 'DeleteOrderPayload';
  errors?: Maybe<Array<MutationError>>;
  status: MutationStatus;
}

export interface DeletePartnerStoreInput {
  _id: Scalars['ID'];
}

export interface DeletePartnerStorePayload {
  __typename?: 'DeletePartnerStorePayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteProductAddonInput {
  _id: Scalars['ID'];
}

export interface DeleteProductAddonPayload {
  __typename?: 'DeleteProductAddonPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteProductBundleInput {
  _id: Scalars['ID'];
}

export interface DeleteProductBundlePayload {
  __typename?: 'DeleteProductBundlePayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteProductDiscountInput {
  _id: Scalars['ID'];
}

export interface DeleteProductDiscountPayload {
  __typename?: 'DeleteProductDiscountPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteProductInput {
  _id: Scalars['ID'];
}

export interface DeleteProductPayload {
  __typename?: 'DeleteProductPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteProjectInput {
  _id: Scalars['ID'];
}

export interface DeleteProjectPayload {
  __typename?: 'DeleteProjectPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteRelationInput {
  _id: Scalars['ID'];
}

export interface DeleteRelationPayload {
  __typename?: 'DeleteRelationPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteRoleInput {
  _id: Scalars['ID'];
}

export interface DeleteRolePayload {
  __typename?: 'DeleteRolePayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteSalesChannelInput {
  _id: Scalars['ID'];
}

export interface DeleteSalesChannelPayload {
  __typename?: 'DeleteSalesChannelPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteTeamInput {
  _id: Scalars['ID'];
}

export interface DeleteTeamMemberInput {
  _id: Scalars['ID'];
}

export interface DeleteTeamMemberPayload {
  __typename?: 'DeleteTeamMemberPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteTeamPayload {
  __typename?: 'DeleteTeamPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export interface DeleteUnsignedOrderInput {
  _id: Scalars['ID'];
}

export interface DeleteUnsignedOrderPayload {
  __typename?: 'DeleteUnsignedOrderPayload';
  errors?: Maybe<Array<MutationError>>;
  status: MutationStatus;
}

export interface EndOrderInput {
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
}

export interface ExternalUser {
  __typename?: 'ExternalUser';
  _id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  fullName?: Maybe<Scalars['String']>;
  lastAssignee?: Maybe<TeamMember>;
  lastAssigneeId?: Maybe<Scalars['ID']>;
  lastCustomer?: Maybe<Customer>;
  lastCustomerId?: Maybe<Scalars['ID']>;
  licenseEndDate: Scalars['DateTime'];
  licenseStartDate: Scalars['DateTime'];
  licenses: Array<License>;
  personId?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  product: Product;
  productId: Scalars['ID'];
  relation?: Maybe<Relation>;
  relationId?: Maybe<Scalars['ID']>;
}

export enum ExternalUsersOrderBy {
  AssigneeFullName = 'assigneeFullName',
  CreatedAt = 'createdAt',
  FullName = 'fullName',
  LicenseEndDate = 'licenseEndDate',
  LicenseStartDate = 'licenseStartDate',
}

export interface FileNode {
  __typename?: 'FileNode';
  url: Scalars['String'];
}

export enum FinanceSystem {
  Arvato = 'ARVATO',
  Fortnox = 'FORTNOX',
}

export enum FinanceSystemItemSyncStatus {
  Full = 'FULL',
  None = 'NONE',
}

export enum FinanceSystemSyncStatus {
  Full = 'FULL',
  None = 'NONE',
  Partial = 'PARTIAL',
  ToTransit = 'TO_TRANSIT',
  Transition = 'TRANSITION',
}

export interface GetAddonCommissionsPayload {
  __typename?: 'GetAddonCommissionsPayload';
  count: Scalars['Int'];
  nodes: Array<AddonCommission>;
}

export interface GetB2BContractStatusHistoryPayload {
  __typename?: 'GetB2BContractStatusHistoryPayload';
  count: Scalars['Int'];
  nodes: Array<B2BContractStatusHistoryItem>;
}

export interface GetB2BCustomersFilterInput {
  creditCheckCodeEq?: InputMaybe<Scalars['Int']>;
  emailLike?: InputMaybe<Scalars['String']>;
  fullNameLike?: InputMaybe<Scalars['String']>;
  idEq?: InputMaybe<Scalars['ID']>;
  lastAssigneeIdEq?: InputMaybe<Scalars['Int']>;
  orgIdLike?: InputMaybe<Scalars['String']>;
  phoneNumberLike?: InputMaybe<Scalars['String']>;
}

export interface GetB2BCustomersPayload {
  __typename?: 'GetB2BCustomersPayload';
  count: Scalars['Int'];
  nodes: Array<B2BCustomer>;
}

export interface GetB2BOrderStatusHistoryPayload {
  __typename?: 'GetB2BOrderStatusHistoryPayload';
  count: Scalars['Int'];
  nodes: Array<B2BOrderStatusHistoryItem>;
}

export interface GetB2BOrdersFilterInput {
  assigneeIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  companyNameLike?: InputMaybe<Scalars['String']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  creditCheckCodeEq?: InputMaybe<Scalars['Int']>;
  deliveryAddressLike?: InputMaybe<Scalars['String']>;
  emailLike?: InputMaybe<Scalars['String']>;
  idEq?: InputMaybe<Scalars['ID']>;
  isUnsigned?: InputMaybe<Scalars['Boolean']>;
  orgIdLike?: InputMaybe<Scalars['String']>;
  phoneNumberLike?: InputMaybe<Scalars['String']>;
  resolvedByIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  scriveContractStatusEq?: InputMaybe<ScriveContractStatus>;
  shippingStatusIn?: InputMaybe<Array<ShippingStatus>>;
  statusIn?: InputMaybe<Array<B2BOrderStatus>>;
}

export interface GetB2BOrdersPayload {
  __typename?: 'GetB2BOrdersPayload';
  count: Scalars['Int'];
  nodes: Array<B2BOrder>;
}

export interface GetB2BSearchHistoryFilterInput {
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  orgIdLike?: InputMaybe<Scalars['String']>;
  teamMemberIdEq: Scalars['ID'];
}

export interface GetB2BSearchHistoryPayload {
  __typename?: 'GetB2BSearchHistoryPayload';
  count: Scalars['Int'];
  nodes: Array<B2BSearchHistoryItem>;
}

export interface GetBundleCommissionsPayload {
  __typename?: 'GetBundleCommissionsPayload';
  count: Scalars['Int'];
  nodes: Array<BundleCommission>;
}

export interface GetChangesLogsPayload {
  __typename?: 'GetChangesLogsPayload';
  count: Scalars['Int'];
  nodes: Array<ChangesLogItem>;
}

export interface GetCommissionsPayload {
  __typename?: 'GetCommissionsPayload';
  count: Scalars['Int'];
  nodes: Array<Commission>;
}

export interface GetCompanyCreditCheck {
  __typename?: 'GetCompanyCreditCheck';
  approvalCode?: Maybe<Scalars['Int']>;
  approvalText?: Maybe<Scalars['String']>;
  casTemplate?: Maybe<Scalars['String']>;
}

export interface GetCompanyCreditCheckPayload {
  __typename?: 'GetCompanyCreditCheckPayload';
  check: GetCompanyCreditCheck;
  rejectionReasons: Array<Scalars['String']>;
}

export interface GetCompanyDataPayload {
  __typename?: 'GetCompanyDataPayload';
  errors: Array<Maybe<MutationError>>;
  node?: Maybe<CompanyData>;
}

export interface GetContractStatusHistoryPayload {
  __typename?: 'GetContractStatusHistoryPayload';
  count: Scalars['Int'];
  nodes: Array<ContractStatusHistoryItem>;
}

export interface GetCustomersFilterInput {
  creditCheckCodeEq?: InputMaybe<Scalars['Int']>;
  emailLike?: InputMaybe<Scalars['String']>;
  fullNameLike?: InputMaybe<Scalars['String']>;
  idEq?: InputMaybe<Scalars['ID']>;
  lastAssigneeIdEq?: InputMaybe<Scalars['Int']>;
  personIdLike?: InputMaybe<Scalars['String']>;
  phoneNumberLike?: InputMaybe<Scalars['String']>;
}

export interface GetCustomersPayload {
  __typename?: 'GetCustomersPayload';
  count: Scalars['Int'];
  nodes: Array<Customer>;
}

export interface GetDashboardCommissionsStatsPayload {
  __typename?: 'GetDashboardCommissionsStatsPayload';
  guaranteedCount: Scalars['Int'];
  guaranteedRevenue: Scalars['Float'];
  regrets: Array<RegretCommissionstats>;
  totalCount: Scalars['Int'];
  totalRevenue: Scalars['Float'];
  underRegretPeriodCount: Scalars['Int'];
  underRegretPeriodRevenue: Scalars['Float'];
}

export interface GetDashboardCustomersStatsBySalesChannel {
  __typename?: 'GetDashboardCustomersStatsBySalesChannel';
  count: Scalars['Int'];
  salesChannelId: Scalars['ID'];
  salesChannelName: Scalars['String'];
}

export interface GetDashboardCustomersStatsPayload {
  __typename?: 'GetDashboardCustomersStatsPayload';
  bySalesChannel: Array<GetDashboardCustomersStatsBySalesChannel>;
  totalCount: Scalars['Int'];
}

export interface GetDashboardLicensesStatsBySalesChannel {
  __typename?: 'GetDashboardLicensesStatsBySalesChannel';
  count: Scalars['Int'];
  ordersCount: Scalars['Int'];
  salesChannelId: Scalars['ID'];
  salesChannelName: Scalars['String'];
}

export interface GetDashboardLicensesStatsPayload {
  __typename?: 'GetDashboardLicensesStatsPayload';
  bySalesChannel: Array<GetDashboardLicensesStatsBySalesChannel>;
  totalCount: Scalars['Int'];
  totalOrdersCount: Scalars['Int'];
}

export interface GetDashboardOrdersStatsPayload {
  __typename?: 'GetDashboardOrdersStatsPayload';
  approvedCount: Scalars['Int'];
  approvedRevenue: Scalars['Float'];
  regrettedCount: Scalars['Int'];
  regrettedRevenue: Scalars['Float'];
  submittedCount: Scalars['Int'];
  submittedRevenue: Scalars['Float'];
  totalCount: Scalars['Int'];
  totalRevenue: Scalars['Float'];
}

export interface GetDashboardRegretsStatsBySalesChannel {
  __typename?: 'GetDashboardRegretsStatsBySalesChannel';
  count: Scalars['Int'];
  percent: Scalars['Float'];
  regrettedCount: Scalars['Int'];
  salesChannelId: Scalars['ID'];
  salesChannelName: Scalars['String'];
}

export interface GetDashboardRegretsStatsPayload {
  __typename?: 'GetDashboardRegretsStatsPayload';
  bySalesChannel: Array<GetDashboardRegretsStatsBySalesChannel>;
  totalCount: Scalars['Int'];
  totalPercent: Scalars['Float'];
  totalRegrettedCount: Scalars['Int'];
}

export interface GetDashboardSalesGroupedByPeriodPayload {
  __typename?: 'GetDashboardSalesGroupedByPeriodPayload';
  guaranteedCount: Scalars['Int'];
  guaranteedRevenue: Scalars['Float'];
  periodFrom: Scalars['DateTime'];
  periodTo: Scalars['DateTime'];
  regrets: Array<RegretSaleStats>;
  /** excluding regretted orders */
  totalCount: Scalars['Int'];
  totalCountGross: Scalars['Int'];
  totalRevenue: Scalars['Float'];
  totalRevenueGross: Scalars['Float'];
  underRegretPeriodCount: Scalars['Int'];
  underRegretPeriodRevenue: Scalars['Float'];
}

export interface GetDashboardSalesStatsPayload {
  __typename?: 'GetDashboardSalesStatsPayload';
  guaranteedCount: Scalars['Int'];
  guaranteedRevenue: Scalars['Float'];
  regrets: Array<RegretSaleStats>;
  /** excluding regretted orders */
  totalCount: Scalars['Int'];
  totalRevenue: Scalars['Float'];
  underRegretPeriodCount: Scalars['Int'];
  underRegretPeriodRevenue: Scalars['Float'];
}

export interface GetDashboardSoSafeTodayCurrentDay {
  __typename?: 'GetDashboardSoSafeTodayCurrentDay';
  buttonsCount: Scalars['Int'];
  buttonsCountDiffPercent: Scalars['Float'];
  salesCount: Scalars['Int'];
  salesCountDiffPercent: Scalars['Float'];
  salesValue: Scalars['Float'];
  salesValueDiffPercent: Scalars['Float'];
}

export interface GetDashboardSoSafeTodayLatestSalesStatsPayload {
  __typename?: 'GetDashboardSoSafeTodayLatestSalesStatsPayload';
  fullName: Scalars['String'];
  revenue: Scalars['Float'];
  salesChannelName: Scalars['String'];
  salesDate: Scalars['DateTime'];
}

export interface GetDashboardSoSafeTodayPreviousDay {
  __typename?: 'GetDashboardSoSafeTodayPreviousDay';
  buttonsCount: Scalars['Int'];
  salesCount: Scalars['Int'];
  salesValue: Scalars['Float'];
}

export interface GetDashboardSoSafeTodayStatsPayload {
  __typename?: 'GetDashboardSoSafeTodayStatsPayload';
  today: GetDashboardSoSafeTodayCurrentDay;
  yesterday: GetDashboardSoSafeTodayPreviousDay;
}

export interface GetDashboardSoSafeTodayTopSalesChannelsStatsPayload {
  __typename?: 'GetDashboardSoSafeTodayTopSalesChannelsStatsPayload';
  buttonsCount: Scalars['Int'];
  salesChannelName: Scalars['String'];
  salesCount: Scalars['Int'];
}

export interface GetDashboardSoSafeTodayTopSrsStatsPayload {
  __typename?: 'GetDashboardSoSafeTodayTopSrsStatsPayload';
  buttonsCount: Scalars['Int'];
  fullName: Scalars['String'];
  salesChannelName: Scalars['String'];
  salesCount: Scalars['Int'];
}

export interface GetDashboardSoSafeTotalStatsPayload {
  __typename?: 'GetDashboardSoSafeTotalStatsPayload';
  avgBtnsPerCustomer: Scalars['Float'];
  avgRevenuePerCustomer: Scalars['Float'];
  buttonsTotal: Scalars['Int'];
  customersTotal: Scalars['Int'];
  /** The % of regretted sales */
  regretsPercent: Scalars['Float'];
  salesTotal: Scalars['Float'];
  /** Customers per sales channel (3 largest by default) */
  topSalesChannels: Array<TopSalesChannel>;
}

export interface GetDashboardSoSafeTotalStatsPayloadTopSalesChannelsArgs {
  limit?: InputMaybe<Scalars['Int']>;
}

export interface GetDashboardTopProductAndBundleStatsPayload {
  __typename?: 'GetDashboardTopProductAndBundleStatsPayload';
  bundle?: Maybe<ProductBundle>;
  product?: Maybe<Product>;
  regrettedCount: Scalars['Int'];
  signedCount: Scalars['Int'];
  type: ProductAndBundleStatsType;
}

export interface GetExternalUsersFilterInput {
  fullNameLike?: InputMaybe<Scalars['String']>;
  idEq?: InputMaybe<Scalars['ID']>;
  lastAssigneeIdIn?: InputMaybe<Array<Scalars['ID']>>;
  lastAssigneeLike?: InputMaybe<Scalars['String']>;
  lastCustomerIdEq?: InputMaybe<Scalars['ID']>;
  lastCustomerIdIn?: InputMaybe<Array<Scalars['ID']>>;
  lastCustomerLike?: InputMaybe<Scalars['String']>;
  licenseEndDateFrom?: InputMaybe<Scalars['DateTime']>;
  licenseEndDateTo?: InputMaybe<Scalars['DateTime']>;
  licenseStartDateFrom?: InputMaybe<Scalars['DateTime']>;
  licenseStartDateTo?: InputMaybe<Scalars['DateTime']>;
  phoneNumberLike?: InputMaybe<Scalars['String']>;
  productIdIn?: InputMaybe<Array<Scalars['ID']>>;
}

export interface GetExternalUsersPayload {
  __typename?: 'GetExternalUsersPayload';
  count: Scalars['Int'];
  nodes: Array<ExternalUser>;
}

export interface GetInvoicesFilterInput {
  orderIdEq: Scalars['ID'];
}

export interface GetInvoicesPayload {
  __typename?: 'GetInvoicesPayload';
  count: Scalars['Int'];
  nodes: Array<Invoice>;
}

export interface GetLicenseBatchesPayload {
  __typename?: 'GetLicenseBatchesPayload';
  count: Scalars['Int'];
  nodes: Array<LicenseBatch>;
}

export interface GetLicenseStatisticFilterInput {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  period: StatisticPeriod;
  toDate?: InputMaybe<Scalars['DateTime']>;
}

export interface GetLicenseStatisticPayload {
  __typename?: 'GetLicenseStatisticPayload';
  count: Scalars['Int'];
  nodes: Array<LicenseStatsNode>;
}

export interface GetLicensesPayload {
  __typename?: 'GetLicensesPayload';
  count: Scalars['Int'];
  nodes: Array<License>;
}

export interface GetNotesFilterInput {
  beforeId?: InputMaybe<Scalars['ID']>;
  entityId: Scalars['ID'];
  entityType: NoteEntityType;
}

export interface GetNotesPayload {
  __typename?: 'GetNotesPayload';
  count: Scalars['Int'];
  nodes: Array<Note>;
}

export interface GetOrderCalculationsInput {
  bundles?: InputMaybe<Array<OrderBundleCalculationsInput>>;
  invoicingFrequency?: InputMaybe<Scalars['Int']>;
  isWinBack?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<OrderItemCalculationsInput>>;
  salesChannelId?: InputMaybe<Scalars['ID']>;
  sourceOrderId?: InputMaybe<Scalars['ID']>;
  upgrades?: InputMaybe<Array<OrderUpgradeCalculationInput>>;
  winBackPrice?: InputMaybe<Scalars['Float']>;
}

export interface GetOrderCalulationsPayload {
  __typename?: 'GetOrderCalulationsPayload';
  errors: Array<MutationError>;
  node?: Maybe<OrderCalculation>;
  status: MutationStatus;
}

export interface GetOrderStatusHistoryPayload {
  __typename?: 'GetOrderStatusHistoryPayload';
  count: Scalars['Int'];
  nodes: Array<OrderStatusHistoryItem>;
}

export interface GetOrdersFilterInput {
  addonIdIn?: InputMaybe<Array<Scalars['ID']>>;
  assigneeFullNameLike?: InputMaybe<Scalars['String']>;
  assigneeIdEq?: InputMaybe<Scalars['ID']>;
  assigneeIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  assigneeLike?: InputMaybe<Scalars['String']>;
  bundleIdIn?: InputMaybe<Array<Scalars['ID']>>;
  coAddressLike?: InputMaybe<Scalars['String']>;
  commissionIsNotZero?: InputMaybe<Scalars['Boolean']>;
  contractStatusIn?: InputMaybe<Array<OrderContractStatus>>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  creditCheckCodeEq?: InputMaybe<Scalars['Int']>;
  customerAddressLike?: InputMaybe<Scalars['String']>;
  /** filter by Personnummer, fullName, phoneNumber, email and ID */
  customerLike?: InputMaybe<Scalars['String']>;
  emailLike?: InputMaybe<Scalars['String']>;
  financeSystemEq?: InputMaybe<FinanceSystem>;
  fullNameLike?: InputMaybe<Scalars['String']>;
  idEq?: InputMaybe<Scalars['ID']>;
  idLike?: InputMaybe<Scalars['String']>;
  idStartsWith?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isSale?: InputMaybe<Scalars['Boolean']>;
  isUnsigned?: InputMaybe<Scalars['Boolean']>;
  isWinBack?: InputMaybe<Scalars['Boolean']>;
  personIdLike?: InputMaybe<Scalars['String']>;
  personIdStartsWith?: InputMaybe<Scalars['String']>;
  phoneNumberLike?: InputMaybe<Scalars['String']>;
  productIdIn?: InputMaybe<Array<Scalars['ID']>>;
  projectIdIn?: InputMaybe<Array<Scalars['ID']>>;
  resolvedByIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  roleIdIn?: InputMaybe<Array<Scalars['ID']>>;
  saleStatusEq?: InputMaybe<SaleStatus>;
  salesChannelIdIn?: InputMaybe<Array<Scalars['ID']>>;
  salesFilterDateFrom?: InputMaybe<Scalars['DateTime']>;
  salesFilterDateTo?: InputMaybe<Scalars['DateTime']>;
  scriveContractStatusEq?: InputMaybe<ScriveContractStatus>;
  shippingStatusIn?: InputMaybe<Array<ShippingStatus>>;
  sourceOrderId?: InputMaybe<Scalars['ID']>;
  statusIn?: InputMaybe<Array<OrderStatus>>;
  teamIdIn?: InputMaybe<Array<Scalars['ID']>>;
}

export interface GetOrdersPayload {
  __typename?: 'GetOrdersPayload';
  count: Scalars['Int'];
  nodes: Array<Order>;
}

export interface GetPartnerStoresPayload {
  __typename?: 'GetPartnerStoresPayload';
  count: Scalars['Int'];
  nodes: Array<PartnerStore>;
}

export interface GetPersonCreditCheck {
  __typename?: 'GetPersonCreditCheck';
  approvalCode?: Maybe<Scalars['Int']>;
  approvalText?: Maybe<Scalars['String']>;
  casTemplate?: Maybe<Scalars['String']>;
}

export interface GetPersonCreditCheckPayload {
  __typename?: 'GetPersonCreditCheckPayload';
  check: GetPersonCreditCheck;
  rejectionReasons: Array<Scalars['String']>;
}

export interface GetPersonDataPayload {
  __typename?: 'GetPersonDataPayload';
  errors: Array<Maybe<MutationError>>;
  node?: Maybe<PersonData>;
}

export interface GetProductAddonsPayload {
  __typename?: 'GetProductAddonsPayload';
  count: Scalars['Int'];
  nodes: Array<ProductAddon>;
}

export interface GetProductBundlesPayload {
  __typename?: 'GetProductBundlesPayload';
  count: Scalars['Int'];
  nodes: Array<ProductBundle>;
}

export interface GetProductDiscountsPayload {
  __typename?: 'GetProductDiscountsPayload';
  count: Scalars['Int'];
  nodes: Array<ProductDiscount>;
}

export interface GetProductUnitsPayload {
  __typename?: 'GetProductUnitsPayload';
  count: Scalars['Int'];
  nodes: Array<ProductUnit>;
}

export interface GetProductsPayload {
  __typename?: 'GetProductsPayload';
  count: Scalars['Int'];
  nodes: Array<Product>;
}

export interface GetProjectsPayload {
  __typename?: 'GetProjectsPayload';
  count: Scalars['Int'];
  nodes: Array<Project>;
}

export interface GetPurchasedLicensesFilterInput {
  personId: Scalars['String'];
  phoneNumbers?: InputMaybe<Array<Scalars['String']>>;
  projectId: Scalars['Int'];
}

export interface GetPurchasedLicensesPayload {
  __typename?: 'GetPurchasedLicensesPayload';
  activeLicenses: Array<PurchasedLicense>;
  inactiveLicenses: Array<PurchasedLicense>;
}

export interface GetRelationsPayload {
  __typename?: 'GetRelationsPayload';
  count: Scalars['Int'];
  nodes: Array<Relation>;
}

export interface GetRolesPayload {
  __typename?: 'GetRolesPayload';
  count: Scalars['Int'];
  nodes: Array<Role>;
}

export interface GetSalesChannelsPayload {
  __typename?: 'GetSalesChannelsPayload';
  count: Scalars['Int'];
  nodes: Array<SalesChannel>;
}

export interface GetSearchHistoryFilterInput {
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  personIdLike?: InputMaybe<Scalars['String']>;
  teamMemberIdEq: Scalars['ID'];
}

export interface GetSearchHistoryPayload {
  __typename?: 'GetSearchHistoryPayload';
  count: Scalars['Int'];
  nodes: Array<SearchHistoryItem>;
}

export interface GetTeamMemberOverviewOrdersPayload {
  __typename?: 'GetTeamMemberOverviewOrdersPayload';
  count: Scalars['Int'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  groupBy: TeamMemberOverviewGroupBy;
  revenue: Scalars['Float'];
}

export interface GetTeamMemberOverviewRegretsPayload {
  __typename?: 'GetTeamMemberOverviewRegretsPayload';
  count: Scalars['Int'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  groupBy: TeamMemberOverviewGroupBy;
  revenue: Scalars['Float'];
}

export interface GetTeamMembersPayload {
  __typename?: 'GetTeamMembersPayload';
  count: Scalars['Int'];
  nodes: Array<TeamMember>;
}

export interface GetTeamsPayload {
  __typename?: 'GetTeamsPayload';
  count: Scalars['Int'];
  nodes: Array<Team>;
}

export interface GetTopSalesManagersPayload {
  __typename?: 'GetTopSalesManagersPayload';
  buttonsCount: Scalars['Int'];
  count: Scalars['Int'];
  countDiff: Scalars['Int'];
  position: PositionDirection;
  revenue: Scalars['Float'];
  teamMember: TeamMember;
  teamMemberId: Scalars['ID'];
}

export enum GroupByPeriod {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Year = 'YEAR',
}

export interface Invoice {
  __typename?: 'Invoice';
  _id: Scalars['ID'];
  bundles: Array<ProductBundle>;
  date: Scalars['DateTime'];
  products: Array<Product>;
  status: InvoiceStatus;
  total: Scalars['Float'];
}

export enum InvoiceStatus {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  Unpaid = 'UNPAID',
  UnpaidOverdue = 'UNPAID_OVERDUE',
}

export enum InvoicingType {
  Email = 'EMAIL',
  Printout = 'PRINTOUT',
}

export interface License {
  __typename?: 'License';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['ID']>;
  externalUser: ExternalUser;
  externalUserId: Scalars['ID'];
  financeSystemSyncStatus: FinanceSystemItemSyncStatus;
  licenseEndDate: Scalars['DateTime'];
  licenseStartDate: Scalars['DateTime'];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['ID']>;
  orderItem?: Maybe<OrderItem>;
  orderItemId?: Maybe<Scalars['ID']>;
  product: Product;
  productAddon?: Maybe<ProductAddon>;
  productAddonId?: Maybe<Scalars['ID']>;
  productId: Scalars['ID'];
  status: LicenseStatus;
  updatedAt: Scalars['DateTime'];
}

export interface LicenseBatch {
  __typename?: 'LicenseBatch';
  _id: Scalars['ID'];
  activatedLicenseCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  duration?: Maybe<Scalars['Int']>;
  licenseCount?: Maybe<Scalars['Int']>;
  partnerStore: PartnerStore;
  partnerStoreId: Scalars['ID'];
  product: Product;
  productId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  zipLink?: Maybe<Scalars['String']>;
  zipReady: Scalars['Boolean'];
}

export interface LicenseBatchesFilterInput {
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  durationEq?: InputMaybe<Scalars['Int']>;
  idEq?: InputMaybe<Scalars['ID']>;
  partnerStoreIdEq?: InputMaybe<Scalars['ID']>;
  partnerStoreNameLike?: InputMaybe<Scalars['String']>;
}

export enum LicenseBatchesOrderBy {
  CreatedAt = 'createdAt',
  Duration = 'duration',
  LicenseCount = 'licenseCount',
}

export interface LicenseStatsNode {
  __typename?: 'LicenseStatsNode';
  count: Scalars['Int'];
  fromDate: Scalars['DateTime'];
  period: StatisticPeriod;
  toDate: Scalars['DateTime'];
}

export enum LicenseStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Regretted = 'REGRETTED',
  Terminated = 'TERMINATED',
}

export interface LicensesFilterInput {
  customerIdEq?: InputMaybe<Scalars['ID']>;
  customerNameLike?: InputMaybe<Scalars['String']>;
  idEq?: InputMaybe<Scalars['ID']>;
  licenseEndDateFrom?: InputMaybe<Scalars['DateTime']>;
  licenseEndDateTo?: InputMaybe<Scalars['DateTime']>;
  licenseStartDateFrom?: InputMaybe<Scalars['DateTime']>;
  licenseStartDateTo?: InputMaybe<Scalars['DateTime']>;
  orderIdEq?: InputMaybe<Scalars['ID']>;
  phoneNumberLike?: InputMaybe<Scalars['String']>;
  productAddonIdEq?: InputMaybe<Scalars['ID']>;
  productIdEq?: InputMaybe<Scalars['ID']>;
  statusEq?: InputMaybe<LicenseStatus>;
}

export enum LicensesOrderBy {
  CreatedAt = 'createdAt',
  CustomerName = 'customerName',
  LicenseEndDate = 'licenseEndDate',
  LicenseStartDate = 'licenseStartDate',
}

export interface Mutation {
  __typename?: 'Mutation';
  approveB2BOrder: ApproveOrderPayload;
  approveOrder: ApproveOrderPayload;
  b2bOrderShipmentStatusUpdate: B2BOrderShipmentStatusUpdatePayload;
  checkSignInStatus: CheckSignInStatusPayload;
  createAddonCommission: CreateAddonCommissionPayload;
  createB2BOrder: CreateB2BOrderPayload;
  createBundleCommission: CreateBundleCommissionPayload;
  createCommission: CreateCommissionPayload;
  createLicenseBatch: CreateLicenseBatchPayload;
  createNote: CreateNotePayload;
  createOrder: CreateOrderPayload;
  createPartnerStore: CreatePartnerStorePayload;
  createProduct: CreateProductPayload;
  createProductAddon: CreateProductAddonPayload;
  createProductBundle: CreateProductBundlePayload;
  createProductDiscount: CreateProductDiscountPayload;
  createProject: CreateProjectPayload;
  createRelation: CreateRelationPayload;
  createRole: CreateRolePayload;
  createSalesChannel: CreateSalesChannelPayload;
  createTeam: CreateTeamPayload;
  createTeamMember: CreateTeamMemberPayload;
  createWinBack: CreateWinBackPayload;
  deleteAddonCommission: DeleteAddonCommissionPayload;
  deleteB2BOrder: DeleteB2BOrderPayload;
  deleteB2BUnsignedOrder: DeleteB2BUnsignedOrderPayload;
  deleteBundleCommission: DeleteBundleCommissionPayload;
  deleteCommission: DeleteCommissionPayload;
  deleteOrder: DeleteOrderPayload;
  deletePartnerStore: DeletePartnerStorePayload;
  deleteProduct: DeleteProductPayload;
  deleteProductAddon: DeleteProductAddonPayload;
  deleteProductBundle: DeleteProductBundlePayload;
  deleteProductDiscount: DeleteProductDiscountPayload;
  deleteProject: DeleteProjectPayload;
  deleteRelation: DeleteRelationPayload;
  deleteRole: DeleteRolePayload;
  deleteSalesChannel: DeleteSalesChannelPayload;
  deleteTeam: DeleteTeamPayload;
  deleteTeamMember: DeleteTeamMemberPayload;
  deleteUnsignedOrder: DeleteUnsignedOrderPayload;
  endOrder: DeleteOrderPayload;
  orderShipmentStatusUpdate: OrderShipmentStatusUpdatePayload;
  refreshToken: RefreshTokenPayload;
  regretOrder: RegretOrderPayload;
  sendB2BOrderContract: SendB2BOrderContractPayload;
  sendOrderContract: SendOrderContractPayload;
  setPassword: SetPasswordPayload;
  signIn: SignInPayload;
  signInWithPassword: SignInWithPasswordPayload;
  terminateLicense: TerminateLicensePayload;
  updateAddonCommission: UpdateAddonCommissionPayload;
  updateB2BOrder: UpdateB2BOrderPayload;
  updateBundleCommission: UpdateBundleCommissionPayload;
  updateCommission: UpdateCommissionPayload;
  updateOrder: UpdateOrderPayload;
  updatePartnerStore: UpdatePartnerStorePayload;
  updateProduct: UpdateProductPayload;
  updateProductAddon: UpdateProductAddonPayload;
  updateProductBundle: UpdateProductBundlePayload;
  updateProductDiscount: UpdateProductDiscountPayload;
  updateProject: UpdateProjectPayload;
  updateRelation: UpdateRelationPayload;
  updateRole: UpdateRolePayload;
  updateRoleAddPermission: UpdateRoleAddPermissionPayload;
  updateRoleRemovePermission: UpdateRoleRemovePermissionPayload;
  updateSalesChannel: UpdateSalesChannelPayload;
  updateTeam: UpdateTeamPayload;
  updateTeamMember: UpdateTeamMemberPayload;
  updateWinBack: UpdateWinBackPayload;
}

export interface MutationApproveB2BOrderArgs {
  input: ApproveOrderInput;
}

export interface MutationApproveOrderArgs {
  input: ApproveOrderInput;
}

export interface MutationB2bOrderShipmentStatusUpdateArgs {
  input: B2BOrderShipmentStatusUpdateInput;
}

export interface MutationCheckSignInStatusArgs {
  input: CheckSignInStatusInput;
}

export interface MutationCreateAddonCommissionArgs {
  input: CreateAddonCommissionInput;
}

export interface MutationCreateB2BOrderArgs {
  input: CreateB2BOrderInput;
}

export interface MutationCreateBundleCommissionArgs {
  input: CreateBundleCommissionInput;
}

export interface MutationCreateCommissionArgs {
  input: CreateCommissionInput;
}

export interface MutationCreateLicenseBatchArgs {
  input: CreateLicenseBatchInput;
}

export interface MutationCreateNoteArgs {
  input?: InputMaybe<CreateNoteInput>;
}

export interface MutationCreateOrderArgs {
  input: CreateOrderInput;
}

export interface MutationCreatePartnerStoreArgs {
  input: CreatePartnerStoreInput;
}

export interface MutationCreateProductArgs {
  input: CreateProductInput;
}

export interface MutationCreateProductAddonArgs {
  input: CreateProductAddonInput;
}

export interface MutationCreateProductBundleArgs {
  input: CreateProductBundleInput;
}

export interface MutationCreateProductDiscountArgs {
  input: CreateProductDiscountInput;
}

export interface MutationCreateProjectArgs {
  input: CreateProjectInput;
}

export interface MutationCreateRelationArgs {
  input: CreateRelationInput;
}

export interface MutationCreateRoleArgs {
  input: CreateRoleInput;
}

export interface MutationCreateSalesChannelArgs {
  input: CreateSalesChannelInput;
}

export interface MutationCreateTeamArgs {
  input: CreateTeamInput;
}

export interface MutationCreateTeamMemberArgs {
  input: CreateTeamMemberInput;
}

export interface MutationCreateWinBackArgs {
  input: CreateWinBackInput;
}

export interface MutationDeleteAddonCommissionArgs {
  input: DeleteAddonCommissionInput;
}

export interface MutationDeleteB2BOrderArgs {
  input: DeleteB2BOrderInput;
}

export interface MutationDeleteB2BUnsignedOrderArgs {
  input: DeleteB2BUnsignedOrderInput;
}

export interface MutationDeleteBundleCommissionArgs {
  input: DeleteBundleCommissionInput;
}

export interface MutationDeleteCommissionArgs {
  input: DeleteCommissionInput;
}

export interface MutationDeleteOrderArgs {
  input: DeleteOrderInput;
}

export interface MutationDeletePartnerStoreArgs {
  input: DeletePartnerStoreInput;
}

export interface MutationDeleteProductArgs {
  input: DeleteProductInput;
}

export interface MutationDeleteProductAddonArgs {
  input: DeleteProductAddonInput;
}

export interface MutationDeleteProductBundleArgs {
  input: DeleteProductBundleInput;
}

export interface MutationDeleteProductDiscountArgs {
  input: DeleteProductDiscountInput;
}

export interface MutationDeleteProjectArgs {
  input: DeleteProjectInput;
}

export interface MutationDeleteRelationArgs {
  input: DeleteRelationInput;
}

export interface MutationDeleteRoleArgs {
  input: DeleteRoleInput;
}

export interface MutationDeleteSalesChannelArgs {
  input: DeleteSalesChannelInput;
}

export interface MutationDeleteTeamArgs {
  input: DeleteTeamInput;
}

export interface MutationDeleteTeamMemberArgs {
  input: DeleteTeamMemberInput;
}

export interface MutationDeleteUnsignedOrderArgs {
  input: DeleteUnsignedOrderInput;
}

export interface MutationEndOrderArgs {
  input: EndOrderInput;
}

export interface MutationOrderShipmentStatusUpdateArgs {
  input: OrderShipmentStatusUpdateInput;
}

export interface MutationRefreshTokenArgs {
  input?: InputMaybe<RefreshTokenInput>;
}

export interface MutationRegretOrderArgs {
  input: RegretOrderInput;
}

export interface MutationSendB2BOrderContractArgs {
  input: SendB2BOrderContractInput;
}

export interface MutationSendOrderContractArgs {
  input: SendOrderContractInput;
}

export interface MutationSetPasswordArgs {
  input: SetPasswordInput;
}

export interface MutationSignInArgs {
  input?: InputMaybe<SignInInput>;
}

export interface MutationSignInWithPasswordArgs {
  input: SignInWithPasswordInput;
}

export interface MutationTerminateLicenseArgs {
  input: TerminateLicenseInput;
}

export interface MutationUpdateAddonCommissionArgs {
  input: UpdateAddonCommissionInput;
}

export interface MutationUpdateB2BOrderArgs {
  input: UpdateB2BOrderInput;
}

export interface MutationUpdateBundleCommissionArgs {
  input: UpdateBundleCommissionInput;
}

export interface MutationUpdateCommissionArgs {
  input: UpdateCommissionInput;
}

export interface MutationUpdateOrderArgs {
  input: UpdateOrderInput;
}

export interface MutationUpdatePartnerStoreArgs {
  input: UpdatePartnerStoreInput;
}

export interface MutationUpdateProductArgs {
  input: UpdateProductInput;
}

export interface MutationUpdateProductAddonArgs {
  input: UpdateProductAddonInput;
}

export interface MutationUpdateProductBundleArgs {
  input: UpdateProductBundleInput;
}

export interface MutationUpdateProductDiscountArgs {
  input: UpdateProductDiscountInput;
}

export interface MutationUpdateProjectArgs {
  input: UpdateProjectInput;
}

export interface MutationUpdateRelationArgs {
  input: UpdateRelationInput;
}

export interface MutationUpdateRoleArgs {
  input: UpdateRoleInput;
}

export interface MutationUpdateRoleAddPermissionArgs {
  input: UpdateRoleAddPermissionInput;
}

export interface MutationUpdateRoleRemovePermissionArgs {
  input: UpdateRoleRemovePermissionInput;
}

export interface MutationUpdateSalesChannelArgs {
  input: UpdateSalesChannelInput;
}

export interface MutationUpdateTeamArgs {
  input: UpdateTeamInput;
}

export interface MutationUpdateTeamMemberArgs {
  input: UpdateTeamMemberInput;
}

export interface MutationUpdateWinBackArgs {
  input: UpdateWinBackInput;
}

export interface MutationError {
  __typename?: 'MutationError';
  code: Scalars['String'];
  field?: Maybe<Scalars['String']>;
}

export enum MutationStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export interface Note {
  __typename?: 'Note';
  _id: Scalars['ID'];
  author?: Maybe<TeamMember>;
  authorId?: Maybe<Scalars['ID']>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entityId: Scalars['ID'];
  entityType: NoteEntityType;
}

export enum NoteEntityType {
  B2BOrder = 'B2B_ORDER',
  Customer = 'CUSTOMER',
  Order = 'ORDER',
}

export interface Order {
  __typename?: 'Order';
  _id: Scalars['ID'];
  assignee: TeamMember;
  assigneeId: Scalars['ID'];
  bundles: Array<OrderBundleItem>;
  coAddress: Scalars['String'];
  commissionGross: Scalars['Float'];
  commissionNet: Scalars['Float'];
  commissionRegret: Scalars['Float'];
  contractSignedDate?: Maybe<Scalars['DateTime']>;
  contractStatus: OrderContractStatus;
  createdAt: Scalars['DateTime'];
  creditCheckCode: Scalars['Int'];
  customer?: Maybe<Customer>;
  customerAddress: Scalars['String'];
  customerId?: Maybe<Scalars['ID']>;
  customerZip: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<TeamMember>;
  deletedById?: Maybe<Scalars['ID']>;
  discountAmount: Scalars['Float'];
  discountPercent: Scalars['Float'];
  email: Scalars['String'];
  financeSystem: FinanceSystem;
  financeSystemSyncStatus: FinanceSystemSyncStatus;
  fortnoxId?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  fullSubtotalWithAddons: Scalars['Float'];
  fullTotal: Scalars['Float'];
  fullTotalGross: Scalars['Float'];
  fullTotalNet: Scalars['Float'];
  fullTotalRegret: Scalars['Float'];
  fullTotalWithAddons: Scalars['Float'];
  hasArvatoSubscriptions: Scalars['Boolean'];
  invoicingAddress?: Maybe<Scalars['String']>;
  invoicingFrequency: Scalars['Int'];
  invoicingType: InvoicingType;
  invoicingZip?: Maybe<Scalars['String']>;
  isActiveCustomer: Scalars['Boolean'];
  isCreditCheckReceived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isFromShopPage: Scalars['Boolean'];
  isRegrettable: Scalars['Boolean'];
  isRegrettableDate?: Maybe<Scalars['DateTime']>;
  isUnsigned: Scalars['Boolean'];
  isWinBack?: Maybe<Scalars['Boolean']>;
  items: Array<OrderItem>;
  monthlySubtotal: Scalars['Float'];
  monthlyTotal: Scalars['Float'];
  notes: GetNotesPayload;
  onePaymentTotal: Scalars['Float'];
  personId: Scalars['String'];
  phoneNumber: Scalars['String'];
  project: Project;
  projectId: Scalars['ID'];
  quarterSubtotal: Scalars['Float'];
  regretOption?: Maybe<RegretOption>;
  regretReason?: Maybe<Scalars['String']>;
  resolvedBy?: Maybe<TeamMember>;
  resolvedById?: Maybe<Scalars['ID']>;
  salesChannel: SalesChannel;
  salesChannelId: Scalars['ID'];
  scriveContractId?: Maybe<Scalars['String']>;
  scriveContractStatus: ScriveContractStatus;
  shippingStatus: ShippingStatus;
  soSafeCoupon?: Maybe<SoSafeCoupon>;
  sourceOrder?: Maybe<Order>;
  sourceOrderId?: Maybe<Scalars['ID']>;
  sourceOrders?: Maybe<Array<Order>>;
  status: OrderStatus;
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  upgrades: Array<OrderUpgradeItem>;
  winBackDuration?: Maybe<Scalars['Int']>;
  winBackFullTotal: Scalars['Float'];
  winBackPrice: Scalars['Float'];
}

export interface OrderNotesArgs {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface OrderBundleCalculation {
  __typename?: 'OrderBundleCalculation';
  addonsPrice: Scalars['Float'];
  itemKey: Scalars['String'];
  items: Array<OrderItemCalculation>;
  productBundleId: Scalars['ID'];
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
}

export interface OrderBundleCalculationsInput {
  itemKey?: InputMaybe<Scalars['String']>;
  items: Array<OrderItemCalculationsInput>;
  postponeInvoicing?: InputMaybe<Scalars['Int']>;
  productBundleId: Scalars['ID'];
}

export interface OrderBundleInput {
  items: Array<InputMaybe<OrderItemInput>>;
  postponeInvoicing?: InputMaybe<Scalars['Int']>;
  productBundleId: Scalars['ID'];
}

export interface OrderBundleItem {
  __typename?: 'OrderBundleItem';
  addonsPrice: Scalars['Float'];
  commission?: Maybe<Commission>;
  commissionAmount: Scalars['Float'];
  commissionId?: Maybe<Scalars['ID']>;
  financeSystemSyncStatus: FinanceSystemItemSyncStatus;
  fortnoxId?: Maybe<Scalars['Int']>;
  items: Array<OrderItem>;
  postponeInvoicing: Scalars['Int'];
  productBundle: ProductBundle;
  productBundleId: Scalars['ID'];
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
}

export interface OrderCalculation {
  __typename?: 'OrderCalculation';
  bundles: Array<OrderBundleCalculation>;
  discountAmount: Scalars['Float'];
  discountPercent: Scalars['Float'];
  fullSubtotalWithAddons: Scalars['Float'];
  fullTotal: Scalars['Float'];
  fullTotalWithAddons: Scalars['Float'];
  items: Array<OrderItemCalculation>;
  monthlySubtotal: Scalars['Float'];
  monthlyTotal: Scalars['Float'];
  onePaymentTotal: Scalars['Float'];
  quarterSubtotal: Scalars['Float'];
  soSafeCoupon?: Maybe<SoSafeCoupon>;
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
  upgrades: Array<OrderUpgradeCalculationItem>;
}

export enum OrderContractStatus {
  Ended = 'ENDED',
  Error = 'ERROR',
  Signed = 'SIGNED',
  Unsigned = 'UNSIGNED',
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface OrderItem {
  __typename?: 'OrderItem';
  addonsPrice: Scalars['Float'];
  commission?: Maybe<Commission>;
  commissionAmount: Scalars['Float'];
  commissionId?: Maybe<Scalars['ID']>;
  discount?: Maybe<ProductDiscount>;
  discountAmount: Scalars['Float'];
  discountId?: Maybe<Scalars['ID']>;
  discountPercent: Scalars['Float'];
  financeSystemSyncStatus: FinanceSystemItemSyncStatus;
  fortnoxId?: Maybe<Scalars['Int']>;
  fullName?: Maybe<Scalars['String']>;
  license?: Maybe<License>;
  personId?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postponeInvoicing: Scalars['Int'];
  product: Product;
  productAddonIds: Array<Scalars['ID']>;
  productAddons: Array<OrderItemProductAddon>;
  productId: Scalars['ID'];
  qty: Scalars['Int'];
  relation: Relation;
  relationId: Scalars['ID'];
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
}

export interface OrderItemCalculation {
  __typename?: 'OrderItemCalculation';
  addonsPrice: Scalars['Float'];
  discountAmount: Scalars['Float'];
  discountPercent: Scalars['Float'];
  itemKey: Scalars['String'];
  qty: Scalars['Int'];
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
}

export interface OrderItemCalculationsInput {
  itemKey?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postponeInvoicing?: InputMaybe<Scalars['Int']>;
  productAddonIds?: InputMaybe<Array<Scalars['ID']>>;
  productId: Scalars['ID'];
  qty: Scalars['Int'];
}

export interface OrderItemInput {
  fullName?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postponeInvoicing?: InputMaybe<Scalars['Int']>;
  productAddonIds?: InputMaybe<Array<Scalars['ID']>>;
  productId: Scalars['ID'];
  qty: Scalars['Int'];
  relationId: Scalars['ID'];
}

export interface OrderItemProductAddon {
  __typename?: 'OrderItemProductAddon';
  license?: Maybe<License>;
  productAddon: ProductAddon;
}

export interface OrderShipmentStatusUpdateInput {
  orderIds: Array<Scalars['ID']>;
  shippingStatus: ShippingStatus;
}

export interface OrderShipmentStatusUpdatePayload {
  __typename?: 'OrderShipmentStatusUpdatePayload';
  errors?: Maybe<Array<MutationError>>;
  status: MutationStatus;
}

export enum OrderStatus {
  Approved = 'APPROVED',
  Ended = 'ENDED',
  Pending = 'PENDING',
  Regretted = 'REGRETTED',
  Winback = 'WINBACK',
}

export interface OrderStatusHistoryItem {
  __typename?: 'OrderStatusHistoryItem';
  byTeamMember: TeamMember;
  byTeamMemberId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  orderId: Scalars['ID'];
  regretOption?: Maybe<RegretOption>;
  regretReason?: Maybe<Scalars['String']>;
  status: OrderStatus;
  winbackInfo?: Maybe<WinbackInfo>;
}

export interface OrderUpgradeCalculationInput {
  itemKey?: InputMaybe<Scalars['String']>;
  licenseId: Scalars['ID'];
  productAddonIds: Array<Scalars['ID']>;
}

export interface OrderUpgradeCalculationItem {
  __typename?: 'OrderUpgradeCalculationItem';
  itemKey: Scalars['String'];
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
}

export interface OrderUpgradeInput {
  licenseId: Scalars['ID'];
  personId?: InputMaybe<Scalars['String']>;
  productAddonIds: Array<Scalars['ID']>;
}

export interface OrderUpgradeItem {
  __typename?: 'OrderUpgradeItem';
  license: License;
  licenseId: Scalars['ID'];
  productAddons: Array<OrderItemProductAddon>;
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
}

export enum OrdersOrderBy {
  ContractSignedDate = 'contractSignedDate',
  CreatedAt = 'createdAt',
  FullName = 'fullName',
  FullTotalWithAddons = 'fullTotalWithAddons',
  SalesFilterDate = 'salesFilterDate',
  Total = 'total',
}

export interface PartnerStore {
  __typename?: 'PartnerStore';
  _id: Scalars['ID'];
  activatedLicenseCount: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  linkToSite?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  project: Project;
  projectId: Scalars['ID'];
  totalLicenseCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
}

export interface PartnerStoresFilterInput {
  addressLike?: InputMaybe<Scalars['String']>;
  contactPersonLike?: InputMaybe<Scalars['String']>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  emailLike?: InputMaybe<Scalars['String']>;
  nameLike?: InputMaybe<Scalars['String']>;
  phoneNumberLike?: InputMaybe<Scalars['String']>;
}

export enum PartnerStoresOrderBy {
  CreatedAt = 'createdAt',
  Name = 'name',
  TotalLicenseCount = 'totalLicenseCount',
}

export interface PermissionDescription {
  __typename?: 'PermissionDescription';
  _id: Scalars['ID'];
  resource: RbacResourceName;
}

export interface PermissionsListPayload {
  __typename?: 'PermissionsListPayload';
  nodes: Array<PermissionDescription>;
}

export interface PersonData {
  __typename?: 'PersonData';
  address?: Maybe<Scalars['String']>;
  allFields: Array<AdditionalField>;
  coAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
}

export enum PositionDirection {
  Down = 'DOWN',
  Same = 'SAME',
  Up = 'UP',
}

export interface Product {
  __typename?: 'Product';
  _id: Scalars['ID'];
  canBeTerminated: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  freeMonths: Scalars['Int'];
  gallery: Array<FileNode>;
  hasCommissions: Scalars['Boolean'];
  isCreditCheckRequired: Scalars['Boolean'];
  isInStok: Scalars['Boolean'];
  isPersonIdRequired: Scalars['Boolean'];
  isStockable: Scalars['Boolean'];
  isSubscription: Scalars['Boolean'];
  licenseDurationMonths?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  nameInContract: Scalars['String'];
  price: Scalars['Float'];
  productAddons: Array<ProductAddon>;
  project: Project;
  projectId: Scalars['ID'];
  status: ProductStatus;
  unitId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  vatPrice: Scalars['Float'];
  vatRate: Scalars['Float'];
}

export interface ProductAddon {
  __typename?: 'ProductAddon';
  _id: Scalars['ID'];
  canBeTerminated: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  freeMonths: Scalars['Int'];
  isCreditCheckRequired: Scalars['Boolean'];
  isInStok: Scalars['Boolean'];
  isPersonIdRequired: Scalars['Boolean'];
  isStockable: Scalars['Boolean'];
  isSubscription: Scalars['Boolean'];
  licenseDurationMonths?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  product: Product;
  productId: Scalars['ID'];
  project: Project;
  projectId: Scalars['ID'];
  status: ProductAddonStatus;
  unitId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  vatPrice: Scalars['Float'];
  vatRate: Scalars['Float'];
}

export enum ProductAddonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface ProductAddonsFilterInput {
  idEq?: InputMaybe<Scalars['ID']>;
  nameLike?: InputMaybe<Scalars['String']>;
  productIdIn?: InputMaybe<Array<Scalars['ID']>>;
  statusEq?: InputMaybe<ProductAddonStatus>;
}

export enum ProductAddonsOrderBy {
  CreatedAt = 'createdAt',
  Name = 'name',
  Price = 'price',
  VatPrice = 'vatPrice',
  VatRate = 'vatRate',
}

export enum ProductAndBundleStatsType {
  Bundle = 'BUNDLE',
  Product = 'PRODUCT',
}

export interface ProductBundle {
  __typename?: 'ProductBundle';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  licenseDurationMonths: Scalars['Int'];
  name: Scalars['String'];
  nameInContract: Scalars['String'];
  price: Scalars['Float'];
  products: Array<ProductBundleToProduct>;
  project: Project;
  projectId: Scalars['ID'];
  status: ProductStatus;
  updatedAt: Scalars['DateTime'];
  vatPrice: Scalars['Float'];
  vatRate: Scalars['Float'];
}

export interface ProductBundleToProduct {
  __typename?: 'ProductBundleToProduct';
  product: Product;
  productId: Scalars['ID'];
  qty: Scalars['Int'];
}

export interface ProductBundleToProductInput {
  productId: Scalars['ID'];
  qty: Scalars['Int'];
}

export interface ProductBundlesFilterInput {
  nameLike?: InputMaybe<Scalars['String']>;
  projectIdIn?: InputMaybe<Array<Scalars['ID']>>;
  statusEq?: InputMaybe<ProductStatus>;
}

export enum ProductBundlesOrderBy {
  CreatedAt = 'createdAt',
  Name = 'name',
}

export interface ProductDiscount {
  __typename?: 'ProductDiscount';
  _id: Scalars['ID'];
  author: TeamMember;
  authorId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  discount: Scalars['Float'];
  isDeletable: Scalars['Boolean'];
  product: Product;
  productId: Scalars['ID'];
  productPrice: Scalars['Float'];
  productVatPrice: Scalars['Float'];
  qty: Scalars['Int'];
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['ID']>;
  salesChannel?: Maybe<SalesChannel>;
  salesChannelId?: Maybe<Scalars['ID']>;
  status: ProductDiscountStatus;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']>;
  teamMember?: Maybe<TeamMember>;
  teamMemberId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
}

export enum ProductDiscountStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface ProductDiscountsFilterInput {
  applicableForTeamMemberId?: InputMaybe<Scalars['ID']>;
  authorLike?: InputMaybe<Scalars['String']>;
  productIdEq?: InputMaybe<Scalars['ID']>;
  roleIdIn?: InputMaybe<Array<Scalars['ID']>>;
  salesChannelIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamMemberIdIn?: InputMaybe<Array<Scalars['ID']>>;
  teamMemberLike?: InputMaybe<Scalars['String']>;
}

export enum ProductDiscountsOrderBy {
  CreatedAt = 'createdAt',
  Discount = 'discount',
  Price = 'price',
  Qty = 'qty',
  VatRate = 'vatRate',
}

export enum ProductStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface ProductUnit {
  __typename?: 'ProductUnit';
  _id: Scalars['ID'];
  title: Scalars['String'];
}

export interface ProductsFilterInput {
  idEq?: InputMaybe<Scalars['ID']>;
  isSellableByTeamMemberId?: InputMaybe<Scalars['ID']>;
  nameLike?: InputMaybe<Scalars['String']>;
  projectIdIn?: InputMaybe<Array<Scalars['ID']>>;
  statusEq?: InputMaybe<ProductStatus>;
}

export enum ProductsOrderBy {
  CreatedAt = 'createdAt',
  Name = 'name',
  Price = 'price',
  VatPrice = 'vatPrice',
  VatRate = 'vatRate',
}

export interface Project {
  __typename?: 'Project';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  productsCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
}

export interface ProjectsFilterInput {
  idEq?: InputMaybe<Scalars['ID']>;
  nameLike?: InputMaybe<Scalars['String']>;
}

export enum ProjectsOrderBy {
  CreatedAt = 'createdAt',
  Name = 'name',
  ProductsCount = 'productsCount',
}

export interface PurchasedLicense {
  __typename?: 'PurchasedLicense';
  _id: Scalars['ID'];
  availableProductAddons: Array<ProductAddon>;
  createdAt: Scalars['DateTime'];
  daysLeft: Scalars['Int'];
  discountAmount: Scalars['Float'];
  discountPercent: Scalars['Float'];
  externalUser: ExternalUser;
  externalUserId: Scalars['ID'];
  financeSystemSyncStatus: FinanceSystemItemSyncStatus;
  isFromBundle: Scalars['Boolean'];
  order: Order;
  orderId: Scalars['ID'];
  postponeInvoicing: Scalars['Int'];
  product: Product;
  productId: Scalars['ID'];
  purchasedProductAddons: Array<ProductAddon>;
  relation: Relation;
  relationId: Scalars['ID'];
  signedAt?: Maybe<Scalars['DateTime']>;
  status: LicenseStatus;
  subtotal: Scalars['Float'];
  total: Scalars['Float'];
  willEndAt?: Maybe<Scalars['DateTime']>;
}

export interface Query {
  __typename?: 'Query';
  getAddonCommission?: Maybe<AddonCommission>;
  getAddonCommissions: GetAddonCommissionsPayload;
  getB2BContractStatusHistory: GetB2BContractStatusHistoryPayload;
  getB2BCustomer?: Maybe<B2BCustomer>;
  getB2BCustomers: GetB2BCustomersPayload;
  getB2BOrder?: Maybe<B2BOrder>;
  getB2BOrderStatusHistory: GetB2BOrderStatusHistoryPayload;
  getB2BOrders: GetB2BOrdersPayload;
  getB2BSearchHistory: GetB2BSearchHistoryPayload;
  getB2BSearchHistoryItem?: Maybe<B2BSearchHistoryItem>;
  getBundleCommission?: Maybe<BundleCommission>;
  getBundleCommissions: GetBundleCommissionsPayload;
  getChangesLogs: GetChangesLogsPayload;
  getCommission?: Maybe<Commission>;
  getCommissions: GetCommissionsPayload;
  getCompanyCreditCheck?: Maybe<GetCompanyCreditCheckPayload>;
  getCompanyData?: Maybe<GetCompanyDataPayload>;
  getContractStatusHistory: GetContractStatusHistoryPayload;
  getCustomer?: Maybe<Customer>;
  getCustomers: GetCustomersPayload;
  getDashboardCommissionsStats: GetDashboardCommissionsStatsPayload;
  getDashboardCustomersStats: GetDashboardCustomersStatsPayload;
  getDashboardLicensesStats: GetDashboardLicensesStatsPayload;
  getDashboardOrdersStats: GetDashboardOrdersStatsPayload;
  getDashboardRegretsStats: GetDashboardRegretsStatsPayload;
  getDashboardSalesGroupedByPeriod: Array<GetDashboardSalesGroupedByPeriodPayload>;
  getDashboardSalesStats: GetDashboardSalesStatsPayload;
  getDashboardSoSafeTodayLatestSalesStats: Array<GetDashboardSoSafeTodayLatestSalesStatsPayload>;
  getDashboardSoSafeTodayStats: GetDashboardSoSafeTodayStatsPayload;
  getDashboardSoSafeTodayTopSRsStats: Array<GetDashboardSoSafeTodayTopSrsStatsPayload>;
  getDashboardSoSafeTodayTopSalesChannelsStats: Array<GetDashboardSoSafeTodayTopSalesChannelsStatsPayload>;
  getDashboardSoSafeTotalStats: GetDashboardSoSafeTotalStatsPayload;
  getDashboardTopProductAndBundleStats: Array<GetDashboardTopProductAndBundleStatsPayload>;
  getDashboardTopSalesManagers: Array<GetTopSalesManagersPayload>;
  getExternalUser?: Maybe<ExternalUser>;
  getExternalUsers: GetExternalUsersPayload;
  getInvoices: GetInvoicesPayload;
  getLicense?: Maybe<License>;
  getLicenseBatches: GetLicenseBatchesPayload;
  getLicenseInStatistic?: Maybe<GetLicenseStatisticPayload>;
  getLicenseOutStatistic?: Maybe<GetLicenseStatisticPayload>;
  getLicenses: GetLicensesPayload;
  getNotes: GetNotesPayload;
  getOrder?: Maybe<Order>;
  getOrderCalculations: GetOrderCalulationsPayload;
  getOrderStatusHistory: GetOrderStatusHistoryPayload;
  getOrders: GetOrdersPayload;
  getPartnerStore?: Maybe<PartnerStore>;
  getPartnerStores: GetPartnerStoresPayload;
  getPermisionsList: PermissionsListPayload;
  getPersonCreditCheck?: Maybe<GetPersonCreditCheckPayload>;
  getPersonData?: Maybe<GetPersonDataPayload>;
  getProduct?: Maybe<Product>;
  getProductAddon?: Maybe<ProductAddon>;
  getProductAddons: GetProductAddonsPayload;
  getProductBundle?: Maybe<ProductBundle>;
  getProductBundles: GetProductBundlesPayload;
  getProductDiscount?: Maybe<ProductDiscount>;
  getProductDiscounts: GetProductDiscountsPayload;
  getProductUnits: GetProductUnitsPayload;
  getProducts: GetProductsPayload;
  getProject?: Maybe<Project>;
  getProjects: GetProjectsPayload;
  getPurchasedLicenses: GetPurchasedLicensesPayload;
  getRelation?: Maybe<Relation>;
  getRelations: GetRelationsPayload;
  getRole?: Maybe<Role>;
  getRoles: GetRolesPayload;
  getSalesChannel?: Maybe<SalesChannel>;
  getSalesChannels: GetSalesChannelsPayload;
  getSearchHistory: GetSearchHistoryPayload;
  getSearchHistoryItem?: Maybe<SearchHistoryItem>;
  getTeam?: Maybe<Team>;
  getTeamMember?: Maybe<TeamMember>;
  getTeamMemberOverviewOrders: Array<GetTeamMemberOverviewOrdersPayload>;
  getTeamMemberOverviewRegrets: Array<GetTeamMemberOverviewRegretsPayload>;
  getTeamMembers: GetTeamMembersPayload;
  getTeams: GetTeamsPayload;
  me: TeamMember;
}

export interface QueryGetAddonCommissionArgs {
  _id: Scalars['ID'];
}

export interface QueryGetAddonCommissionsArgs {
  filter?: InputMaybe<AddonCommissionsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<AddonCommissionsOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetB2BContractStatusHistoryArgs {
  orderId: Scalars['ID'];
}

export interface QueryGetB2BCustomerArgs {
  _id: Scalars['ID'];
}

export interface QueryGetB2BCustomersArgs {
  filter?: InputMaybe<GetB2BCustomersFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<B2BCustomersOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetB2BOrderArgs {
  _id: Scalars['ID'];
}

export interface QueryGetB2BOrderStatusHistoryArgs {
  orderId: Scalars['ID'];
}

export interface QueryGetB2BOrdersArgs {
  filter?: InputMaybe<GetB2BOrdersFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<B2BOrdersOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetB2BSearchHistoryArgs {
  filter?: InputMaybe<GetB2BSearchHistoryFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<B2BSearchHistoryOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetB2BSearchHistoryItemArgs {
  _id: Scalars['ID'];
}

export interface QueryGetBundleCommissionArgs {
  _id: Scalars['ID'];
}

export interface QueryGetBundleCommissionsArgs {
  filter?: InputMaybe<BundleCommissionsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<BundleCommissionsOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetChangesLogsArgs {
  filter?: InputMaybe<ChangesLogsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<ChangesLogsOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetCommissionArgs {
  _id: Scalars['ID'];
}

export interface QueryGetCommissionsArgs {
  filter?: InputMaybe<CommissionsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<CommissionsOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetCompanyCreditCheckArgs {
  orgId: Scalars['String'];
}

export interface QueryGetCompanyDataArgs {
  orgId: Scalars['String'];
}

export interface QueryGetContractStatusHistoryArgs {
  orderId: Scalars['ID'];
}

export interface QueryGetCustomerArgs {
  _id: Scalars['ID'];
}

export interface QueryGetCustomersArgs {
  filter?: InputMaybe<GetCustomersFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<CustomersOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetDashboardCommissionsStatsArgs {
  filter?: InputMaybe<DashboardFilterInput>;
}

export interface QueryGetDashboardCustomersStatsArgs {
  filter?: InputMaybe<DashboardFilterInput>;
}

export interface QueryGetDashboardLicensesStatsArgs {
  filter?: InputMaybe<DashboardFilterInput>;
}

export interface QueryGetDashboardOrdersStatsArgs {
  filter?: InputMaybe<DashboardFilterInput>;
}

export interface QueryGetDashboardRegretsStatsArgs {
  filter?: InputMaybe<DashboardFilterInput>;
}

export interface QueryGetDashboardSalesGroupedByPeriodArgs {
  filter?: InputMaybe<DashboardFilterInput>;
  groupBy: GroupByPeriod;
}

export interface QueryGetDashboardSalesStatsArgs {
  filter?: InputMaybe<DashboardFilterInput>;
}

export interface QueryGetDashboardSoSafeTodayLatestSalesStatsArgs {
  limit?: InputMaybe<Scalars['Int']>;
  timezone: Scalars['String'];
}

export interface QueryGetDashboardSoSafeTodayTopSRsStatsArgs {
  limit?: InputMaybe<Scalars['Int']>;
  timezone: Scalars['String'];
}

export interface QueryGetDashboardSoSafeTodayTopSalesChannelsStatsArgs {
  limit?: InputMaybe<Scalars['Int']>;
  timezone: Scalars['String'];
}

export interface QueryGetDashboardTopProductAndBundleStatsArgs {
  filter?: InputMaybe<DashboardFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetDashboardTopSalesManagersArgs {
  filter?: InputMaybe<DashboardFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetExternalUserArgs {
  _id: Scalars['ID'];
}

export interface QueryGetExternalUsersArgs {
  filter?: InputMaybe<GetExternalUsersFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<ExternalUsersOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetInvoicesArgs {
  filter: GetInvoicesFilterInput;
}

export interface QueryGetLicenseArgs {
  _id: Scalars['ID'];
}

export interface QueryGetLicenseBatchesArgs {
  filter?: InputMaybe<LicenseBatchesFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<LicenseBatchesOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetLicenseInStatisticArgs {
  filter: GetLicenseStatisticFilterInput;
}

export interface QueryGetLicenseOutStatisticArgs {
  filter: GetLicenseStatisticFilterInput;
}

export interface QueryGetLicensesArgs {
  filter?: InputMaybe<LicensesFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<LicensesOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetNotesArgs {
  filter: GetNotesFilterInput;
  limit?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetOrderArgs {
  _id: Scalars['ID'];
}

export interface QueryGetOrderCalculationsArgs {
  input: GetOrderCalculationsInput;
}

export interface QueryGetOrderStatusHistoryArgs {
  orderId: Scalars['ID'];
}

export interface QueryGetOrdersArgs {
  filter?: InputMaybe<GetOrdersFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<OrdersOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetPartnerStoreArgs {
  _id: Scalars['ID'];
}

export interface QueryGetPartnerStoresArgs {
  filter?: InputMaybe<PartnerStoresFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<PartnerStoresOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetPersonCreditCheckArgs {
  personId: Scalars['String'];
}

export interface QueryGetPersonDataArgs {
  personId: Scalars['String'];
}

export interface QueryGetProductArgs {
  _id: Scalars['ID'];
}

export interface QueryGetProductAddonArgs {
  _id: Scalars['ID'];
}

export interface QueryGetProductAddonsArgs {
  filter?: InputMaybe<ProductAddonsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<ProductAddonsOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetProductBundleArgs {
  _id: Scalars['ID'];
}

export interface QueryGetProductBundlesArgs {
  filter?: InputMaybe<ProductBundlesFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<ProductBundlesOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetProductDiscountArgs {
  _id: Scalars['ID'];
}

export interface QueryGetProductDiscountsArgs {
  filter?: InputMaybe<ProductDiscountsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<ProductDiscountsOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetProductsArgs {
  filter?: InputMaybe<ProductsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<ProductsOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetProjectArgs {
  _id: Scalars['ID'];
}

export interface QueryGetProjectsArgs {
  filter?: InputMaybe<ProjectsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<ProjectsOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetPurchasedLicensesArgs {
  filter: GetPurchasedLicensesFilterInput;
}

export interface QueryGetRelationArgs {
  _id: Scalars['ID'];
}

export interface QueryGetRelationsArgs {
  filter?: InputMaybe<RelationsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<RelationsOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetRoleArgs {
  _id: Scalars['ID'];
}

export interface QueryGetRolesArgs {
  filter?: InputMaybe<RolesFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<RolesOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetSalesChannelArgs {
  _id: Scalars['ID'];
}

export interface QueryGetSalesChannelsArgs {
  filter?: InputMaybe<SalesChannelsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<SalesChannelsOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetSearchHistoryArgs {
  filter?: InputMaybe<GetSearchHistoryFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<SearchHistoryOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetSearchHistoryItemArgs {
  _id: Scalars['ID'];
}

export interface QueryGetTeamArgs {
  _id: Scalars['ID'];
}

export interface QueryGetTeamMemberArgs {
  _id: Scalars['ID'];
}

export interface QueryGetTeamMemberOverviewOrdersArgs {
  _id: Scalars['ID'];
  filter?: InputMaybe<TeamMemberOverviewOrdersFilterInput>;
  groupBy: TeamMemberOverviewGroupBy;
}

export interface QueryGetTeamMemberOverviewRegretsArgs {
  _id: Scalars['ID'];
  filter?: InputMaybe<TeamMemberOverviewRegretsFilterInput>;
  groupBy: TeamMemberOverviewGroupBy;
}

export interface QueryGetTeamMembersArgs {
  filter?: InputMaybe<TeamMembersFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<TeamMembersOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export interface QueryGetTeamsArgs {
  filter?: InputMaybe<TeamsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  od?: InputMaybe<OrderDirection>;
  of?: InputMaybe<TeamsOrderBy>;
  skip?: InputMaybe<Scalars['Int']>;
}

export enum RbacResourceName {
  AddonCommissionManaging = 'ADDON_COMMISSION__MANAGING',
  AddonCommissionView = 'ADDON_COMMISSION__VIEW',
  B2BCustomerView = 'B2B_CUSTOMER__VIEW',
  B2BOrderNotesManaging = 'B2B_ORDER_NOTES__MANAGING',
  B2BOrderNotesView = 'B2B_ORDER_NOTES__VIEW',
  B2BOrderShipmentChangeStatus = 'B2B_ORDER_SHIPMENT__CHANGE_STATUS',
  B2BOrderShipmentView = 'B2B_ORDER_SHIPMENT__VIEW',
  B2BOrderCreate = 'B2B_ORDER__CREATE',
  B2BOrderDelete = 'B2B_ORDER__DELETE',
  B2BOrderDeleteUnsigned = 'B2B_ORDER__DELETE_UNSIGNED',
  B2BOrderPaymentHistory = 'B2B_ORDER__PAYMENT_HISTORY',
  B2BOrderSendContract = 'B2B_ORDER__SEND__CONTRACT',
  B2BOrderUpdate = 'B2B_ORDER__UPDATE',
  B2BOrderView = 'B2B_ORDER__VIEW',
  B2BOrderViewDeleted = 'B2B_ORDER__VIEW_DELETED',
  BatchExternalUsersView = 'BATCH_EXTERNAL_USERS__VIEW',
  BatchLicenseView = 'BATCH_LICENSE__VIEW',
  BundleCommissionManaging = 'BUNDLE_COMMISSION__MANAGING',
  BundleCommissionView = 'BUNDLE_COMMISSION__VIEW',
  ChangesLogView = 'CHANGES_LOG__VIEW',
  CommissionManaging = 'COMMISSION__MANAGING',
  CommissionView = 'COMMISSION__VIEW',
  CustomerNotesManaging = 'CUSTOMER_NOTES__MANAGING',
  CustomerNotesView = 'CUSTOMER_NOTES__VIEW',
  CustomerView = 'CUSTOMER__VIEW',
  DashboardExportCommissions = 'DASHBOARD__EXPORT_COMMISSIONS',
  DashboardSosafeTotalView = 'DASHBOARD__SOSAFE_TOTAL__VIEW',
  DashboardView = 'DASHBOARD__VIEW',
  DiscountManaging = 'DISCOUNT__MANAGING',
  DiscountView = 'DISCOUNT__VIEW',
  FinanceSystemManage = 'FINANCE_SYSTEM__MANAGE',
  FinanceSystemView = 'FINANCE_SYSTEM__VIEW',
  LicenseBatchCreate = 'LICENSE_BATCH__CREATE',
  LicenseBatchView = 'LICENSE_BATCH__VIEW',
  LicenseTerminate = 'LICENSE__TERMINATE',
  OrderExternalUsersView = 'ORDER_EXTERNAL_USERS__VIEW',
  OrderLicenseView = 'ORDER_LICENSE__VIEW',
  OrderNotesManaging = 'ORDER_NOTES__MANAGING',
  OrderNotesView = 'ORDER_NOTES__VIEW',
  OrderShipmentChangeStatus = 'ORDER_SHIPMENT__CHANGE_STATUS',
  OrderShipmentView = 'ORDER_SHIPMENT__VIEW',
  OrderApproveRegret = 'ORDER__APPROVE_REGRET',
  OrderCreate = 'ORDER__CREATE',
  OrderCreateWinBack = 'ORDER__CREATE_WIN_BACK',
  OrderDelete = 'ORDER__DELETE',
  OrderDeleteUnsigned = 'ORDER__DELETE_UNSIGNED',
  OrderEndContract = 'ORDER__END_CONTRACT',
  OrderPaymentHistory = 'ORDER__PAYMENT_HISTORY',
  OrderRegretArp = 'ORDER__REGRET_ARP',
  OrderSellAddons = 'ORDER__SELL_ADDONS',
  OrderSellBundles = 'ORDER__SELL_BUNDLES',
  OrderSellProducts = 'ORDER__SELL_PRODUCTS',
  OrderSendContract = 'ORDER__SEND__CONTRACT',
  OrderUpdate = 'ORDER__UPDATE',
  OrderView = 'ORDER__VIEW',
  OrderViewDeleted = 'ORDER__VIEW_DELETED',
  OrderViewEndedContract = 'ORDER__VIEW_ENDED_CONTRACT',
  OrderViewContract = 'ORDER__VIEW__CONTRACT',
  PartnerStoreCreate = 'PARTNER_STORE__CREATE',
  PartnerStoreDelete = 'PARTNER_STORE__DELETE',
  PartnerStoreUpdate = 'PARTNER_STORE__UPDATE',
  PartnerStoreView = 'PARTNER_STORE__VIEW',
  ProductAddonManaging = 'PRODUCT_ADDON__MANAGING',
  ProductAddonView = 'PRODUCT_ADDON__VIEW',
  ProductBundleCreate = 'PRODUCT_BUNDLE__CREATE',
  ProductBundleDelete = 'PRODUCT_BUNDLE__DELETE',
  ProductBundleUpdate = 'PRODUCT_BUNDLE__UPDATE',
  ProductBundleView = 'PRODUCT_BUNDLE__VIEW',
  ProductCreate = 'PRODUCT__CREATE',
  ProductDelete = 'PRODUCT__DELETE',
  ProductUpdate = 'PRODUCT__UPDATE',
  ProductView = 'PRODUCT__VIEW',
  ProjectCreate = 'PROJECT__CREATE',
  ProjectDelete = 'PROJECT__DELETE',
  ProjectUpdate = 'PROJECT__UPDATE',
  ProjectView = 'PROJECT__VIEW',
  RelationManaging = 'RELATION__MANAGING',
  RelationView = 'RELATION__VIEW',
  RoleManaging = 'ROLE__MANAGING',
  RoleView = 'ROLE__VIEW',
  SalesChannelManaging = 'SALES_CHANNEL__MANAGING',
  SalesChannelView = 'SALES_CHANNEL__VIEW',
  TeamMemberActivation = 'TEAM_MEMBER__ACTIVATION',
  TeamMemberCreate = 'TEAM_MEMBER__CREATE',
  TeamMemberDelete = 'TEAM_MEMBER__DELETE',
  TeamMemberUpdate = 'TEAM_MEMBER__UPDATE',
  TeamMemberView = 'TEAM_MEMBER__VIEW',
  TeamCreate = 'TEAM__CREATE',
  TeamDelete = 'TEAM__DELETE',
  TeamUpdate = 'TEAM__UPDATE',
  TeamView = 'TEAM__VIEW',
}

export interface RefreshTokenInput {
  refreshToken: Scalars['String'];
}

export interface RefreshTokenPayload {
  __typename?: 'RefreshTokenPayload';
  errors?: Maybe<Array<MutationError>>;
  status: MutationStatus;
  tokens?: Maybe<AuthTokens>;
}

export interface RegretCommissionstats {
  __typename?: 'RegretCommissionstats';
  count: Scalars['Int'];
  revenue: Scalars['Float'];
  type: RegretOption;
}

export enum RegretOption {
  ByCustomer = 'BY_CUSTOMER',
  ByCustomerArp = 'BY_CUSTOMER_ARP',
  ByQa = 'BY_QA',
}

export interface RegretOrderInput {
  _id: Scalars['ID'];
  arvatoCancelRemainingNum?: InputMaybe<Scalars['Int']>;
  arvatoCancelType?: InputMaybe<CancelArvatoSubscriptionType>;
  regretOption: RegretOption;
  regretReason: Scalars['String'];
}

export interface RegretOrderPayload {
  __typename?: 'RegretOrderPayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<Order>;
  status: MutationStatus;
}

export interface RegretSaleStats {
  __typename?: 'RegretSaleStats';
  count: Scalars['Int'];
  revenue: Scalars['Float'];
  type: RegretOption;
}

export interface Relation {
  __typename?: 'Relation';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  isOwn: Scalars['Boolean'];
  name: Scalars['String'];
  project: Project;
  projectId: Scalars['ID'];
  status: RelationStatus;
  updatedAt: Scalars['DateTime'];
}

export enum RelationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface RelationsFilterInput {
  nameLike?: InputMaybe<Scalars['String']>;
  projectIdIn?: InputMaybe<Array<Scalars['ID']>>;
  statusEq?: InputMaybe<RelationStatus>;
}

export enum RelationsOrderBy {
  CreatedAt = 'createdAt',
  Name = 'name',
}

export interface Role {
  __typename?: 'Role';
  _id: Scalars['ID'];
  b2bSearchRestriction: Scalars['Int'];
  canSeeOtherB2BSearchHistory: Scalars['Boolean'];
  canSeeOtherSearchHistory: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  isSuper: Scalars['Boolean'];
  name: Scalars['String'];
  onlyOwnAndChildData: Scalars['Boolean'];
  onlySalesChannelData: Scalars['Boolean'];
  onlyTeamData: Scalars['Boolean'];
  parentRole?: Maybe<Role>;
  parentRoleId?: Maybe<Scalars['ID']>;
  permissionIds: Array<Scalars['ID']>;
  searchRestriction: Scalars['Int'];
  team: Team;
  teamId: Scalars['ID'];
  teamMembersCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
}

export interface RolesFilterInput {
  nameLike?: InputMaybe<Scalars['String']>;
  teamIdIn?: InputMaybe<Array<Scalars['ID']>>;
}

export enum RolesOrderBy {
  CreatedAt = 'createdAt',
  Name = 'name',
}

export enum SaleStatus {
  Guaranteed = 'GUARANTEED',
  UnderRegretPeriod = 'UNDER_REGRET_PERIOD',
}

export interface SalesChannel {
  __typename?: 'SalesChannel';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  financeSystem: FinanceSystem;
  name: Scalars['String'];
  project: Project;
  projectId: Scalars['ID'];
  status: SalesChannelStatus;
  updatedAt: Scalars['DateTime'];
}

export enum SalesChannelStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface SalesChannelsFilterInput {
  nameLike?: InputMaybe<Scalars['String']>;
  projectIdIn?: InputMaybe<Array<Scalars['ID']>>;
  statusEq?: InputMaybe<SalesChannelStatus>;
}

export enum SalesChannelsOrderBy {
  Id = '_id',
  CreatedAt = 'createdAt',
  Name = 'name',
}

export enum ScriveContractStatus {
  Canceled = 'canceled',
  Closed = 'closed',
  DocumentError = 'document_error',
  NotExist = 'not_exist',
  Pending = 'pending',
  Preparation = 'preparation',
  Rejected = 'rejected',
  Timedout = 'timedout',
}

export interface SearchHistoryItem {
  __typename?: 'SearchHistoryItem';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  personId: Scalars['String'];
  teamMember: TeamMember;
  teamMemberId: Scalars['ID'];
}

export enum SearchHistoryOrderBy {
  CreatedAt = 'createdAt',
}

export interface SendB2BOrderContractInput {
  _id: Scalars['ID'];
}

export interface SendB2BOrderContractPayload {
  __typename?: 'SendB2BOrderContractPayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<B2BOrder>;
  status: MutationStatus;
}

export interface SendOrderContractInput {
  _id: Scalars['ID'];
}

export interface SendOrderContractPayload {
  __typename?: 'SendOrderContractPayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<Order>;
  status: MutationStatus;
}

export interface SetPasswordInput {
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
}

export interface SetPasswordPayload {
  __typename?: 'SetPasswordPayload';
  errors: Array<MutationError>;
  status: MutationStatus;
}

export enum ShippingStatus {
  Needed = 'NEEDED',
  NotNeeded = 'NOT_NEEDED',
  Sent = 'SENT',
}

export interface SignInInput {
  personId: Scalars['String'];
}

export interface SignInPayload {
  __typename?: 'SignInPayload';
  /** Base64 encoded QR code image */
  qrcode?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  status: MutationStatus;
}

export interface SignInWithPasswordInput {
  email: Scalars['String'];
  password: Scalars['String'];
}

export interface SignInWithPasswordPayload {
  __typename?: 'SignInWithPasswordPayload';
  status: MutationStatus;
  tokens?: Maybe<AuthTokens>;
}

export interface SoSafeCoupon {
  __typename?: 'SoSafeCoupon';
  amount: Scalars['Float'];
  code: Scalars['String'];
  type: SoSafeCouponType;
}

export enum SoSafeCouponType {
  Fixed = 'FIXED',
  Percent = 'PERCENT',
}

export enum StatisticPeriod {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export interface Team {
  __typename?: 'Team';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectIds: Array<Scalars['ID']>;
  projects: Array<Project>;
  rolesCount: Scalars['Int'];
  teamMembersCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
}

export interface TeamMember {
  __typename?: 'TeamMember';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  parentTeamMember?: Maybe<TeamMember>;
  parentTeamMemberId?: Maybe<Scalars['ID']>;
  personId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  projects: Array<TeamMemberProject>;
  role: Role;
  roleId: Scalars['ID'];
  searchHistoryCount: Scalars['Int'];
  status: TeamMemberStatus;
  team: Team;
  teamId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
}

export enum TeamMemberOverviewGroupBy {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR',
}

export interface TeamMemberOverviewOrdersFilterInput {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
}

export interface TeamMemberOverviewRegretsFilterInput {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  regretOptionIn?: InputMaybe<Array<RegretOption>>;
}

export interface TeamMemberProject {
  __typename?: 'TeamMemberProject';
  projectId: Scalars['ID'];
  projectName: Scalars['String'];
  salesChannelId?: Maybe<Scalars['ID']>;
  salesChannelName?: Maybe<Scalars['String']>;
}

export interface TeamMemberProjectInput {
  projectId: Scalars['ID'];
  salesChannelId?: InputMaybe<Scalars['ID']>;
}

export enum TeamMemberStatus {
  Active = 'ACTIVE',
  Invited = 'INVITED',
  Suspended = 'SUSPENDED',
}

export interface TeamMembersFilterInput {
  fullNameLike?: InputMaybe<Scalars['String']>;
  idIn?: InputMaybe<Scalars['ID']>;
  personIdLike?: InputMaybe<Scalars['String']>;
  phoneNumberLike?: InputMaybe<Scalars['String']>;
  projectIdEq?: InputMaybe<Scalars['ID']>;
  roleIdEq?: InputMaybe<Scalars['ID']>;
  statusEq?: InputMaybe<TeamMemberStatus>;
  teamIdEq?: InputMaybe<Scalars['ID']>;
}

export enum TeamMembersOrderBy {
  CreatedAt = 'createdAt',
  FullName = 'fullName',
}

export interface TeamsFilterInput {
  nameLike?: InputMaybe<Scalars['String']>;
}

export enum TeamsOrderBy {
  CreatedAt = 'createdAt',
  Name = 'name',
}

export interface TerminateLicenseInput {
  _id: Scalars['ID'];
  arvatoCancelRemainingNum?: InputMaybe<Scalars['Int']>;
  arvatoCancelType?: InputMaybe<CancelArvatoSubscriptionType>;
}

export interface TerminateLicensePayload {
  __typename?: 'TerminateLicensePayload';
  errors: Array<MutationError>;
  node?: Maybe<License>;
  status: MutationStatus;
}

export interface TopSalesChannel {
  __typename?: 'TopSalesChannel';
  _id: Scalars['ID'];
  customersTotal: Scalars['Int'];
  name: Scalars['String'];
}

export interface UpdateAddonCommissionInput {
  _id: Scalars['ID'];
  qty?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['ID']>;
  salesChannelId?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<AddonCommissionStatus>;
  teamId?: InputMaybe<Scalars['ID']>;
  teamMemberId?: InputMaybe<Scalars['ID']>;
}

export interface UpdateAddonCommissionPayload {
  __typename?: 'UpdateAddonCommissionPayload';
  errors: Array<MutationError>;
  node?: Maybe<AddonCommission>;
  status: MutationStatus;
}

export interface UpdateB2BOrderInput {
  _id: Scalars['ID'];
  coAddress?: InputMaybe<Scalars['String']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  deliveryAddress?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  invoicingAddress?: InputMaybe<Scalars['String']>;
  invoicingFrequency?: InputMaybe<Scalars['Int']>;
  invoicingZip?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<B2BOrderItemInput>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  sendContract?: InputMaybe<Scalars['Boolean']>;
  shippingStatus?: InputMaybe<ShippingStatus>;
}

export interface UpdateB2BOrderPayload {
  __typename?: 'UpdateB2BOrderPayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<B2BOrder>;
  status: MutationStatus;
}

export interface UpdateBundleCommissionInput {
  _id: Scalars['ID'];
  roleId?: InputMaybe<Scalars['ID']>;
  salesChannelId?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<BundleCommissionStatus>;
  teamId?: InputMaybe<Scalars['ID']>;
  teamMemberId?: InputMaybe<Scalars['ID']>;
}

export interface UpdateBundleCommissionPayload {
  __typename?: 'UpdateBundleCommissionPayload';
  errors: Array<MutationError>;
  node?: Maybe<BundleCommission>;
  status: MutationStatus;
}

export interface UpdateCommissionInput {
  _id: Scalars['ID'];
  qty?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['ID']>;
  salesChannelId?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<CommissionStatus>;
  teamId?: InputMaybe<Scalars['ID']>;
  teamMemberId?: InputMaybe<Scalars['ID']>;
}

export interface UpdateCommissionPayload {
  __typename?: 'UpdateCommissionPayload';
  errors: Array<MutationError>;
  node?: Maybe<Commission>;
  status: MutationStatus;
}

export interface UpdateOrderInput {
  _id: Scalars['ID'];
  arvatoCancelType?: InputMaybe<CancelArvatoSubscriptionType>;
  bundles?: InputMaybe<Array<OrderBundleInput>>;
  coAddress?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  financeSystem?: InputMaybe<FinanceSystem>;
  invoicingAddress?: InputMaybe<Scalars['String']>;
  invoicingFrequency?: InputMaybe<Scalars['Int']>;
  invoicingType?: InputMaybe<InvoicingType>;
  invoicingZip?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<OrderItemInput>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  sendContract?: InputMaybe<Scalars['Boolean']>;
  shippingStatus?: InputMaybe<ShippingStatus>;
  upgrades?: InputMaybe<Array<OrderUpgradeInput>>;
}

export interface UpdateOrderPayload {
  __typename?: 'UpdateOrderPayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<Order>;
  status: MutationStatus;
}

export interface UpdatePartnerStoreInput {
  _id: Scalars['ID'];
  address?: InputMaybe<Scalars['String']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  linkToSite?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
}

export interface UpdatePartnerStorePayload {
  __typename?: 'UpdatePartnerStorePayload';
  errors: Array<MutationError>;
  node?: Maybe<PartnerStore>;
  status: MutationStatus;
}

export interface UpdateProductAddonInput {
  _id: Scalars['ID'];
  canBeTerminated?: InputMaybe<Scalars['Boolean']>;
  freeMonths?: InputMaybe<Scalars['Int']>;
  isCreditCheckRequired?: InputMaybe<Scalars['Boolean']>;
  isInStok?: InputMaybe<Scalars['Boolean']>;
  isPersonIdRequired?: InputMaybe<Scalars['Boolean']>;
  isStockable?: InputMaybe<Scalars['Boolean']>;
  isSubscription?: InputMaybe<Scalars['Boolean']>;
  licenseDurationMonths?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<ProductAddonStatus>;
  unitId?: InputMaybe<Scalars['ID']>;
  vatRate?: InputMaybe<Scalars['Float']>;
}

export interface UpdateProductAddonPayload {
  __typename?: 'UpdateProductAddonPayload';
  errors: Array<MutationError>;
  node?: Maybe<ProductAddon>;
  status: MutationStatus;
}

export interface UpdateProductBundleInput {
  _id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  nameInContract?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productIds?: InputMaybe<Array<ProductBundleToProductInput>>;
  projectId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<ProductStatus>;
  vatRate?: InputMaybe<Scalars['Float']>;
}

export interface UpdateProductBundlePayload {
  __typename?: 'UpdateProductBundlePayload';
  errors: Array<MutationError>;
  node?: Maybe<ProductBundle>;
  status: MutationStatus;
}

export interface UpdateProductDiscountInput {
  _id: Scalars['ID'];
  discount?: InputMaybe<Scalars['Float']>;
  qty?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['ID']>;
  salesChannelId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<ProductDiscountStatus>;
  teamId?: InputMaybe<Scalars['ID']>;
  teamMemberId?: InputMaybe<Scalars['ID']>;
}

export interface UpdateProductDiscountPayload {
  __typename?: 'UpdateProductDiscountPayload';
  errors: Array<MutationError>;
  node?: Maybe<ProductDiscount>;
  status: MutationStatus;
}

export interface UpdateProductInput {
  _id: Scalars['ID'];
  canBeTerminated?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  freeMonths?: InputMaybe<Scalars['Int']>;
  isCreditCheckRequired?: InputMaybe<Scalars['Boolean']>;
  isInStok?: InputMaybe<Scalars['Boolean']>;
  isPersonIdRequired?: InputMaybe<Scalars['Boolean']>;
  isStockable?: InputMaybe<Scalars['Boolean']>;
  isSubscription?: InputMaybe<Scalars['Boolean']>;
  licenseDurationMonths?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nameInContract?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<ProductStatus>;
  unitId?: InputMaybe<Scalars['ID']>;
  vatRate?: InputMaybe<Scalars['Float']>;
}

export interface UpdateProductPayload {
  __typename?: 'UpdateProductPayload';
  errors: Array<MutationError>;
  node?: Maybe<Product>;
  status: MutationStatus;
}

export interface UpdateProjectInput {
  _id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}

export interface UpdateProjectPayload {
  __typename?: 'UpdateProjectPayload';
  errors: Array<MutationError>;
  node?: Maybe<Project>;
  status: MutationStatus;
}

export interface UpdateRelationInput {
  _id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<RelationStatus>;
}

export interface UpdateRelationPayload {
  __typename?: 'UpdateRelationPayload';
  errors: Array<MutationError>;
  node?: Maybe<Relation>;
  status: MutationStatus;
}

export interface UpdateRoleAddPermissionInput {
  _id: Scalars['ID'];
  permissionId: RbacResourceName;
}

export interface UpdateRoleAddPermissionPayload {
  __typename?: 'UpdateRoleAddPermissionPayload';
  errors: Array<MutationError>;
  node?: Maybe<Role>;
  status: MutationStatus;
}

export interface UpdateRoleInput {
  _id: Scalars['ID'];
  b2bSearchRestriction?: InputMaybe<Scalars['Int']>;
  canSeeOtherB2BSearchHistory?: InputMaybe<Scalars['Boolean']>;
  canSeeOtherSearchHistory?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  isSuper?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  onlyOwnAndChildData?: InputMaybe<Scalars['Boolean']>;
  onlySalesChannelData?: InputMaybe<Scalars['Boolean']>;
  onlyTeamData?: InputMaybe<Scalars['Boolean']>;
  parentRoleId?: InputMaybe<Scalars['ID']>;
  permissionIds?: InputMaybe<Array<RbacResourceName>>;
  searchRestriction?: InputMaybe<Scalars['Int']>;
}

export interface UpdateRolePayload {
  __typename?: 'UpdateRolePayload';
  errors: Array<MutationError>;
  node?: Maybe<Role>;
  status: MutationStatus;
}

export interface UpdateRoleRemovePermissionInput {
  _id: Scalars['ID'];
  permissionId: RbacResourceName;
}

export interface UpdateRoleRemovePermissionPayload {
  __typename?: 'UpdateRoleRemovePermissionPayload';
  errors: Array<MutationError>;
  node?: Maybe<Role>;
  status: MutationStatus;
}

export interface UpdateSalesChannelInput {
  _id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  financeSystem?: InputMaybe<FinanceSystem>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<SalesChannelStatus>;
}

export interface UpdateSalesChannelPayload {
  __typename?: 'UpdateSalesChannelPayload';
  errors: Array<MutationError>;
  node?: Maybe<SalesChannel>;
  status: MutationStatus;
}

export interface UpdateTeamInput {
  _id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  projectIds?: InputMaybe<Array<Scalars['ID']>>;
}

export interface UpdateTeamMemberInput {
  _id: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  parentTeamMemberId?: InputMaybe<Scalars['ID']>;
  personId?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  projects?: InputMaybe<Array<TeamMemberProjectInput>>;
  roleId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<TeamMemberStatus>;
  teamId?: InputMaybe<Scalars['ID']>;
}

export interface UpdateTeamMemberPayload {
  __typename?: 'UpdateTeamMemberPayload';
  errors: Array<MutationError>;
  node?: Maybe<TeamMember>;
  status: MutationStatus;
}

export interface UpdateTeamPayload {
  __typename?: 'UpdateTeamPayload';
  errors: Array<MutationError>;
  node?: Maybe<Team>;
  status: MutationStatus;
}

export interface UpdateWinBackInput {
  _id: Scalars['ID'];
  bundles?: InputMaybe<Array<OrderBundleInput>>;
  coAddress?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  invoicingAddress?: InputMaybe<Scalars['String']>;
  invoicingFrequency?: InputMaybe<Scalars['Int']>;
  invoicingType?: InputMaybe<InvoicingType>;
  invoicingZip?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<OrderItemInput>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  sendContract?: InputMaybe<Scalars['Boolean']>;
  shippingStatus?: InputMaybe<ShippingStatus>;
  upgrades?: InputMaybe<Array<OrderUpgradeInput>>;
  winBackDuration: Scalars['Int'];
  winBackPrice: Scalars['Float'];
}

export interface UpdateWinBackPayload {
  __typename?: 'UpdateWinBackPayload';
  errors?: Maybe<Array<MutationError>>;
  node?: Maybe<Order>;
  status: MutationStatus;
}

export interface WinbackInfo {
  __typename?: 'WinbackInfo';
  itemChanges: Array<WinbackInfoItemChange>;
  newContractValue: Scalars['Float'];
  newDuration: Scalars['Float'];
  oldContractValue: Scalars['Float'];
  oldDuration: Scalars['Float'];
}

export interface WinbackInfoItemChange {
  __typename?: 'WinbackInfoItemChange';
  newQty: Scalars['Int'];
  oldQty: Scalars['Int'];
  product?: Maybe<Product>;
  productAddon?: Maybe<ProductAddon>;
  productAddonId?: Maybe<Scalars['ID']>;
  productBundle?: Maybe<ProductBundle>;
  productBundleId?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  type: WinbackItemType;
}

export enum WinbackItemType {
  Addon = 'ADDON',
  Bundle = 'BUNDLE',
  Product = 'PRODUCT',
}

export type ProductAddonFragment = {
  __typename?: 'ProductAddon';
  _id: string;
  projectId: string;
  productId: string;
  name: string;
  status: ProductAddonStatus;
  price: number;
  vatRate: number;
  vatPrice: number;
  freeMonths: number;
  licenseDurationMonths?: number | null;
  isSubscription: boolean;
  unitId: string;
  isCreditCheckRequired: boolean;
  isStockable: boolean;
  isInStok: boolean;
  createdAt: any;
  updatedAt: any;
  isPersonIdRequired: boolean;
  canBeTerminated: boolean;
  project: {
    __typename?: 'Project';
    _id: string;
    name: string;
    description?: string | null;
    productsCount: number;
    createdAt: any;
    updatedAt: any;
  };
  product: {
    __typename?: 'Product';
    _id: string;
    projectId: string;
    name: string;
    nameInContract: string;
    description?: string | null;
    status: ProductStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    freeMonths: number;
    licenseDurationMonths?: number | null;
    isSubscription: boolean;
    canBeTerminated: boolean;
    unitId: string;
    isCreditCheckRequired: boolean;
    isPersonIdRequired: boolean;
    isStockable: boolean;
    isInStok: boolean;
    hasCommissions: boolean;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
    productAddons: Array<{
      __typename?: 'ProductAddon';
      _id: string;
      name: string;
      vatPrice: number;
      status: ProductAddonStatus;
      isPersonIdRequired: boolean;
      isCreditCheckRequired: boolean;
      isSubscription: boolean;
    }>;
  };
};

export type ProductAddonOrderFragment = {
  __typename?: 'ProductAddon';
  _id: string;
  name: string;
  vatPrice: number;
  status: ProductAddonStatus;
  isPersonIdRequired: boolean;
  isCreditCheckRequired: boolean;
  isSubscription: boolean;
};

export type ProductAddonsFilterFragment = {
  __typename?: 'ProductAddon';
  _id: string;
  name: string;
};

export type CreateProductAddonMutationVariables = Exact<{
  input: CreateProductAddonInput;
}>;

export type CreateProductAddonMutation = {
  __typename?: 'Mutation';
  createProductAddon: {
    __typename?: 'CreateProductAddonPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'ProductAddon';
      _id: string;
      projectId: string;
      productId: string;
      name: string;
      status: ProductAddonStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      createdAt: any;
      updatedAt: any;
      isPersonIdRequired: boolean;
      canBeTerminated: boolean;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateProductAddonMutationVariables = Exact<{
  input: UpdateProductAddonInput;
}>;

export type UpdateProductAddonMutation = {
  __typename?: 'Mutation';
  updateProductAddon: {
    __typename?: 'UpdateProductAddonPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'ProductAddon';
      _id: string;
      projectId: string;
      productId: string;
      name: string;
      status: ProductAddonStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      createdAt: any;
      updatedAt: any;
      isPersonIdRequired: boolean;
      canBeTerminated: boolean;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteProductAddonMutationVariables = Exact<{
  input: DeleteProductAddonInput;
}>;

export type DeleteProductAddonMutation = {
  __typename?: 'Mutation';
  deleteProductAddon: {
    __typename?: 'DeleteProductAddonPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetProductAddonsQueryVariables = Exact<{
  filter?: InputMaybe<ProductAddonsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<ProductAddonsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetProductAddonsQuery = {
  __typename?: 'Query';
  getProductAddons: {
    __typename?: 'GetProductAddonsPayload';
    count: number;
    nodes: Array<{
      __typename?: 'ProductAddon';
      _id: string;
      projectId: string;
      productId: string;
      name: string;
      status: ProductAddonStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      createdAt: any;
      updatedAt: any;
      isPersonIdRequired: boolean;
      canBeTerminated: boolean;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
    }>;
  };
};

export type GetProductAddonQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetProductAddonQuery = {
  __typename?: 'Query';
  getProductAddon?: {
    __typename?: 'ProductAddon';
    _id: string;
    projectId: string;
    productId: string;
    name: string;
    status: ProductAddonStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    freeMonths: number;
    licenseDurationMonths?: number | null;
    isSubscription: boolean;
    unitId: string;
    isCreditCheckRequired: boolean;
    isStockable: boolean;
    isInStok: boolean;
    createdAt: any;
    updatedAt: any;
    isPersonIdRequired: boolean;
    canBeTerminated: boolean;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
  } | null;
};

export type GetProductAddonsFilterQueryVariables = Exact<{
  filter?: InputMaybe<ProductAddonsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<ProductAddonsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetProductAddonsFilterQuery = {
  __typename?: 'Query';
  getProductAddons: {
    __typename?: 'GetProductAddonsPayload';
    count: number;
    nodes: Array<{ __typename?: 'ProductAddon'; _id: string; name: string }>;
  };
};

export type AddonCommissionFragment = {
  __typename?: 'AddonCommission';
  _id: string;
  productAddonId: string;
  teamId?: string | null;
  roleId?: string | null;
  salesChannelId?: string | null;
  teamMemberId?: string | null;
  authorId: string;
  size: number;
  qty: number;
  status: AddonCommissionStatus;
  createdAt: any;
  updatedAt: any;
  productAddon: {
    __typename?: 'ProductAddon';
    _id: string;
    projectId: string;
    productId: string;
    name: string;
    status: ProductAddonStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    freeMonths: number;
    licenseDurationMonths?: number | null;
    isSubscription: boolean;
    unitId: string;
    isCreditCheckRequired: boolean;
    isStockable: boolean;
    isInStok: boolean;
    createdAt: any;
    updatedAt: any;
    isPersonIdRequired: boolean;
    canBeTerminated: boolean;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
  };
  team?: {
    __typename?: 'Team';
    _id: string;
    name: string;
    rolesCount: number;
    teamMembersCount: number;
    description?: string | null;
    projectIds: Array<string>;
    createdAt: any;
    updatedAt: any;
    projects: Array<{
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    }>;
  } | null;
  role?: {
    __typename?: 'Role';
    _id: string;
    permissionIds: Array<string>;
    teamId: string;
    parentRoleId?: string | null;
    name: string;
    onlyTeamData: boolean;
    onlySalesChannelData: boolean;
    onlyOwnAndChildData: boolean;
    canSeeOtherSearchHistory: boolean;
    searchRestriction: number;
    isSuper: boolean;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
  } | null;
  salesChannel?: {
    __typename?: 'SalesChannel';
    _id: string;
    projectId: string;
    name: string;
    description?: string | null;
    status: SalesChannelStatus;
    financeSystem: FinanceSystem;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  } | null;
  teamMember?: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  } | null;
  author: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
};

export type CreateAddonCommissionMutationVariables = Exact<{
  input: CreateAddonCommissionInput;
}>;

export type CreateAddonCommissionMutation = {
  __typename?: 'Mutation';
  createAddonCommission: {
    __typename?: 'CreateAddonCommissionPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'AddonCommission';
      _id: string;
      productAddonId: string;
      teamId?: string | null;
      roleId?: string | null;
      salesChannelId?: string | null;
      teamMemberId?: string | null;
      authorId: string;
      size: number;
      qty: number;
      status: AddonCommissionStatus;
      createdAt: any;
      updatedAt: any;
      productAddon: {
        __typename?: 'ProductAddon';
        _id: string;
        projectId: string;
        productId: string;
        name: string;
        status: ProductAddonStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        createdAt: any;
        updatedAt: any;
        isPersonIdRequired: boolean;
        canBeTerminated: boolean;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateAddonCommissionMutationVariables = Exact<{
  input: UpdateAddonCommissionInput;
}>;

export type UpdateAddonCommissionMutation = {
  __typename?: 'Mutation';
  updateAddonCommission: {
    __typename?: 'UpdateAddonCommissionPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'AddonCommission';
      _id: string;
      productAddonId: string;
      teamId?: string | null;
      roleId?: string | null;
      salesChannelId?: string | null;
      teamMemberId?: string | null;
      authorId: string;
      size: number;
      qty: number;
      status: AddonCommissionStatus;
      createdAt: any;
      updatedAt: any;
      productAddon: {
        __typename?: 'ProductAddon';
        _id: string;
        projectId: string;
        productId: string;
        name: string;
        status: ProductAddonStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        createdAt: any;
        updatedAt: any;
        isPersonIdRequired: boolean;
        canBeTerminated: boolean;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteAddonCommissionMutationVariables = Exact<{
  input: DeleteAddonCommissionInput;
}>;

export type DeleteAddonCommissionMutation = {
  __typename?: 'Mutation';
  deleteAddonCommission: {
    __typename?: 'DeleteAddonCommissionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetAddonCommissionsQueryVariables = Exact<{
  filter?: InputMaybe<AddonCommissionsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<AddonCommissionsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetAddonCommissionsQuery = {
  __typename?: 'Query';
  getAddonCommissions: {
    __typename?: 'GetAddonCommissionsPayload';
    count: number;
    nodes: Array<{
      __typename?: 'AddonCommission';
      _id: string;
      productAddonId: string;
      teamId?: string | null;
      roleId?: string | null;
      salesChannelId?: string | null;
      teamMemberId?: string | null;
      authorId: string;
      size: number;
      qty: number;
      status: AddonCommissionStatus;
      createdAt: any;
      updatedAt: any;
      productAddon: {
        __typename?: 'ProductAddon';
        _id: string;
        projectId: string;
        productId: string;
        name: string;
        status: ProductAddonStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        createdAt: any;
        updatedAt: any;
        isPersonIdRequired: boolean;
        canBeTerminated: boolean;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    }>;
  };
};

export type GetAddonCommissionQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetAddonCommissionQuery = {
  __typename?: 'Query';
  getAddonCommission?: {
    __typename?: 'AddonCommission';
    _id: string;
    productAddonId: string;
    teamId?: string | null;
    roleId?: string | null;
    salesChannelId?: string | null;
    teamMemberId?: string | null;
    authorId: string;
    size: number;
    qty: number;
    status: AddonCommissionStatus;
    createdAt: any;
    updatedAt: any;
    productAddon: {
      __typename?: 'ProductAddon';
      _id: string;
      projectId: string;
      productId: string;
      name: string;
      status: ProductAddonStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      createdAt: any;
      updatedAt: any;
      isPersonIdRequired: boolean;
      canBeTerminated: boolean;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
    };
    team?: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    } | null;
    role?: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    } | null;
    salesChannel?: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    teamMember?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    author: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
  } | null;
};

export type SignInMutationVariables = Exact<{
  input?: InputMaybe<SignInInput>;
}>;

export type SignInMutation = {
  __typename?: 'Mutation';
  signIn: {
    __typename?: 'SignInPayload';
    sessionId?: string | null;
    qrcode?: string | null;
    status: MutationStatus;
  };
};

export type CheckSignInStatusMutationVariables = Exact<{
  input: CheckSignInStatusInput;
}>;

export type CheckSignInStatusMutation = {
  __typename?: 'Mutation';
  checkSignInStatus: {
    __typename?: 'CheckSignInStatusPayload';
    status: MutationStatus;
    tokens?: {
      __typename?: 'AuthTokens';
      accessToken: { __typename?: 'AuthToken'; expiresIn: number; token: string };
      refreshToken: { __typename?: 'AuthToken'; expiresIn: number; token: string };
    } | null;
  };
};

export type BatchFragment = {
  __typename?: 'LicenseBatch';
  _id: string;
  productId: string;
  partnerStoreId: string;
  duration?: number | null;
  licenseCount?: number | null;
  activatedLicenseCount?: number | null;
  zipReady: boolean;
  zipLink?: string | null;
  createdAt: any;
  updatedAt: any;
  product: {
    __typename?: 'Product';
    _id: string;
    projectId: string;
    name: string;
    nameInContract: string;
    description?: string | null;
    status: ProductStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    freeMonths: number;
    licenseDurationMonths?: number | null;
    isSubscription: boolean;
    canBeTerminated: boolean;
    unitId: string;
    isCreditCheckRequired: boolean;
    isPersonIdRequired: boolean;
    isStockable: boolean;
    isInStok: boolean;
    hasCommissions: boolean;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
    productAddons: Array<{
      __typename?: 'ProductAddon';
      _id: string;
      name: string;
      vatPrice: number;
      status: ProductAddonStatus;
      isPersonIdRequired: boolean;
      isCreditCheckRequired: boolean;
      isSubscription: boolean;
    }>;
  };
  partnerStore: {
    __typename?: 'PartnerStore';
    _id: string;
    projectId: string;
    name: string;
    address?: string | null;
    phoneNumber?: string | null;
    contactPerson?: string | null;
    email?: string | null;
    linkToSite?: string | null;
    totalLicenseCount: number;
    activatedLicenseCount: number;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  };
};

export type CreateBatchMutationVariables = Exact<{
  input: CreateLicenseBatchInput;
}>;

export type CreateBatchMutation = {
  __typename?: 'Mutation';
  createLicenseBatch: {
    __typename?: 'CreateLicenseBatchPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'LicenseBatch';
      _id: string;
      productId: string;
      partnerStoreId: string;
      duration?: number | null;
      licenseCount?: number | null;
      activatedLicenseCount?: number | null;
      zipReady: boolean;
      zipLink?: string | null;
      createdAt: any;
      updatedAt: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      partnerStore: {
        __typename?: 'PartnerStore';
        _id: string;
        projectId: string;
        name: string;
        address?: string | null;
        phoneNumber?: string | null;
        contactPerson?: string | null;
        email?: string | null;
        linkToSite?: string | null;
        totalLicenseCount: number;
        activatedLicenseCount: number;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetBatchesQueryVariables = Exact<{
  filter?: InputMaybe<LicenseBatchesFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<LicenseBatchesOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetBatchesQuery = {
  __typename?: 'Query';
  getLicenseBatches: {
    __typename?: 'GetLicenseBatchesPayload';
    count: number;
    nodes: Array<{
      __typename?: 'LicenseBatch';
      _id: string;
      productId: string;
      partnerStoreId: string;
      duration?: number | null;
      licenseCount?: number | null;
      activatedLicenseCount?: number | null;
      zipReady: boolean;
      zipLink?: string | null;
      createdAt: any;
      updatedAt: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      partnerStore: {
        __typename?: 'PartnerStore';
        _id: string;
        projectId: string;
        name: string;
        address?: string | null;
        phoneNumber?: string | null;
        contactPerson?: string | null;
        email?: string | null;
        linkToSite?: string | null;
        totalLicenseCount: number;
        activatedLicenseCount: number;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
    }>;
  };
};

export type BundleFragment = {
  __typename?: 'ProductBundle';
  _id: string;
  projectId: string;
  name: string;
  nameInContract: string;
  status: ProductStatus;
  price: number;
  vatRate: number;
  vatPrice: number;
  createdAt: any;
  updatedAt: any;
  project: {
    __typename?: 'Project';
    _id: string;
    name: string;
    description?: string | null;
    productsCount: number;
    createdAt: any;
    updatedAt: any;
  };
  products: Array<{
    __typename?: 'ProductBundleToProduct';
    productId: string;
    qty: number;
    product: {
      __typename?: 'Product';
      name: string;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      isCreditCheckRequired: boolean;
      licenseDurationMonths?: number | null;
      isPersonIdRequired: boolean;
      isSubscription: boolean;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
  }>;
};

export type CreateProductBundleMutationVariables = Exact<{
  input: CreateProductBundleInput;
}>;

export type CreateProductBundleMutation = {
  __typename?: 'Mutation';
  createProductBundle: {
    __typename?: 'CreateProductBundlePayload';
    status: MutationStatus;
    node?: {
      __typename?: 'ProductBundle';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      products: Array<{
        __typename?: 'ProductBundleToProduct';
        productId: string;
        qty: number;
        product: {
          __typename?: 'Product';
          name: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          isCreditCheckRequired: boolean;
          licenseDurationMonths?: number | null;
          isPersonIdRequired: boolean;
          isSubscription: boolean;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      }>;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateProductBundleMutationVariables = Exact<{
  input: UpdateProductBundleInput;
}>;

export type UpdateProductBundleMutation = {
  __typename?: 'Mutation';
  updateProductBundle: {
    __typename?: 'UpdateProductBundlePayload';
    status: MutationStatus;
    node?: {
      __typename?: 'ProductBundle';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      products: Array<{
        __typename?: 'ProductBundleToProduct';
        productId: string;
        qty: number;
        product: {
          __typename?: 'Product';
          name: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          isCreditCheckRequired: boolean;
          licenseDurationMonths?: number | null;
          isPersonIdRequired: boolean;
          isSubscription: boolean;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      }>;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteProductBundleMutationVariables = Exact<{
  input: DeleteProductBundleInput;
}>;

export type DeleteProductBundleMutation = {
  __typename?: 'Mutation';
  deleteProductBundle: {
    __typename?: 'DeleteProductBundlePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetProductBundlesQueryVariables = Exact<{
  filter?: InputMaybe<ProductBundlesFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<ProductBundlesOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetProductBundlesQuery = {
  __typename?: 'Query';
  getProductBundles: {
    __typename?: 'GetProductBundlesPayload';
    count: number;
    nodes: Array<{
      __typename?: 'ProductBundle';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      products: Array<{
        __typename?: 'ProductBundleToProduct';
        productId: string;
        qty: number;
        product: {
          __typename?: 'Product';
          name: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          isCreditCheckRequired: boolean;
          licenseDurationMonths?: number | null;
          isPersonIdRequired: boolean;
          isSubscription: boolean;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      }>;
    }>;
  };
};

export type GetProductBundleQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetProductBundleQuery = {
  __typename?: 'Query';
  getProductBundle?: {
    __typename?: 'ProductBundle';
    _id: string;
    projectId: string;
    name: string;
    nameInContract: string;
    status: ProductStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    products: Array<{
      __typename?: 'ProductBundleToProduct';
      productId: string;
      qty: number;
      product: {
        __typename?: 'Product';
        name: string;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        isCreditCheckRequired: boolean;
        licenseDurationMonths?: number | null;
        isPersonIdRequired: boolean;
        isSubscription: boolean;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
    }>;
  } | null;
};

export type BundleCommissionFragment = {
  __typename?: 'BundleCommission';
  _id: string;
  productBundleId: string;
  teamId?: string | null;
  roleId?: string | null;
  salesChannelId?: string | null;
  teamMemberId?: string | null;
  authorId: string;
  size: number;
  status: BundleCommissionStatus;
  createdAt: any;
  updatedAt: any;
  productBundle: {
    __typename?: 'ProductBundle';
    _id: string;
    projectId: string;
    name: string;
    nameInContract: string;
    status: ProductStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    products: Array<{
      __typename?: 'ProductBundleToProduct';
      productId: string;
      qty: number;
      product: {
        __typename?: 'Product';
        name: string;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        isCreditCheckRequired: boolean;
        licenseDurationMonths?: number | null;
        isPersonIdRequired: boolean;
        isSubscription: boolean;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
    }>;
  };
  team?: {
    __typename?: 'Team';
    _id: string;
    name: string;
    rolesCount: number;
    teamMembersCount: number;
    description?: string | null;
    projectIds: Array<string>;
    createdAt: any;
    updatedAt: any;
    projects: Array<{
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    }>;
  } | null;
  role?: {
    __typename?: 'Role';
    _id: string;
    permissionIds: Array<string>;
    teamId: string;
    parentRoleId?: string | null;
    name: string;
    onlyTeamData: boolean;
    onlySalesChannelData: boolean;
    onlyOwnAndChildData: boolean;
    canSeeOtherSearchHistory: boolean;
    searchRestriction: number;
    isSuper: boolean;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
  } | null;
  salesChannel?: {
    __typename?: 'SalesChannel';
    _id: string;
    projectId: string;
    name: string;
    description?: string | null;
    status: SalesChannelStatus;
    financeSystem: FinanceSystem;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  } | null;
  teamMember?: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  } | null;
  author: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
};

export type CreateBundleCommissionMutationVariables = Exact<{
  input: CreateBundleCommissionInput;
}>;

export type CreateBundleCommissionMutation = {
  __typename?: 'Mutation';
  createBundleCommission: {
    __typename?: 'CreateBundleCommissionPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'BundleCommission';
      _id: string;
      productBundleId: string;
      teamId?: string | null;
      roleId?: string | null;
      salesChannelId?: string | null;
      teamMemberId?: string | null;
      authorId: string;
      size: number;
      status: BundleCommissionStatus;
      createdAt: any;
      updatedAt: any;
      productBundle: {
        __typename?: 'ProductBundle';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        products: Array<{
          __typename?: 'ProductBundleToProduct';
          productId: string;
          qty: number;
          product: {
            __typename?: 'Product';
            name: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            isCreditCheckRequired: boolean;
            licenseDurationMonths?: number | null;
            isPersonIdRequired: boolean;
            isSubscription: boolean;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        }>;
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateBundleCommissionMutationVariables = Exact<{
  input: UpdateBundleCommissionInput;
}>;

export type UpdateBundleCommissionMutation = {
  __typename?: 'Mutation';
  updateBundleCommission: {
    __typename?: 'UpdateBundleCommissionPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'BundleCommission';
      _id: string;
      productBundleId: string;
      teamId?: string | null;
      roleId?: string | null;
      salesChannelId?: string | null;
      teamMemberId?: string | null;
      authorId: string;
      size: number;
      status: BundleCommissionStatus;
      createdAt: any;
      updatedAt: any;
      productBundle: {
        __typename?: 'ProductBundle';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        products: Array<{
          __typename?: 'ProductBundleToProduct';
          productId: string;
          qty: number;
          product: {
            __typename?: 'Product';
            name: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            isCreditCheckRequired: boolean;
            licenseDurationMonths?: number | null;
            isPersonIdRequired: boolean;
            isSubscription: boolean;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        }>;
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteBundleCommissionMutationVariables = Exact<{
  input: DeleteBundleCommissionInput;
}>;

export type DeleteBundleCommissionMutation = {
  __typename?: 'Mutation';
  deleteBundleCommission: {
    __typename?: 'DeleteBundleCommissionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetBundleCommissionsQueryVariables = Exact<{
  filter?: InputMaybe<BundleCommissionsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<BundleCommissionsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetBundleCommissionsQuery = {
  __typename?: 'Query';
  getBundleCommissions: {
    __typename?: 'GetBundleCommissionsPayload';
    count: number;
    nodes: Array<{
      __typename?: 'BundleCommission';
      _id: string;
      productBundleId: string;
      teamId?: string | null;
      roleId?: string | null;
      salesChannelId?: string | null;
      teamMemberId?: string | null;
      authorId: string;
      size: number;
      status: BundleCommissionStatus;
      createdAt: any;
      updatedAt: any;
      productBundle: {
        __typename?: 'ProductBundle';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        products: Array<{
          __typename?: 'ProductBundleToProduct';
          productId: string;
          qty: number;
          product: {
            __typename?: 'Product';
            name: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            isCreditCheckRequired: boolean;
            licenseDurationMonths?: number | null;
            isPersonIdRequired: boolean;
            isSubscription: boolean;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        }>;
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    }>;
  };
};

export type GetBundleCommissionQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetBundleCommissionQuery = {
  __typename?: 'Query';
  getBundleCommission?: {
    __typename?: 'BundleCommission';
    _id: string;
    productBundleId: string;
    teamId?: string | null;
    roleId?: string | null;
    salesChannelId?: string | null;
    teamMemberId?: string | null;
    authorId: string;
    size: number;
    status: BundleCommissionStatus;
    createdAt: any;
    updatedAt: any;
    productBundle: {
      __typename?: 'ProductBundle';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      products: Array<{
        __typename?: 'ProductBundleToProduct';
        productId: string;
        qty: number;
        product: {
          __typename?: 'Product';
          name: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          isCreditCheckRequired: boolean;
          licenseDurationMonths?: number | null;
          isPersonIdRequired: boolean;
          isSubscription: boolean;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      }>;
    };
    team?: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    } | null;
    role?: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    } | null;
    salesChannel?: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    teamMember?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    author: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
  } | null;
};

export type CommissionFragment = {
  __typename?: 'Commission';
  _id: string;
  productId: string;
  teamId?: string | null;
  roleId?: string | null;
  salesChannelId?: string | null;
  teamMemberId?: string | null;
  authorId: string;
  qty: number;
  size: number;
  status: CommissionStatus;
  createdAt: any;
  updatedAt: any;
  product: {
    __typename?: 'Product';
    _id: string;
    projectId: string;
    name: string;
    nameInContract: string;
    description?: string | null;
    status: ProductStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    freeMonths: number;
    licenseDurationMonths?: number | null;
    isSubscription: boolean;
    canBeTerminated: boolean;
    unitId: string;
    isCreditCheckRequired: boolean;
    isPersonIdRequired: boolean;
    isStockable: boolean;
    isInStok: boolean;
    hasCommissions: boolean;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
    productAddons: Array<{
      __typename?: 'ProductAddon';
      _id: string;
      name: string;
      vatPrice: number;
      status: ProductAddonStatus;
      isPersonIdRequired: boolean;
      isCreditCheckRequired: boolean;
      isSubscription: boolean;
    }>;
  };
  team?: {
    __typename?: 'Team';
    _id: string;
    name: string;
    rolesCount: number;
    teamMembersCount: number;
    description?: string | null;
    projectIds: Array<string>;
    createdAt: any;
    updatedAt: any;
    projects: Array<{
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    }>;
  } | null;
  role?: {
    __typename?: 'Role';
    _id: string;
    permissionIds: Array<string>;
    teamId: string;
    parentRoleId?: string | null;
    name: string;
    onlyTeamData: boolean;
    onlySalesChannelData: boolean;
    onlyOwnAndChildData: boolean;
    canSeeOtherSearchHistory: boolean;
    searchRestriction: number;
    isSuper: boolean;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
  } | null;
  salesChannel?: {
    __typename?: 'SalesChannel';
    _id: string;
    projectId: string;
    name: string;
    description?: string | null;
    status: SalesChannelStatus;
    financeSystem: FinanceSystem;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  } | null;
  teamMember?: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  } | null;
  author: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
};

export type CreateCommissionMutationVariables = Exact<{
  input: CreateCommissionInput;
}>;

export type CreateCommissionMutation = {
  __typename?: 'Mutation';
  createCommission: {
    __typename?: 'CreateCommissionPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Commission';
      _id: string;
      productId: string;
      teamId?: string | null;
      roleId?: string | null;
      salesChannelId?: string | null;
      teamMemberId?: string | null;
      authorId: string;
      qty: number;
      size: number;
      status: CommissionStatus;
      createdAt: any;
      updatedAt: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateCommissionMutationVariables = Exact<{
  input: UpdateCommissionInput;
}>;

export type UpdateCommissionMutation = {
  __typename?: 'Mutation';
  updateCommission: {
    __typename?: 'UpdateCommissionPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Commission';
      _id: string;
      productId: string;
      teamId?: string | null;
      roleId?: string | null;
      salesChannelId?: string | null;
      teamMemberId?: string | null;
      authorId: string;
      qty: number;
      size: number;
      status: CommissionStatus;
      createdAt: any;
      updatedAt: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteCommissionMutationVariables = Exact<{
  input: DeleteCommissionInput;
}>;

export type DeleteCommissionMutation = {
  __typename?: 'Mutation';
  deleteCommission: {
    __typename?: 'DeleteCommissionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetCommissionsQueryVariables = Exact<{
  filter?: InputMaybe<CommissionsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<CommissionsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetCommissionsQuery = {
  __typename?: 'Query';
  getCommissions: {
    __typename?: 'GetCommissionsPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Commission';
      _id: string;
      productId: string;
      teamId?: string | null;
      roleId?: string | null;
      salesChannelId?: string | null;
      teamMemberId?: string | null;
      authorId: string;
      qty: number;
      size: number;
      status: CommissionStatus;
      createdAt: any;
      updatedAt: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    }>;
  };
};

export type GetCommissionQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetCommissionQuery = {
  __typename?: 'Query';
  getCommission?: {
    __typename?: 'Commission';
    _id: string;
    productId: string;
    teamId?: string | null;
    roleId?: string | null;
    salesChannelId?: string | null;
    teamMemberId?: string | null;
    authorId: string;
    qty: number;
    size: number;
    status: CommissionStatus;
    createdAt: any;
    updatedAt: any;
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
    team?: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    } | null;
    role?: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    } | null;
    salesChannel?: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    teamMember?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    author: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
  } | null;
};

export type ContractStatusHistoryItemFragment = {
  __typename?: 'ContractStatusHistoryItem';
  orderId: string;
  status: ContractStatus;
  createdAt: any;
  scriveContractId: string;
  scriveContractStatus?: string | null;
};

export type B2BContractStatusHistoryItemFragment = {
  __typename?: 'B2BContractStatusHistoryItem';
  orderId: string;
  status: ContractStatus;
  createdAt: any;
  scriveContractId: string;
  scriveContractStatus?: string | null;
};

export type GetContractStatusHistoryQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type GetContractStatusHistoryQuery = {
  __typename?: 'Query';
  getContractStatusHistory: {
    __typename?: 'GetContractStatusHistoryPayload';
    count: number;
    nodes: Array<{
      __typename?: 'ContractStatusHistoryItem';
      orderId: string;
      status: ContractStatus;
      createdAt: any;
      scriveContractId: string;
      scriveContractStatus?: string | null;
    }>;
  };
};

export type GetB2BContractStatusHistoryQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type GetB2BContractStatusHistoryQuery = {
  __typename?: 'Query';
  getB2BContractStatusHistory: {
    __typename?: 'GetB2BContractStatusHistoryPayload';
    count: number;
    nodes: Array<{
      __typename?: 'B2BContractStatusHistoryItem';
      orderId: string;
      status: ContractStatus;
      createdAt: any;
      scriveContractId: string;
      scriveContractStatus?: string | null;
    }>;
  };
};

export type CustomerFragment = {
  __typename?: 'Customer';
  _id: string;
  salesChannelId: string;
  lastAssigneeId: string;
  creditCheckCode: number;
  fullName?: string | null;
  phoneNumber?: string | null;
  personId: string;
  email?: string | null;
  address?: string | null;
  zipCode?: string | null;
  coAddress?: string | null;
  updatedAt: any;
  createdAt: any;
  salesChannel: {
    __typename?: 'SalesChannel';
    _id: string;
    projectId: string;
    name: string;
    description?: string | null;
    status: SalesChannelStatus;
    financeSystem: FinanceSystem;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  };
  lastAssignee: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
};

export type B2BCustomerFragment = {
  __typename?: 'B2BCustomer';
  _id: string;
  lastAssigneeId: string;
  creditCheckCode: number;
  fullName?: string | null;
  phoneNumber?: string | null;
  orgId: string;
  email?: string | null;
  updatedAt: any;
  createdAt: any;
  lastAssignee: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
};

export type GetCustomersForListQueryVariables = Exact<{
  filter?: InputMaybe<GetCustomersFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<CustomersOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetCustomersForListQuery = {
  __typename?: 'Query';
  getCustomersForList: {
    __typename?: 'GetCustomersPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Customer';
      _id: string;
      fullName?: string | null;
      personId: string;
      email?: string | null;
      phoneNumber?: string | null;
      creditCheckCode: number;
    }>;
  };
};

export type GetCustomerQueryVariables = Exact<{
  _id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;

export type GetCustomerQuery = {
  __typename?: 'Query';
  getCustomer?: {
    __typename?: 'Customer';
    _id: string;
    salesChannelId: string;
    lastAssigneeId: string;
    creditCheckCode: number;
    fullName?: string | null;
    phoneNumber?: string | null;
    personId: string;
    email?: string | null;
    address?: string | null;
    zipCode?: string | null;
    coAddress?: string | null;
    updatedAt: any;
    createdAt: any;
    notes: {
      __typename?: 'GetNotesPayload';
      count: number;
      nodes: Array<{
        __typename?: 'Note';
        _id: string;
        entityType: NoteEntityType;
        entityId: string;
        authorId?: string | null;
        content: string;
        createdAt: any;
        author?: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
      }>;
    };
    salesChannel: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    };
    lastAssignee: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
  } | null;
};

export type ProductDiscountFragment = {
  __typename?: 'ProductDiscount';
  _id: string;
  productId: string;
  teamId?: string | null;
  roleId?: string | null;
  teamMemberId?: string | null;
  salesChannelId?: string | null;
  authorId: string;
  qty: number;
  discount: number;
  status: ProductDiscountStatus;
  productPrice: number;
  productVatPrice: number;
  createdAt: any;
  updatedAt: any;
  product: {
    __typename?: 'Product';
    _id: string;
    projectId: string;
    name: string;
    nameInContract: string;
    description?: string | null;
    status: ProductStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    freeMonths: number;
    licenseDurationMonths?: number | null;
    isSubscription: boolean;
    canBeTerminated: boolean;
    unitId: string;
    isCreditCheckRequired: boolean;
    isPersonIdRequired: boolean;
    isStockable: boolean;
    isInStok: boolean;
    hasCommissions: boolean;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
    productAddons: Array<{
      __typename?: 'ProductAddon';
      _id: string;
      name: string;
      vatPrice: number;
      status: ProductAddonStatus;
      isPersonIdRequired: boolean;
      isCreditCheckRequired: boolean;
      isSubscription: boolean;
    }>;
  };
  team?: {
    __typename?: 'Team';
    _id: string;
    name: string;
    rolesCount: number;
    teamMembersCount: number;
    description?: string | null;
    projectIds: Array<string>;
    createdAt: any;
    updatedAt: any;
    projects: Array<{
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    }>;
  } | null;
  role?: {
    __typename?: 'Role';
    _id: string;
    permissionIds: Array<string>;
    teamId: string;
    parentRoleId?: string | null;
    name: string;
    onlyTeamData: boolean;
    onlySalesChannelData: boolean;
    onlyOwnAndChildData: boolean;
    canSeeOtherSearchHistory: boolean;
    searchRestriction: number;
    isSuper: boolean;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
  } | null;
  teamMember?: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  } | null;
  salesChannel?: {
    __typename?: 'SalesChannel';
    _id: string;
    projectId: string;
    name: string;
    description?: string | null;
    status: SalesChannelStatus;
    financeSystem: FinanceSystem;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  } | null;
  author: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
};

export type CreateProductDiscountMutationVariables = Exact<{
  input: CreateProductDiscountInput;
}>;

export type CreateProductDiscountMutation = {
  __typename?: 'Mutation';
  createProductDiscount: {
    __typename?: 'CreateProductDiscountPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'ProductDiscount';
      _id: string;
      productId: string;
      teamId?: string | null;
      roleId?: string | null;
      teamMemberId?: string | null;
      salesChannelId?: string | null;
      authorId: string;
      qty: number;
      discount: number;
      status: ProductDiscountStatus;
      productPrice: number;
      productVatPrice: number;
      createdAt: any;
      updatedAt: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateProductDiscountMutationVariables = Exact<{
  input: UpdateProductDiscountInput;
}>;

export type UpdateProductDiscountMutation = {
  __typename?: 'Mutation';
  updateProductDiscount: {
    __typename?: 'UpdateProductDiscountPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'ProductDiscount';
      _id: string;
      productId: string;
      teamId?: string | null;
      roleId?: string | null;
      teamMemberId?: string | null;
      salesChannelId?: string | null;
      authorId: string;
      qty: number;
      discount: number;
      status: ProductDiscountStatus;
      productPrice: number;
      productVatPrice: number;
      createdAt: any;
      updatedAt: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteProductDiscountMutationVariables = Exact<{
  input: DeleteProductDiscountInput;
}>;

export type DeleteProductDiscountMutation = {
  __typename?: 'Mutation';
  deleteProductDiscount: {
    __typename?: 'DeleteProductDiscountPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetProductDiscountsQueryVariables = Exact<{
  filter?: InputMaybe<ProductDiscountsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<ProductDiscountsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetProductDiscountsQuery = {
  __typename?: 'Query';
  getProductDiscounts: {
    __typename?: 'GetProductDiscountsPayload';
    count: number;
    nodes: Array<{
      __typename?: 'ProductDiscount';
      _id: string;
      productId: string;
      teamId?: string | null;
      roleId?: string | null;
      teamMemberId?: string | null;
      salesChannelId?: string | null;
      authorId: string;
      qty: number;
      discount: number;
      status: ProductDiscountStatus;
      productPrice: number;
      productVatPrice: number;
      createdAt: any;
      updatedAt: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    }>;
  };
};

export type GetProductDiscountQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetProductDiscountQuery = {
  __typename?: 'Query';
  getProductDiscount?: {
    __typename?: 'ProductDiscount';
    _id: string;
    productId: string;
    teamId?: string | null;
    roleId?: string | null;
    teamMemberId?: string | null;
    salesChannelId?: string | null;
    authorId: string;
    qty: number;
    discount: number;
    status: ProductDiscountStatus;
    productPrice: number;
    productVatPrice: number;
    createdAt: any;
    updatedAt: any;
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
    team?: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    } | null;
    role?: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    } | null;
    teamMember?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    salesChannel?: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    author: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
  } | null;
};

export type ExternalUserFragment = {
  __typename?: 'ExternalUser';
  _id: string;
  lastCustomerId?: string | null;
  lastAssigneeId?: string | null;
  relationId?: string | null;
  productId: string;
  fullName?: string | null;
  phoneNumber: string;
  personId?: string | null;
  address?: string | null;
  licenseStartDate: any;
  licenseEndDate: any;
  createdAt: any;
  lastCustomer?: {
    __typename?: 'Customer';
    _id: string;
    salesChannelId: string;
    lastAssigneeId: string;
    creditCheckCode: number;
    fullName?: string | null;
    phoneNumber?: string | null;
    personId: string;
    email?: string | null;
    address?: string | null;
    zipCode?: string | null;
    coAddress?: string | null;
    updatedAt: any;
    createdAt: any;
    salesChannel: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    };
    lastAssignee: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
  } | null;
  lastAssignee?: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  } | null;
  relation?: {
    __typename?: 'Relation';
    _id: string;
    projectId: string;
    status: RelationStatus;
    name: string;
    description?: string | null;
    isOwn: boolean;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  } | null;
  product: {
    __typename?: 'Product';
    _id: string;
    projectId: string;
    name: string;
    nameInContract: string;
    description?: string | null;
    status: ProductStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    freeMonths: number;
    licenseDurationMonths?: number | null;
    isSubscription: boolean;
    canBeTerminated: boolean;
    unitId: string;
    isCreditCheckRequired: boolean;
    isPersonIdRequired: boolean;
    isStockable: boolean;
    isInStok: boolean;
    hasCommissions: boolean;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
    productAddons: Array<{
      __typename?: 'ProductAddon';
      _id: string;
      name: string;
      vatPrice: number;
      status: ProductAddonStatus;
      isPersonIdRequired: boolean;
      isCreditCheckRequired: boolean;
      isSubscription: boolean;
    }>;
  };
  licenses: Array<{
    __typename?: 'License';
    productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
  }>;
};

export type GetExternalUsersQueryVariables = Exact<{
  filter?: InputMaybe<GetExternalUsersFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<ExternalUsersOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetExternalUsersQuery = {
  __typename?: 'Query';
  getExternalUsers: {
    __typename?: 'GetExternalUsersPayload';
    count: number;
    nodes: Array<{
      __typename?: 'ExternalUser';
      _id: string;
      lastCustomerId?: string | null;
      lastAssigneeId?: string | null;
      relationId?: string | null;
      productId: string;
      fullName?: string | null;
      phoneNumber: string;
      personId?: string | null;
      address?: string | null;
      licenseStartDate: any;
      licenseEndDate: any;
      createdAt: any;
      lastCustomer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      lastAssignee?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      relation?: {
        __typename?: 'Relation';
        _id: string;
        projectId: string;
        status: RelationStatus;
        name: string;
        description?: string | null;
        isOwn: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      licenses: Array<{
        __typename?: 'License';
        productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
      }>;
    }>;
  };
};

export type GetExternalUserQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetExternalUserQuery = {
  __typename?: 'Query';
  getExternalUser?: {
    __typename?: 'ExternalUser';
    _id: string;
    lastCustomerId?: string | null;
    lastAssigneeId?: string | null;
    relationId?: string | null;
    productId: string;
    fullName?: string | null;
    phoneNumber: string;
    personId?: string | null;
    address?: string | null;
    licenseStartDate: any;
    licenseEndDate: any;
    createdAt: any;
    lastCustomer?: {
      __typename?: 'Customer';
      _id: string;
      salesChannelId: string;
      lastAssigneeId: string;
      creditCheckCode: number;
      fullName?: string | null;
      phoneNumber?: string | null;
      personId: string;
      email?: string | null;
      address?: string | null;
      zipCode?: string | null;
      coAddress?: string | null;
      updatedAt: any;
      createdAt: any;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      lastAssignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    lastAssignee?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    relation?: {
      __typename?: 'Relation';
      _id: string;
      projectId: string;
      status: RelationStatus;
      name: string;
      description?: string | null;
      isOwn: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
    licenses: Array<{
      __typename?: 'License';
      productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
    }>;
  } | null;
};

export type LicenseFragment = {
  __typename?: 'License';
  _id: string;
  productId: string;
  orderId?: string | null;
  orderItemId?: string | null;
  customerId?: string | null;
  externalUserId: string;
  status: LicenseStatus;
  licenseStartDate: any;
  licenseEndDate: any;
  createdAt: any;
  updatedAt: any;
  financeSystemSyncStatus: FinanceSystemItemSyncStatus;
  product: {
    __typename?: 'Product';
    _id: string;
    projectId: string;
    name: string;
    nameInContract: string;
    description?: string | null;
    status: ProductStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    freeMonths: number;
    licenseDurationMonths?: number | null;
    isSubscription: boolean;
    canBeTerminated: boolean;
    unitId: string;
    isCreditCheckRequired: boolean;
    isPersonIdRequired: boolean;
    isStockable: boolean;
    isInStok: boolean;
    hasCommissions: boolean;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
    productAddons: Array<{
      __typename?: 'ProductAddon';
      _id: string;
      name: string;
      vatPrice: number;
      status: ProductAddonStatus;
      isPersonIdRequired: boolean;
      isCreditCheckRequired: boolean;
      isSubscription: boolean;
    }>;
  };
  productAddon?: {
    __typename?: 'ProductAddon';
    _id: string;
    projectId: string;
    productId: string;
    name: string;
    status: ProductAddonStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    freeMonths: number;
    licenseDurationMonths?: number | null;
    isSubscription: boolean;
    unitId: string;
    isCreditCheckRequired: boolean;
    isStockable: boolean;
    isInStok: boolean;
    createdAt: any;
    updatedAt: any;
    isPersonIdRequired: boolean;
    canBeTerminated: boolean;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
  } | null;
  order?: {
    __typename?: 'Order';
    _id: string;
    salesChannelId: string;
    projectId: string;
    customerId?: string | null;
    assigneeId: string;
    resolvedById?: string | null;
    isCreditCheckReceived: boolean;
    isActiveCustomer: boolean;
    financeSystemSyncStatus: FinanceSystemSyncStatus;
    financeSystem: FinanceSystem;
    creditCheckCode: number;
    fullName?: string | null;
    email: string;
    phoneNumber: string;
    personId: string;
    status: OrderStatus;
    coAddress: string;
    invoicingAddress?: string | null;
    invoicingType: InvoicingType;
    invoicingZip?: string | null;
    invoicingFrequency: number;
    customerAddress: string;
    customerZip: string;
    subtotal: number;
    total: number;
    monthlyTotal: number;
    onePaymentTotal: number;
    discountPercent: number;
    discountAmount: number;
    monthlySubtotal: number;
    quarterSubtotal: number;
    fullTotalWithAddons: number;
    regretReason?: string | null;
    regretOption?: RegretOption | null;
    shippingStatus: ShippingStatus;
    scriveContractStatus: ScriveContractStatus;
    scriveContractId?: string | null;
    isUnsigned: boolean;
    isRegrettable: boolean;
    isRegrettableDate?: any | null;
    isDeleted: boolean;
    isWinBack?: boolean | null;
    isFromShopPage: boolean;
    updatedAt: any;
    createdAt: any;
    contractStatus: OrderContractStatus;
    contractSignedDate?: any | null;
    winBackPrice: number;
    winBackDuration?: number | null;
    salesChannel: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    };
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    customer?: {
      __typename?: 'Customer';
      _id: string;
      salesChannelId: string;
      lastAssigneeId: string;
      creditCheckCode: number;
      fullName?: string | null;
      phoneNumber?: string | null;
      personId: string;
      email?: string | null;
      address?: string | null;
      zipCode?: string | null;
      coAddress?: string | null;
      updatedAt: any;
      createdAt: any;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      lastAssignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    assignee: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
    resolvedBy?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    items: Array<{
      __typename?: 'OrderItem';
      relationId: string;
      productId: string;
      discountId?: string | null;
      discountAmount: number;
      discountPercent: number;
      qty: number;
      postponeInvoicing: number;
      personId?: string | null;
      fullName?: string | null;
      subtotal: number;
      total: number;
      addonsPrice: number;
      phoneNumber: string;
      relation: {
        __typename?: 'Relation';
        _id: string;
        projectId: string;
        status: RelationStatus;
        name: string;
        description?: string | null;
        isOwn: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      productAddons: Array<{
        __typename?: 'OrderItemProductAddon';
        productAddon: {
          __typename?: 'ProductAddon';
          _id: string;
          projectId: string;
          productId: string;
          name: string;
          status: ProductAddonStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          createdAt: any;
          updatedAt: any;
          isPersonIdRequired: boolean;
          canBeTerminated: boolean;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        };
        license?: { __typename?: 'License'; status: LicenseStatus } | null;
      }>;
      discount?: {
        __typename?: 'ProductDiscount';
        _id: string;
        productId: string;
        teamId?: string | null;
        roleId?: string | null;
        teamMemberId?: string | null;
        salesChannelId?: string | null;
        authorId: string;
        qty: number;
        discount: number;
        status: ProductDiscountStatus;
        productPrice: number;
        productVatPrice: number;
        createdAt: any;
        updatedAt: any;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        team?: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        } | null;
        role?: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        } | null;
        teamMember?: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        salesChannel?: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        } | null;
        author: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      license?: {
        __typename?: 'License';
        _id: string;
        status: LicenseStatus;
        licenseEndDate: any;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        externalUser: {
          __typename?: 'ExternalUser';
          _id: string;
          lastCustomerId?: string | null;
          lastAssigneeId?: string | null;
          relationId?: string | null;
          productId: string;
          fullName?: string | null;
          phoneNumber: string;
          personId?: string | null;
          address?: string | null;
          licenseStartDate: any;
          licenseEndDate: any;
          createdAt: any;
          lastCustomer?: {
            __typename?: 'Customer';
            _id: string;
            salesChannelId: string;
            lastAssigneeId: string;
            creditCheckCode: number;
            fullName?: string | null;
            phoneNumber?: string | null;
            personId: string;
            email?: string | null;
            address?: string | null;
            zipCode?: string | null;
            coAddress?: string | null;
            updatedAt: any;
            createdAt: any;
            salesChannel: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            lastAssignee: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          lastAssignee?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          relation?: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          licenses: Array<{
            __typename?: 'License';
            productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
          }>;
        };
      } | null;
    }>;
    bundles: Array<{
      __typename?: 'OrderBundleItem';
      productBundleId: string;
      postponeInvoicing: number;
      total: number;
      subtotal: number;
      addonsPrice: number;
      productBundle: {
        __typename?: 'ProductBundle';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        products: Array<{
          __typename?: 'ProductBundleToProduct';
          productId: string;
          qty: number;
          product: {
            __typename?: 'Product';
            name: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            isCreditCheckRequired: boolean;
            licenseDurationMonths?: number | null;
            isPersonIdRequired: boolean;
            isSubscription: boolean;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        }>;
      };
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
    }>;
    upgrades: Array<{
      __typename?: 'OrderUpgradeItem';
      licenseId: string;
      total: number;
      license: {
        __typename?: 'License';
        _id: string;
        status: LicenseStatus;
        licenseEndDate: any;
        orderItem?: {
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        } | null;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        externalUser: {
          __typename?: 'ExternalUser';
          _id: string;
          lastCustomerId?: string | null;
          lastAssigneeId?: string | null;
          relationId?: string | null;
          productId: string;
          fullName?: string | null;
          phoneNumber: string;
          personId?: string | null;
          address?: string | null;
          licenseStartDate: any;
          licenseEndDate: any;
          createdAt: any;
          lastCustomer?: {
            __typename?: 'Customer';
            _id: string;
            salesChannelId: string;
            lastAssigneeId: string;
            creditCheckCode: number;
            fullName?: string | null;
            phoneNumber?: string | null;
            personId: string;
            email?: string | null;
            address?: string | null;
            zipCode?: string | null;
            coAddress?: string | null;
            updatedAt: any;
            createdAt: any;
            salesChannel: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            lastAssignee: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          lastAssignee?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          relation?: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          licenses: Array<{
            __typename?: 'License';
            productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
          }>;
        };
      };
      productAddons: Array<{
        __typename?: 'OrderItemProductAddon';
        productAddon: {
          __typename?: 'ProductAddon';
          _id: string;
          projectId: string;
          productId: string;
          name: string;
          status: ProductAddonStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          createdAt: any;
          updatedAt: any;
          isPersonIdRequired: boolean;
          canBeTerminated: boolean;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        };
        license?: { __typename?: 'License'; status: LicenseStatus } | null;
      }>;
    }>;
    soSafeCoupon?: {
      __typename?: 'SoSafeCoupon';
      code: string;
      amount: number;
      type: SoSafeCouponType;
    } | null;
    sourceOrders?: Array<{
      __typename?: 'Order';
      _id: string;
      fullTotalWithAddons: number;
      monthlySubtotal: number;
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundleId: string;
        postponeInvoicing: number;
        total: number;
        subtotal: number;
        addonsPrice: number;
        productBundle: {
          __typename?: 'ProductBundle';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          products: Array<{
            __typename?: 'ProductBundleToProduct';
            productId: string;
            qty: number;
            product: {
              __typename?: 'Product';
              name: string;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              isCreditCheckRequired: boolean;
              licenseDurationMonths?: number | null;
              isPersonIdRequired: boolean;
              isSubscription: boolean;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          }>;
        };
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
      }>;
    }> | null;
  } | null;
  orderItem?: {
    __typename?: 'OrderItem';
    relationId: string;
    productId: string;
    discountId?: string | null;
    discountAmount: number;
    discountPercent: number;
    qty: number;
    postponeInvoicing: number;
    personId?: string | null;
    fullName?: string | null;
    subtotal: number;
    total: number;
    addonsPrice: number;
    phoneNumber: string;
    relation: {
      __typename?: 'Relation';
      _id: string;
      projectId: string;
      status: RelationStatus;
      name: string;
      description?: string | null;
      isOwn: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    };
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
    productAddons: Array<{
      __typename?: 'OrderItemProductAddon';
      productAddon: {
        __typename?: 'ProductAddon';
        _id: string;
        projectId: string;
        productId: string;
        name: string;
        status: ProductAddonStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        createdAt: any;
        updatedAt: any;
        isPersonIdRequired: boolean;
        canBeTerminated: boolean;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      };
      license?: { __typename?: 'License'; status: LicenseStatus } | null;
    }>;
    discount?: {
      __typename?: 'ProductDiscount';
      _id: string;
      productId: string;
      teamId?: string | null;
      roleId?: string | null;
      teamMemberId?: string | null;
      salesChannelId?: string | null;
      authorId: string;
      qty: number;
      discount: number;
      status: ProductDiscountStatus;
      productPrice: number;
      productVatPrice: number;
      createdAt: any;
      updatedAt: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    license?: {
      __typename?: 'License';
      _id: string;
      status: LicenseStatus;
      licenseEndDate: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      externalUser: {
        __typename?: 'ExternalUser';
        _id: string;
        lastCustomerId?: string | null;
        lastAssigneeId?: string | null;
        relationId?: string | null;
        productId: string;
        fullName?: string | null;
        phoneNumber: string;
        personId?: string | null;
        address?: string | null;
        licenseStartDate: any;
        licenseEndDate: any;
        createdAt: any;
        lastCustomer?: {
          __typename?: 'Customer';
          _id: string;
          salesChannelId: string;
          lastAssigneeId: string;
          creditCheckCode: number;
          fullName?: string | null;
          phoneNumber?: string | null;
          personId: string;
          email?: string | null;
          address?: string | null;
          zipCode?: string | null;
          coAddress?: string | null;
          updatedAt: any;
          createdAt: any;
          salesChannel: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          lastAssignee: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        lastAssignee?: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        relation?: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        } | null;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        licenses: Array<{
          __typename?: 'License';
          productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
        }>;
      };
    } | null;
  } | null;
  customer?: {
    __typename?: 'Customer';
    _id: string;
    salesChannelId: string;
    lastAssigneeId: string;
    creditCheckCode: number;
    fullName?: string | null;
    phoneNumber?: string | null;
    personId: string;
    email?: string | null;
    address?: string | null;
    zipCode?: string | null;
    coAddress?: string | null;
    updatedAt: any;
    createdAt: any;
    salesChannel: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    };
    lastAssignee: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
  } | null;
  externalUser: {
    __typename?: 'ExternalUser';
    _id: string;
    lastCustomerId?: string | null;
    lastAssigneeId?: string | null;
    relationId?: string | null;
    productId: string;
    fullName?: string | null;
    phoneNumber: string;
    personId?: string | null;
    address?: string | null;
    licenseStartDate: any;
    licenseEndDate: any;
    createdAt: any;
    lastCustomer?: {
      __typename?: 'Customer';
      _id: string;
      salesChannelId: string;
      lastAssigneeId: string;
      creditCheckCode: number;
      fullName?: string | null;
      phoneNumber?: string | null;
      personId: string;
      email?: string | null;
      address?: string | null;
      zipCode?: string | null;
      coAddress?: string | null;
      updatedAt: any;
      createdAt: any;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      lastAssignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    lastAssignee?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    relation?: {
      __typename?: 'Relation';
      _id: string;
      projectId: string;
      status: RelationStatus;
      name: string;
      description?: string | null;
      isOwn: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
    licenses: Array<{
      __typename?: 'License';
      productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
    }>;
  };
};

export type PurchasedLicenseFragment = {
  __typename?: 'PurchasedLicense';
  _id: string;
  createdAt: any;
  signedAt?: any | null;
  willEndAt?: any | null;
  relationId: string;
  productId: string;
  externalUserId: string;
  orderId: string;
  status: LicenseStatus;
  daysLeft: number;
  isFromBundle: boolean;
  postponeInvoicing: number;
  discountPercent: number;
  subtotal: number;
  total: number;
  product: {
    __typename?: 'Product';
    _id: string;
    name: string;
    productAddons: Array<{
      __typename?: 'ProductAddon';
      _id: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
    }>;
  };
  relation: { __typename?: 'Relation'; name: string };
  externalUser: {
    __typename?: 'ExternalUser';
    fullName?: string | null;
    personId?: string | null;
    phoneNumber: string;
  };
  order: { __typename?: 'Order'; project: { __typename?: 'Project'; name: string } };
  availableProductAddons: Array<{
    __typename?: 'ProductAddon';
    _id: string;
    name: string;
    vatPrice: number;
    isCreditCheckRequired: boolean;
    isPersonIdRequired: boolean;
  }>;
  purchasedProductAddons: Array<{
    __typename?: 'ProductAddon';
    _id: string;
    name: string;
    vatPrice: number;
  }>;
};

export type TerminateLicenseMutationVariables = Exact<{
  input: TerminateLicenseInput;
}>;

export type TerminateLicenseMutation = {
  __typename?: 'Mutation';
  terminateLicense: {
    __typename?: 'TerminateLicensePayload';
    status: MutationStatus;
    node?: {
      __typename?: 'License';
      _id: string;
      productId: string;
      orderId?: string | null;
      orderItemId?: string | null;
      customerId?: string | null;
      externalUserId: string;
      status: LicenseStatus;
      licenseStartDate: any;
      licenseEndDate: any;
      createdAt: any;
      updatedAt: any;
      financeSystemSyncStatus: FinanceSystemItemSyncStatus;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      productAddon?: {
        __typename?: 'ProductAddon';
        _id: string;
        projectId: string;
        productId: string;
        name: string;
        status: ProductAddonStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        createdAt: any;
        updatedAt: any;
        isPersonIdRequired: boolean;
        canBeTerminated: boolean;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      } | null;
      order?: {
        __typename?: 'Order';
        _id: string;
        salesChannelId: string;
        projectId: string;
        customerId?: string | null;
        assigneeId: string;
        resolvedById?: string | null;
        isCreditCheckReceived: boolean;
        isActiveCustomer: boolean;
        financeSystemSyncStatus: FinanceSystemSyncStatus;
        financeSystem: FinanceSystem;
        creditCheckCode: number;
        fullName?: string | null;
        email: string;
        phoneNumber: string;
        personId: string;
        status: OrderStatus;
        coAddress: string;
        invoicingAddress?: string | null;
        invoicingType: InvoicingType;
        invoicingZip?: string | null;
        invoicingFrequency: number;
        customerAddress: string;
        customerZip: string;
        subtotal: number;
        total: number;
        monthlyTotal: number;
        onePaymentTotal: number;
        discountPercent: number;
        discountAmount: number;
        monthlySubtotal: number;
        quarterSubtotal: number;
        fullTotalWithAddons: number;
        regretReason?: string | null;
        regretOption?: RegretOption | null;
        shippingStatus: ShippingStatus;
        scriveContractStatus: ScriveContractStatus;
        scriveContractId?: string | null;
        isUnsigned: boolean;
        isRegrettable: boolean;
        isRegrettableDate?: any | null;
        isDeleted: boolean;
        isWinBack?: boolean | null;
        isFromShopPage: boolean;
        updatedAt: any;
        createdAt: any;
        contractStatus: OrderContractStatus;
        contractSignedDate?: any | null;
        winBackPrice: number;
        winBackDuration?: number | null;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        customer?: {
          __typename?: 'Customer';
          _id: string;
          salesChannelId: string;
          lastAssigneeId: string;
          creditCheckCode: number;
          fullName?: string | null;
          phoneNumber?: string | null;
          personId: string;
          email?: string | null;
          address?: string | null;
          zipCode?: string | null;
          coAddress?: string | null;
          updatedAt: any;
          createdAt: any;
          salesChannel: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          lastAssignee: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        assignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
        resolvedBy?: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
        bundles: Array<{
          __typename?: 'OrderBundleItem';
          productBundleId: string;
          postponeInvoicing: number;
          total: number;
          subtotal: number;
          addonsPrice: number;
          productBundle: {
            __typename?: 'ProductBundle';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            products: Array<{
              __typename?: 'ProductBundleToProduct';
              productId: string;
              qty: number;
              product: {
                __typename?: 'Product';
                name: string;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                isCreditCheckRequired: boolean;
                licenseDurationMonths?: number | null;
                isPersonIdRequired: boolean;
                isSubscription: boolean;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            }>;
          };
          items: Array<{
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          }>;
        }>;
        upgrades: Array<{
          __typename?: 'OrderUpgradeItem';
          licenseId: string;
          total: number;
          license: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            orderItem?: {
              __typename?: 'OrderItem';
              relationId: string;
              productId: string;
              discountId?: string | null;
              discountAmount: number;
              discountPercent: number;
              qty: number;
              postponeInvoicing: number;
              personId?: string | null;
              fullName?: string | null;
              subtotal: number;
              total: number;
              addonsPrice: number;
              phoneNumber: string;
              relation: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              productAddons: Array<{
                __typename?: 'OrderItemProductAddon';
                productAddon: {
                  __typename?: 'ProductAddon';
                  _id: string;
                  projectId: string;
                  productId: string;
                  name: string;
                  status: ProductAddonStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  createdAt: any;
                  updatedAt: any;
                  isPersonIdRequired: boolean;
                  canBeTerminated: boolean;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  product: {
                    __typename?: 'Product';
                    _id: string;
                    projectId: string;
                    name: string;
                    nameInContract: string;
                    description?: string | null;
                    status: ProductStatus;
                    price: number;
                    vatRate: number;
                    vatPrice: number;
                    freeMonths: number;
                    licenseDurationMonths?: number | null;
                    isSubscription: boolean;
                    canBeTerminated: boolean;
                    unitId: string;
                    isCreditCheckRequired: boolean;
                    isPersonIdRequired: boolean;
                    isStockable: boolean;
                    isInStok: boolean;
                    hasCommissions: boolean;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                    productAddons: Array<{
                      __typename?: 'ProductAddon';
                      _id: string;
                      name: string;
                      vatPrice: number;
                      status: ProductAddonStatus;
                      isPersonIdRequired: boolean;
                      isCreditCheckRequired: boolean;
                      isSubscription: boolean;
                    }>;
                  };
                };
                license?: { __typename?: 'License'; status: LicenseStatus } | null;
              }>;
              discount?: {
                __typename?: 'ProductDiscount';
                _id: string;
                productId: string;
                teamId?: string | null;
                roleId?: string | null;
                teamMemberId?: string | null;
                salesChannelId?: string | null;
                authorId: string;
                qty: number;
                discount: number;
                status: ProductDiscountStatus;
                productPrice: number;
                productVatPrice: number;
                createdAt: any;
                updatedAt: any;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                team?: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                } | null;
                role?: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                } | null;
                teamMember?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                salesChannel?: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                author: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              license?: {
                __typename?: 'License';
                _id: string;
                status: LicenseStatus;
                licenseEndDate: any;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                externalUser: {
                  __typename?: 'ExternalUser';
                  _id: string;
                  lastCustomerId?: string | null;
                  lastAssigneeId?: string | null;
                  relationId?: string | null;
                  productId: string;
                  fullName?: string | null;
                  phoneNumber: string;
                  personId?: string | null;
                  address?: string | null;
                  licenseStartDate: any;
                  licenseEndDate: any;
                  createdAt: any;
                  lastCustomer?: {
                    __typename?: 'Customer';
                    _id: string;
                    salesChannelId: string;
                    lastAssigneeId: string;
                    creditCheckCode: number;
                    fullName?: string | null;
                    phoneNumber?: string | null;
                    personId: string;
                    email?: string | null;
                    address?: string | null;
                    zipCode?: string | null;
                    coAddress?: string | null;
                    updatedAt: any;
                    createdAt: any;
                    salesChannel: {
                      __typename?: 'SalesChannel';
                      _id: string;
                      projectId: string;
                      name: string;
                      description?: string | null;
                      status: SalesChannelStatus;
                      financeSystem: FinanceSystem;
                      createdAt: any;
                      updatedAt: any;
                      project: {
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      };
                    };
                    lastAssignee: {
                      __typename?: 'TeamMember';
                      parentTeamMemberId?: string | null;
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      parentTeamMember?: {
                        __typename?: 'TeamMember';
                        _id: string;
                        personId: string;
                        teamId: string;
                        roleId: string;
                        fullName?: string | null;
                        email?: string | null;
                        phoneNumber?: string | null;
                        status: TeamMemberStatus;
                        searchHistoryCount: number;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        projects: Array<{
                          __typename?: 'TeamMemberProject';
                          projectId: string;
                          projectName: string;
                          salesChannelId?: string | null;
                        }>;
                        role: {
                          __typename?: 'Role';
                          _id: string;
                          permissionIds: Array<string>;
                          teamId: string;
                          parentRoleId?: string | null;
                          name: string;
                          onlyTeamData: boolean;
                          onlySalesChannelData: boolean;
                          onlyOwnAndChildData: boolean;
                          canSeeOtherSearchHistory: boolean;
                          searchRestriction: number;
                          isSuper: boolean;
                          description?: string | null;
                          createdAt: any;
                          updatedAt: any;
                          team: {
                            __typename?: 'Team';
                            _id: string;
                            name: string;
                            rolesCount: number;
                            teamMembersCount: number;
                            description?: string | null;
                            projectIds: Array<string>;
                            createdAt: any;
                            updatedAt: any;
                            projects: Array<{
                              __typename?: 'Project';
                              _id: string;
                              name: string;
                              description?: string | null;
                              productsCount: number;
                              createdAt: any;
                              updatedAt: any;
                            }>;
                          };
                          parentRole?: {
                            __typename?: 'Role';
                            _id: string;
                            teamId: string;
                            name: string;
                          } | null;
                        };
                      } | null;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    };
                  } | null;
                  lastAssignee?: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  relation?: {
                    __typename?: 'Relation';
                    _id: string;
                    projectId: string;
                    status: RelationStatus;
                    name: string;
                    description?: string | null;
                    isOwn: boolean;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  } | null;
                  product: {
                    __typename?: 'Product';
                    _id: string;
                    projectId: string;
                    name: string;
                    nameInContract: string;
                    description?: string | null;
                    status: ProductStatus;
                    price: number;
                    vatRate: number;
                    vatPrice: number;
                    freeMonths: number;
                    licenseDurationMonths?: number | null;
                    isSubscription: boolean;
                    canBeTerminated: boolean;
                    unitId: string;
                    isCreditCheckRequired: boolean;
                    isPersonIdRequired: boolean;
                    isStockable: boolean;
                    isInStok: boolean;
                    hasCommissions: boolean;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                    productAddons: Array<{
                      __typename?: 'ProductAddon';
                      _id: string;
                      name: string;
                      vatPrice: number;
                      status: ProductAddonStatus;
                      isPersonIdRequired: boolean;
                      isCreditCheckRequired: boolean;
                      isSubscription: boolean;
                    }>;
                  };
                  licenses: Array<{
                    __typename?: 'License';
                    productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                  }>;
                };
              } | null;
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
        }>;
        soSafeCoupon?: {
          __typename?: 'SoSafeCoupon';
          code: string;
          amount: number;
          type: SoSafeCouponType;
        } | null;
        sourceOrders?: Array<{
          __typename?: 'Order';
          _id: string;
          fullTotalWithAddons: number;
          monthlySubtotal: number;
          items: Array<{
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          }>;
          bundles: Array<{
            __typename?: 'OrderBundleItem';
            productBundleId: string;
            postponeInvoicing: number;
            total: number;
            subtotal: number;
            addonsPrice: number;
            productBundle: {
              __typename?: 'ProductBundle';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              products: Array<{
                __typename?: 'ProductBundleToProduct';
                productId: string;
                qty: number;
                product: {
                  __typename?: 'Product';
                  name: string;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  isCreditCheckRequired: boolean;
                  licenseDurationMonths?: number | null;
                  isPersonIdRequired: boolean;
                  isSubscription: boolean;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              }>;
            };
            items: Array<{
              __typename?: 'OrderItem';
              relationId: string;
              productId: string;
              discountId?: string | null;
              discountAmount: number;
              discountPercent: number;
              qty: number;
              postponeInvoicing: number;
              personId?: string | null;
              fullName?: string | null;
              subtotal: number;
              total: number;
              addonsPrice: number;
              phoneNumber: string;
              relation: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              productAddons: Array<{
                __typename?: 'OrderItemProductAddon';
                productAddon: {
                  __typename?: 'ProductAddon';
                  _id: string;
                  projectId: string;
                  productId: string;
                  name: string;
                  status: ProductAddonStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  createdAt: any;
                  updatedAt: any;
                  isPersonIdRequired: boolean;
                  canBeTerminated: boolean;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  product: {
                    __typename?: 'Product';
                    _id: string;
                    projectId: string;
                    name: string;
                    nameInContract: string;
                    description?: string | null;
                    status: ProductStatus;
                    price: number;
                    vatRate: number;
                    vatPrice: number;
                    freeMonths: number;
                    licenseDurationMonths?: number | null;
                    isSubscription: boolean;
                    canBeTerminated: boolean;
                    unitId: string;
                    isCreditCheckRequired: boolean;
                    isPersonIdRequired: boolean;
                    isStockable: boolean;
                    isInStok: boolean;
                    hasCommissions: boolean;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                    productAddons: Array<{
                      __typename?: 'ProductAddon';
                      _id: string;
                      name: string;
                      vatPrice: number;
                      status: ProductAddonStatus;
                      isPersonIdRequired: boolean;
                      isCreditCheckRequired: boolean;
                      isSubscription: boolean;
                    }>;
                  };
                };
                license?: { __typename?: 'License'; status: LicenseStatus } | null;
              }>;
              discount?: {
                __typename?: 'ProductDiscount';
                _id: string;
                productId: string;
                teamId?: string | null;
                roleId?: string | null;
                teamMemberId?: string | null;
                salesChannelId?: string | null;
                authorId: string;
                qty: number;
                discount: number;
                status: ProductDiscountStatus;
                productPrice: number;
                productVatPrice: number;
                createdAt: any;
                updatedAt: any;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                team?: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                } | null;
                role?: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                } | null;
                teamMember?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                salesChannel?: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                author: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              license?: {
                __typename?: 'License';
                _id: string;
                status: LicenseStatus;
                licenseEndDate: any;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                externalUser: {
                  __typename?: 'ExternalUser';
                  _id: string;
                  lastCustomerId?: string | null;
                  lastAssigneeId?: string | null;
                  relationId?: string | null;
                  productId: string;
                  fullName?: string | null;
                  phoneNumber: string;
                  personId?: string | null;
                  address?: string | null;
                  licenseStartDate: any;
                  licenseEndDate: any;
                  createdAt: any;
                  lastCustomer?: {
                    __typename?: 'Customer';
                    _id: string;
                    salesChannelId: string;
                    lastAssigneeId: string;
                    creditCheckCode: number;
                    fullName?: string | null;
                    phoneNumber?: string | null;
                    personId: string;
                    email?: string | null;
                    address?: string | null;
                    zipCode?: string | null;
                    coAddress?: string | null;
                    updatedAt: any;
                    createdAt: any;
                    salesChannel: {
                      __typename?: 'SalesChannel';
                      _id: string;
                      projectId: string;
                      name: string;
                      description?: string | null;
                      status: SalesChannelStatus;
                      financeSystem: FinanceSystem;
                      createdAt: any;
                      updatedAt: any;
                      project: {
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      };
                    };
                    lastAssignee: {
                      __typename?: 'TeamMember';
                      parentTeamMemberId?: string | null;
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      parentTeamMember?: {
                        __typename?: 'TeamMember';
                        _id: string;
                        personId: string;
                        teamId: string;
                        roleId: string;
                        fullName?: string | null;
                        email?: string | null;
                        phoneNumber?: string | null;
                        status: TeamMemberStatus;
                        searchHistoryCount: number;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        projects: Array<{
                          __typename?: 'TeamMemberProject';
                          projectId: string;
                          projectName: string;
                          salesChannelId?: string | null;
                        }>;
                        role: {
                          __typename?: 'Role';
                          _id: string;
                          permissionIds: Array<string>;
                          teamId: string;
                          parentRoleId?: string | null;
                          name: string;
                          onlyTeamData: boolean;
                          onlySalesChannelData: boolean;
                          onlyOwnAndChildData: boolean;
                          canSeeOtherSearchHistory: boolean;
                          searchRestriction: number;
                          isSuper: boolean;
                          description?: string | null;
                          createdAt: any;
                          updatedAt: any;
                          team: {
                            __typename?: 'Team';
                            _id: string;
                            name: string;
                            rolesCount: number;
                            teamMembersCount: number;
                            description?: string | null;
                            projectIds: Array<string>;
                            createdAt: any;
                            updatedAt: any;
                            projects: Array<{
                              __typename?: 'Project';
                              _id: string;
                              name: string;
                              description?: string | null;
                              productsCount: number;
                              createdAt: any;
                              updatedAt: any;
                            }>;
                          };
                          parentRole?: {
                            __typename?: 'Role';
                            _id: string;
                            teamId: string;
                            name: string;
                          } | null;
                        };
                      } | null;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    };
                  } | null;
                  lastAssignee?: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  relation?: {
                    __typename?: 'Relation';
                    _id: string;
                    projectId: string;
                    status: RelationStatus;
                    name: string;
                    description?: string | null;
                    isOwn: boolean;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  } | null;
                  product: {
                    __typename?: 'Product';
                    _id: string;
                    projectId: string;
                    name: string;
                    nameInContract: string;
                    description?: string | null;
                    status: ProductStatus;
                    price: number;
                    vatRate: number;
                    vatPrice: number;
                    freeMonths: number;
                    licenseDurationMonths?: number | null;
                    isSubscription: boolean;
                    canBeTerminated: boolean;
                    unitId: string;
                    isCreditCheckRequired: boolean;
                    isPersonIdRequired: boolean;
                    isStockable: boolean;
                    isInStok: boolean;
                    hasCommissions: boolean;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                    productAddons: Array<{
                      __typename?: 'ProductAddon';
                      _id: string;
                      name: string;
                      vatPrice: number;
                      status: ProductAddonStatus;
                      isPersonIdRequired: boolean;
                      isCreditCheckRequired: boolean;
                      isSubscription: boolean;
                    }>;
                  };
                  licenses: Array<{
                    __typename?: 'License';
                    productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                  }>;
                };
              } | null;
            }>;
          }>;
        }> | null;
      } | null;
      orderItem?: {
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      } | null;
      customer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      externalUser: {
        __typename?: 'ExternalUser';
        _id: string;
        lastCustomerId?: string | null;
        lastAssigneeId?: string | null;
        relationId?: string | null;
        productId: string;
        fullName?: string | null;
        phoneNumber: string;
        personId?: string | null;
        address?: string | null;
        licenseStartDate: any;
        licenseEndDate: any;
        createdAt: any;
        lastCustomer?: {
          __typename?: 'Customer';
          _id: string;
          salesChannelId: string;
          lastAssigneeId: string;
          creditCheckCode: number;
          fullName?: string | null;
          phoneNumber?: string | null;
          personId: string;
          email?: string | null;
          address?: string | null;
          zipCode?: string | null;
          coAddress?: string | null;
          updatedAt: any;
          createdAt: any;
          salesChannel: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          lastAssignee: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        lastAssignee?: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        relation?: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        } | null;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        licenses: Array<{
          __typename?: 'License';
          productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
        }>;
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetLicensesForListQueryVariables = Exact<{
  filter?: InputMaybe<LicensesFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<LicensesOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetLicensesForListQuery = {
  __typename?: 'Query';
  getLicensesForList: {
    __typename?: 'GetLicensesPayload';
    count: number;
    nodes: Array<{
      __typename?: 'License';
      _id: string;
      status: LicenseStatus;
      licenseStartDate: any;
      licenseEndDate: any;
      orderId?: string | null;
      financeSystemSyncStatus: FinanceSystemItemSyncStatus;
      product: { __typename?: 'Product'; name: string; canBeTerminated: boolean };
      productAddon?: { __typename?: 'ProductAddon'; name: string; canBeTerminated: boolean } | null;
      externalUser: { __typename?: 'ExternalUser'; phoneNumber: string; fullName?: string | null };
      customer?: { __typename?: 'Customer'; fullName?: string | null } | null;
      order?: {
        __typename?: 'Order';
        financeSystem: FinanceSystem;
        financeSystemSyncStatus: FinanceSystemSyncStatus;
      } | null;
    }>;
  };
};

export type GetPurchasedLicensesQueryVariables = Exact<{
  filter: GetPurchasedLicensesFilterInput;
}>;

export type GetPurchasedLicensesQuery = {
  __typename?: 'Query';
  getPurchasedLicenses: {
    __typename?: 'GetPurchasedLicensesPayload';
    activeLicenses: Array<{
      __typename?: 'PurchasedLicense';
      _id: string;
      createdAt: any;
      signedAt?: any | null;
      willEndAt?: any | null;
      relationId: string;
      productId: string;
      externalUserId: string;
      orderId: string;
      status: LicenseStatus;
      daysLeft: number;
      isFromBundle: boolean;
      postponeInvoicing: number;
      discountPercent: number;
      subtotal: number;
      total: number;
      product: {
        __typename?: 'Product';
        _id: string;
        name: string;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
        }>;
      };
      relation: { __typename?: 'Relation'; name: string };
      externalUser: {
        __typename?: 'ExternalUser';
        fullName?: string | null;
        personId?: string | null;
        phoneNumber: string;
      };
      order: { __typename?: 'Order'; project: { __typename?: 'Project'; name: string } };
      availableProductAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
      }>;
      purchasedProductAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
      }>;
    }>;
    inactiveLicenses: Array<{
      __typename?: 'PurchasedLicense';
      _id: string;
      createdAt: any;
      signedAt?: any | null;
      willEndAt?: any | null;
      relationId: string;
      productId: string;
      externalUserId: string;
      orderId: string;
      status: LicenseStatus;
      daysLeft: number;
      isFromBundle: boolean;
      postponeInvoicing: number;
      discountPercent: number;
      subtotal: number;
      total: number;
      product: {
        __typename?: 'Product';
        _id: string;
        name: string;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
        }>;
      };
      relation: { __typename?: 'Relation'; name: string };
      externalUser: {
        __typename?: 'ExternalUser';
        fullName?: string | null;
        personId?: string | null;
        phoneNumber: string;
      };
      order: { __typename?: 'Order'; project: { __typename?: 'Project'; name: string } };
      availableProductAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
      }>;
      purchasedProductAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
      }>;
    }>;
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
};

export type NoteFragment = {
  __typename?: 'Note';
  _id: string;
  entityType: NoteEntityType;
  entityId: string;
  authorId?: string | null;
  content: string;
  createdAt: any;
  author?: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  } | null;
};

export type CreateNoteMutationVariables = Exact<{
  input?: InputMaybe<CreateNoteInput>;
}>;

export type CreateNoteMutation = {
  __typename?: 'Mutation';
  createNote: {
    __typename?: 'CreateNotePayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Note';
      _id: string;
      entityType: NoteEntityType;
      entityId: string;
      authorId?: string | null;
      content: string;
      createdAt: any;
      author?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
    } | null;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type GetNotesQueryVariables = Exact<{
  filter: GetNotesFilterInput;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetNotesQuery = {
  __typename?: 'Query';
  getNotes: {
    __typename?: 'GetNotesPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Note';
      _id: string;
      entityType: NoteEntityType;
      entityId: string;
      authorId?: string | null;
      content: string;
      createdAt: any;
      author?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
    }>;
  };
};

export type OrderStatusHistoryItemFragment = {
  __typename?: 'OrderStatusHistoryItem';
  orderId: string;
  status: OrderStatus;
  createdAt: any;
  regretReason?: string | null;
  regretOption?: RegretOption | null;
  winbackInfo?: {
    __typename?: 'WinbackInfo';
    oldContractValue: number;
    oldDuration: number;
    newContractValue: number;
    newDuration: number;
    itemChanges: Array<{
      __typename?: 'WinbackInfoItemChange';
      type: WinbackItemType;
      productId?: string | null;
      productBundleId?: string | null;
      productAddonId?: string | null;
      oldQty: number;
      newQty: number;
      product?: { __typename?: 'Product'; name: string } | null;
      productBundle?: { __typename?: 'ProductBundle'; name: string } | null;
      productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
    }>;
  } | null;
  byTeamMember: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
};

export type B2BOrderStatusHistoryItemFragment = {
  __typename?: 'B2BOrderStatusHistoryItem';
  orderId: string;
  status: B2BOrderStatus;
  createdAt: any;
  byTeamMember: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
};

export type GetOrderStatusHistoryQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type GetOrderStatusHistoryQuery = {
  __typename?: 'Query';
  getOrderStatusHistory: {
    __typename?: 'GetOrderStatusHistoryPayload';
    count: number;
    nodes: Array<{
      __typename?: 'OrderStatusHistoryItem';
      orderId: string;
      status: OrderStatus;
      createdAt: any;
      regretReason?: string | null;
      regretOption?: RegretOption | null;
      winbackInfo?: {
        __typename?: 'WinbackInfo';
        oldContractValue: number;
        oldDuration: number;
        newContractValue: number;
        newDuration: number;
        itemChanges: Array<{
          __typename?: 'WinbackInfoItemChange';
          type: WinbackItemType;
          productId?: string | null;
          productBundleId?: string | null;
          productAddonId?: string | null;
          oldQty: number;
          newQty: number;
          product?: { __typename?: 'Product'; name: string } | null;
          productBundle?: { __typename?: 'ProductBundle'; name: string } | null;
          productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
        }>;
      } | null;
      byTeamMember: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    }>;
  };
};

export type GetB2BOrderStatusHistoryQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type GetB2BOrderStatusHistoryQuery = {
  __typename?: 'Query';
  getB2BOrderStatusHistory: {
    __typename?: 'GetB2BOrderStatusHistoryPayload';
    count: number;
    nodes: Array<{
      __typename?: 'B2BOrderStatusHistoryItem';
      orderId: string;
      status: B2BOrderStatus;
      createdAt: any;
      byTeamMember: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    }>;
  };
};

export type OrderItemCalculationFragment = {
  __typename?: 'OrderItemCalculation';
  itemKey: string;
  discountAmount: number;
  discountPercent: number;
  qty: number;
  subtotal: number;
  total: number;
  addonsPrice: number;
};

export type OrderCalculationFragment = {
  __typename?: 'OrderCalculation';
  subtotal: number;
  total: number;
  onePaymentTotal: number;
  monthlyTotal: number;
  fullTotal: number;
  discountPercent: number;
  discountAmount: number;
  monthlySubtotal: number;
  quarterSubtotal: number;
  fullTotalWithAddons: number;
  items: Array<{
    __typename?: 'OrderItemCalculation';
    itemKey: string;
    discountAmount: number;
    discountPercent: number;
    qty: number;
    subtotal: number;
    total: number;
    addonsPrice: number;
  }>;
  bundles: Array<{
    __typename?: 'OrderBundleCalculation';
    productBundleId: string;
    itemKey: string;
    subtotal: number;
    addonsPrice: number;
    total: number;
    items: Array<{
      __typename?: 'OrderItemCalculation';
      itemKey: string;
      discountAmount: number;
      discountPercent: number;
      qty: number;
      subtotal: number;
      total: number;
      addonsPrice: number;
    }>;
  }>;
  upgrades: Array<{
    __typename?: 'OrderUpgradeCalculationItem';
    itemKey: string;
    subtotal: number;
    total: number;
  }>;
  soSafeCoupon?: {
    __typename?: 'SoSafeCoupon';
    code: string;
    amount: number;
    type: SoSafeCouponType;
  } | null;
};

export type OrderItemFragment = {
  __typename?: 'OrderItem';
  relationId: string;
  productId: string;
  discountId?: string | null;
  discountAmount: number;
  discountPercent: number;
  qty: number;
  postponeInvoicing: number;
  personId?: string | null;
  fullName?: string | null;
  subtotal: number;
  total: number;
  addonsPrice: number;
  phoneNumber: string;
  relation: {
    __typename?: 'Relation';
    _id: string;
    projectId: string;
    status: RelationStatus;
    name: string;
    description?: string | null;
    isOwn: boolean;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  };
  product: {
    __typename?: 'Product';
    _id: string;
    projectId: string;
    name: string;
    nameInContract: string;
    description?: string | null;
    status: ProductStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    freeMonths: number;
    licenseDurationMonths?: number | null;
    isSubscription: boolean;
    canBeTerminated: boolean;
    unitId: string;
    isCreditCheckRequired: boolean;
    isPersonIdRequired: boolean;
    isStockable: boolean;
    isInStok: boolean;
    hasCommissions: boolean;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
    productAddons: Array<{
      __typename?: 'ProductAddon';
      _id: string;
      name: string;
      vatPrice: number;
      status: ProductAddonStatus;
      isPersonIdRequired: boolean;
      isCreditCheckRequired: boolean;
      isSubscription: boolean;
    }>;
  };
  productAddons: Array<{
    __typename?: 'OrderItemProductAddon';
    productAddon: {
      __typename?: 'ProductAddon';
      _id: string;
      projectId: string;
      productId: string;
      name: string;
      status: ProductAddonStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      createdAt: any;
      updatedAt: any;
      isPersonIdRequired: boolean;
      canBeTerminated: boolean;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
    };
    license?: { __typename?: 'License'; status: LicenseStatus } | null;
  }>;
  discount?: {
    __typename?: 'ProductDiscount';
    _id: string;
    productId: string;
    teamId?: string | null;
    roleId?: string | null;
    teamMemberId?: string | null;
    salesChannelId?: string | null;
    authorId: string;
    qty: number;
    discount: number;
    status: ProductDiscountStatus;
    productPrice: number;
    productVatPrice: number;
    createdAt: any;
    updatedAt: any;
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
    team?: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    } | null;
    role?: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    } | null;
    teamMember?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    salesChannel?: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    author: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
  } | null;
  license?: {
    __typename?: 'License';
    _id: string;
    status: LicenseStatus;
    licenseEndDate: any;
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
    externalUser: {
      __typename?: 'ExternalUser';
      _id: string;
      lastCustomerId?: string | null;
      lastAssigneeId?: string | null;
      relationId?: string | null;
      productId: string;
      fullName?: string | null;
      phoneNumber: string;
      personId?: string | null;
      address?: string | null;
      licenseStartDate: any;
      licenseEndDate: any;
      createdAt: any;
      lastCustomer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      lastAssignee?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      relation?: {
        __typename?: 'Relation';
        _id: string;
        projectId: string;
        status: RelationStatus;
        name: string;
        description?: string | null;
        isOwn: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      licenses: Array<{
        __typename?: 'License';
        productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
      }>;
    };
  } | null;
};

export type OrderUpgradeItemFragment = {
  __typename?: 'OrderUpgradeItem';
  licenseId: string;
  total: number;
  license: {
    __typename?: 'License';
    _id: string;
    status: LicenseStatus;
    licenseEndDate: any;
    orderItem?: {
      __typename?: 'OrderItem';
      relationId: string;
      productId: string;
      discountId?: string | null;
      discountAmount: number;
      discountPercent: number;
      qty: number;
      postponeInvoicing: number;
      personId?: string | null;
      fullName?: string | null;
      subtotal: number;
      total: number;
      addonsPrice: number;
      phoneNumber: string;
      relation: {
        __typename?: 'Relation';
        _id: string;
        projectId: string;
        status: RelationStatus;
        name: string;
        description?: string | null;
        isOwn: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      productAddons: Array<{
        __typename?: 'OrderItemProductAddon';
        productAddon: {
          __typename?: 'ProductAddon';
          _id: string;
          projectId: string;
          productId: string;
          name: string;
          status: ProductAddonStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          createdAt: any;
          updatedAt: any;
          isPersonIdRequired: boolean;
          canBeTerminated: boolean;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        };
        license?: { __typename?: 'License'; status: LicenseStatus } | null;
      }>;
      discount?: {
        __typename?: 'ProductDiscount';
        _id: string;
        productId: string;
        teamId?: string | null;
        roleId?: string | null;
        teamMemberId?: string | null;
        salesChannelId?: string | null;
        authorId: string;
        qty: number;
        discount: number;
        status: ProductDiscountStatus;
        productPrice: number;
        productVatPrice: number;
        createdAt: any;
        updatedAt: any;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        team?: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        } | null;
        role?: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        } | null;
        teamMember?: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        salesChannel?: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        } | null;
        author: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      license?: {
        __typename?: 'License';
        _id: string;
        status: LicenseStatus;
        licenseEndDate: any;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        externalUser: {
          __typename?: 'ExternalUser';
          _id: string;
          lastCustomerId?: string | null;
          lastAssigneeId?: string | null;
          relationId?: string | null;
          productId: string;
          fullName?: string | null;
          phoneNumber: string;
          personId?: string | null;
          address?: string | null;
          licenseStartDate: any;
          licenseEndDate: any;
          createdAt: any;
          lastCustomer?: {
            __typename?: 'Customer';
            _id: string;
            salesChannelId: string;
            lastAssigneeId: string;
            creditCheckCode: number;
            fullName?: string | null;
            phoneNumber?: string | null;
            personId: string;
            email?: string | null;
            address?: string | null;
            zipCode?: string | null;
            coAddress?: string | null;
            updatedAt: any;
            createdAt: any;
            salesChannel: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            lastAssignee: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          lastAssignee?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          relation?: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          licenses: Array<{
            __typename?: 'License';
            productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
          }>;
        };
      } | null;
    } | null;
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
    externalUser: {
      __typename?: 'ExternalUser';
      _id: string;
      lastCustomerId?: string | null;
      lastAssigneeId?: string | null;
      relationId?: string | null;
      productId: string;
      fullName?: string | null;
      phoneNumber: string;
      personId?: string | null;
      address?: string | null;
      licenseStartDate: any;
      licenseEndDate: any;
      createdAt: any;
      lastCustomer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      lastAssignee?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      relation?: {
        __typename?: 'Relation';
        _id: string;
        projectId: string;
        status: RelationStatus;
        name: string;
        description?: string | null;
        isOwn: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      licenses: Array<{
        __typename?: 'License';
        productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
      }>;
    };
  };
  productAddons: Array<{
    __typename?: 'OrderItemProductAddon';
    productAddon: {
      __typename?: 'ProductAddon';
      _id: string;
      projectId: string;
      productId: string;
      name: string;
      status: ProductAddonStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      createdAt: any;
      updatedAt: any;
      isPersonIdRequired: boolean;
      canBeTerminated: boolean;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
    };
    license?: { __typename?: 'License'; status: LicenseStatus } | null;
  }>;
};

export type OrderFragment = {
  __typename?: 'Order';
  _id: string;
  salesChannelId: string;
  projectId: string;
  customerId?: string | null;
  assigneeId: string;
  resolvedById?: string | null;
  isCreditCheckReceived: boolean;
  isActiveCustomer: boolean;
  financeSystemSyncStatus: FinanceSystemSyncStatus;
  financeSystem: FinanceSystem;
  creditCheckCode: number;
  fullName?: string | null;
  email: string;
  phoneNumber: string;
  personId: string;
  status: OrderStatus;
  coAddress: string;
  invoicingAddress?: string | null;
  invoicingType: InvoicingType;
  invoicingZip?: string | null;
  invoicingFrequency: number;
  customerAddress: string;
  customerZip: string;
  subtotal: number;
  total: number;
  monthlyTotal: number;
  onePaymentTotal: number;
  discountPercent: number;
  discountAmount: number;
  monthlySubtotal: number;
  quarterSubtotal: number;
  fullTotalWithAddons: number;
  regretReason?: string | null;
  regretOption?: RegretOption | null;
  shippingStatus: ShippingStatus;
  scriveContractStatus: ScriveContractStatus;
  scriveContractId?: string | null;
  isUnsigned: boolean;
  isRegrettable: boolean;
  isRegrettableDate?: any | null;
  isDeleted: boolean;
  isWinBack?: boolean | null;
  isFromShopPage: boolean;
  updatedAt: any;
  createdAt: any;
  contractStatus: OrderContractStatus;
  contractSignedDate?: any | null;
  winBackPrice: number;
  winBackDuration?: number | null;
  salesChannel: {
    __typename?: 'SalesChannel';
    _id: string;
    projectId: string;
    name: string;
    description?: string | null;
    status: SalesChannelStatus;
    financeSystem: FinanceSystem;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  };
  project: {
    __typename?: 'Project';
    _id: string;
    name: string;
    description?: string | null;
    productsCount: number;
    createdAt: any;
    updatedAt: any;
  };
  customer?: {
    __typename?: 'Customer';
    _id: string;
    salesChannelId: string;
    lastAssigneeId: string;
    creditCheckCode: number;
    fullName?: string | null;
    phoneNumber?: string | null;
    personId: string;
    email?: string | null;
    address?: string | null;
    zipCode?: string | null;
    coAddress?: string | null;
    updatedAt: any;
    createdAt: any;
    salesChannel: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    };
    lastAssignee: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
  } | null;
  assignee: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
  resolvedBy?: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  } | null;
  items: Array<{
    __typename?: 'OrderItem';
    relationId: string;
    productId: string;
    discountId?: string | null;
    discountAmount: number;
    discountPercent: number;
    qty: number;
    postponeInvoicing: number;
    personId?: string | null;
    fullName?: string | null;
    subtotal: number;
    total: number;
    addonsPrice: number;
    phoneNumber: string;
    relation: {
      __typename?: 'Relation';
      _id: string;
      projectId: string;
      status: RelationStatus;
      name: string;
      description?: string | null;
      isOwn: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    };
    product: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    };
    productAddons: Array<{
      __typename?: 'OrderItemProductAddon';
      productAddon: {
        __typename?: 'ProductAddon';
        _id: string;
        projectId: string;
        productId: string;
        name: string;
        status: ProductAddonStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        createdAt: any;
        updatedAt: any;
        isPersonIdRequired: boolean;
        canBeTerminated: boolean;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      };
      license?: { __typename?: 'License'; status: LicenseStatus } | null;
    }>;
    discount?: {
      __typename?: 'ProductDiscount';
      _id: string;
      productId: string;
      teamId?: string | null;
      roleId?: string | null;
      teamMemberId?: string | null;
      salesChannelId?: string | null;
      authorId: string;
      qty: number;
      discount: number;
      status: ProductDiscountStatus;
      productPrice: number;
      productVatPrice: number;
      createdAt: any;
      updatedAt: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      team?: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      } | null;
      role?: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      } | null;
      teamMember?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      } | null;
      author: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    license?: {
      __typename?: 'License';
      _id: string;
      status: LicenseStatus;
      licenseEndDate: any;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      externalUser: {
        __typename?: 'ExternalUser';
        _id: string;
        lastCustomerId?: string | null;
        lastAssigneeId?: string | null;
        relationId?: string | null;
        productId: string;
        fullName?: string | null;
        phoneNumber: string;
        personId?: string | null;
        address?: string | null;
        licenseStartDate: any;
        licenseEndDate: any;
        createdAt: any;
        lastCustomer?: {
          __typename?: 'Customer';
          _id: string;
          salesChannelId: string;
          lastAssigneeId: string;
          creditCheckCode: number;
          fullName?: string | null;
          phoneNumber?: string | null;
          personId: string;
          email?: string | null;
          address?: string | null;
          zipCode?: string | null;
          coAddress?: string | null;
          updatedAt: any;
          createdAt: any;
          salesChannel: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          lastAssignee: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        lastAssignee?: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        relation?: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        } | null;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        licenses: Array<{
          __typename?: 'License';
          productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
        }>;
      };
    } | null;
  }>;
  bundles: Array<{
    __typename?: 'OrderBundleItem';
    productBundleId: string;
    postponeInvoicing: number;
    total: number;
    subtotal: number;
    addonsPrice: number;
    productBundle: {
      __typename?: 'ProductBundle';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      products: Array<{
        __typename?: 'ProductBundleToProduct';
        productId: string;
        qty: number;
        product: {
          __typename?: 'Product';
          name: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          isCreditCheckRequired: boolean;
          licenseDurationMonths?: number | null;
          isPersonIdRequired: boolean;
          isSubscription: boolean;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      }>;
    };
    items: Array<{
      __typename?: 'OrderItem';
      relationId: string;
      productId: string;
      discountId?: string | null;
      discountAmount: number;
      discountPercent: number;
      qty: number;
      postponeInvoicing: number;
      personId?: string | null;
      fullName?: string | null;
      subtotal: number;
      total: number;
      addonsPrice: number;
      phoneNumber: string;
      relation: {
        __typename?: 'Relation';
        _id: string;
        projectId: string;
        status: RelationStatus;
        name: string;
        description?: string | null;
        isOwn: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      productAddons: Array<{
        __typename?: 'OrderItemProductAddon';
        productAddon: {
          __typename?: 'ProductAddon';
          _id: string;
          projectId: string;
          productId: string;
          name: string;
          status: ProductAddonStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          createdAt: any;
          updatedAt: any;
          isPersonIdRequired: boolean;
          canBeTerminated: boolean;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        };
        license?: { __typename?: 'License'; status: LicenseStatus } | null;
      }>;
      discount?: {
        __typename?: 'ProductDiscount';
        _id: string;
        productId: string;
        teamId?: string | null;
        roleId?: string | null;
        teamMemberId?: string | null;
        salesChannelId?: string | null;
        authorId: string;
        qty: number;
        discount: number;
        status: ProductDiscountStatus;
        productPrice: number;
        productVatPrice: number;
        createdAt: any;
        updatedAt: any;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        team?: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        } | null;
        role?: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        } | null;
        teamMember?: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        salesChannel?: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        } | null;
        author: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      license?: {
        __typename?: 'License';
        _id: string;
        status: LicenseStatus;
        licenseEndDate: any;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        externalUser: {
          __typename?: 'ExternalUser';
          _id: string;
          lastCustomerId?: string | null;
          lastAssigneeId?: string | null;
          relationId?: string | null;
          productId: string;
          fullName?: string | null;
          phoneNumber: string;
          personId?: string | null;
          address?: string | null;
          licenseStartDate: any;
          licenseEndDate: any;
          createdAt: any;
          lastCustomer?: {
            __typename?: 'Customer';
            _id: string;
            salesChannelId: string;
            lastAssigneeId: string;
            creditCheckCode: number;
            fullName?: string | null;
            phoneNumber?: string | null;
            personId: string;
            email?: string | null;
            address?: string | null;
            zipCode?: string | null;
            coAddress?: string | null;
            updatedAt: any;
            createdAt: any;
            salesChannel: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            lastAssignee: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          lastAssignee?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          relation?: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          licenses: Array<{
            __typename?: 'License';
            productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
          }>;
        };
      } | null;
    }>;
  }>;
  upgrades: Array<{
    __typename?: 'OrderUpgradeItem';
    licenseId: string;
    total: number;
    license: {
      __typename?: 'License';
      _id: string;
      status: LicenseStatus;
      licenseEndDate: any;
      orderItem?: {
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      } | null;
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      externalUser: {
        __typename?: 'ExternalUser';
        _id: string;
        lastCustomerId?: string | null;
        lastAssigneeId?: string | null;
        relationId?: string | null;
        productId: string;
        fullName?: string | null;
        phoneNumber: string;
        personId?: string | null;
        address?: string | null;
        licenseStartDate: any;
        licenseEndDate: any;
        createdAt: any;
        lastCustomer?: {
          __typename?: 'Customer';
          _id: string;
          salesChannelId: string;
          lastAssigneeId: string;
          creditCheckCode: number;
          fullName?: string | null;
          phoneNumber?: string | null;
          personId: string;
          email?: string | null;
          address?: string | null;
          zipCode?: string | null;
          coAddress?: string | null;
          updatedAt: any;
          createdAt: any;
          salesChannel: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          lastAssignee: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        lastAssignee?: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        relation?: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        } | null;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        licenses: Array<{
          __typename?: 'License';
          productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
        }>;
      };
    };
    productAddons: Array<{
      __typename?: 'OrderItemProductAddon';
      productAddon: {
        __typename?: 'ProductAddon';
        _id: string;
        projectId: string;
        productId: string;
        name: string;
        status: ProductAddonStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        createdAt: any;
        updatedAt: any;
        isPersonIdRequired: boolean;
        canBeTerminated: boolean;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
      };
      license?: { __typename?: 'License'; status: LicenseStatus } | null;
    }>;
  }>;
  soSafeCoupon?: {
    __typename?: 'SoSafeCoupon';
    code: string;
    amount: number;
    type: SoSafeCouponType;
  } | null;
  sourceOrders?: Array<{
    __typename?: 'Order';
    _id: string;
    fullTotalWithAddons: number;
    monthlySubtotal: number;
    items: Array<{
      __typename?: 'OrderItem';
      relationId: string;
      productId: string;
      discountId?: string | null;
      discountAmount: number;
      discountPercent: number;
      qty: number;
      postponeInvoicing: number;
      personId?: string | null;
      fullName?: string | null;
      subtotal: number;
      total: number;
      addonsPrice: number;
      phoneNumber: string;
      relation: {
        __typename?: 'Relation';
        _id: string;
        projectId: string;
        status: RelationStatus;
        name: string;
        description?: string | null;
        isOwn: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      productAddons: Array<{
        __typename?: 'OrderItemProductAddon';
        productAddon: {
          __typename?: 'ProductAddon';
          _id: string;
          projectId: string;
          productId: string;
          name: string;
          status: ProductAddonStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          createdAt: any;
          updatedAt: any;
          isPersonIdRequired: boolean;
          canBeTerminated: boolean;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        };
        license?: { __typename?: 'License'; status: LicenseStatus } | null;
      }>;
      discount?: {
        __typename?: 'ProductDiscount';
        _id: string;
        productId: string;
        teamId?: string | null;
        roleId?: string | null;
        teamMemberId?: string | null;
        salesChannelId?: string | null;
        authorId: string;
        qty: number;
        discount: number;
        status: ProductDiscountStatus;
        productPrice: number;
        productVatPrice: number;
        createdAt: any;
        updatedAt: any;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        team?: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        } | null;
        role?: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        } | null;
        teamMember?: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        salesChannel?: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        } | null;
        author: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      license?: {
        __typename?: 'License';
        _id: string;
        status: LicenseStatus;
        licenseEndDate: any;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        externalUser: {
          __typename?: 'ExternalUser';
          _id: string;
          lastCustomerId?: string | null;
          lastAssigneeId?: string | null;
          relationId?: string | null;
          productId: string;
          fullName?: string | null;
          phoneNumber: string;
          personId?: string | null;
          address?: string | null;
          licenseStartDate: any;
          licenseEndDate: any;
          createdAt: any;
          lastCustomer?: {
            __typename?: 'Customer';
            _id: string;
            salesChannelId: string;
            lastAssigneeId: string;
            creditCheckCode: number;
            fullName?: string | null;
            phoneNumber?: string | null;
            personId: string;
            email?: string | null;
            address?: string | null;
            zipCode?: string | null;
            coAddress?: string | null;
            updatedAt: any;
            createdAt: any;
            salesChannel: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            lastAssignee: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          lastAssignee?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          relation?: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          licenses: Array<{
            __typename?: 'License';
            productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
          }>;
        };
      } | null;
    }>;
    bundles: Array<{
      __typename?: 'OrderBundleItem';
      productBundleId: string;
      postponeInvoicing: number;
      total: number;
      subtotal: number;
      addonsPrice: number;
      productBundle: {
        __typename?: 'ProductBundle';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        products: Array<{
          __typename?: 'ProductBundleToProduct';
          productId: string;
          qty: number;
          product: {
            __typename?: 'Product';
            name: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            isCreditCheckRequired: boolean;
            licenseDurationMonths?: number | null;
            isPersonIdRequired: boolean;
            isSubscription: boolean;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        }>;
      };
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
    }>;
  }> | null;
};

export type B2BOrderFragment = {
  __typename?: 'B2BOrder';
  _id: string;
  projectId: string;
  customerId?: string | null;
  assigneeId: string;
  resolvedById?: string | null;
  contactPerson: string;
  isCreditCheckReceived: boolean;
  creditCheckCode: number;
  companyName?: string | null;
  email: string;
  phoneNumber: string;
  orgId: string;
  status: B2BOrderStatus;
  deliveryAddress?: string | null;
  coAddress?: string | null;
  invoicingAddress?: string | null;
  invoicingZip?: string | null;
  invoicingFrequency: number;
  total: number;
  monthlyTotal: number;
  discountAmount: number;
  shippingStatus: ShippingStatus;
  scriveContractStatus: ScriveContractStatus;
  scriveContractId?: string | null;
  isUnsigned: boolean;
  updatedAt: any;
  createdAt: any;
  project: {
    __typename?: 'Project';
    _id: string;
    name: string;
    description?: string | null;
    productsCount: number;
    createdAt: any;
    updatedAt: any;
  };
  customer?: {
    __typename?: 'B2BCustomer';
    _id: string;
    lastAssigneeId: string;
    creditCheckCode: number;
    fullName?: string | null;
    phoneNumber?: string | null;
    orgId: string;
    email?: string | null;
    updatedAt: any;
    createdAt: any;
    lastAssignee: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
  } | null;
  assignee: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
  resolvedBy?: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  } | null;
  items: Array<{
    __typename?: 'B2BOrderItem';
    productId: string;
    qty: number;
    duration: number;
    monthlyPricePerOneLicense: number;
    monthlyPricePerAllLicenses: number;
    discountAmount: number;
  }>;
};

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;

export type CreateOrderMutation = {
  __typename?: 'Mutation';
  createOrder: {
    __typename?: 'CreateOrderPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Order';
      _id: string;
      salesChannelId: string;
      projectId: string;
      customerId?: string | null;
      assigneeId: string;
      resolvedById?: string | null;
      isCreditCheckReceived: boolean;
      isActiveCustomer: boolean;
      financeSystemSyncStatus: FinanceSystemSyncStatus;
      financeSystem: FinanceSystem;
      creditCheckCode: number;
      fullName?: string | null;
      email: string;
      phoneNumber: string;
      personId: string;
      status: OrderStatus;
      coAddress: string;
      invoicingAddress?: string | null;
      invoicingType: InvoicingType;
      invoicingZip?: string | null;
      invoicingFrequency: number;
      customerAddress: string;
      customerZip: string;
      subtotal: number;
      total: number;
      monthlyTotal: number;
      onePaymentTotal: number;
      discountPercent: number;
      discountAmount: number;
      monthlySubtotal: number;
      quarterSubtotal: number;
      fullTotalWithAddons: number;
      regretReason?: string | null;
      regretOption?: RegretOption | null;
      shippingStatus: ShippingStatus;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      isUnsigned: boolean;
      isRegrettable: boolean;
      isRegrettableDate?: any | null;
      isDeleted: boolean;
      isWinBack?: boolean | null;
      isFromShopPage: boolean;
      updatedAt: any;
      createdAt: any;
      contractStatus: OrderContractStatus;
      contractSignedDate?: any | null;
      winBackPrice: number;
      winBackDuration?: number | null;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      customer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
      resolvedBy?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundleId: string;
        postponeInvoicing: number;
        total: number;
        subtotal: number;
        addonsPrice: number;
        productBundle: {
          __typename?: 'ProductBundle';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          products: Array<{
            __typename?: 'ProductBundleToProduct';
            productId: string;
            qty: number;
            product: {
              __typename?: 'Product';
              name: string;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              isCreditCheckRequired: boolean;
              licenseDurationMonths?: number | null;
              isPersonIdRequired: boolean;
              isSubscription: boolean;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          }>;
        };
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeItem';
        licenseId: string;
        total: number;
        license: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          orderItem?: {
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
      }>;
      soSafeCoupon?: {
        __typename?: 'SoSafeCoupon';
        code: string;
        amount: number;
        type: SoSafeCouponType;
      } | null;
      sourceOrders?: Array<{
        __typename?: 'Order';
        _id: string;
        fullTotalWithAddons: number;
        monthlySubtotal: number;
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
        bundles: Array<{
          __typename?: 'OrderBundleItem';
          productBundleId: string;
          postponeInvoicing: number;
          total: number;
          subtotal: number;
          addonsPrice: number;
          productBundle: {
            __typename?: 'ProductBundle';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            products: Array<{
              __typename?: 'ProductBundleToProduct';
              productId: string;
              qty: number;
              product: {
                __typename?: 'Product';
                name: string;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                isCreditCheckRequired: boolean;
                licenseDurationMonths?: number | null;
                isPersonIdRequired: boolean;
                isSubscription: boolean;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            }>;
          };
          items: Array<{
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          }>;
        }>;
      }> | null;
    } | null;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type CreateB2BOrderMutationVariables = Exact<{
  input: CreateB2BOrderInput;
}>;

export type CreateB2BOrderMutation = {
  __typename?: 'Mutation';
  createB2BOrder: {
    __typename?: 'CreateB2BOrderPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'B2BOrder';
      _id: string;
      projectId: string;
      customerId?: string | null;
      assigneeId: string;
      resolvedById?: string | null;
      contactPerson: string;
      isCreditCheckReceived: boolean;
      creditCheckCode: number;
      companyName?: string | null;
      email: string;
      phoneNumber: string;
      orgId: string;
      status: B2BOrderStatus;
      deliveryAddress?: string | null;
      coAddress?: string | null;
      invoicingAddress?: string | null;
      invoicingZip?: string | null;
      invoicingFrequency: number;
      total: number;
      monthlyTotal: number;
      discountAmount: number;
      shippingStatus: ShippingStatus;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      isUnsigned: boolean;
      updatedAt: any;
      createdAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      customer?: {
        __typename?: 'B2BCustomer';
        _id: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        orgId: string;
        email?: string | null;
        updatedAt: any;
        createdAt: any;
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
      resolvedBy?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      items: Array<{
        __typename?: 'B2BOrderItem';
        productId: string;
        qty: number;
        duration: number;
        monthlyPricePerOneLicense: number;
        monthlyPricePerAllLicenses: number;
        discountAmount: number;
      }>;
    } | null;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type UpdateOrderMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;

export type UpdateOrderMutation = {
  __typename?: 'Mutation';
  updateOrder: {
    __typename?: 'UpdateOrderPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Order';
      _id: string;
      salesChannelId: string;
      projectId: string;
      customerId?: string | null;
      assigneeId: string;
      resolvedById?: string | null;
      isCreditCheckReceived: boolean;
      isActiveCustomer: boolean;
      financeSystemSyncStatus: FinanceSystemSyncStatus;
      financeSystem: FinanceSystem;
      creditCheckCode: number;
      fullName?: string | null;
      email: string;
      phoneNumber: string;
      personId: string;
      status: OrderStatus;
      coAddress: string;
      invoicingAddress?: string | null;
      invoicingType: InvoicingType;
      invoicingZip?: string | null;
      invoicingFrequency: number;
      customerAddress: string;
      customerZip: string;
      subtotal: number;
      total: number;
      monthlyTotal: number;
      onePaymentTotal: number;
      discountPercent: number;
      discountAmount: number;
      monthlySubtotal: number;
      quarterSubtotal: number;
      fullTotalWithAddons: number;
      regretReason?: string | null;
      regretOption?: RegretOption | null;
      shippingStatus: ShippingStatus;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      isUnsigned: boolean;
      isRegrettable: boolean;
      isRegrettableDate?: any | null;
      isDeleted: boolean;
      isWinBack?: boolean | null;
      isFromShopPage: boolean;
      updatedAt: any;
      createdAt: any;
      contractStatus: OrderContractStatus;
      contractSignedDate?: any | null;
      winBackPrice: number;
      winBackDuration?: number | null;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      customer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
      resolvedBy?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundleId: string;
        postponeInvoicing: number;
        total: number;
        subtotal: number;
        addonsPrice: number;
        productBundle: {
          __typename?: 'ProductBundle';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          products: Array<{
            __typename?: 'ProductBundleToProduct';
            productId: string;
            qty: number;
            product: {
              __typename?: 'Product';
              name: string;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              isCreditCheckRequired: boolean;
              licenseDurationMonths?: number | null;
              isPersonIdRequired: boolean;
              isSubscription: boolean;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          }>;
        };
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeItem';
        licenseId: string;
        total: number;
        license: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          orderItem?: {
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
      }>;
      soSafeCoupon?: {
        __typename?: 'SoSafeCoupon';
        code: string;
        amount: number;
        type: SoSafeCouponType;
      } | null;
      sourceOrders?: Array<{
        __typename?: 'Order';
        _id: string;
        fullTotalWithAddons: number;
        monthlySubtotal: number;
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
        bundles: Array<{
          __typename?: 'OrderBundleItem';
          productBundleId: string;
          postponeInvoicing: number;
          total: number;
          subtotal: number;
          addonsPrice: number;
          productBundle: {
            __typename?: 'ProductBundle';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            products: Array<{
              __typename?: 'ProductBundleToProduct';
              productId: string;
              qty: number;
              product: {
                __typename?: 'Product';
                name: string;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                isCreditCheckRequired: boolean;
                licenseDurationMonths?: number | null;
                isPersonIdRequired: boolean;
                isSubscription: boolean;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            }>;
          };
          items: Array<{
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          }>;
        }>;
      }> | null;
    } | null;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type UpdateB2BOrderMutationVariables = Exact<{
  input: UpdateB2BOrderInput;
}>;

export type UpdateB2BOrderMutation = {
  __typename?: 'Mutation';
  updateB2BOrder: {
    __typename?: 'UpdateB2BOrderPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'B2BOrder';
      _id: string;
      projectId: string;
      customerId?: string | null;
      assigneeId: string;
      resolvedById?: string | null;
      contactPerson: string;
      isCreditCheckReceived: boolean;
      creditCheckCode: number;
      companyName?: string | null;
      email: string;
      phoneNumber: string;
      orgId: string;
      status: B2BOrderStatus;
      deliveryAddress?: string | null;
      coAddress?: string | null;
      invoicingAddress?: string | null;
      invoicingZip?: string | null;
      invoicingFrequency: number;
      total: number;
      monthlyTotal: number;
      discountAmount: number;
      shippingStatus: ShippingStatus;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      isUnsigned: boolean;
      updatedAt: any;
      createdAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      customer?: {
        __typename?: 'B2BCustomer';
        _id: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        orgId: string;
        email?: string | null;
        updatedAt: any;
        createdAt: any;
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
      resolvedBy?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      items: Array<{
        __typename?: 'B2BOrderItem';
        productId: string;
        qty: number;
        duration: number;
        monthlyPricePerOneLicense: number;
        monthlyPricePerAllLicenses: number;
        discountAmount: number;
      }>;
    } | null;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type ApproveOrderMutationVariables = Exact<{
  input: ApproveOrderInput;
}>;

export type ApproveOrderMutation = {
  __typename?: 'Mutation';
  approveOrder: {
    __typename?: 'ApproveOrderPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Order';
      _id: string;
      salesChannelId: string;
      projectId: string;
      customerId?: string | null;
      assigneeId: string;
      resolvedById?: string | null;
      isCreditCheckReceived: boolean;
      isActiveCustomer: boolean;
      financeSystemSyncStatus: FinanceSystemSyncStatus;
      financeSystem: FinanceSystem;
      creditCheckCode: number;
      fullName?: string | null;
      email: string;
      phoneNumber: string;
      personId: string;
      status: OrderStatus;
      coAddress: string;
      invoicingAddress?: string | null;
      invoicingType: InvoicingType;
      invoicingZip?: string | null;
      invoicingFrequency: number;
      customerAddress: string;
      customerZip: string;
      subtotal: number;
      total: number;
      monthlyTotal: number;
      onePaymentTotal: number;
      discountPercent: number;
      discountAmount: number;
      monthlySubtotal: number;
      quarterSubtotal: number;
      fullTotalWithAddons: number;
      regretReason?: string | null;
      regretOption?: RegretOption | null;
      shippingStatus: ShippingStatus;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      isUnsigned: boolean;
      isRegrettable: boolean;
      isRegrettableDate?: any | null;
      isDeleted: boolean;
      isWinBack?: boolean | null;
      isFromShopPage: boolean;
      updatedAt: any;
      createdAt: any;
      contractStatus: OrderContractStatus;
      contractSignedDate?: any | null;
      winBackPrice: number;
      winBackDuration?: number | null;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      customer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
      resolvedBy?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundleId: string;
        postponeInvoicing: number;
        total: number;
        subtotal: number;
        addonsPrice: number;
        productBundle: {
          __typename?: 'ProductBundle';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          products: Array<{
            __typename?: 'ProductBundleToProduct';
            productId: string;
            qty: number;
            product: {
              __typename?: 'Product';
              name: string;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              isCreditCheckRequired: boolean;
              licenseDurationMonths?: number | null;
              isPersonIdRequired: boolean;
              isSubscription: boolean;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          }>;
        };
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeItem';
        licenseId: string;
        total: number;
        license: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          orderItem?: {
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
      }>;
      soSafeCoupon?: {
        __typename?: 'SoSafeCoupon';
        code: string;
        amount: number;
        type: SoSafeCouponType;
      } | null;
      sourceOrders?: Array<{
        __typename?: 'Order';
        _id: string;
        fullTotalWithAddons: number;
        monthlySubtotal: number;
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
        bundles: Array<{
          __typename?: 'OrderBundleItem';
          productBundleId: string;
          postponeInvoicing: number;
          total: number;
          subtotal: number;
          addonsPrice: number;
          productBundle: {
            __typename?: 'ProductBundle';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            products: Array<{
              __typename?: 'ProductBundleToProduct';
              productId: string;
              qty: number;
              product: {
                __typename?: 'Product';
                name: string;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                isCreditCheckRequired: boolean;
                licenseDurationMonths?: number | null;
                isPersonIdRequired: boolean;
                isSubscription: boolean;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            }>;
          };
          items: Array<{
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          }>;
        }>;
      }> | null;
    } | null;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type ApproveB2BOrderMutationVariables = Exact<{
  input: ApproveOrderInput;
}>;

export type ApproveB2BOrderMutation = {
  __typename?: 'Mutation';
  approveB2BOrder: {
    __typename?: 'ApproveOrderPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Order';
      _id: string;
      salesChannelId: string;
      projectId: string;
      customerId?: string | null;
      assigneeId: string;
      resolvedById?: string | null;
      isCreditCheckReceived: boolean;
      isActiveCustomer: boolean;
      financeSystemSyncStatus: FinanceSystemSyncStatus;
      financeSystem: FinanceSystem;
      creditCheckCode: number;
      fullName?: string | null;
      email: string;
      phoneNumber: string;
      personId: string;
      status: OrderStatus;
      coAddress: string;
      invoicingAddress?: string | null;
      invoicingType: InvoicingType;
      invoicingZip?: string | null;
      invoicingFrequency: number;
      customerAddress: string;
      customerZip: string;
      subtotal: number;
      total: number;
      monthlyTotal: number;
      onePaymentTotal: number;
      discountPercent: number;
      discountAmount: number;
      monthlySubtotal: number;
      quarterSubtotal: number;
      fullTotalWithAddons: number;
      regretReason?: string | null;
      regretOption?: RegretOption | null;
      shippingStatus: ShippingStatus;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      isUnsigned: boolean;
      isRegrettable: boolean;
      isRegrettableDate?: any | null;
      isDeleted: boolean;
      isWinBack?: boolean | null;
      isFromShopPage: boolean;
      updatedAt: any;
      createdAt: any;
      contractStatus: OrderContractStatus;
      contractSignedDate?: any | null;
      winBackPrice: number;
      winBackDuration?: number | null;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      customer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
      resolvedBy?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundleId: string;
        postponeInvoicing: number;
        total: number;
        subtotal: number;
        addonsPrice: number;
        productBundle: {
          __typename?: 'ProductBundle';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          products: Array<{
            __typename?: 'ProductBundleToProduct';
            productId: string;
            qty: number;
            product: {
              __typename?: 'Product';
              name: string;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              isCreditCheckRequired: boolean;
              licenseDurationMonths?: number | null;
              isPersonIdRequired: boolean;
              isSubscription: boolean;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          }>;
        };
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeItem';
        licenseId: string;
        total: number;
        license: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          orderItem?: {
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
      }>;
      soSafeCoupon?: {
        __typename?: 'SoSafeCoupon';
        code: string;
        amount: number;
        type: SoSafeCouponType;
      } | null;
      sourceOrders?: Array<{
        __typename?: 'Order';
        _id: string;
        fullTotalWithAddons: number;
        monthlySubtotal: number;
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
        bundles: Array<{
          __typename?: 'OrderBundleItem';
          productBundleId: string;
          postponeInvoicing: number;
          total: number;
          subtotal: number;
          addonsPrice: number;
          productBundle: {
            __typename?: 'ProductBundle';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            products: Array<{
              __typename?: 'ProductBundleToProduct';
              productId: string;
              qty: number;
              product: {
                __typename?: 'Product';
                name: string;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                isCreditCheckRequired: boolean;
                licenseDurationMonths?: number | null;
                isPersonIdRequired: boolean;
                isSubscription: boolean;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            }>;
          };
          items: Array<{
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          }>;
        }>;
      }> | null;
    } | null;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type RegretOrderMutationVariables = Exact<{
  input: RegretOrderInput;
}>;

export type RegretOrderMutation = {
  __typename?: 'Mutation';
  regretOrder: {
    __typename?: 'RegretOrderPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Order';
      _id: string;
      salesChannelId: string;
      projectId: string;
      customerId?: string | null;
      assigneeId: string;
      resolvedById?: string | null;
      isCreditCheckReceived: boolean;
      isActiveCustomer: boolean;
      financeSystemSyncStatus: FinanceSystemSyncStatus;
      financeSystem: FinanceSystem;
      creditCheckCode: number;
      fullName?: string | null;
      email: string;
      phoneNumber: string;
      personId: string;
      status: OrderStatus;
      coAddress: string;
      invoicingAddress?: string | null;
      invoicingType: InvoicingType;
      invoicingZip?: string | null;
      invoicingFrequency: number;
      customerAddress: string;
      customerZip: string;
      subtotal: number;
      total: number;
      monthlyTotal: number;
      onePaymentTotal: number;
      discountPercent: number;
      discountAmount: number;
      monthlySubtotal: number;
      quarterSubtotal: number;
      fullTotalWithAddons: number;
      regretReason?: string | null;
      regretOption?: RegretOption | null;
      shippingStatus: ShippingStatus;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      isUnsigned: boolean;
      isRegrettable: boolean;
      isRegrettableDate?: any | null;
      isDeleted: boolean;
      isWinBack?: boolean | null;
      isFromShopPage: boolean;
      updatedAt: any;
      createdAt: any;
      contractStatus: OrderContractStatus;
      contractSignedDate?: any | null;
      winBackPrice: number;
      winBackDuration?: number | null;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      customer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
      resolvedBy?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundleId: string;
        postponeInvoicing: number;
        total: number;
        subtotal: number;
        addonsPrice: number;
        productBundle: {
          __typename?: 'ProductBundle';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          products: Array<{
            __typename?: 'ProductBundleToProduct';
            productId: string;
            qty: number;
            product: {
              __typename?: 'Product';
              name: string;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              isCreditCheckRequired: boolean;
              licenseDurationMonths?: number | null;
              isPersonIdRequired: boolean;
              isSubscription: boolean;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          }>;
        };
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeItem';
        licenseId: string;
        total: number;
        license: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          orderItem?: {
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
      }>;
      soSafeCoupon?: {
        __typename?: 'SoSafeCoupon';
        code: string;
        amount: number;
        type: SoSafeCouponType;
      } | null;
      sourceOrders?: Array<{
        __typename?: 'Order';
        _id: string;
        fullTotalWithAddons: number;
        monthlySubtotal: number;
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
        bundles: Array<{
          __typename?: 'OrderBundleItem';
          productBundleId: string;
          postponeInvoicing: number;
          total: number;
          subtotal: number;
          addonsPrice: number;
          productBundle: {
            __typename?: 'ProductBundle';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            products: Array<{
              __typename?: 'ProductBundleToProduct';
              productId: string;
              qty: number;
              product: {
                __typename?: 'Product';
                name: string;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                isCreditCheckRequired: boolean;
                licenseDurationMonths?: number | null;
                isPersonIdRequired: boolean;
                isSubscription: boolean;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            }>;
          };
          items: Array<{
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          }>;
        }>;
      }> | null;
    } | null;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type DeleteOrderMutationVariables = Exact<{
  input: DeleteOrderInput;
}>;

export type DeleteOrderMutation = {
  __typename?: 'Mutation';
  deleteOrder: {
    __typename?: 'DeleteOrderPayload';
    status: MutationStatus;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type DeleteB2BOrderMutationVariables = Exact<{
  input: DeleteB2BOrderInput;
}>;

export type DeleteB2BOrderMutation = {
  __typename?: 'Mutation';
  deleteB2BOrder: {
    __typename?: 'DeleteB2BOrderPayload';
    status: MutationStatus;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type DeleteB2BUnsignedOrderMutationVariables = Exact<{
  input: DeleteB2BUnsignedOrderInput;
}>;

export type DeleteB2BUnsignedOrderMutation = {
  __typename?: 'Mutation';
  deleteB2BUnsignedOrder: {
    __typename?: 'DeleteB2BUnsignedOrderPayload';
    status: MutationStatus;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type DeleteUnsignedOrderMutationVariables = Exact<{
  input: DeleteUnsignedOrderInput;
}>;

export type DeleteUnsignedOrderMutation = {
  __typename?: 'Mutation';
  deleteUnsignedOrder: {
    __typename?: 'DeleteUnsignedOrderPayload';
    status: MutationStatus;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type UpdateShipmentOrderStatusMutationVariables = Exact<{
  input: OrderShipmentStatusUpdateInput;
}>;

export type UpdateShipmentOrderStatusMutation = {
  __typename?: 'Mutation';
  orderShipmentStatusUpdate: {
    __typename?: 'OrderShipmentStatusUpdatePayload';
    status: MutationStatus;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type UpdateB2BShipmentOrderStatusMutationVariables = Exact<{
  input: B2BOrderShipmentStatusUpdateInput;
}>;

export type UpdateB2BShipmentOrderStatusMutation = {
  __typename?: 'Mutation';
  b2bOrderShipmentStatusUpdate: {
    __typename?: 'B2BOrderShipmentStatusUpdatePayload';
    status: MutationStatus;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type SendOrderContractMutationVariables = Exact<{
  input: SendOrderContractInput;
}>;

export type SendOrderContractMutation = {
  __typename?: 'Mutation';
  sendOrderContract: {
    __typename?: 'SendOrderContractPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Order';
      _id: string;
      salesChannelId: string;
      projectId: string;
      customerId?: string | null;
      assigneeId: string;
      resolvedById?: string | null;
      isCreditCheckReceived: boolean;
      isActiveCustomer: boolean;
      financeSystemSyncStatus: FinanceSystemSyncStatus;
      financeSystem: FinanceSystem;
      creditCheckCode: number;
      fullName?: string | null;
      email: string;
      phoneNumber: string;
      personId: string;
      status: OrderStatus;
      coAddress: string;
      invoicingAddress?: string | null;
      invoicingType: InvoicingType;
      invoicingZip?: string | null;
      invoicingFrequency: number;
      customerAddress: string;
      customerZip: string;
      subtotal: number;
      total: number;
      monthlyTotal: number;
      onePaymentTotal: number;
      discountPercent: number;
      discountAmount: number;
      monthlySubtotal: number;
      quarterSubtotal: number;
      fullTotalWithAddons: number;
      regretReason?: string | null;
      regretOption?: RegretOption | null;
      shippingStatus: ShippingStatus;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      isUnsigned: boolean;
      isRegrettable: boolean;
      isRegrettableDate?: any | null;
      isDeleted: boolean;
      isWinBack?: boolean | null;
      isFromShopPage: boolean;
      updatedAt: any;
      createdAt: any;
      contractStatus: OrderContractStatus;
      contractSignedDate?: any | null;
      winBackPrice: number;
      winBackDuration?: number | null;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      customer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
      resolvedBy?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundleId: string;
        postponeInvoicing: number;
        total: number;
        subtotal: number;
        addonsPrice: number;
        productBundle: {
          __typename?: 'ProductBundle';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          products: Array<{
            __typename?: 'ProductBundleToProduct';
            productId: string;
            qty: number;
            product: {
              __typename?: 'Product';
              name: string;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              isCreditCheckRequired: boolean;
              licenseDurationMonths?: number | null;
              isPersonIdRequired: boolean;
              isSubscription: boolean;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          }>;
        };
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeItem';
        licenseId: string;
        total: number;
        license: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          orderItem?: {
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
      }>;
      soSafeCoupon?: {
        __typename?: 'SoSafeCoupon';
        code: string;
        amount: number;
        type: SoSafeCouponType;
      } | null;
      sourceOrders?: Array<{
        __typename?: 'Order';
        _id: string;
        fullTotalWithAddons: number;
        monthlySubtotal: number;
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
        bundles: Array<{
          __typename?: 'OrderBundleItem';
          productBundleId: string;
          postponeInvoicing: number;
          total: number;
          subtotal: number;
          addonsPrice: number;
          productBundle: {
            __typename?: 'ProductBundle';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            products: Array<{
              __typename?: 'ProductBundleToProduct';
              productId: string;
              qty: number;
              product: {
                __typename?: 'Product';
                name: string;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                isCreditCheckRequired: boolean;
                licenseDurationMonths?: number | null;
                isPersonIdRequired: boolean;
                isSubscription: boolean;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            }>;
          };
          items: Array<{
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          }>;
        }>;
      }> | null;
    } | null;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type GetOrdersForOrdersListQueryVariables = Exact<{
  filter?: InputMaybe<GetOrdersFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<OrdersOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetOrdersForOrdersListQuery = {
  __typename?: 'Query';
  getOrdersForOrdersList: {
    __typename?: 'GetOrdersPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Order';
      _id: string;
      salesChannelId: string;
      projectId: string;
      customerId?: string | null;
      isRegrettableDate?: any | null;
      isRegrettable: boolean;
      assigneeId: string;
      isCreditCheckReceived: boolean;
      financeSystemSyncStatus: FinanceSystemSyncStatus;
      financeSystem: FinanceSystem;
      creditCheckCode: number;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      contractStatus: OrderContractStatus;
      contractSignedDate?: any | null;
      fullName?: string | null;
      email: string;
      phoneNumber: string;
      personId: string;
      status: OrderStatus;
      subtotal: number;
      total: number;
      discountPercent: number;
      discountAmount: number;
      fullTotalWithAddons: number;
      fullSubtotalWithAddons: number;
      createdAt: any;
      isDeleted: boolean;
      isWinBack?: boolean | null;
      winBackFullTotal: number;
      salesChannel: {
        __typename?: 'SalesChannel';
        name: string;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
      };
      customer?: {
        __typename?: 'Customer';
        _id: string;
        personId: string;
        fullName?: string | null;
      } | null;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundle: { __typename?: 'ProductBundle'; name: string };
        items: Array<{
          __typename?: 'OrderItem';
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: { __typename?: 'ProductAddon'; name: string };
          }>;
        }>;
      }>;
      items: Array<{
        __typename?: 'OrderItem';
        qty: number;
        product: { __typename?: 'Product'; name: string };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: { __typename?: 'ProductAddon'; name: string };
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeItem';
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: { __typename?: 'ProductAddon'; name: string };
        }>;
      }>;
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    }>;
  };
};

export type GetB2BOrdersForOrdersListQueryVariables = Exact<{
  filter?: InputMaybe<GetB2BOrdersFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<B2BOrdersOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetB2BOrdersForOrdersListQuery = {
  __typename?: 'Query';
  getB2BOrdersForOrdersList: {
    __typename?: 'GetB2BOrdersPayload';
    count: number;
    nodes: Array<{
      __typename?: 'B2BOrder';
      _id: string;
      orgId: string;
      companyName?: string | null;
      creditCheckCode: number;
      total: number;
      subtotal: number;
      status: B2BOrderStatus;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      items: Array<{
        __typename?: 'B2BOrderItem';
        productId: string;
        monthlyPricePerAllLicenses: number;
        duration: number;
        discountAmount: number;
      }>;
    }>;
  };
};

export type GetShippingForListQueryVariables = Exact<{
  filter?: InputMaybe<GetOrdersFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<OrdersOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetShippingForListQuery = {
  __typename?: 'Query';
  getShippingForList: {
    __typename?: 'GetOrdersPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Order';
      _id: string;
      fullName?: string | null;
      customerAddress: string;
      coAddress: string;
      phoneNumber: string;
      personId: string;
      shippingStatus: ShippingStatus;
    }>;
  };
};

export type GetB2BShippingForListQueryVariables = Exact<{
  filter?: InputMaybe<GetB2BOrdersFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<B2BOrdersOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetB2BShippingForListQuery = {
  __typename?: 'Query';
  getB2BShippingForList: {
    __typename?: 'GetB2BOrdersPayload';
    count: number;
    nodes: Array<{
      __typename?: 'B2BOrder';
      _id: string;
      companyName?: string | null;
      deliveryAddress?: string | null;
      coAddress?: string | null;
      phoneNumber: string;
      orgId: string;
      shippingStatus: ShippingStatus;
    }>;
  };
};

export type GetOrderQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetOrderQuery = {
  __typename?: 'Query';
  getOrder?: {
    __typename?: 'Order';
    _id: string;
    salesChannelId: string;
    projectId: string;
    customerId?: string | null;
    assigneeId: string;
    resolvedById?: string | null;
    isCreditCheckReceived: boolean;
    isActiveCustomer: boolean;
    financeSystemSyncStatus: FinanceSystemSyncStatus;
    financeSystem: FinanceSystem;
    creditCheckCode: number;
    fullName?: string | null;
    email: string;
    phoneNumber: string;
    personId: string;
    status: OrderStatus;
    coAddress: string;
    invoicingAddress?: string | null;
    invoicingType: InvoicingType;
    invoicingZip?: string | null;
    invoicingFrequency: number;
    customerAddress: string;
    customerZip: string;
    subtotal: number;
    total: number;
    monthlyTotal: number;
    onePaymentTotal: number;
    discountPercent: number;
    discountAmount: number;
    monthlySubtotal: number;
    quarterSubtotal: number;
    fullTotalWithAddons: number;
    regretReason?: string | null;
    regretOption?: RegretOption | null;
    shippingStatus: ShippingStatus;
    scriveContractStatus: ScriveContractStatus;
    scriveContractId?: string | null;
    isUnsigned: boolean;
    isRegrettable: boolean;
    isRegrettableDate?: any | null;
    isDeleted: boolean;
    isWinBack?: boolean | null;
    isFromShopPage: boolean;
    updatedAt: any;
    createdAt: any;
    contractStatus: OrderContractStatus;
    contractSignedDate?: any | null;
    winBackPrice: number;
    winBackDuration?: number | null;
    salesChannel: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    };
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    customer?: {
      __typename?: 'Customer';
      _id: string;
      salesChannelId: string;
      lastAssigneeId: string;
      creditCheckCode: number;
      fullName?: string | null;
      phoneNumber?: string | null;
      personId: string;
      email?: string | null;
      address?: string | null;
      zipCode?: string | null;
      coAddress?: string | null;
      updatedAt: any;
      createdAt: any;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      lastAssignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    assignee: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
    resolvedBy?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    items: Array<{
      __typename?: 'OrderItem';
      relationId: string;
      productId: string;
      discountId?: string | null;
      discountAmount: number;
      discountPercent: number;
      qty: number;
      postponeInvoicing: number;
      personId?: string | null;
      fullName?: string | null;
      subtotal: number;
      total: number;
      addonsPrice: number;
      phoneNumber: string;
      relation: {
        __typename?: 'Relation';
        _id: string;
        projectId: string;
        status: RelationStatus;
        name: string;
        description?: string | null;
        isOwn: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      product: {
        __typename?: 'Product';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        description?: string | null;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        freeMonths: number;
        licenseDurationMonths?: number | null;
        isSubscription: boolean;
        canBeTerminated: boolean;
        unitId: string;
        isCreditCheckRequired: boolean;
        isPersonIdRequired: boolean;
        isStockable: boolean;
        isInStok: boolean;
        hasCommissions: boolean;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        gallery: Array<{ __typename?: 'FileNode'; url: string }>;
        productAddons: Array<{
          __typename?: 'ProductAddon';
          _id: string;
          name: string;
          vatPrice: number;
          status: ProductAddonStatus;
          isPersonIdRequired: boolean;
          isCreditCheckRequired: boolean;
          isSubscription: boolean;
        }>;
      };
      productAddons: Array<{
        __typename?: 'OrderItemProductAddon';
        productAddon: {
          __typename?: 'ProductAddon';
          _id: string;
          projectId: string;
          productId: string;
          name: string;
          status: ProductAddonStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          createdAt: any;
          updatedAt: any;
          isPersonIdRequired: boolean;
          canBeTerminated: boolean;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        };
        license?: { __typename?: 'License'; status: LicenseStatus } | null;
      }>;
      discount?: {
        __typename?: 'ProductDiscount';
        _id: string;
        productId: string;
        teamId?: string | null;
        roleId?: string | null;
        teamMemberId?: string | null;
        salesChannelId?: string | null;
        authorId: string;
        qty: number;
        discount: number;
        status: ProductDiscountStatus;
        productPrice: number;
        productVatPrice: number;
        createdAt: any;
        updatedAt: any;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        team?: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        } | null;
        role?: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        } | null;
        teamMember?: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        salesChannel?: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        } | null;
        author: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      license?: {
        __typename?: 'License';
        _id: string;
        status: LicenseStatus;
        licenseEndDate: any;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        externalUser: {
          __typename?: 'ExternalUser';
          _id: string;
          lastCustomerId?: string | null;
          lastAssigneeId?: string | null;
          relationId?: string | null;
          productId: string;
          fullName?: string | null;
          phoneNumber: string;
          personId?: string | null;
          address?: string | null;
          licenseStartDate: any;
          licenseEndDate: any;
          createdAt: any;
          lastCustomer?: {
            __typename?: 'Customer';
            _id: string;
            salesChannelId: string;
            lastAssigneeId: string;
            creditCheckCode: number;
            fullName?: string | null;
            phoneNumber?: string | null;
            personId: string;
            email?: string | null;
            address?: string | null;
            zipCode?: string | null;
            coAddress?: string | null;
            updatedAt: any;
            createdAt: any;
            salesChannel: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            lastAssignee: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          lastAssignee?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          relation?: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          licenses: Array<{
            __typename?: 'License';
            productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
          }>;
        };
      } | null;
    }>;
    bundles: Array<{
      __typename?: 'OrderBundleItem';
      productBundleId: string;
      postponeInvoicing: number;
      total: number;
      subtotal: number;
      addonsPrice: number;
      productBundle: {
        __typename?: 'ProductBundle';
        _id: string;
        projectId: string;
        name: string;
        nameInContract: string;
        status: ProductStatus;
        price: number;
        vatRate: number;
        vatPrice: number;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
        products: Array<{
          __typename?: 'ProductBundleToProduct';
          productId: string;
          qty: number;
          product: {
            __typename?: 'Product';
            name: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            isCreditCheckRequired: boolean;
            licenseDurationMonths?: number | null;
            isPersonIdRequired: boolean;
            isSubscription: boolean;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        }>;
      };
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
    }>;
    upgrades: Array<{
      __typename?: 'OrderUpgradeItem';
      licenseId: string;
      total: number;
      license: {
        __typename?: 'License';
        _id: string;
        status: LicenseStatus;
        licenseEndDate: any;
        orderItem?: {
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        } | null;
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        externalUser: {
          __typename?: 'ExternalUser';
          _id: string;
          lastCustomerId?: string | null;
          lastAssigneeId?: string | null;
          relationId?: string | null;
          productId: string;
          fullName?: string | null;
          phoneNumber: string;
          personId?: string | null;
          address?: string | null;
          licenseStartDate: any;
          licenseEndDate: any;
          createdAt: any;
          lastCustomer?: {
            __typename?: 'Customer';
            _id: string;
            salesChannelId: string;
            lastAssigneeId: string;
            creditCheckCode: number;
            fullName?: string | null;
            phoneNumber?: string | null;
            personId: string;
            email?: string | null;
            address?: string | null;
            zipCode?: string | null;
            coAddress?: string | null;
            updatedAt: any;
            createdAt: any;
            salesChannel: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            lastAssignee: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          lastAssignee?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          relation?: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          licenses: Array<{
            __typename?: 'License';
            productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
          }>;
        };
      };
      productAddons: Array<{
        __typename?: 'OrderItemProductAddon';
        productAddon: {
          __typename?: 'ProductAddon';
          _id: string;
          projectId: string;
          productId: string;
          name: string;
          status: ProductAddonStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          createdAt: any;
          updatedAt: any;
          isPersonIdRequired: boolean;
          canBeTerminated: boolean;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
        };
        license?: { __typename?: 'License'; status: LicenseStatus } | null;
      }>;
    }>;
    soSafeCoupon?: {
      __typename?: 'SoSafeCoupon';
      code: string;
      amount: number;
      type: SoSafeCouponType;
    } | null;
    sourceOrders?: Array<{
      __typename?: 'Order';
      _id: string;
      fullTotalWithAddons: number;
      monthlySubtotal: number;
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundleId: string;
        postponeInvoicing: number;
        total: number;
        subtotal: number;
        addonsPrice: number;
        productBundle: {
          __typename?: 'ProductBundle';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          products: Array<{
            __typename?: 'ProductBundleToProduct';
            productId: string;
            qty: number;
            product: {
              __typename?: 'Product';
              name: string;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              isCreditCheckRequired: boolean;
              licenseDurationMonths?: number | null;
              isPersonIdRequired: boolean;
              isSubscription: boolean;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          }>;
        };
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
      }>;
    }> | null;
  } | null;
};

export type GetB2BOrderQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetB2BOrderQuery = {
  __typename?: 'Query';
  getB2BOrder?: {
    __typename?: 'B2BOrder';
    _id: string;
    projectId: string;
    customerId?: string | null;
    assigneeId: string;
    resolvedById?: string | null;
    contactPerson: string;
    isCreditCheckReceived: boolean;
    creditCheckCode: number;
    companyName?: string | null;
    email: string;
    phoneNumber: string;
    orgId: string;
    status: B2BOrderStatus;
    deliveryAddress?: string | null;
    coAddress?: string | null;
    invoicingAddress?: string | null;
    invoicingZip?: string | null;
    invoicingFrequency: number;
    total: number;
    monthlyTotal: number;
    discountAmount: number;
    shippingStatus: ShippingStatus;
    scriveContractStatus: ScriveContractStatus;
    scriveContractId?: string | null;
    isUnsigned: boolean;
    updatedAt: any;
    createdAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    customer?: {
      __typename?: 'B2BCustomer';
      _id: string;
      lastAssigneeId: string;
      creditCheckCode: number;
      fullName?: string | null;
      phoneNumber?: string | null;
      orgId: string;
      email?: string | null;
      updatedAt: any;
      createdAt: any;
      lastAssignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    assignee: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
    resolvedBy?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    items: Array<{
      __typename?: 'B2BOrderItem';
      productId: string;
      qty: number;
      duration: number;
      monthlyPricePerOneLicense: number;
      monthlyPricePerAllLicenses: number;
      discountAmount: number;
    }>;
  } | null;
};

export type GetPersonDataQueryVariables = Exact<{
  personId: Scalars['String'];
}>;

export type GetPersonDataQuery = {
  __typename?: 'Query';
  getPersonData?: {
    __typename?: 'GetPersonDataPayload';
    node?: {
      __typename?: 'PersonData';
      firstName?: string | null;
      lastName?: string | null;
      address?: string | null;
      zipCode?: string | null;
      coAddress?: string | null;
      allFields: Array<{ __typename?: 'AdditionalField'; name: string; value: string }>;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string } | null>;
  } | null;
};

export type GetCompanyDataQueryVariables = Exact<{
  orgId: Scalars['String'];
}>;

export type GetCompanyDataQuery = {
  __typename?: 'Query';
  getCompanyData?: {
    __typename?: 'GetCompanyDataPayload';
    node?: {
      __typename?: 'CompanyData';
      fullName?: string | null;
      orgId?: string | null;
      allFields: Array<{ __typename?: 'AdditionalField'; name: string; value: string }>;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string } | null>;
  } | null;
};

export type GetPersonCreditCheckQueryVariables = Exact<{
  personId: Scalars['String'];
}>;

export type GetPersonCreditCheckQuery = {
  __typename?: 'Query';
  getPersonCreditCheck?: {
    __typename?: 'GetPersonCreditCheckPayload';
    rejectionReasons: Array<string>;
    check: {
      __typename?: 'GetPersonCreditCheck';
      casTemplate?: string | null;
      approvalCode?: number | null;
      approvalText?: string | null;
    };
  } | null;
};

export type GetCompanyCreditCheckQueryVariables = Exact<{
  orgId: Scalars['String'];
}>;

export type GetCompanyCreditCheckQuery = {
  __typename?: 'Query';
  getCompanyCreditCheck?: {
    __typename?: 'GetCompanyCreditCheckPayload';
    rejectionReasons: Array<string>;
    check: {
      __typename?: 'GetCompanyCreditCheck';
      casTemplate?: string | null;
      approvalCode?: number | null;
      approvalText?: string | null;
    };
  } | null;
};

export type GetInvoicesQueryVariables = Exact<{
  filter: GetInvoicesFilterInput;
}>;

export type GetInvoicesQuery = {
  __typename?: 'Query';
  getInvoices: {
    __typename?: 'GetInvoicesPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Invoice';
      _id: string;
      date: any;
      status: InvoiceStatus;
      total: number;
    }>;
  };
};

export type GetOrderCalculationsQueryVariables = Exact<{
  input: GetOrderCalculationsInput;
}>;

export type GetOrderCalculationsQuery = {
  __typename?: 'Query';
  getOrderCalculations: {
    __typename?: 'GetOrderCalulationsPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'OrderCalculation';
      subtotal: number;
      total: number;
      onePaymentTotal: number;
      monthlyTotal: number;
      fullTotal: number;
      discountPercent: number;
      discountAmount: number;
      monthlySubtotal: number;
      quarterSubtotal: number;
      fullTotalWithAddons: number;
      items: Array<{
        __typename?: 'OrderItemCalculation';
        itemKey: string;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        subtotal: number;
        total: number;
        addonsPrice: number;
      }>;
      bundles: Array<{
        __typename?: 'OrderBundleCalculation';
        productBundleId: string;
        itemKey: string;
        subtotal: number;
        addonsPrice: number;
        total: number;
        items: Array<{
          __typename?: 'OrderItemCalculation';
          itemKey: string;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          subtotal: number;
          total: number;
          addonsPrice: number;
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeCalculationItem';
        itemKey: string;
        subtotal: number;
        total: number;
      }>;
      soSafeCoupon?: {
        __typename?: 'SoSafeCoupon';
        code: string;
        amount: number;
        type: SoSafeCouponType;
      } | null;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type PartnerStoreFragment = {
  __typename?: 'PartnerStore';
  _id: string;
  projectId: string;
  name: string;
  address?: string | null;
  phoneNumber?: string | null;
  contactPerson?: string | null;
  email?: string | null;
  linkToSite?: string | null;
  totalLicenseCount: number;
  activatedLicenseCount: number;
  description?: string | null;
  createdAt: any;
  updatedAt: any;
  project: {
    __typename?: 'Project';
    _id: string;
    name: string;
    description?: string | null;
    productsCount: number;
    createdAt: any;
    updatedAt: any;
  };
};

export type PartnerStoresFilterFragment = {
  __typename?: 'PartnerStore';
  _id: string;
  name: string;
};

export type CreatePartnerStoreMutationVariables = Exact<{
  input: CreatePartnerStoreInput;
}>;

export type CreatePartnerStoreMutation = {
  __typename?: 'Mutation';
  createPartnerStore: {
    __typename?: 'CreatePartnerStorePayload';
    status: MutationStatus;
    node?: {
      __typename?: 'PartnerStore';
      _id: string;
      projectId: string;
      name: string;
      address?: string | null;
      phoneNumber?: string | null;
      contactPerson?: string | null;
      email?: string | null;
      linkToSite?: string | null;
      totalLicenseCount: number;
      activatedLicenseCount: number;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdatePartnerStoreMutationVariables = Exact<{
  input: UpdatePartnerStoreInput;
}>;

export type UpdatePartnerStoreMutation = {
  __typename?: 'Mutation';
  updatePartnerStore: {
    __typename?: 'UpdatePartnerStorePayload';
    status: MutationStatus;
    node?: {
      __typename?: 'PartnerStore';
      _id: string;
      projectId: string;
      name: string;
      address?: string | null;
      phoneNumber?: string | null;
      contactPerson?: string | null;
      email?: string | null;
      linkToSite?: string | null;
      totalLicenseCount: number;
      activatedLicenseCount: number;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeletePartnerStoreMutationVariables = Exact<{
  input: DeletePartnerStoreInput;
}>;

export type DeletePartnerStoreMutation = {
  __typename?: 'Mutation';
  deletePartnerStore: {
    __typename?: 'DeletePartnerStorePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetPartnerStoresQueryVariables = Exact<{
  filter?: InputMaybe<PartnerStoresFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<PartnerStoresOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetPartnerStoresQuery = {
  __typename?: 'Query';
  getPartnerStores: {
    __typename?: 'GetPartnerStoresPayload';
    count: number;
    nodes: Array<{
      __typename?: 'PartnerStore';
      _id: string;
      projectId: string;
      name: string;
      address?: string | null;
      phoneNumber?: string | null;
      contactPerson?: string | null;
      email?: string | null;
      linkToSite?: string | null;
      totalLicenseCount: number;
      activatedLicenseCount: number;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    }>;
  };
};

export type GetPartnerStoreQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetPartnerStoreQuery = {
  __typename?: 'Query';
  getPartnerStore?: {
    __typename?: 'PartnerStore';
    _id: string;
    projectId: string;
    name: string;
    address?: string | null;
    phoneNumber?: string | null;
    contactPerson?: string | null;
    email?: string | null;
    linkToSite?: string | null;
    totalLicenseCount: number;
    activatedLicenseCount: number;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  } | null;
};

export type GetPartnerStoresFilterQueryVariables = Exact<{
  filter?: InputMaybe<PartnerStoresFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<PartnerStoresOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetPartnerStoresFilterQuery = {
  __typename?: 'Query';
  getPartnerStores: {
    __typename?: 'GetPartnerStoresPayload';
    count: number;
    nodes: Array<{ __typename?: 'PartnerStore'; _id: string; name: string }>;
  };
};

export type GetPermisionsListQueryVariables = Exact<{ [key: string]: never }>;

export type GetPermisionsListQuery = {
  __typename?: 'Query';
  getPermisionsList: {
    __typename?: 'PermissionsListPayload';
    nodes: Array<{ __typename?: 'PermissionDescription'; _id: string; resource: RbacResourceName }>;
  };
};

export type ProductFragment = {
  __typename?: 'Product';
  _id: string;
  projectId: string;
  name: string;
  nameInContract: string;
  description?: string | null;
  status: ProductStatus;
  price: number;
  vatRate: number;
  vatPrice: number;
  freeMonths: number;
  licenseDurationMonths?: number | null;
  isSubscription: boolean;
  canBeTerminated: boolean;
  unitId: string;
  isCreditCheckRequired: boolean;
  isPersonIdRequired: boolean;
  isStockable: boolean;
  isInStok: boolean;
  hasCommissions: boolean;
  createdAt: any;
  updatedAt: any;
  project: {
    __typename?: 'Project';
    _id: string;
    name: string;
    description?: string | null;
    productsCount: number;
    createdAt: any;
    updatedAt: any;
  };
  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
  productAddons: Array<{
    __typename?: 'ProductAddon';
    _id: string;
    name: string;
    vatPrice: number;
    status: ProductAddonStatus;
    isPersonIdRequired: boolean;
    isCreditCheckRequired: boolean;
    isSubscription: boolean;
  }>;
};

export type ProductsFilterFragment = { __typename?: 'Product'; _id: string; name: string };

export type CreateProductMutationVariables = Exact<{
  input: CreateProductInput;
}>;

export type CreateProductMutation = {
  __typename?: 'Mutation';
  createProduct: {
    __typename?: 'CreateProductPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput;
}>;

export type UpdateProductMutation = {
  __typename?: 'Mutation';
  updateProduct: {
    __typename?: 'UpdateProductPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteProductMutationVariables = Exact<{
  input: DeleteProductInput;
}>;

export type DeleteProductMutation = {
  __typename?: 'Mutation';
  deleteProduct: {
    __typename?: 'DeleteProductPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetProductsQueryVariables = Exact<{
  filter?: InputMaybe<ProductsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<ProductsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetProductsQuery = {
  __typename?: 'Query';
  getProducts: {
    __typename?: 'GetProductsPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Product';
      _id: string;
      projectId: string;
      name: string;
      nameInContract: string;
      description?: string | null;
      status: ProductStatus;
      price: number;
      vatRate: number;
      vatPrice: number;
      freeMonths: number;
      licenseDurationMonths?: number | null;
      isSubscription: boolean;
      canBeTerminated: boolean;
      unitId: string;
      isCreditCheckRequired: boolean;
      isPersonIdRequired: boolean;
      isStockable: boolean;
      isInStok: boolean;
      hasCommissions: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      gallery: Array<{ __typename?: 'FileNode'; url: string }>;
      productAddons: Array<{
        __typename?: 'ProductAddon';
        _id: string;
        name: string;
        vatPrice: number;
        status: ProductAddonStatus;
        isPersonIdRequired: boolean;
        isCreditCheckRequired: boolean;
        isSubscription: boolean;
      }>;
    }>;
  };
};

export type GetProductQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetProductQuery = {
  __typename?: 'Query';
  getProduct?: {
    __typename?: 'Product';
    _id: string;
    projectId: string;
    name: string;
    nameInContract: string;
    description?: string | null;
    status: ProductStatus;
    price: number;
    vatRate: number;
    vatPrice: number;
    freeMonths: number;
    licenseDurationMonths?: number | null;
    isSubscription: boolean;
    canBeTerminated: boolean;
    unitId: string;
    isCreditCheckRequired: boolean;
    isPersonIdRequired: boolean;
    isStockable: boolean;
    isInStok: boolean;
    hasCommissions: boolean;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    gallery: Array<{ __typename?: 'FileNode'; url: string }>;
    productAddons: Array<{
      __typename?: 'ProductAddon';
      _id: string;
      name: string;
      vatPrice: number;
      status: ProductAddonStatus;
      isPersonIdRequired: boolean;
      isCreditCheckRequired: boolean;
      isSubscription: boolean;
    }>;
  } | null;
};

export type GetProductUnitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductUnitsQuery = {
  __typename?: 'Query';
  getProductUnits: {
    __typename?: 'GetProductUnitsPayload';
    count: number;
    nodes: Array<{ __typename?: 'ProductUnit'; _id: string; title: string }>;
  };
};

export type GetProductsFilterQueryVariables = Exact<{
  filter?: InputMaybe<ProductsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<ProductsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetProductsFilterQuery = {
  __typename?: 'Query';
  getProducts: {
    __typename?: 'GetProductsPayload';
    count: number;
    nodes: Array<{ __typename?: 'Product'; _id: string; name: string }>;
  };
};

export type ProjectFragment = {
  __typename?: 'Project';
  _id: string;
  name: string;
  description?: string | null;
  productsCount: number;
  createdAt: any;
  updatedAt: any;
};

export type ProjectsFilterFragment = { __typename?: 'Project'; _id: string; name: string };

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject: {
    __typename?: 'CreateProjectPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject: {
    __typename?: 'UpdateProjectPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteProjectMutationVariables = Exact<{
  input: DeleteProjectInput;
}>;

export type DeleteProjectMutation = {
  __typename?: 'Mutation';
  deleteProject: {
    __typename?: 'DeleteProjectPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetProjectsQueryVariables = Exact<{
  filter?: InputMaybe<ProjectsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<ProjectsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  getProjects: {
    __typename?: 'GetProjectsPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    }>;
  };
};

export type GetProjectQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetProjectQuery = {
  __typename?: 'Query';
  getProject?: {
    __typename?: 'Project';
    _id: string;
    name: string;
    description?: string | null;
    productsCount: number;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type GetProjectsFilterQueryVariables = Exact<{
  filter?: InputMaybe<ProjectsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<ProjectsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetProjectsFilterQuery = {
  __typename?: 'Query';
  getProjects: {
    __typename?: 'GetProjectsPayload';
    count: number;
    nodes: Array<{ __typename?: 'Project'; _id: string; name: string }>;
  };
};

export type RelationFragment = {
  __typename?: 'Relation';
  _id: string;
  projectId: string;
  status: RelationStatus;
  name: string;
  description?: string | null;
  isOwn: boolean;
  createdAt: any;
  updatedAt: any;
  project: {
    __typename?: 'Project';
    _id: string;
    name: string;
    description?: string | null;
    productsCount: number;
    createdAt: any;
    updatedAt: any;
  };
};

export type CreateRelationMutationVariables = Exact<{
  input: CreateRelationInput;
}>;

export type CreateRelationMutation = {
  __typename?: 'Mutation';
  createRelation: {
    __typename?: 'CreateRelationPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Relation';
      _id: string;
      projectId: string;
      status: RelationStatus;
      name: string;
      description?: string | null;
      isOwn: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateRelationMutationVariables = Exact<{
  input: UpdateRelationInput;
}>;

export type UpdateRelationMutation = {
  __typename?: 'Mutation';
  updateRelation: {
    __typename?: 'UpdateRelationPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Relation';
      _id: string;
      projectId: string;
      status: RelationStatus;
      name: string;
      description?: string | null;
      isOwn: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteRelationMutationVariables = Exact<{
  input: DeleteRelationInput;
}>;

export type DeleteRelationMutation = {
  __typename?: 'Mutation';
  deleteRelation: {
    __typename?: 'DeleteRelationPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetRelationsQueryVariables = Exact<{
  filter?: InputMaybe<RelationsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<RelationsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetRelationsQuery = {
  __typename?: 'Query';
  getRelations: {
    __typename?: 'GetRelationsPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Relation';
      _id: string;
      projectId: string;
      status: RelationStatus;
      name: string;
      description?: string | null;
      isOwn: boolean;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    }>;
  };
};

export type GetRelationQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetRelationQuery = {
  __typename?: 'Query';
  getRelation?: {
    __typename?: 'Relation';
    _id: string;
    projectId: string;
    status: RelationStatus;
    name: string;
    description?: string | null;
    isOwn: boolean;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  } | null;
};

export type RoleFragment = {
  __typename?: 'Role';
  _id: string;
  permissionIds: Array<string>;
  teamId: string;
  parentRoleId?: string | null;
  name: string;
  onlyTeamData: boolean;
  onlySalesChannelData: boolean;
  onlyOwnAndChildData: boolean;
  canSeeOtherSearchHistory: boolean;
  searchRestriction: number;
  isSuper: boolean;
  description?: string | null;
  createdAt: any;
  updatedAt: any;
  team: {
    __typename?: 'Team';
    _id: string;
    name: string;
    rolesCount: number;
    teamMembersCount: number;
    description?: string | null;
    projectIds: Array<string>;
    createdAt: any;
    updatedAt: any;
    projects: Array<{
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    }>;
  };
  parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
};

export type RolesFilterFragment = { __typename?: 'Role'; _id: string; name: string };

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;

export type CreateRoleMutation = {
  __typename?: 'Mutation';
  createRole: {
    __typename?: 'CreateRolePayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;

export type UpdateRoleMutation = {
  __typename?: 'Mutation';
  updateRole: {
    __typename?: 'UpdateRolePayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateRoleAddPermissionMutationVariables = Exact<{
  input: UpdateRoleAddPermissionInput;
}>;

export type UpdateRoleAddPermissionMutation = {
  __typename?: 'Mutation';
  updateRoleAddPermission: {
    __typename?: 'UpdateRoleAddPermissionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateRoleRemovePermissionMutationVariables = Exact<{
  input: UpdateRoleRemovePermissionInput;
}>;

export type UpdateRoleRemovePermissionMutation = {
  __typename?: 'Mutation';
  updateRoleRemovePermission: {
    __typename?: 'UpdateRoleRemovePermissionPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteRoleMutationVariables = Exact<{
  input: DeleteRoleInput;
}>;

export type DeleteRoleMutation = {
  __typename?: 'Mutation';
  deleteRole: {
    __typename?: 'DeleteRolePayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetRolesQueryVariables = Exact<{
  filter?: InputMaybe<RolesFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<RolesOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetRolesQuery = {
  __typename?: 'Query';
  getRoles: {
    __typename?: 'GetRolesPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    }>;
  };
};

export type GetRoleQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetRoleQuery = {
  __typename?: 'Query';
  getRole?: {
    __typename?: 'Role';
    _id: string;
    permissionIds: Array<string>;
    teamId: string;
    parentRoleId?: string | null;
    name: string;
    onlyTeamData: boolean;
    onlySalesChannelData: boolean;
    onlyOwnAndChildData: boolean;
    canSeeOtherSearchHistory: boolean;
    searchRestriction: number;
    isSuper: boolean;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
  } | null;
};

export type GetRolesFilterQueryVariables = Exact<{
  filter?: InputMaybe<RolesFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<RolesOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetRolesFilterQuery = {
  __typename?: 'Query';
  getRoles: {
    __typename?: 'GetRolesPayload';
    count: number;
    nodes: Array<{ __typename?: 'Role'; _id: string; name: string }>;
  };
};

export type SalesChannelFragment = {
  __typename?: 'SalesChannel';
  _id: string;
  projectId: string;
  name: string;
  description?: string | null;
  status: SalesChannelStatus;
  financeSystem: FinanceSystem;
  createdAt: any;
  updatedAt: any;
  project: {
    __typename?: 'Project';
    _id: string;
    name: string;
    description?: string | null;
    productsCount: number;
    createdAt: any;
    updatedAt: any;
  };
};

export type SalesChannelsFilterFragment = {
  __typename?: 'SalesChannel';
  _id: string;
  name: string;
};

export type CreateSalesChannelMutationVariables = Exact<{
  input: CreateSalesChannelInput;
}>;

export type CreateSalesChannelMutation = {
  __typename?: 'Mutation';
  createSalesChannel: {
    __typename?: 'CreateSalesChannelPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateSalesChannelMutationVariables = Exact<{
  input: UpdateSalesChannelInput;
}>;

export type UpdateSalesChannelMutation = {
  __typename?: 'Mutation';
  updateSalesChannel: {
    __typename?: 'UpdateSalesChannelPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteSalesChannelMutationVariables = Exact<{
  input: DeleteSalesChannelInput;
}>;

export type DeleteSalesChannelMutation = {
  __typename?: 'Mutation';
  deleteSalesChannel: {
    __typename?: 'DeleteSalesChannelPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetSalesChannelsQueryVariables = Exact<{
  filter?: InputMaybe<SalesChannelsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<SalesChannelsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetSalesChannelsQuery = {
  __typename?: 'Query';
  getSalesChannels: {
    __typename?: 'GetSalesChannelsPayload';
    count: number;
    nodes: Array<{
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    }>;
  };
};

export type GetSalesChannelQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetSalesChannelQuery = {
  __typename?: 'Query';
  getSalesChannel?: {
    __typename?: 'SalesChannel';
    _id: string;
    projectId: string;
    name: string;
    description?: string | null;
    status: SalesChannelStatus;
    financeSystem: FinanceSystem;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  } | null;
};

export type GetSalesChannelsFilterQueryVariables = Exact<{
  filter?: InputMaybe<SalesChannelsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<SalesChannelsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetSalesChannelsFilterQuery = {
  __typename?: 'Query';
  getSalesChannels: {
    __typename?: 'GetSalesChannelsPayload';
    count: number;
    nodes: Array<{ __typename?: 'SalesChannel'; _id: string; name: string }>;
  };
};

export type SalesHistoryItemFragment = {
  __typename?: 'Order';
  _id: string;
  salesChannelId: string;
  customerId?: string | null;
  projectId: string;
  assigneeId: string;
  subtotal: number;
  total: number;
  commissionGross: number;
  commissionNet: number;
  commissionRegret: number;
  createdAt: any;
  contractSignedDate?: any | null;
  salesChannel: {
    __typename?: 'SalesChannel';
    _id: string;
    projectId: string;
    name: string;
    description?: string | null;
    status: SalesChannelStatus;
    financeSystem: FinanceSystem;
    createdAt: any;
    updatedAt: any;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
  };
  customer?: {
    __typename?: 'Customer';
    _id: string;
    salesChannelId: string;
    lastAssigneeId: string;
    creditCheckCode: number;
    fullName?: string | null;
    phoneNumber?: string | null;
    personId: string;
    email?: string | null;
    address?: string | null;
    zipCode?: string | null;
    coAddress?: string | null;
    updatedAt: any;
    createdAt: any;
    salesChannel: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    };
    lastAssignee: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
  } | null;
  project: {
    __typename?: 'Project';
    _id: string;
    name: string;
    description?: string | null;
    productsCount: number;
    createdAt: any;
    updatedAt: any;
  };
  assignee: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  };
  bundles: Array<{
    __typename?: 'OrderBundleItem';
    productBundle: { __typename?: 'ProductBundle'; name: string };
    items: Array<{
      __typename?: 'OrderItem';
      productAddons: Array<{
        __typename?: 'OrderItemProductAddon';
        productAddon: { __typename?: 'ProductAddon'; name: string };
      }>;
    }>;
  }>;
  items: Array<{
    __typename?: 'OrderItem';
    qty: number;
    product: { __typename?: 'Product'; name: string };
    productAddons: Array<{
      __typename?: 'OrderItemProductAddon';
      productAddon: { __typename?: 'ProductAddon'; name: string };
    }>;
  }>;
  upgrades: Array<{
    __typename?: 'OrderUpgradeItem';
    productAddons: Array<{
      __typename?: 'OrderItemProductAddon';
      productAddon: { __typename?: 'ProductAddon'; name: string };
    }>;
  }>;
};

export type GetSalesHistoryQueryVariables = Exact<{
  filter?: InputMaybe<GetOrdersFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<OrdersOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetSalesHistoryQuery = {
  __typename?: 'Query';
  getOrders: {
    __typename?: 'GetOrdersPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Order';
      _id: string;
      salesChannelId: string;
      customerId?: string | null;
      projectId: string;
      assigneeId: string;
      subtotal: number;
      total: number;
      commissionGross: number;
      commissionNet: number;
      commissionRegret: number;
      createdAt: any;
      contractSignedDate?: any | null;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      customer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundle: { __typename?: 'ProductBundle'; name: string };
        items: Array<{
          __typename?: 'OrderItem';
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: { __typename?: 'ProductAddon'; name: string };
          }>;
        }>;
      }>;
      items: Array<{
        __typename?: 'OrderItem';
        qty: number;
        product: { __typename?: 'Product'; name: string };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: { __typename?: 'ProductAddon'; name: string };
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeItem';
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: { __typename?: 'ProductAddon'; name: string };
        }>;
      }>;
    }>;
  };
};

export type GetSalesHistoryItemQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetSalesHistoryItemQuery = {
  __typename?: 'Query';
  getOrder?: {
    __typename?: 'Order';
    _id: string;
    salesChannelId: string;
    customerId?: string | null;
    projectId: string;
    assigneeId: string;
    subtotal: number;
    total: number;
    commissionGross: number;
    commissionNet: number;
    commissionRegret: number;
    createdAt: any;
    contractSignedDate?: any | null;
    salesChannel: {
      __typename?: 'SalesChannel';
      _id: string;
      projectId: string;
      name: string;
      description?: string | null;
      status: SalesChannelStatus;
      financeSystem: FinanceSystem;
      createdAt: any;
      updatedAt: any;
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
    };
    customer?: {
      __typename?: 'Customer';
      _id: string;
      salesChannelId: string;
      lastAssigneeId: string;
      creditCheckCode: number;
      fullName?: string | null;
      phoneNumber?: string | null;
      personId: string;
      email?: string | null;
      address?: string | null;
      zipCode?: string | null;
      coAddress?: string | null;
      updatedAt: any;
      createdAt: any;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      lastAssignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
    } | null;
    project: {
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    };
    assignee: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    };
    bundles: Array<{
      __typename?: 'OrderBundleItem';
      productBundle: { __typename?: 'ProductBundle'; name: string };
      items: Array<{
        __typename?: 'OrderItem';
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: { __typename?: 'ProductAddon'; name: string };
        }>;
      }>;
    }>;
    items: Array<{
      __typename?: 'OrderItem';
      qty: number;
      product: { __typename?: 'Product'; name: string };
      productAddons: Array<{
        __typename?: 'OrderItemProductAddon';
        productAddon: { __typename?: 'ProductAddon'; name: string };
      }>;
    }>;
    upgrades: Array<{
      __typename?: 'OrderUpgradeItem';
      productAddons: Array<{
        __typename?: 'OrderItemProductAddon';
        productAddon: { __typename?: 'ProductAddon'; name: string };
      }>;
    }>;
  } | null;
};

export type GetSalesHistoryForListQueryVariables = Exact<{
  filter?: InputMaybe<GetOrdersFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<OrdersOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetSalesHistoryForListQuery = {
  __typename?: 'Query';
  getSalesHistoryForList: {
    __typename?: 'GetOrdersPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Order';
      _id: string;
      fullSubtotalWithAddons: number;
      fullTotalWithAddons: number;
      commissionGross: number;
      commissionNet: number;
      commissionRegret: number;
      createdAt: any;
      contractSignedDate?: any | null;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundle: { __typename?: 'ProductBundle'; name: string };
        items: Array<{
          __typename?: 'OrderItem';
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: { __typename?: 'ProductAddon'; name: string };
          }>;
        }>;
      }>;
      items: Array<{
        __typename?: 'OrderItem';
        qty: number;
        product: { __typename?: 'Product'; name: string };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: { __typename?: 'ProductAddon'; name: string };
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeItem';
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: { __typename?: 'ProductAddon'; name: string };
        }>;
      }>;
      project: { __typename?: 'Project'; name: string };
      salesChannel: { __typename?: 'SalesChannel'; name: string };
      assignee: { __typename?: 'TeamMember'; fullName?: string | null };
    }>;
  };
};

export type SearchHistoryItemFragment = {
  __typename?: 'SearchHistoryItem';
  _id: string;
  personId: string;
  createdAt: any;
};

export type GetSearchHistoryQueryVariables = Exact<{
  filter?: InputMaybe<GetSearchHistoryFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<SearchHistoryOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetSearchHistoryQuery = {
  __typename?: 'Query';
  getSearchHistory: {
    __typename?: 'GetSearchHistoryPayload';
    count: number;
    nodes: Array<{
      __typename?: 'SearchHistoryItem';
      _id: string;
      personId: string;
      createdAt: any;
    }>;
  };
};

export type GetDashboardSoSafeTotalStatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardSoSafeTotalStatsQuery = {
  __typename?: 'Query';
  getDashboardSoSafeTotalStats: {
    __typename?: 'GetDashboardSoSafeTotalStatsPayload';
    salesTotal: number;
    avgRevenuePerCustomer: number;
    customersTotal: number;
    regretsPercent: number;
    buttonsTotal: number;
    avgBtnsPerCustomer: number;
    topSalesChannels: Array<{
      __typename?: 'TopSalesChannel';
      _id: string;
      name: string;
      customersTotal: number;
    }>;
  };
};

export type GetDashboardSoSafeTodayStatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardSoSafeTodayStatsQuery = {
  __typename?: 'Query';
  getDashboardSoSafeTodayStats: {
    __typename?: 'GetDashboardSoSafeTodayStatsPayload';
    yesterday: {
      __typename?: 'GetDashboardSoSafeTodayPreviousDay';
      salesValue: number;
      buttonsCount: number;
      salesCount: number;
    };
    today: {
      __typename?: 'GetDashboardSoSafeTodayCurrentDay';
      salesValue: number;
      buttonsCount: number;
      salesCount: number;
      salesValueDiffPercent: number;
      buttonsCountDiffPercent: number;
      salesCountDiffPercent: number;
    };
  };
};

export type GetDashboardSoSafeTodayLatestSalesStatsQueryVariables = Exact<{
  timezone: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetDashboardSoSafeTodayLatestSalesStatsQuery = {
  __typename?: 'Query';
  getDashboardSoSafeTodayLatestSalesStats: Array<{
    __typename?: 'GetDashboardSoSafeTodayLatestSalesStatsPayload';
    salesDate: any;
    fullName: string;
    salesChannelName: string;
    revenue: number;
  }>;
};

export type GetDashboardSoSafeTodayTopSRsStatsQueryVariables = Exact<{
  timezone: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetDashboardSoSafeTodayTopSRsStatsQuery = {
  __typename?: 'Query';
  getDashboardSoSafeTodayTopSRsStats: Array<{
    __typename?: 'GetDashboardSoSafeTodayTopSrsStatsPayload';
    fullName: string;
    salesChannelName: string;
    salesCount: number;
    buttonsCount: number;
  }>;
};

export type GetDashboardSoSafeTodayTopSalesChannelsStatsQueryVariables = Exact<{
  timezone: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetDashboardSoSafeTodayTopSalesChannelsStatsQuery = {
  __typename?: 'Query';
  getDashboardSoSafeTodayTopSalesChannelsStats: Array<{
    __typename?: 'GetDashboardSoSafeTodayTopSalesChannelsStatsPayload';
    salesChannelName: string;
    salesCount: number;
    buttonsCount: number;
  }>;
};

export type GetDashboardCustomersStatsQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
}>;

export type GetDashboardCustomersStatsQuery = {
  __typename?: 'Query';
  getDashboardCustomersStats: {
    __typename?: 'GetDashboardCustomersStatsPayload';
    totalCount: number;
    bySalesChannel: Array<{
      __typename?: 'GetDashboardCustomersStatsBySalesChannel';
      salesChannelId: string;
      salesChannelName: string;
      count: number;
    }>;
  };
};

export type GetDashboardLicensesStatsQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
}>;

export type GetDashboardLicensesStatsQuery = {
  __typename?: 'Query';
  getDashboardLicensesStats: {
    __typename?: 'GetDashboardLicensesStatsPayload';
    totalCount: number;
    totalOrdersCount: number;
    bySalesChannel: Array<{
      __typename?: 'GetDashboardLicensesStatsBySalesChannel';
      salesChannelId: string;
      salesChannelName: string;
      count: number;
      ordersCount: number;
    }>;
  };
};

export type GetDashboardRegretsStatsQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
}>;

export type GetDashboardRegretsStatsQuery = {
  __typename?: 'Query';
  getDashboardRegretsStats: {
    __typename?: 'GetDashboardRegretsStatsPayload';
    totalCount: number;
    totalRegrettedCount: number;
    totalPercent: number;
    bySalesChannel: Array<{
      __typename?: 'GetDashboardRegretsStatsBySalesChannel';
      salesChannelId: string;
      salesChannelName: string;
      count: number;
      regrettedCount: number;
      percent: number;
    }>;
  };
};

export type GetDashboardOrdersStatsQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
}>;

export type GetDashboardOrdersStatsQuery = {
  __typename?: 'Query';
  getDashboardOrdersStats: {
    __typename?: 'GetDashboardOrdersStatsPayload';
    totalCount: number;
    totalRevenue: number;
    approvedCount: number;
    regrettedCount: number;
    submittedCount: number;
  };
};

export type GetDashboardCommissionsStatsQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
}>;

export type GetDashboardCommissionsStatsQuery = {
  __typename?: 'Query';
  getDashboardCommissionsStats: {
    __typename?: 'GetDashboardCommissionsStatsPayload';
    totalRevenue: number;
    guaranteedRevenue: number;
    underRegretPeriodRevenue: number;
    regrets: Array<{
      __typename?: 'RegretCommissionstats';
      type: RegretOption;
      revenue: number;
      count: number;
    }>;
  };
};

export type GetDashboardTopSalesManagersQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetDashboardTopSalesManagersQuery = {
  __typename?: 'Query';
  getDashboardTopSalesManagers: Array<{
    __typename?: 'GetTopSalesManagersPayload';
    teamMemberId: string;
    count: number;
    buttonsCount: number;
    revenue: number;
    position: PositionDirection;
    countDiff: number;
    teamMember: {
      __typename?: 'TeamMember';
      fullName?: string | null;
      projects: Array<{ __typename?: 'TeamMemberProject'; projectId: string; projectName: string }>;
    };
  }>;
};

export type GetDashboardSalesStatsQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
}>;

export type GetDashboardSalesStatsQuery = {
  __typename?: 'Query';
  getDashboardSalesStats: {
    __typename?: 'GetDashboardSalesStatsPayload';
    totalCount: number;
    totalRevenue: number;
    guaranteedCount: number;
    guaranteedRevenue: number;
    underRegretPeriodCount: number;
    underRegretPeriodRevenue: number;
    regrets: Array<{
      __typename?: 'RegretSaleStats';
      type: RegretOption;
      count: number;
      revenue: number;
    }>;
  };
};

export type GetDashboardSalesGroupedByPeriodQueryVariables = Exact<{
  groupBy: GroupByPeriod;
  filter?: InputMaybe<DashboardFilterInput>;
}>;

export type GetDashboardSalesGroupedByPeriodQuery = {
  __typename?: 'Query';
  getDashboardSalesGroupedByPeriod: Array<{
    __typename?: 'GetDashboardSalesGroupedByPeriodPayload';
    totalCount: number;
    totalRevenue: number;
    totalCountGross: number;
    guaranteedCount: number;
    guaranteedRevenue: number;
    underRegretPeriodCount: number;
    underRegretPeriodRevenue: number;
    totalRevenueGross: number;
    periodFrom: any;
    periodTo: any;
    regrets: Array<{
      __typename?: 'RegretSaleStats';
      type: RegretOption;
      count: number;
      revenue: number;
    }>;
  }>;
};

export type GetDashboardTopProductAndBundleStatsQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetDashboardTopProductAndBundleStatsQuery = {
  __typename?: 'Query';
  getDashboardTopProductAndBundleStats: Array<{
    __typename?: 'GetDashboardTopProductAndBundleStatsPayload';
    type: ProductAndBundleStatsType;
    signedCount: number;
    regrettedCount: number;
    product?: { __typename?: 'Product'; name: string } | null;
    bundle?: { __typename?: 'ProductBundle'; name: string } | null;
  }>;
};

export type GetDashboardOrdersListQueryVariables = Exact<{
  filter?: InputMaybe<GetOrdersFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<OrdersOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetDashboardOrdersListQuery = {
  __typename?: 'Query';
  getDashboardOrdersList: {
    __typename?: 'GetOrdersPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Order';
      _id: string;
      fullName?: string | null;
      fullTotalWithAddons: number;
      status: OrderStatus;
      createdAt: any;
    }>;
  };
};

export type GetDashboardStatsQueryVariables = Exact<{
  filter?: InputMaybe<DashboardFilterInput>;
  topSalesManagersLimit?: InputMaybe<Scalars['Int']>;
  topProductAndBundleLimit?: InputMaybe<Scalars['Int']>;
  groupBy: GroupByPeriod;
  isPermittedViewOrders: Scalars['Boolean'];
  isPermittedViewCustomers: Scalars['Boolean'];
}>;

export type GetDashboardStatsQuery = {
  __typename?: 'Query';
  customerStats: {
    __typename?: 'GetDashboardCustomersStatsPayload';
    totalCount: number;
    bySalesChannel: Array<{
      __typename?: 'GetDashboardCustomersStatsBySalesChannel';
      salesChannelId: string;
      salesChannelName: string;
      count: number;
    }>;
  };
  licensesStats: {
    __typename?: 'GetDashboardLicensesStatsPayload';
    totalCount: number;
    totalOrdersCount: number;
    bySalesChannel: Array<{
      __typename?: 'GetDashboardLicensesStatsBySalesChannel';
      salesChannelId: string;
      salesChannelName: string;
      count: number;
      ordersCount: number;
    }>;
  };
  regretsStats: {
    __typename?: 'GetDashboardRegretsStatsPayload';
    totalCount: number;
    totalRegrettedCount: number;
    totalPercent: number;
    bySalesChannel: Array<{
      __typename?: 'GetDashboardRegretsStatsBySalesChannel';
      salesChannelId: string;
      salesChannelName: string;
      count: number;
      regrettedCount: number;
      percent: number;
    }>;
  };
  orderStats: {
    __typename?: 'GetDashboardOrdersStatsPayload';
    totalCount: number;
    totalRevenue: number;
    approvedCount: number;
    regrettedCount: number;
    submittedCount: number;
  };
  topProductAndBundleStats: Array<{
    __typename?: 'GetDashboardTopProductAndBundleStatsPayload';
    type: ProductAndBundleStatsType;
    signedCount: number;
    regrettedCount: number;
    product?: { __typename?: 'Product'; name: string } | null;
    bundle?: { __typename?: 'ProductBundle'; name: string } | null;
  }>;
  allCommissionsStats: {
    __typename?: 'GetDashboardCommissionsStatsPayload';
    totalRevenue: number;
    guaranteedRevenue: number;
    underRegretPeriodRevenue: number;
    regrets: Array<{ __typename?: 'RegretCommissionstats'; type: RegretOption; revenue: number }>;
  };
  salesStats: {
    __typename?: 'GetDashboardSalesStatsPayload';
    totalCount: number;
    totalRevenue: number;
    guaranteedCount: number;
    guaranteedRevenue: number;
    underRegretPeriodCount: number;
    underRegretPeriodRevenue: number;
    regrets: Array<{
      __typename?: 'RegretSaleStats';
      type: RegretOption;
      count: number;
      revenue: number;
    }>;
  };
  salesGroupedByPeriod: Array<{
    __typename?: 'GetDashboardSalesGroupedByPeriodPayload';
    totalCount: number;
    totalRevenue: number;
    totalCountGross: number;
    totalRevenueGross: number;
    guaranteedCount: number;
    guaranteedRevenue: number;
    underRegretPeriodRevenue: number;
    underRegretPeriodCount: number;
    periodFrom: any;
    periodTo: any;
    regrets: Array<{
      __typename?: 'RegretSaleStats';
      type: RegretOption;
      count: number;
      revenue: number;
    }>;
  }>;
  topSalesManagers: Array<{
    __typename?: 'GetTopSalesManagersPayload';
    teamMemberId: string;
    count: number;
    buttonsCount: number;
    revenue: number;
    position: PositionDirection;
    countDiff: number;
    teamMember: {
      __typename?: 'TeamMember';
      fullName?: string | null;
      projects: Array<{ __typename?: 'TeamMemberProject'; projectId: string; projectName: string }>;
    };
  }>;
};

export type TeamFragment = {
  __typename?: 'Team';
  _id: string;
  name: string;
  rolesCount: number;
  teamMembersCount: number;
  description?: string | null;
  projectIds: Array<string>;
  createdAt: any;
  updatedAt: any;
  projects: Array<{
    __typename?: 'Project';
    _id: string;
    name: string;
    description?: string | null;
    productsCount: number;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamInput;
}>;

export type CreateTeamMutation = {
  __typename?: 'Mutation';
  createTeam: {
    __typename?: 'CreateTeamPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateTeamInput;
}>;

export type UpdateTeamMutation = {
  __typename?: 'Mutation';
  updateTeam: {
    __typename?: 'UpdateTeamPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteTeamMutationVariables = Exact<{
  input: DeleteTeamInput;
}>;

export type DeleteTeamMutation = {
  __typename?: 'Mutation';
  deleteTeam: {
    __typename?: 'DeleteTeamPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetTeamsQueryVariables = Exact<{
  filter?: InputMaybe<TeamsFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<TeamsOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetTeamsQuery = {
  __typename?: 'Query';
  getTeams: {
    __typename?: 'GetTeamsPayload';
    count: number;
    nodes: Array<{
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    }>;
  };
};

export type GetTeamQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetTeamQuery = {
  __typename?: 'Query';
  getTeam?: {
    __typename?: 'Team';
    _id: string;
    name: string;
    rolesCount: number;
    teamMembersCount: number;
    description?: string | null;
    projectIds: Array<string>;
    createdAt: any;
    updatedAt: any;
    projects: Array<{
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    }>;
  } | null;
};

export type BasicTeamMemberFragment = {
  __typename?: 'TeamMember';
  _id: string;
  personId: string;
  teamId: string;
  roleId: string;
  fullName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  status: TeamMemberStatus;
  searchHistoryCount: number;
  createdAt: any;
  updatedAt: any;
  team: {
    __typename?: 'Team';
    _id: string;
    name: string;
    rolesCount: number;
    teamMembersCount: number;
    description?: string | null;
    projectIds: Array<string>;
    createdAt: any;
    updatedAt: any;
    projects: Array<{
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    }>;
  };
  projects: Array<{
    __typename?: 'TeamMemberProject';
    projectId: string;
    projectName: string;
    salesChannelId?: string | null;
  }>;
  role: {
    __typename?: 'Role';
    _id: string;
    permissionIds: Array<string>;
    teamId: string;
    parentRoleId?: string | null;
    name: string;
    onlyTeamData: boolean;
    onlySalesChannelData: boolean;
    onlyOwnAndChildData: boolean;
    canSeeOtherSearchHistory: boolean;
    searchRestriction: number;
    isSuper: boolean;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
  };
};

export type TeamMemberFragment = {
  __typename?: 'TeamMember';
  parentTeamMemberId?: string | null;
  _id: string;
  personId: string;
  teamId: string;
  roleId: string;
  fullName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  status: TeamMemberStatus;
  searchHistoryCount: number;
  createdAt: any;
  updatedAt: any;
  parentTeamMember?: {
    __typename?: 'TeamMember';
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  } | null;
  team: {
    __typename?: 'Team';
    _id: string;
    name: string;
    rolesCount: number;
    teamMembersCount: number;
    description?: string | null;
    projectIds: Array<string>;
    createdAt: any;
    updatedAt: any;
    projects: Array<{
      __typename?: 'Project';
      _id: string;
      name: string;
      description?: string | null;
      productsCount: number;
      createdAt: any;
      updatedAt: any;
    }>;
  };
  projects: Array<{
    __typename?: 'TeamMemberProject';
    projectId: string;
    projectName: string;
    salesChannelId?: string | null;
  }>;
  role: {
    __typename?: 'Role';
    _id: string;
    permissionIds: Array<string>;
    teamId: string;
    parentRoleId?: string | null;
    name: string;
    onlyTeamData: boolean;
    onlySalesChannelData: boolean;
    onlyOwnAndChildData: boolean;
    canSeeOtherSearchHistory: boolean;
    searchRestriction: number;
    isSuper: boolean;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
  };
};

export type TeamMembersFilterFragment = {
  __typename?: 'TeamMember';
  _id: string;
  fullName?: string | null;
};

export type CreateTeamMemberMutationVariables = Exact<{
  input: CreateTeamMemberInput;
}>;

export type CreateTeamMemberMutation = {
  __typename?: 'Mutation';
  createTeamMember: {
    __typename?: 'CreateTeamMemberPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type UpdateTeamMemberMutationVariables = Exact<{
  input: UpdateTeamMemberInput;
}>;

export type UpdateTeamMemberMutation = {
  __typename?: 'Mutation';
  updateTeamMember: {
    __typename?: 'UpdateTeamMemberPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type DeleteTeamMemberMutationVariables = Exact<{
  input: DeleteTeamMemberInput;
}>;

export type DeleteTeamMemberMutation = {
  __typename?: 'Mutation';
  deleteTeamMember: {
    __typename?: 'DeleteTeamMemberPayload';
    status: MutationStatus;
    errors: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }>;
  };
};

export type GetTeamMembersQueryVariables = Exact<{
  filter?: InputMaybe<TeamMembersFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<TeamMembersOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetTeamMembersQuery = {
  __typename?: 'Query';
  getTeamMembers: {
    __typename?: 'GetTeamMembersPayload';
    count: number;
    nodes: Array<{
      __typename?: 'TeamMember';
      parentTeamMemberId?: string | null;
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      parentTeamMember?: {
        __typename?: 'TeamMember';
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    }>;
  };
};

export type GetTeamMemberQueryVariables = Exact<{
  _id: Scalars['ID'];
}>;

export type GetTeamMemberQuery = {
  __typename?: 'Query';
  getTeamMember?: {
    __typename?: 'TeamMember';
    parentTeamMemberId?: string | null;
    _id: string;
    personId: string;
    teamId: string;
    roleId: string;
    fullName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    status: TeamMemberStatus;
    searchHistoryCount: number;
    createdAt: any;
    updatedAt: any;
    parentTeamMember?: {
      __typename?: 'TeamMember';
      _id: string;
      personId: string;
      teamId: string;
      roleId: string;
      fullName?: string | null;
      email?: string | null;
      phoneNumber?: string | null;
      status: TeamMemberStatus;
      searchHistoryCount: number;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      projects: Array<{
        __typename?: 'TeamMemberProject';
        projectId: string;
        projectName: string;
        salesChannelId?: string | null;
      }>;
      role: {
        __typename?: 'Role';
        _id: string;
        permissionIds: Array<string>;
        teamId: string;
        parentRoleId?: string | null;
        name: string;
        onlyTeamData: boolean;
        onlySalesChannelData: boolean;
        onlyOwnAndChildData: boolean;
        canSeeOtherSearchHistory: boolean;
        searchRestriction: number;
        isSuper: boolean;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
      };
    } | null;
    team: {
      __typename?: 'Team';
      _id: string;
      name: string;
      rolesCount: number;
      teamMembersCount: number;
      description?: string | null;
      projectIds: Array<string>;
      createdAt: any;
      updatedAt: any;
      projects: Array<{
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      }>;
    };
    projects: Array<{
      __typename?: 'TeamMemberProject';
      projectId: string;
      projectName: string;
      salesChannelId?: string | null;
    }>;
    role: {
      __typename?: 'Role';
      _id: string;
      permissionIds: Array<string>;
      teamId: string;
      parentRoleId?: string | null;
      name: string;
      onlyTeamData: boolean;
      onlySalesChannelData: boolean;
      onlyOwnAndChildData: boolean;
      canSeeOtherSearchHistory: boolean;
      searchRestriction: number;
      isSuper: boolean;
      description?: string | null;
      createdAt: any;
      updatedAt: any;
      team: {
        __typename?: 'Team';
        _id: string;
        name: string;
        rolesCount: number;
        teamMembersCount: number;
        description?: string | null;
        projectIds: Array<string>;
        createdAt: any;
        updatedAt: any;
        projects: Array<{
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        }>;
      };
      parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
    };
  } | null;
};

export type GetTeamMembersFilterQueryVariables = Exact<{
  filter?: InputMaybe<TeamMembersFilterInput>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  of?: InputMaybe<TeamMembersOrderBy>;
  od?: InputMaybe<OrderDirection>;
}>;

export type GetTeamMembersFilterQuery = {
  __typename?: 'Query';
  getTeamMembers: {
    __typename?: 'GetTeamMembersPayload';
    count: number;
    nodes: Array<{ __typename?: 'TeamMember'; _id: string; fullName?: string | null }>;
  };
};

export type CreateWinBackMutationVariables = Exact<{
  input: CreateWinBackInput;
}>;

export type CreateWinBackMutation = {
  __typename?: 'Mutation';
  createWinBack: {
    __typename?: 'CreateWinBackPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Order';
      _id: string;
      salesChannelId: string;
      projectId: string;
      customerId?: string | null;
      assigneeId: string;
      resolvedById?: string | null;
      isCreditCheckReceived: boolean;
      isActiveCustomer: boolean;
      financeSystemSyncStatus: FinanceSystemSyncStatus;
      financeSystem: FinanceSystem;
      creditCheckCode: number;
      fullName?: string | null;
      email: string;
      phoneNumber: string;
      personId: string;
      status: OrderStatus;
      coAddress: string;
      invoicingAddress?: string | null;
      invoicingType: InvoicingType;
      invoicingZip?: string | null;
      invoicingFrequency: number;
      customerAddress: string;
      customerZip: string;
      subtotal: number;
      total: number;
      monthlyTotal: number;
      onePaymentTotal: number;
      discountPercent: number;
      discountAmount: number;
      monthlySubtotal: number;
      quarterSubtotal: number;
      fullTotalWithAddons: number;
      regretReason?: string | null;
      regretOption?: RegretOption | null;
      shippingStatus: ShippingStatus;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      isUnsigned: boolean;
      isRegrettable: boolean;
      isRegrettableDate?: any | null;
      isDeleted: boolean;
      isWinBack?: boolean | null;
      isFromShopPage: boolean;
      updatedAt: any;
      createdAt: any;
      contractStatus: OrderContractStatus;
      contractSignedDate?: any | null;
      winBackPrice: number;
      winBackDuration?: number | null;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      customer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
      resolvedBy?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundleId: string;
        postponeInvoicing: number;
        total: number;
        subtotal: number;
        addonsPrice: number;
        productBundle: {
          __typename?: 'ProductBundle';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          products: Array<{
            __typename?: 'ProductBundleToProduct';
            productId: string;
            qty: number;
            product: {
              __typename?: 'Product';
              name: string;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              isCreditCheckRequired: boolean;
              licenseDurationMonths?: number | null;
              isPersonIdRequired: boolean;
              isSubscription: boolean;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          }>;
        };
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeItem';
        licenseId: string;
        total: number;
        license: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          orderItem?: {
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
      }>;
      soSafeCoupon?: {
        __typename?: 'SoSafeCoupon';
        code: string;
        amount: number;
        type: SoSafeCouponType;
      } | null;
      sourceOrders?: Array<{
        __typename?: 'Order';
        _id: string;
        fullTotalWithAddons: number;
        monthlySubtotal: number;
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
        bundles: Array<{
          __typename?: 'OrderBundleItem';
          productBundleId: string;
          postponeInvoicing: number;
          total: number;
          subtotal: number;
          addonsPrice: number;
          productBundle: {
            __typename?: 'ProductBundle';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            products: Array<{
              __typename?: 'ProductBundleToProduct';
              productId: string;
              qty: number;
              product: {
                __typename?: 'Product';
                name: string;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                isCreditCheckRequired: boolean;
                licenseDurationMonths?: number | null;
                isPersonIdRequired: boolean;
                isSubscription: boolean;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            }>;
          };
          items: Array<{
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          }>;
        }>;
      }> | null;
    } | null;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export type UpdateWinBackMutationVariables = Exact<{
  input: UpdateWinBackInput;
}>;

export type UpdateWinBackMutation = {
  __typename?: 'Mutation';
  updateWinBack: {
    __typename?: 'UpdateWinBackPayload';
    status: MutationStatus;
    node?: {
      __typename?: 'Order';
      _id: string;
      salesChannelId: string;
      projectId: string;
      customerId?: string | null;
      assigneeId: string;
      resolvedById?: string | null;
      isCreditCheckReceived: boolean;
      isActiveCustomer: boolean;
      financeSystemSyncStatus: FinanceSystemSyncStatus;
      financeSystem: FinanceSystem;
      creditCheckCode: number;
      fullName?: string | null;
      email: string;
      phoneNumber: string;
      personId: string;
      status: OrderStatus;
      coAddress: string;
      invoicingAddress?: string | null;
      invoicingType: InvoicingType;
      invoicingZip?: string | null;
      invoicingFrequency: number;
      customerAddress: string;
      customerZip: string;
      subtotal: number;
      total: number;
      monthlyTotal: number;
      onePaymentTotal: number;
      discountPercent: number;
      discountAmount: number;
      monthlySubtotal: number;
      quarterSubtotal: number;
      fullTotalWithAddons: number;
      regretReason?: string | null;
      regretOption?: RegretOption | null;
      shippingStatus: ShippingStatus;
      scriveContractStatus: ScriveContractStatus;
      scriveContractId?: string | null;
      isUnsigned: boolean;
      isRegrettable: boolean;
      isRegrettableDate?: any | null;
      isDeleted: boolean;
      isWinBack?: boolean | null;
      isFromShopPage: boolean;
      updatedAt: any;
      createdAt: any;
      contractStatus: OrderContractStatus;
      contractSignedDate?: any | null;
      winBackPrice: number;
      winBackDuration?: number | null;
      salesChannel: {
        __typename?: 'SalesChannel';
        _id: string;
        projectId: string;
        name: string;
        description?: string | null;
        status: SalesChannelStatus;
        financeSystem: FinanceSystem;
        createdAt: any;
        updatedAt: any;
        project: {
          __typename?: 'Project';
          _id: string;
          name: string;
          description?: string | null;
          productsCount: number;
          createdAt: any;
          updatedAt: any;
        };
      };
      project: {
        __typename?: 'Project';
        _id: string;
        name: string;
        description?: string | null;
        productsCount: number;
        createdAt: any;
        updatedAt: any;
      };
      customer?: {
        __typename?: 'Customer';
        _id: string;
        salesChannelId: string;
        lastAssigneeId: string;
        creditCheckCode: number;
        fullName?: string | null;
        phoneNumber?: string | null;
        personId: string;
        email?: string | null;
        address?: string | null;
        zipCode?: string | null;
        coAddress?: string | null;
        updatedAt: any;
        createdAt: any;
        salesChannel: {
          __typename?: 'SalesChannel';
          _id: string;
          projectId: string;
          name: string;
          description?: string | null;
          status: SalesChannelStatus;
          financeSystem: FinanceSystem;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        lastAssignee: {
          __typename?: 'TeamMember';
          parentTeamMemberId?: string | null;
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          parentTeamMember?: {
            __typename?: 'TeamMember';
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        };
      } | null;
      assignee: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      };
      resolvedBy?: {
        __typename?: 'TeamMember';
        parentTeamMemberId?: string | null;
        _id: string;
        personId: string;
        teamId: string;
        roleId: string;
        fullName?: string | null;
        email?: string | null;
        phoneNumber?: string | null;
        status: TeamMemberStatus;
        searchHistoryCount: number;
        createdAt: any;
        updatedAt: any;
        parentTeamMember?: {
          __typename?: 'TeamMember';
          _id: string;
          personId: string;
          teamId: string;
          roleId: string;
          fullName?: string | null;
          email?: string | null;
          phoneNumber?: string | null;
          status: TeamMemberStatus;
          searchHistoryCount: number;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          projects: Array<{
            __typename?: 'TeamMemberProject';
            projectId: string;
            projectName: string;
            salesChannelId?: string | null;
          }>;
          role: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          };
        } | null;
        team: {
          __typename?: 'Team';
          _id: string;
          name: string;
          rolesCount: number;
          teamMembersCount: number;
          description?: string | null;
          projectIds: Array<string>;
          createdAt: any;
          updatedAt: any;
          projects: Array<{
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          }>;
        };
        projects: Array<{
          __typename?: 'TeamMemberProject';
          projectId: string;
          projectName: string;
          salesChannelId?: string | null;
        }>;
        role: {
          __typename?: 'Role';
          _id: string;
          permissionIds: Array<string>;
          teamId: string;
          parentRoleId?: string | null;
          name: string;
          onlyTeamData: boolean;
          onlySalesChannelData: boolean;
          onlyOwnAndChildData: boolean;
          canSeeOtherSearchHistory: boolean;
          searchRestriction: number;
          isSuper: boolean;
          description?: string | null;
          createdAt: any;
          updatedAt: any;
          team: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          };
          parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
        };
      } | null;
      items: Array<{
        __typename?: 'OrderItem';
        relationId: string;
        productId: string;
        discountId?: string | null;
        discountAmount: number;
        discountPercent: number;
        qty: number;
        postponeInvoicing: number;
        personId?: string | null;
        fullName?: string | null;
        subtotal: number;
        total: number;
        addonsPrice: number;
        phoneNumber: string;
        relation: {
          __typename?: 'Relation';
          _id: string;
          projectId: string;
          status: RelationStatus;
          name: string;
          description?: string | null;
          isOwn: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
        };
        product: {
          __typename?: 'Product';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          description?: string | null;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          freeMonths: number;
          licenseDurationMonths?: number | null;
          isSubscription: boolean;
          canBeTerminated: boolean;
          unitId: string;
          isCreditCheckRequired: boolean;
          isPersonIdRequired: boolean;
          isStockable: boolean;
          isInStok: boolean;
          hasCommissions: boolean;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          gallery: Array<{ __typename?: 'FileNode'; url: string }>;
          productAddons: Array<{
            __typename?: 'ProductAddon';
            _id: string;
            name: string;
            vatPrice: number;
            status: ProductAddonStatus;
            isPersonIdRequired: boolean;
            isCreditCheckRequired: boolean;
            isSubscription: boolean;
          }>;
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
        discount?: {
          __typename?: 'ProductDiscount';
          _id: string;
          productId: string;
          teamId?: string | null;
          roleId?: string | null;
          teamMemberId?: string | null;
          salesChannelId?: string | null;
          authorId: string;
          qty: number;
          discount: number;
          status: ProductDiscountStatus;
          productPrice: number;
          productVatPrice: number;
          createdAt: any;
          updatedAt: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          team?: {
            __typename?: 'Team';
            _id: string;
            name: string;
            rolesCount: number;
            teamMembersCount: number;
            description?: string | null;
            projectIds: Array<string>;
            createdAt: any;
            updatedAt: any;
            projects: Array<{
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            }>;
          } | null;
          role?: {
            __typename?: 'Role';
            _id: string;
            permissionIds: Array<string>;
            teamId: string;
            parentRoleId?: string | null;
            name: string;
            onlyTeamData: boolean;
            onlySalesChannelData: boolean;
            onlyOwnAndChildData: boolean;
            canSeeOtherSearchHistory: boolean;
            searchRestriction: number;
            isSuper: boolean;
            description?: string | null;
            createdAt: any;
            updatedAt: any;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            parentRole?: { __typename?: 'Role'; _id: string; teamId: string; name: string } | null;
          } | null;
          teamMember?: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          } | null;
          salesChannel?: {
            __typename?: 'SalesChannel';
            _id: string;
            projectId: string;
            name: string;
            description?: string | null;
            status: SalesChannelStatus;
            financeSystem: FinanceSystem;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          } | null;
          author: {
            __typename?: 'TeamMember';
            parentTeamMemberId?: string | null;
            _id: string;
            personId: string;
            teamId: string;
            roleId: string;
            fullName?: string | null;
            email?: string | null;
            phoneNumber?: string | null;
            status: TeamMemberStatus;
            searchHistoryCount: number;
            createdAt: any;
            updatedAt: any;
            parentTeamMember?: {
              __typename?: 'TeamMember';
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            team: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            };
            projects: Array<{
              __typename?: 'TeamMemberProject';
              projectId: string;
              projectName: string;
              salesChannelId?: string | null;
            }>;
            role: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            };
          };
        } | null;
        license?: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        } | null;
      }>;
      bundles: Array<{
        __typename?: 'OrderBundleItem';
        productBundleId: string;
        postponeInvoicing: number;
        total: number;
        subtotal: number;
        addonsPrice: number;
        productBundle: {
          __typename?: 'ProductBundle';
          _id: string;
          projectId: string;
          name: string;
          nameInContract: string;
          status: ProductStatus;
          price: number;
          vatRate: number;
          vatPrice: number;
          createdAt: any;
          updatedAt: any;
          project: {
            __typename?: 'Project';
            _id: string;
            name: string;
            description?: string | null;
            productsCount: number;
            createdAt: any;
            updatedAt: any;
          };
          products: Array<{
            __typename?: 'ProductBundleToProduct';
            productId: string;
            qty: number;
            product: {
              __typename?: 'Product';
              name: string;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              isCreditCheckRequired: boolean;
              licenseDurationMonths?: number | null;
              isPersonIdRequired: boolean;
              isSubscription: boolean;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          }>;
        };
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
      }>;
      upgrades: Array<{
        __typename?: 'OrderUpgradeItem';
        licenseId: string;
        total: number;
        license: {
          __typename?: 'License';
          _id: string;
          status: LicenseStatus;
          licenseEndDate: any;
          orderItem?: {
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          } | null;
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          externalUser: {
            __typename?: 'ExternalUser';
            _id: string;
            lastCustomerId?: string | null;
            lastAssigneeId?: string | null;
            relationId?: string | null;
            productId: string;
            fullName?: string | null;
            phoneNumber: string;
            personId?: string | null;
            address?: string | null;
            licenseStartDate: any;
            licenseEndDate: any;
            createdAt: any;
            lastCustomer?: {
              __typename?: 'Customer';
              _id: string;
              salesChannelId: string;
              lastAssigneeId: string;
              creditCheckCode: number;
              fullName?: string | null;
              phoneNumber?: string | null;
              personId: string;
              email?: string | null;
              address?: string | null;
              zipCode?: string | null;
              coAddress?: string | null;
              updatedAt: any;
              createdAt: any;
              salesChannel: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              };
              lastAssignee: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            lastAssignee?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            relation?: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            licenses: Array<{
              __typename?: 'License';
              productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
            }>;
          };
        };
        productAddons: Array<{
          __typename?: 'OrderItemProductAddon';
          productAddon: {
            __typename?: 'ProductAddon';
            _id: string;
            projectId: string;
            productId: string;
            name: string;
            status: ProductAddonStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            createdAt: any;
            updatedAt: any;
            isPersonIdRequired: boolean;
            canBeTerminated: boolean;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
          };
          license?: { __typename?: 'License'; status: LicenseStatus } | null;
        }>;
      }>;
      soSafeCoupon?: {
        __typename?: 'SoSafeCoupon';
        code: string;
        amount: number;
        type: SoSafeCouponType;
      } | null;
      sourceOrders?: Array<{
        __typename?: 'Order';
        _id: string;
        fullTotalWithAddons: number;
        monthlySubtotal: number;
        items: Array<{
          __typename?: 'OrderItem';
          relationId: string;
          productId: string;
          discountId?: string | null;
          discountAmount: number;
          discountPercent: number;
          qty: number;
          postponeInvoicing: number;
          personId?: string | null;
          fullName?: string | null;
          subtotal: number;
          total: number;
          addonsPrice: number;
          phoneNumber: string;
          relation: {
            __typename?: 'Relation';
            _id: string;
            projectId: string;
            status: RelationStatus;
            name: string;
            description?: string | null;
            isOwn: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
          };
          product: {
            __typename?: 'Product';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            description?: string | null;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            freeMonths: number;
            licenseDurationMonths?: number | null;
            isSubscription: boolean;
            canBeTerminated: boolean;
            unitId: string;
            isCreditCheckRequired: boolean;
            isPersonIdRequired: boolean;
            isStockable: boolean;
            isInStok: boolean;
            hasCommissions: boolean;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            gallery: Array<{ __typename?: 'FileNode'; url: string }>;
            productAddons: Array<{
              __typename?: 'ProductAddon';
              _id: string;
              name: string;
              vatPrice: number;
              status: ProductAddonStatus;
              isPersonIdRequired: boolean;
              isCreditCheckRequired: boolean;
              isSubscription: boolean;
            }>;
          };
          productAddons: Array<{
            __typename?: 'OrderItemProductAddon';
            productAddon: {
              __typename?: 'ProductAddon';
              _id: string;
              projectId: string;
              productId: string;
              name: string;
              status: ProductAddonStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              createdAt: any;
              updatedAt: any;
              isPersonIdRequired: boolean;
              canBeTerminated: boolean;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            };
            license?: { __typename?: 'License'; status: LicenseStatus } | null;
          }>;
          discount?: {
            __typename?: 'ProductDiscount';
            _id: string;
            productId: string;
            teamId?: string | null;
            roleId?: string | null;
            teamMemberId?: string | null;
            salesChannelId?: string | null;
            authorId: string;
            qty: number;
            discount: number;
            status: ProductDiscountStatus;
            productPrice: number;
            productVatPrice: number;
            createdAt: any;
            updatedAt: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            team?: {
              __typename?: 'Team';
              _id: string;
              name: string;
              rolesCount: number;
              teamMembersCount: number;
              description?: string | null;
              projectIds: Array<string>;
              createdAt: any;
              updatedAt: any;
              projects: Array<{
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              }>;
            } | null;
            role?: {
              __typename?: 'Role';
              _id: string;
              permissionIds: Array<string>;
              teamId: string;
              parentRoleId?: string | null;
              name: string;
              onlyTeamData: boolean;
              onlySalesChannelData: boolean;
              onlyOwnAndChildData: boolean;
              canSeeOtherSearchHistory: boolean;
              searchRestriction: number;
              isSuper: boolean;
              description?: string | null;
              createdAt: any;
              updatedAt: any;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              parentRole?: {
                __typename?: 'Role';
                _id: string;
                teamId: string;
                name: string;
              } | null;
            } | null;
            teamMember?: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            } | null;
            salesChannel?: {
              __typename?: 'SalesChannel';
              _id: string;
              projectId: string;
              name: string;
              description?: string | null;
              status: SalesChannelStatus;
              financeSystem: FinanceSystem;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            } | null;
            author: {
              __typename?: 'TeamMember';
              parentTeamMemberId?: string | null;
              _id: string;
              personId: string;
              teamId: string;
              roleId: string;
              fullName?: string | null;
              email?: string | null;
              phoneNumber?: string | null;
              status: TeamMemberStatus;
              searchHistoryCount: number;
              createdAt: any;
              updatedAt: any;
              parentTeamMember?: {
                __typename?: 'TeamMember';
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              team: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              };
              projects: Array<{
                __typename?: 'TeamMemberProject';
                projectId: string;
                projectName: string;
                salesChannelId?: string | null;
              }>;
              role: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              };
            };
          } | null;
          license?: {
            __typename?: 'License';
            _id: string;
            status: LicenseStatus;
            licenseEndDate: any;
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            externalUser: {
              __typename?: 'ExternalUser';
              _id: string;
              lastCustomerId?: string | null;
              lastAssigneeId?: string | null;
              relationId?: string | null;
              productId: string;
              fullName?: string | null;
              phoneNumber: string;
              personId?: string | null;
              address?: string | null;
              licenseStartDate: any;
              licenseEndDate: any;
              createdAt: any;
              lastCustomer?: {
                __typename?: 'Customer';
                _id: string;
                salesChannelId: string;
                lastAssigneeId: string;
                creditCheckCode: number;
                fullName?: string | null;
                phoneNumber?: string | null;
                personId: string;
                email?: string | null;
                address?: string | null;
                zipCode?: string | null;
                coAddress?: string | null;
                updatedAt: any;
                createdAt: any;
                salesChannel: {
                  __typename?: 'SalesChannel';
                  _id: string;
                  projectId: string;
                  name: string;
                  description?: string | null;
                  status: SalesChannelStatus;
                  financeSystem: FinanceSystem;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                };
                lastAssignee: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                };
              } | null;
              lastAssignee?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              relation?: {
                __typename?: 'Relation';
                _id: string;
                projectId: string;
                status: RelationStatus;
                name: string;
                description?: string | null;
                isOwn: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              licenses: Array<{
                __typename?: 'License';
                productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
              }>;
            };
          } | null;
        }>;
        bundles: Array<{
          __typename?: 'OrderBundleItem';
          productBundleId: string;
          postponeInvoicing: number;
          total: number;
          subtotal: number;
          addonsPrice: number;
          productBundle: {
            __typename?: 'ProductBundle';
            _id: string;
            projectId: string;
            name: string;
            nameInContract: string;
            status: ProductStatus;
            price: number;
            vatRate: number;
            vatPrice: number;
            createdAt: any;
            updatedAt: any;
            project: {
              __typename?: 'Project';
              _id: string;
              name: string;
              description?: string | null;
              productsCount: number;
              createdAt: any;
              updatedAt: any;
            };
            products: Array<{
              __typename?: 'ProductBundleToProduct';
              productId: string;
              qty: number;
              product: {
                __typename?: 'Product';
                name: string;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                isCreditCheckRequired: boolean;
                licenseDurationMonths?: number | null;
                isPersonIdRequired: boolean;
                isSubscription: boolean;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
            }>;
          };
          items: Array<{
            __typename?: 'OrderItem';
            relationId: string;
            productId: string;
            discountId?: string | null;
            discountAmount: number;
            discountPercent: number;
            qty: number;
            postponeInvoicing: number;
            personId?: string | null;
            fullName?: string | null;
            subtotal: number;
            total: number;
            addonsPrice: number;
            phoneNumber: string;
            relation: {
              __typename?: 'Relation';
              _id: string;
              projectId: string;
              status: RelationStatus;
              name: string;
              description?: string | null;
              isOwn: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
            };
            product: {
              __typename?: 'Product';
              _id: string;
              projectId: string;
              name: string;
              nameInContract: string;
              description?: string | null;
              status: ProductStatus;
              price: number;
              vatRate: number;
              vatPrice: number;
              freeMonths: number;
              licenseDurationMonths?: number | null;
              isSubscription: boolean;
              canBeTerminated: boolean;
              unitId: string;
              isCreditCheckRequired: boolean;
              isPersonIdRequired: boolean;
              isStockable: boolean;
              isInStok: boolean;
              hasCommissions: boolean;
              createdAt: any;
              updatedAt: any;
              project: {
                __typename?: 'Project';
                _id: string;
                name: string;
                description?: string | null;
                productsCount: number;
                createdAt: any;
                updatedAt: any;
              };
              gallery: Array<{ __typename?: 'FileNode'; url: string }>;
              productAddons: Array<{
                __typename?: 'ProductAddon';
                _id: string;
                name: string;
                vatPrice: number;
                status: ProductAddonStatus;
                isPersonIdRequired: boolean;
                isCreditCheckRequired: boolean;
                isSubscription: boolean;
              }>;
            };
            productAddons: Array<{
              __typename?: 'OrderItemProductAddon';
              productAddon: {
                __typename?: 'ProductAddon';
                _id: string;
                projectId: string;
                productId: string;
                name: string;
                status: ProductAddonStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                createdAt: any;
                updatedAt: any;
                isPersonIdRequired: boolean;
                canBeTerminated: boolean;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
              };
              license?: { __typename?: 'License'; status: LicenseStatus } | null;
            }>;
            discount?: {
              __typename?: 'ProductDiscount';
              _id: string;
              productId: string;
              teamId?: string | null;
              roleId?: string | null;
              teamMemberId?: string | null;
              salesChannelId?: string | null;
              authorId: string;
              qty: number;
              discount: number;
              status: ProductDiscountStatus;
              productPrice: number;
              productVatPrice: number;
              createdAt: any;
              updatedAt: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              team?: {
                __typename?: 'Team';
                _id: string;
                name: string;
                rolesCount: number;
                teamMembersCount: number;
                description?: string | null;
                projectIds: Array<string>;
                createdAt: any;
                updatedAt: any;
                projects: Array<{
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                }>;
              } | null;
              role?: {
                __typename?: 'Role';
                _id: string;
                permissionIds: Array<string>;
                teamId: string;
                parentRoleId?: string | null;
                name: string;
                onlyTeamData: boolean;
                onlySalesChannelData: boolean;
                onlyOwnAndChildData: boolean;
                canSeeOtherSearchHistory: boolean;
                searchRestriction: number;
                isSuper: boolean;
                description?: string | null;
                createdAt: any;
                updatedAt: any;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                parentRole?: {
                  __typename?: 'Role';
                  _id: string;
                  teamId: string;
                  name: string;
                } | null;
              } | null;
              teamMember?: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              } | null;
              salesChannel?: {
                __typename?: 'SalesChannel';
                _id: string;
                projectId: string;
                name: string;
                description?: string | null;
                status: SalesChannelStatus;
                financeSystem: FinanceSystem;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
              } | null;
              author: {
                __typename?: 'TeamMember';
                parentTeamMemberId?: string | null;
                _id: string;
                personId: string;
                teamId: string;
                roleId: string;
                fullName?: string | null;
                email?: string | null;
                phoneNumber?: string | null;
                status: TeamMemberStatus;
                searchHistoryCount: number;
                createdAt: any;
                updatedAt: any;
                parentTeamMember?: {
                  __typename?: 'TeamMember';
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                team: {
                  __typename?: 'Team';
                  _id: string;
                  name: string;
                  rolesCount: number;
                  teamMembersCount: number;
                  description?: string | null;
                  projectIds: Array<string>;
                  createdAt: any;
                  updatedAt: any;
                  projects: Array<{
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  }>;
                };
                projects: Array<{
                  __typename?: 'TeamMemberProject';
                  projectId: string;
                  projectName: string;
                  salesChannelId?: string | null;
                }>;
                role: {
                  __typename?: 'Role';
                  _id: string;
                  permissionIds: Array<string>;
                  teamId: string;
                  parentRoleId?: string | null;
                  name: string;
                  onlyTeamData: boolean;
                  onlySalesChannelData: boolean;
                  onlyOwnAndChildData: boolean;
                  canSeeOtherSearchHistory: boolean;
                  searchRestriction: number;
                  isSuper: boolean;
                  description?: string | null;
                  createdAt: any;
                  updatedAt: any;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  parentRole?: {
                    __typename?: 'Role';
                    _id: string;
                    teamId: string;
                    name: string;
                  } | null;
                };
              };
            } | null;
            license?: {
              __typename?: 'License';
              _id: string;
              status: LicenseStatus;
              licenseEndDate: any;
              product: {
                __typename?: 'Product';
                _id: string;
                projectId: string;
                name: string;
                nameInContract: string;
                description?: string | null;
                status: ProductStatus;
                price: number;
                vatRate: number;
                vatPrice: number;
                freeMonths: number;
                licenseDurationMonths?: number | null;
                isSubscription: boolean;
                canBeTerminated: boolean;
                unitId: string;
                isCreditCheckRequired: boolean;
                isPersonIdRequired: boolean;
                isStockable: boolean;
                isInStok: boolean;
                hasCommissions: boolean;
                createdAt: any;
                updatedAt: any;
                project: {
                  __typename?: 'Project';
                  _id: string;
                  name: string;
                  description?: string | null;
                  productsCount: number;
                  createdAt: any;
                  updatedAt: any;
                };
                gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                productAddons: Array<{
                  __typename?: 'ProductAddon';
                  _id: string;
                  name: string;
                  vatPrice: number;
                  status: ProductAddonStatus;
                  isPersonIdRequired: boolean;
                  isCreditCheckRequired: boolean;
                  isSubscription: boolean;
                }>;
              };
              externalUser: {
                __typename?: 'ExternalUser';
                _id: string;
                lastCustomerId?: string | null;
                lastAssigneeId?: string | null;
                relationId?: string | null;
                productId: string;
                fullName?: string | null;
                phoneNumber: string;
                personId?: string | null;
                address?: string | null;
                licenseStartDate: any;
                licenseEndDate: any;
                createdAt: any;
                lastCustomer?: {
                  __typename?: 'Customer';
                  _id: string;
                  salesChannelId: string;
                  lastAssigneeId: string;
                  creditCheckCode: number;
                  fullName?: string | null;
                  phoneNumber?: string | null;
                  personId: string;
                  email?: string | null;
                  address?: string | null;
                  zipCode?: string | null;
                  coAddress?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  salesChannel: {
                    __typename?: 'SalesChannel';
                    _id: string;
                    projectId: string;
                    name: string;
                    description?: string | null;
                    status: SalesChannelStatus;
                    financeSystem: FinanceSystem;
                    createdAt: any;
                    updatedAt: any;
                    project: {
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    };
                  };
                  lastAssignee: {
                    __typename?: 'TeamMember';
                    parentTeamMemberId?: string | null;
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    parentTeamMember?: {
                      __typename?: 'TeamMember';
                      _id: string;
                      personId: string;
                      teamId: string;
                      roleId: string;
                      fullName?: string | null;
                      email?: string | null;
                      phoneNumber?: string | null;
                      status: TeamMemberStatus;
                      searchHistoryCount: number;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      projects: Array<{
                        __typename?: 'TeamMemberProject';
                        projectId: string;
                        projectName: string;
                        salesChannelId?: string | null;
                      }>;
                      role: {
                        __typename?: 'Role';
                        _id: string;
                        permissionIds: Array<string>;
                        teamId: string;
                        parentRoleId?: string | null;
                        name: string;
                        onlyTeamData: boolean;
                        onlySalesChannelData: boolean;
                        onlyOwnAndChildData: boolean;
                        canSeeOtherSearchHistory: boolean;
                        searchRestriction: number;
                        isSuper: boolean;
                        description?: string | null;
                        createdAt: any;
                        updatedAt: any;
                        team: {
                          __typename?: 'Team';
                          _id: string;
                          name: string;
                          rolesCount: number;
                          teamMembersCount: number;
                          description?: string | null;
                          projectIds: Array<string>;
                          createdAt: any;
                          updatedAt: any;
                          projects: Array<{
                            __typename?: 'Project';
                            _id: string;
                            name: string;
                            description?: string | null;
                            productsCount: number;
                            createdAt: any;
                            updatedAt: any;
                          }>;
                        };
                        parentRole?: {
                          __typename?: 'Role';
                          _id: string;
                          teamId: string;
                          name: string;
                        } | null;
                      };
                    } | null;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  };
                } | null;
                lastAssignee?: {
                  __typename?: 'TeamMember';
                  parentTeamMemberId?: string | null;
                  _id: string;
                  personId: string;
                  teamId: string;
                  roleId: string;
                  fullName?: string | null;
                  email?: string | null;
                  phoneNumber?: string | null;
                  status: TeamMemberStatus;
                  searchHistoryCount: number;
                  createdAt: any;
                  updatedAt: any;
                  parentTeamMember?: {
                    __typename?: 'TeamMember';
                    _id: string;
                    personId: string;
                    teamId: string;
                    roleId: string;
                    fullName?: string | null;
                    email?: string | null;
                    phoneNumber?: string | null;
                    status: TeamMemberStatus;
                    searchHistoryCount: number;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    projects: Array<{
                      __typename?: 'TeamMemberProject';
                      projectId: string;
                      projectName: string;
                      salesChannelId?: string | null;
                    }>;
                    role: {
                      __typename?: 'Role';
                      _id: string;
                      permissionIds: Array<string>;
                      teamId: string;
                      parentRoleId?: string | null;
                      name: string;
                      onlyTeamData: boolean;
                      onlySalesChannelData: boolean;
                      onlyOwnAndChildData: boolean;
                      canSeeOtherSearchHistory: boolean;
                      searchRestriction: number;
                      isSuper: boolean;
                      description?: string | null;
                      createdAt: any;
                      updatedAt: any;
                      team: {
                        __typename?: 'Team';
                        _id: string;
                        name: string;
                        rolesCount: number;
                        teamMembersCount: number;
                        description?: string | null;
                        projectIds: Array<string>;
                        createdAt: any;
                        updatedAt: any;
                        projects: Array<{
                          __typename?: 'Project';
                          _id: string;
                          name: string;
                          description?: string | null;
                          productsCount: number;
                          createdAt: any;
                          updatedAt: any;
                        }>;
                      };
                      parentRole?: {
                        __typename?: 'Role';
                        _id: string;
                        teamId: string;
                        name: string;
                      } | null;
                    };
                  } | null;
                  team: {
                    __typename?: 'Team';
                    _id: string;
                    name: string;
                    rolesCount: number;
                    teamMembersCount: number;
                    description?: string | null;
                    projectIds: Array<string>;
                    createdAt: any;
                    updatedAt: any;
                    projects: Array<{
                      __typename?: 'Project';
                      _id: string;
                      name: string;
                      description?: string | null;
                      productsCount: number;
                      createdAt: any;
                      updatedAt: any;
                    }>;
                  };
                  projects: Array<{
                    __typename?: 'TeamMemberProject';
                    projectId: string;
                    projectName: string;
                    salesChannelId?: string | null;
                  }>;
                  role: {
                    __typename?: 'Role';
                    _id: string;
                    permissionIds: Array<string>;
                    teamId: string;
                    parentRoleId?: string | null;
                    name: string;
                    onlyTeamData: boolean;
                    onlySalesChannelData: boolean;
                    onlyOwnAndChildData: boolean;
                    canSeeOtherSearchHistory: boolean;
                    searchRestriction: number;
                    isSuper: boolean;
                    description?: string | null;
                    createdAt: any;
                    updatedAt: any;
                    team: {
                      __typename?: 'Team';
                      _id: string;
                      name: string;
                      rolesCount: number;
                      teamMembersCount: number;
                      description?: string | null;
                      projectIds: Array<string>;
                      createdAt: any;
                      updatedAt: any;
                      projects: Array<{
                        __typename?: 'Project';
                        _id: string;
                        name: string;
                        description?: string | null;
                        productsCount: number;
                        createdAt: any;
                        updatedAt: any;
                      }>;
                    };
                    parentRole?: {
                      __typename?: 'Role';
                      _id: string;
                      teamId: string;
                      name: string;
                    } | null;
                  };
                } | null;
                relation?: {
                  __typename?: 'Relation';
                  _id: string;
                  projectId: string;
                  status: RelationStatus;
                  name: string;
                  description?: string | null;
                  isOwn: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                } | null;
                product: {
                  __typename?: 'Product';
                  _id: string;
                  projectId: string;
                  name: string;
                  nameInContract: string;
                  description?: string | null;
                  status: ProductStatus;
                  price: number;
                  vatRate: number;
                  vatPrice: number;
                  freeMonths: number;
                  licenseDurationMonths?: number | null;
                  isSubscription: boolean;
                  canBeTerminated: boolean;
                  unitId: string;
                  isCreditCheckRequired: boolean;
                  isPersonIdRequired: boolean;
                  isStockable: boolean;
                  isInStok: boolean;
                  hasCommissions: boolean;
                  createdAt: any;
                  updatedAt: any;
                  project: {
                    __typename?: 'Project';
                    _id: string;
                    name: string;
                    description?: string | null;
                    productsCount: number;
                    createdAt: any;
                    updatedAt: any;
                  };
                  gallery: Array<{ __typename?: 'FileNode'; url: string }>;
                  productAddons: Array<{
                    __typename?: 'ProductAddon';
                    _id: string;
                    name: string;
                    vatPrice: number;
                    status: ProductAddonStatus;
                    isPersonIdRequired: boolean;
                    isCreditCheckRequired: boolean;
                    isSubscription: boolean;
                  }>;
                };
                licenses: Array<{
                  __typename?: 'License';
                  productAddon?: { __typename?: 'ProductAddon'; name: string } | null;
                }>;
              };
            } | null;
          }>;
        }>;
      }> | null;
    } | null;
    errors?: Array<{ __typename?: 'MutationError'; field?: string | null; code: string }> | null;
  };
};

export const ProductAddonsFilterFragmentDoc = gql`
  fragment ProductAddonsFilter on ProductAddon {
    _id
    name
  }
`;
export const ProjectFragmentDoc = gql`
  fragment Project on Project {
    _id
    name
    description
    productsCount
    createdAt
    updatedAt
  }
`;
export const ProductAddonOrderFragmentDoc = gql`
  fragment ProductAddonOrder on ProductAddon {
    _id
    name
    vatPrice
    status
    isPersonIdRequired
    isCreditCheckRequired
    isSubscription
  }
`;
export const ProductFragmentDoc = gql`
  fragment Product on Product {
    _id
    project {
      ...Project
    }
    projectId
    name
    nameInContract
    description
    status
    price
    vatRate
    vatPrice
    freeMonths
    licenseDurationMonths
    isSubscription
    canBeTerminated
    unitId
    isCreditCheckRequired
    isPersonIdRequired
    isStockable
    isInStok
    gallery {
      url
    }
    productAddons {
      ...ProductAddonOrder
    }
    hasCommissions
    createdAt
    updatedAt
  }
  ${ProjectFragmentDoc}
  ${ProductAddonOrderFragmentDoc}
`;
export const ProductAddonFragmentDoc = gql`
  fragment ProductAddon on ProductAddon {
    _id
    project {
      ...Project
    }
    projectId
    product {
      ...Product
    }
    productId
    name
    status
    price
    vatRate
    vatPrice
    freeMonths
    licenseDurationMonths
    isSubscription
    unitId
    isCreditCheckRequired
    isStockable
    isInStok
    createdAt
    updatedAt
    isPersonIdRequired
    canBeTerminated
  }
  ${ProjectFragmentDoc}
  ${ProductFragmentDoc}
`;
export const TeamFragmentDoc = gql`
  fragment Team on Team {
    _id
    name
    rolesCount
    teamMembersCount
    description
    projectIds
    projects {
      ...Project
    }
    createdAt
    updatedAt
  }
  ${ProjectFragmentDoc}
`;
export const RoleFragmentDoc = gql`
  fragment Role on Role {
    _id
    permissionIds
    teamId
    team {
      ...Team
    }
    parentRoleId
    parentRole {
      _id
      teamId
      name
    }
    name
    onlyTeamData
    onlySalesChannelData
    onlyOwnAndChildData
    canSeeOtherSearchHistory
    searchRestriction
    isSuper
    description
    createdAt
    updatedAt
  }
  ${TeamFragmentDoc}
`;
export const SalesChannelFragmentDoc = gql`
  fragment SalesChannel on SalesChannel {
    _id
    projectId
    project {
      ...Project
    }
    name
    description
    status
    financeSystem
    createdAt
    updatedAt
    financeSystem
  }
  ${ProjectFragmentDoc}
`;
export const BasicTeamMemberFragmentDoc = gql`
  fragment BasicTeamMember on TeamMember {
    _id
    personId
    teamId
    team {
      ...Team
    }
    projects {
      projectId
      projectName
      salesChannelId
    }
    role {
      ...Role
    }
    roleId
    fullName
    personId
    email
    phoneNumber
    status
    searchHistoryCount
    createdAt
    updatedAt
  }
  ${TeamFragmentDoc}
  ${RoleFragmentDoc}
`;
export const TeamMemberFragmentDoc = gql`
  fragment TeamMember on TeamMember {
    parentTeamMemberId
    parentTeamMember {
      ...BasicTeamMember
    }
    ...BasicTeamMember
  }
  ${BasicTeamMemberFragmentDoc}
`;
export const AddonCommissionFragmentDoc = gql`
  fragment AddonCommission on AddonCommission {
    _id
    productAddonId
    productAddon {
      ...ProductAddon
    }
    teamId
    team {
      ...Team
    }
    roleId
    role {
      ...Role
    }
    salesChannelId
    salesChannel {
      ...SalesChannel
    }
    teamMemberId
    teamMember {
      ...TeamMember
    }
    authorId
    author {
      ...TeamMember
    }
    size
    qty
    status
    createdAt
    updatedAt
  }
  ${ProductAddonFragmentDoc}
  ${TeamFragmentDoc}
  ${RoleFragmentDoc}
  ${SalesChannelFragmentDoc}
  ${TeamMemberFragmentDoc}
`;
export const PartnerStoreFragmentDoc = gql`
  fragment PartnerStore on PartnerStore {
    _id
    projectId
    project {
      ...Project
    }
    name
    address
    phoneNumber
    contactPerson
    email
    linkToSite
    totalLicenseCount
    activatedLicenseCount
    description
    createdAt
    updatedAt
  }
  ${ProjectFragmentDoc}
`;
export const BatchFragmentDoc = gql`
  fragment Batch on LicenseBatch {
    _id
    productId
    product {
      ...Product
    }
    partnerStoreId
    partnerStore {
      ...PartnerStore
    }
    duration
    licenseCount
    activatedLicenseCount
    zipReady
    zipLink
    createdAt
    updatedAt
  }
  ${ProductFragmentDoc}
  ${PartnerStoreFragmentDoc}
`;
export const BundleFragmentDoc = gql`
  fragment Bundle on ProductBundle {
    _id
    project {
      ...Project
    }
    projectId
    products {
      productId
      qty
      product {
        name
        status
        price
        vatRate
        vatPrice
        freeMonths
        isCreditCheckRequired
        licenseDurationMonths
        isPersonIdRequired
        isSubscription
        productAddons {
          ...ProductAddonOrder
        }
      }
    }
    name
    nameInContract
    status
    price
    vatRate
    vatPrice
    createdAt
    updatedAt
  }
  ${ProjectFragmentDoc}
  ${ProductAddonOrderFragmentDoc}
`;
export const BundleCommissionFragmentDoc = gql`
  fragment BundleCommission on BundleCommission {
    _id
    productBundleId
    productBundle {
      ...Bundle
    }
    teamId
    team {
      ...Team
    }
    roleId
    role {
      ...Role
    }
    salesChannelId
    salesChannel {
      ...SalesChannel
    }
    teamMemberId
    teamMember {
      ...TeamMember
    }
    authorId
    author {
      ...TeamMember
    }
    size
    status
    createdAt
    updatedAt
  }
  ${BundleFragmentDoc}
  ${TeamFragmentDoc}
  ${RoleFragmentDoc}
  ${SalesChannelFragmentDoc}
  ${TeamMemberFragmentDoc}
`;
export const CommissionFragmentDoc = gql`
  fragment Commission on Commission {
    _id
    productId
    product {
      ...Product
    }
    teamId
    team {
      ...Team
    }
    roleId
    role {
      ...Role
    }
    salesChannelId
    salesChannel {
      ...SalesChannel
    }
    teamMemberId
    teamMember {
      ...TeamMember
    }
    authorId
    author {
      ...TeamMember
    }
    qty
    size
    status
    createdAt
    updatedAt
  }
  ${ProductFragmentDoc}
  ${TeamFragmentDoc}
  ${RoleFragmentDoc}
  ${SalesChannelFragmentDoc}
  ${TeamMemberFragmentDoc}
`;
export const ContractStatusHistoryItemFragmentDoc = gql`
  fragment ContractStatusHistoryItem on ContractStatusHistoryItem {
    orderId
    status
    createdAt
    scriveContractId
    scriveContractStatus
  }
`;
export const B2BContractStatusHistoryItemFragmentDoc = gql`
  fragment B2BContractStatusHistoryItem on B2BContractStatusHistoryItem {
    orderId
    status
    createdAt
    scriveContractId
    scriveContractStatus
  }
`;
export const CustomerFragmentDoc = gql`
  fragment Customer on Customer {
    _id
    salesChannelId
    salesChannel {
      ...SalesChannel
    }
    lastAssigneeId
    lastAssignee {
      ...TeamMember
    }
    creditCheckCode
    fullName
    phoneNumber
    personId
    email
    address
    zipCode
    coAddress
    updatedAt
    createdAt
  }
  ${SalesChannelFragmentDoc}
  ${TeamMemberFragmentDoc}
`;
export const RelationFragmentDoc = gql`
  fragment Relation on Relation {
    _id
    projectId
    project {
      ...Project
    }
    status
    name
    description
    isOwn
    createdAt
    updatedAt
  }
  ${ProjectFragmentDoc}
`;
export const ProductDiscountFragmentDoc = gql`
  fragment ProductDiscount on ProductDiscount {
    _id
    productId
    product {
      ...Product
    }
    teamId
    team {
      ...Team
    }
    roleId
    role {
      ...Role
    }
    teamMemberId
    teamMember {
      ...TeamMember
    }
    salesChannelId
    salesChannel {
      ...SalesChannel
    }
    authorId
    author {
      ...TeamMember
    }
    qty
    discount
    status
    productPrice
    productVatPrice
    createdAt
    updatedAt
  }
  ${ProductFragmentDoc}
  ${TeamFragmentDoc}
  ${RoleFragmentDoc}
  ${TeamMemberFragmentDoc}
  ${SalesChannelFragmentDoc}
`;
export const ExternalUserFragmentDoc = gql`
  fragment ExternalUser on ExternalUser {
    _id
    lastCustomerId
    lastCustomer {
      ...Customer
    }
    lastAssigneeId
    lastAssignee {
      ...TeamMember
    }
    relationId
    relation {
      ...Relation
    }
    productId
    product {
      ...Product
    }
    fullName
    phoneNumber
    personId
    address
    licenses {
      productAddon {
        name
      }
    }
    licenseStartDate
    licenseEndDate
    createdAt
  }
  ${CustomerFragmentDoc}
  ${TeamMemberFragmentDoc}
  ${RelationFragmentDoc}
  ${ProductFragmentDoc}
`;
export const OrderItemFragmentDoc = gql`
  fragment OrderItem on OrderItem {
    relationId
    relation {
      ...Relation
    }
    productId
    product {
      ...Product
    }
    productAddons {
      productAddon {
        ...ProductAddon
      }
      license {
        status
      }
    }
    discountId
    discount {
      ...ProductDiscount
    }
    license {
      _id
      status
      product {
        ...Product
      }
      externalUser {
        ...ExternalUser
      }
      licenseEndDate
    }
    discountAmount
    discountPercent
    qty
    postponeInvoicing
    personId
    fullName
    subtotal
    total
    addonsPrice
    phoneNumber
  }
  ${RelationFragmentDoc}
  ${ProductFragmentDoc}
  ${ProductAddonFragmentDoc}
  ${ProductDiscountFragmentDoc}
  ${ExternalUserFragmentDoc}
`;
export const OrderUpgradeItemFragmentDoc = gql`
  fragment OrderUpgradeItem on OrderUpgradeItem {
    licenseId
    license {
      _id
      orderItem {
        ...OrderItem
      }
      product {
        ...Product
      }
      externalUser {
        ...ExternalUser
      }
      status
      licenseEndDate
    }
    productAddons {
      productAddon {
        ...ProductAddon
      }
      license {
        status
      }
    }
    total
  }
  ${OrderItemFragmentDoc}
  ${ProductFragmentDoc}
  ${ExternalUserFragmentDoc}
  ${ProductAddonFragmentDoc}
`;
export const OrderFragmentDoc = gql`
  fragment Order on Order {
    _id
    salesChannelId
    salesChannel {
      ...SalesChannel
    }
    projectId
    project {
      ...Project
    }
    customerId
    customer {
      ...Customer
    }
    assigneeId
    assignee {
      ...TeamMember
    }
    resolvedById
    resolvedBy {
      ...TeamMember
    }
    isCreditCheckReceived
    isActiveCustomer
    financeSystemSyncStatus
    financeSystem
    creditCheckCode
    fullName
    email
    phoneNumber
    personId
    status
    items {
      ...OrderItem
    }
    bundles {
      productBundleId
      postponeInvoicing
      total
      subtotal
      addonsPrice
      productBundle {
        ...Bundle
      }
      items {
        ...OrderItem
      }
    }
    upgrades {
      ...OrderUpgradeItem
    }
    coAddress
    invoicingAddress
    invoicingType
    invoicingZip
    invoicingFrequency
    customerAddress
    customerZip
    subtotal
    total
    monthlyTotal
    onePaymentTotal
    discountPercent
    discountAmount
    monthlySubtotal
    quarterSubtotal
    fullTotalWithAddons
    regretReason
    regretOption
    shippingStatus
    scriveContractStatus
    scriveContractId
    isUnsigned
    isRegrettable
    isRegrettableDate
    isDeleted
    isWinBack
    isFromShopPage
    soSafeCoupon {
      code
      amount
      type
    }
    updatedAt
    createdAt
    contractStatus
    contractSignedDate
    sourceOrders {
      _id
      fullTotalWithAddons
      monthlySubtotal
      items {
        ...OrderItem
      }
      bundles {
        productBundleId
        postponeInvoicing
        total
        subtotal
        addonsPrice
        productBundle {
          ...Bundle
        }
        items {
          ...OrderItem
        }
      }
    }
    winBackPrice
    winBackDuration
  }
  ${SalesChannelFragmentDoc}
  ${ProjectFragmentDoc}
  ${CustomerFragmentDoc}
  ${TeamMemberFragmentDoc}
  ${OrderItemFragmentDoc}
  ${BundleFragmentDoc}
  ${OrderUpgradeItemFragmentDoc}
`;
export const LicenseFragmentDoc = gql`
  fragment License on License {
    _id
    productId
    product {
      ...Product
    }
    productAddon {
      ...ProductAddon
    }
    orderId
    order {
      ...Order
    }
    orderItemId
    orderItem {
      ...OrderItem
    }
    customerId
    customer {
      ...Customer
    }
    externalUserId
    externalUser {
      ...ExternalUser
    }
    status
    licenseStartDate
    licenseEndDate
    createdAt
    updatedAt
    financeSystemSyncStatus
  }
  ${ProductFragmentDoc}
  ${ProductAddonFragmentDoc}
  ${OrderFragmentDoc}
  ${OrderItemFragmentDoc}
  ${CustomerFragmentDoc}
  ${ExternalUserFragmentDoc}
`;
export const PurchasedLicenseFragmentDoc = gql`
  fragment PurchasedLicense on PurchasedLicense {
    _id
    createdAt
    signedAt
    willEndAt
    relationId
    productId
    product {
      _id
      name
      productAddons {
        _id
        isCreditCheckRequired
        isPersonIdRequired
      }
    }
    relation {
      name
    }
    externalUserId
    externalUser {
      fullName
      personId
      phoneNumber
    }
    orderId
    order {
      project {
        name
      }
    }
    status
    daysLeft
    isFromBundle
    availableProductAddons {
      _id
      name
      vatPrice
      isCreditCheckRequired
      isPersonIdRequired
    }
    purchasedProductAddons {
      _id
      name
      vatPrice
    }
    postponeInvoicing
    discountPercent
    subtotal
    total
  }
`;
export const NoteFragmentDoc = gql`
  fragment Note on Note {
    _id
    entityType
    entityId
    authorId
    author {
      ...TeamMember
    }
    content
    createdAt
  }
  ${TeamMemberFragmentDoc}
`;
export const OrderStatusHistoryItemFragmentDoc = gql`
  fragment OrderStatusHistoryItem on OrderStatusHistoryItem {
    orderId
    status
    createdAt
    regretReason
    regretOption
    winbackInfo {
      oldContractValue
      oldDuration
      newContractValue
      newDuration
      itemChanges {
        type
        productId
        product {
          name
        }
        productBundleId
        productBundle {
          name
        }
        productAddonId
        productAddon {
          name
        }
        oldQty
        newQty
      }
    }
    byTeamMember {
      ...TeamMember
    }
  }
  ${TeamMemberFragmentDoc}
`;
export const B2BOrderStatusHistoryItemFragmentDoc = gql`
  fragment B2BOrderStatusHistoryItem on B2BOrderStatusHistoryItem {
    orderId
    status
    createdAt
    byTeamMember {
      ...TeamMember
    }
  }
  ${TeamMemberFragmentDoc}
`;
export const OrderItemCalculationFragmentDoc = gql`
  fragment OrderItemCalculation on OrderItemCalculation {
    itemKey
    discountAmount
    discountPercent
    qty
    subtotal
    total
    addonsPrice
  }
`;
export const OrderCalculationFragmentDoc = gql`
  fragment OrderCalculation on OrderCalculation {
    items {
      ...OrderItemCalculation
    }
    bundles {
      productBundleId
      itemKey
      subtotal
      addonsPrice
      total
      items {
        ...OrderItemCalculation
      }
    }
    upgrades {
      itemKey
      subtotal
      total
    }
    subtotal
    total
    onePaymentTotal
    monthlyTotal
    fullTotal
    discountPercent
    discountAmount
    monthlySubtotal
    quarterSubtotal
    fullTotalWithAddons
    soSafeCoupon {
      code
      amount
      type
    }
  }
  ${OrderItemCalculationFragmentDoc}
`;
export const B2BCustomerFragmentDoc = gql`
  fragment B2BCustomer on B2BCustomer {
    _id
    lastAssigneeId
    lastAssignee {
      ...TeamMember
    }
    creditCheckCode
    fullName
    phoneNumber
    orgId
    email
    updatedAt
    createdAt
  }
  ${TeamMemberFragmentDoc}
`;
export const B2BOrderFragmentDoc = gql`
  fragment B2BOrder on B2BOrder {
    _id
    projectId
    project {
      ...Project
    }
    customerId
    customer {
      ...B2BCustomer
    }
    assigneeId
    assignee {
      ...TeamMember
    }
    resolvedById
    resolvedBy {
      ...TeamMember
    }
    items {
      productId
      qty
      duration
      monthlyPricePerOneLicense
      monthlyPricePerAllLicenses
      discountAmount
    }
    contactPerson
    isCreditCheckReceived
    creditCheckCode
    companyName
    email
    phoneNumber
    orgId
    status
    deliveryAddress
    coAddress
    invoicingAddress
    invoicingZip
    invoicingFrequency
    total
    monthlyTotal
    discountAmount
    shippingStatus
    scriveContractStatus
    scriveContractId
    isUnsigned
    updatedAt
    createdAt
  }
  ${ProjectFragmentDoc}
  ${B2BCustomerFragmentDoc}
  ${TeamMemberFragmentDoc}
`;
export const PartnerStoresFilterFragmentDoc = gql`
  fragment PartnerStoresFilter on PartnerStore {
    _id
    name
  }
`;
export const ProductsFilterFragmentDoc = gql`
  fragment ProductsFilter on Product {
    _id
    name
  }
`;
export const ProjectsFilterFragmentDoc = gql`
  fragment ProjectsFilter on Project {
    _id
    name
  }
`;
export const RolesFilterFragmentDoc = gql`
  fragment RolesFilter on Role {
    _id
    name
  }
`;
export const SalesChannelsFilterFragmentDoc = gql`
  fragment SalesChannelsFilter on SalesChannel {
    _id
    name
  }
`;
export const SalesHistoryItemFragmentDoc = gql`
  fragment SalesHistoryItem on Order {
    _id
    salesChannelId
    salesChannel {
      ...SalesChannel
    }
    customerId
    customer {
      ...Customer
    }
    projectId
    project {
      ...Project
    }
    assigneeId
    assignee {
      ...TeamMember
    }
    bundles {
      productBundle {
        name
      }
      items {
        productAddons {
          productAddon {
            name
          }
        }
      }
    }
    items {
      qty
      product {
        name
      }
      productAddons {
        productAddon {
          name
        }
      }
    }
    upgrades {
      productAddons {
        productAddon {
          name
        }
      }
    }
    subtotal
    total
    commissionGross
    commissionNet
    commissionRegret
    createdAt
    contractSignedDate
  }
  ${SalesChannelFragmentDoc}
  ${CustomerFragmentDoc}
  ${ProjectFragmentDoc}
  ${TeamMemberFragmentDoc}
`;
export const SearchHistoryItemFragmentDoc = gql`
  fragment SearchHistoryItem on SearchHistoryItem {
    _id
    personId
    createdAt
  }
`;
export const TeamMembersFilterFragmentDoc = gql`
  fragment TeamMembersFilter on TeamMember {
    _id
    fullName
  }
`;
export const CreateProductAddonDocument = gql`
  mutation CreateProductAddon($input: CreateProductAddonInput!) {
    createProductAddon(input: $input) {
      status
      node {
        ...ProductAddon
      }
      errors {
        field
        code
      }
    }
  }
  ${ProductAddonFragmentDoc}
`;
export type CreateProductAddonMutationFn = Apollo.MutationFunction<
  CreateProductAddonMutation,
  CreateProductAddonMutationVariables
>;

/**
 * __useCreateProductAddonMutation__
 *
 * To run a mutation, you first call `useCreateProductAddonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductAddonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductAddonMutation, { data, loading, error }] = useCreateProductAddonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductAddonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProductAddonMutation,
    CreateProductAddonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProductAddonMutation, CreateProductAddonMutationVariables>(
    CreateProductAddonDocument,
    options,
  );
}
export type CreateProductAddonMutationHookResult = ReturnType<typeof useCreateProductAddonMutation>;
export type CreateProductAddonMutationResult = Apollo.MutationResult<CreateProductAddonMutation>;
export type CreateProductAddonMutationOptions = Apollo.BaseMutationOptions<
  CreateProductAddonMutation,
  CreateProductAddonMutationVariables
>;
export const UpdateProductAddonDocument = gql`
  mutation UpdateProductAddon($input: UpdateProductAddonInput!) {
    updateProductAddon(input: $input) {
      status
      node {
        ...ProductAddon
      }
      errors {
        field
        code
      }
    }
  }
  ${ProductAddonFragmentDoc}
`;
export type UpdateProductAddonMutationFn = Apollo.MutationFunction<
  UpdateProductAddonMutation,
  UpdateProductAddonMutationVariables
>;

/**
 * __useUpdateProductAddonMutation__
 *
 * To run a mutation, you first call `useUpdateProductAddonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductAddonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductAddonMutation, { data, loading, error }] = useUpdateProductAddonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductAddonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductAddonMutation,
    UpdateProductAddonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductAddonMutation, UpdateProductAddonMutationVariables>(
    UpdateProductAddonDocument,
    options,
  );
}
export type UpdateProductAddonMutationHookResult = ReturnType<typeof useUpdateProductAddonMutation>;
export type UpdateProductAddonMutationResult = Apollo.MutationResult<UpdateProductAddonMutation>;
export type UpdateProductAddonMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductAddonMutation,
  UpdateProductAddonMutationVariables
>;
export const DeleteProductAddonDocument = gql`
  mutation DeleteProductAddon($input: DeleteProductAddonInput!) {
    deleteProductAddon(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteProductAddonMutationFn = Apollo.MutationFunction<
  DeleteProductAddonMutation,
  DeleteProductAddonMutationVariables
>;

/**
 * __useDeleteProductAddonMutation__
 *
 * To run a mutation, you first call `useDeleteProductAddonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductAddonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductAddonMutation, { data, loading, error }] = useDeleteProductAddonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductAddonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProductAddonMutation,
    DeleteProductAddonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProductAddonMutation, DeleteProductAddonMutationVariables>(
    DeleteProductAddonDocument,
    options,
  );
}
export type DeleteProductAddonMutationHookResult = ReturnType<typeof useDeleteProductAddonMutation>;
export type DeleteProductAddonMutationResult = Apollo.MutationResult<DeleteProductAddonMutation>;
export type DeleteProductAddonMutationOptions = Apollo.BaseMutationOptions<
  DeleteProductAddonMutation,
  DeleteProductAddonMutationVariables
>;
export const GetProductAddonsDocument = gql`
  query getProductAddons(
    $filter: ProductAddonsFilterInput
    $skip: Int
    $limit: Int
    $of: ProductAddonsOrderBy
    $od: OrderDirection
  ) {
    getProductAddons(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...ProductAddon
      }
      count
    }
  }
  ${ProductAddonFragmentDoc}
`;

/**
 * __useGetProductAddonsQuery__
 *
 * To run a query within a React component, call `useGetProductAddonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductAddonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductAddonsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetProductAddonsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProductAddonsQuery, GetProductAddonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductAddonsQuery, GetProductAddonsQueryVariables>(
    GetProductAddonsDocument,
    options,
  );
}
export function useGetProductAddonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductAddonsQuery, GetProductAddonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductAddonsQuery, GetProductAddonsQueryVariables>(
    GetProductAddonsDocument,
    options,
  );
}
export type GetProductAddonsQueryHookResult = ReturnType<typeof useGetProductAddonsQuery>;
export type GetProductAddonsLazyQueryHookResult = ReturnType<typeof useGetProductAddonsLazyQuery>;
export type GetProductAddonsQueryResult = Apollo.QueryResult<
  GetProductAddonsQuery,
  GetProductAddonsQueryVariables
>;
export const GetProductAddonDocument = gql`
  query getProductAddon($_id: ID!) {
    getProductAddon(_id: $_id) {
      ...ProductAddon
    }
  }
  ${ProductAddonFragmentDoc}
`;

/**
 * __useGetProductAddonQuery__
 *
 * To run a query within a React component, call `useGetProductAddonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductAddonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductAddonQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetProductAddonQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductAddonQuery, GetProductAddonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductAddonQuery, GetProductAddonQueryVariables>(
    GetProductAddonDocument,
    options,
  );
}
export function useGetProductAddonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductAddonQuery, GetProductAddonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductAddonQuery, GetProductAddonQueryVariables>(
    GetProductAddonDocument,
    options,
  );
}
export type GetProductAddonQueryHookResult = ReturnType<typeof useGetProductAddonQuery>;
export type GetProductAddonLazyQueryHookResult = ReturnType<typeof useGetProductAddonLazyQuery>;
export type GetProductAddonQueryResult = Apollo.QueryResult<
  GetProductAddonQuery,
  GetProductAddonQueryVariables
>;
export const GetProductAddonsFilterDocument = gql`
  query getProductAddonsFilter(
    $filter: ProductAddonsFilterInput
    $skip: Int
    $limit: Int
    $of: ProductAddonsOrderBy
    $od: OrderDirection
  ) {
    getProductAddons(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...ProductAddonsFilter
      }
      count
    }
  }
  ${ProductAddonsFilterFragmentDoc}
`;

/**
 * __useGetProductAddonsFilterQuery__
 *
 * To run a query within a React component, call `useGetProductAddonsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductAddonsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductAddonsFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetProductAddonsFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductAddonsFilterQuery,
    GetProductAddonsFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductAddonsFilterQuery, GetProductAddonsFilterQueryVariables>(
    GetProductAddonsFilterDocument,
    options,
  );
}
export function useGetProductAddonsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductAddonsFilterQuery,
    GetProductAddonsFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductAddonsFilterQuery, GetProductAddonsFilterQueryVariables>(
    GetProductAddonsFilterDocument,
    options,
  );
}
export type GetProductAddonsFilterQueryHookResult = ReturnType<
  typeof useGetProductAddonsFilterQuery
>;
export type GetProductAddonsFilterLazyQueryHookResult = ReturnType<
  typeof useGetProductAddonsFilterLazyQuery
>;
export type GetProductAddonsFilterQueryResult = Apollo.QueryResult<
  GetProductAddonsFilterQuery,
  GetProductAddonsFilterQueryVariables
>;
export const CreateAddonCommissionDocument = gql`
  mutation createAddonCommission($input: CreateAddonCommissionInput!) {
    createAddonCommission(input: $input) {
      status
      node {
        ...AddonCommission
      }
      errors {
        field
        code
      }
    }
  }
  ${AddonCommissionFragmentDoc}
`;
export type CreateAddonCommissionMutationFn = Apollo.MutationFunction<
  CreateAddonCommissionMutation,
  CreateAddonCommissionMutationVariables
>;

/**
 * __useCreateAddonCommissionMutation__
 *
 * To run a mutation, you first call `useCreateAddonCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddonCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddonCommissionMutation, { data, loading, error }] = useCreateAddonCommissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAddonCommissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAddonCommissionMutation,
    CreateAddonCommissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAddonCommissionMutation, CreateAddonCommissionMutationVariables>(
    CreateAddonCommissionDocument,
    options,
  );
}
export type CreateAddonCommissionMutationHookResult = ReturnType<
  typeof useCreateAddonCommissionMutation
>;
export type CreateAddonCommissionMutationResult =
  Apollo.MutationResult<CreateAddonCommissionMutation>;
export type CreateAddonCommissionMutationOptions = Apollo.BaseMutationOptions<
  CreateAddonCommissionMutation,
  CreateAddonCommissionMutationVariables
>;
export const UpdateAddonCommissionDocument = gql`
  mutation updateAddonCommission($input: UpdateAddonCommissionInput!) {
    updateAddonCommission(input: $input) {
      status
      node {
        ...AddonCommission
      }
      errors {
        field
        code
      }
    }
  }
  ${AddonCommissionFragmentDoc}
`;
export type UpdateAddonCommissionMutationFn = Apollo.MutationFunction<
  UpdateAddonCommissionMutation,
  UpdateAddonCommissionMutationVariables
>;

/**
 * __useUpdateAddonCommissionMutation__
 *
 * To run a mutation, you first call `useUpdateAddonCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddonCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddonCommissionMutation, { data, loading, error }] = useUpdateAddonCommissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddonCommissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAddonCommissionMutation,
    UpdateAddonCommissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAddonCommissionMutation, UpdateAddonCommissionMutationVariables>(
    UpdateAddonCommissionDocument,
    options,
  );
}
export type UpdateAddonCommissionMutationHookResult = ReturnType<
  typeof useUpdateAddonCommissionMutation
>;
export type UpdateAddonCommissionMutationResult =
  Apollo.MutationResult<UpdateAddonCommissionMutation>;
export type UpdateAddonCommissionMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddonCommissionMutation,
  UpdateAddonCommissionMutationVariables
>;
export const DeleteAddonCommissionDocument = gql`
  mutation deleteAddonCommission($input: DeleteAddonCommissionInput!) {
    deleteAddonCommission(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteAddonCommissionMutationFn = Apollo.MutationFunction<
  DeleteAddonCommissionMutation,
  DeleteAddonCommissionMutationVariables
>;

/**
 * __useDeleteAddonCommissionMutation__
 *
 * To run a mutation, you first call `useDeleteAddonCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddonCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddonCommissionMutation, { data, loading, error }] = useDeleteAddonCommissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAddonCommissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAddonCommissionMutation,
    DeleteAddonCommissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAddonCommissionMutation, DeleteAddonCommissionMutationVariables>(
    DeleteAddonCommissionDocument,
    options,
  );
}
export type DeleteAddonCommissionMutationHookResult = ReturnType<
  typeof useDeleteAddonCommissionMutation
>;
export type DeleteAddonCommissionMutationResult =
  Apollo.MutationResult<DeleteAddonCommissionMutation>;
export type DeleteAddonCommissionMutationOptions = Apollo.BaseMutationOptions<
  DeleteAddonCommissionMutation,
  DeleteAddonCommissionMutationVariables
>;
export const GetAddonCommissionsDocument = gql`
  query getAddonCommissions(
    $filter: AddonCommissionsFilterInput
    $skip: Int
    $limit: Int
    $of: AddonCommissionsOrderBy
    $od: OrderDirection
  ) {
    getAddonCommissions(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...AddonCommission
      }
      count
    }
  }
  ${AddonCommissionFragmentDoc}
`;

/**
 * __useGetAddonCommissionsQuery__
 *
 * To run a query within a React component, call `useGetAddonCommissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddonCommissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddonCommissionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetAddonCommissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAddonCommissionsQuery,
    GetAddonCommissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAddonCommissionsQuery, GetAddonCommissionsQueryVariables>(
    GetAddonCommissionsDocument,
    options,
  );
}
export function useGetAddonCommissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddonCommissionsQuery,
    GetAddonCommissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAddonCommissionsQuery, GetAddonCommissionsQueryVariables>(
    GetAddonCommissionsDocument,
    options,
  );
}
export type GetAddonCommissionsQueryHookResult = ReturnType<typeof useGetAddonCommissionsQuery>;
export type GetAddonCommissionsLazyQueryHookResult = ReturnType<
  typeof useGetAddonCommissionsLazyQuery
>;
export type GetAddonCommissionsQueryResult = Apollo.QueryResult<
  GetAddonCommissionsQuery,
  GetAddonCommissionsQueryVariables
>;
export const GetAddonCommissionDocument = gql`
  query getAddonCommission($_id: ID!) {
    getAddonCommission(_id: $_id) {
      ...AddonCommission
    }
  }
  ${AddonCommissionFragmentDoc}
`;

/**
 * __useGetAddonCommissionQuery__
 *
 * To run a query within a React component, call `useGetAddonCommissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddonCommissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddonCommissionQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetAddonCommissionQuery(
  baseOptions: Apollo.QueryHookOptions<GetAddonCommissionQuery, GetAddonCommissionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAddonCommissionQuery, GetAddonCommissionQueryVariables>(
    GetAddonCommissionDocument,
    options,
  );
}
export function useGetAddonCommissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddonCommissionQuery,
    GetAddonCommissionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAddonCommissionQuery, GetAddonCommissionQueryVariables>(
    GetAddonCommissionDocument,
    options,
  );
}
export type GetAddonCommissionQueryHookResult = ReturnType<typeof useGetAddonCommissionQuery>;
export type GetAddonCommissionLazyQueryHookResult = ReturnType<
  typeof useGetAddonCommissionLazyQuery
>;
export type GetAddonCommissionQueryResult = Apollo.QueryResult<
  GetAddonCommissionQuery,
  GetAddonCommissionQueryVariables
>;
export const SignInDocument = gql`
  mutation SignIn($input: SignInInput) {
    signIn(input: $input) {
      sessionId
      qrcode
      status
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const CheckSignInStatusDocument = gql`
  mutation CheckSignInStatus($input: CheckSignInStatusInput!) {
    checkSignInStatus(input: $input) {
      status
      tokens {
        accessToken {
          expiresIn
          token
        }
        refreshToken {
          expiresIn
          token
        }
      }
    }
  }
`;
export type CheckSignInStatusMutationFn = Apollo.MutationFunction<
  CheckSignInStatusMutation,
  CheckSignInStatusMutationVariables
>;

/**
 * __useCheckSignInStatusMutation__
 *
 * To run a mutation, you first call `useCheckSignInStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckSignInStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkSignInStatusMutation, { data, loading, error }] = useCheckSignInStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckSignInStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckSignInStatusMutation,
    CheckSignInStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CheckSignInStatusMutation, CheckSignInStatusMutationVariables>(
    CheckSignInStatusDocument,
    options,
  );
}
export type CheckSignInStatusMutationHookResult = ReturnType<typeof useCheckSignInStatusMutation>;
export type CheckSignInStatusMutationResult = Apollo.MutationResult<CheckSignInStatusMutation>;
export type CheckSignInStatusMutationOptions = Apollo.BaseMutationOptions<
  CheckSignInStatusMutation,
  CheckSignInStatusMutationVariables
>;
export const CreateBatchDocument = gql`
  mutation CreateBatch($input: CreateLicenseBatchInput!) {
    createLicenseBatch(input: $input) {
      status
      node {
        ...Batch
      }
      errors {
        field
        code
      }
    }
  }
  ${BatchFragmentDoc}
`;
export type CreateBatchMutationFn = Apollo.MutationFunction<
  CreateBatchMutation,
  CreateBatchMutationVariables
>;

/**
 * __useCreateBatchMutation__
 *
 * To run a mutation, you first call `useCreateBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchMutation, { data, loading, error }] = useCreateBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBatchMutation, CreateBatchMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBatchMutation, CreateBatchMutationVariables>(
    CreateBatchDocument,
    options,
  );
}
export type CreateBatchMutationHookResult = ReturnType<typeof useCreateBatchMutation>;
export type CreateBatchMutationResult = Apollo.MutationResult<CreateBatchMutation>;
export type CreateBatchMutationOptions = Apollo.BaseMutationOptions<
  CreateBatchMutation,
  CreateBatchMutationVariables
>;
export const GetBatchesDocument = gql`
  query getBatches(
    $filter: LicenseBatchesFilterInput
    $skip: Int
    $limit: Int
    $of: LicenseBatchesOrderBy
    $od: OrderDirection
  ) {
    getLicenseBatches(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...Batch
      }
      count
    }
  }
  ${BatchFragmentDoc}
`;

/**
 * __useGetBatchesQuery__
 *
 * To run a query within a React component, call `useGetBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetBatchesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBatchesQuery, GetBatchesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBatchesQuery, GetBatchesQueryVariables>(GetBatchesDocument, options);
}
export function useGetBatchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBatchesQuery, GetBatchesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBatchesQuery, GetBatchesQueryVariables>(
    GetBatchesDocument,
    options,
  );
}
export type GetBatchesQueryHookResult = ReturnType<typeof useGetBatchesQuery>;
export type GetBatchesLazyQueryHookResult = ReturnType<typeof useGetBatchesLazyQuery>;
export type GetBatchesQueryResult = Apollo.QueryResult<GetBatchesQuery, GetBatchesQueryVariables>;
export const CreateProductBundleDocument = gql`
  mutation createProductBundle($input: CreateProductBundleInput!) {
    createProductBundle(input: $input) {
      status
      node {
        ...Bundle
      }
      errors {
        field
        code
      }
    }
  }
  ${BundleFragmentDoc}
`;
export type CreateProductBundleMutationFn = Apollo.MutationFunction<
  CreateProductBundleMutation,
  CreateProductBundleMutationVariables
>;

/**
 * __useCreateProductBundleMutation__
 *
 * To run a mutation, you first call `useCreateProductBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductBundleMutation, { data, loading, error }] = useCreateProductBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProductBundleMutation,
    CreateProductBundleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProductBundleMutation, CreateProductBundleMutationVariables>(
    CreateProductBundleDocument,
    options,
  );
}
export type CreateProductBundleMutationHookResult = ReturnType<
  typeof useCreateProductBundleMutation
>;
export type CreateProductBundleMutationResult = Apollo.MutationResult<CreateProductBundleMutation>;
export type CreateProductBundleMutationOptions = Apollo.BaseMutationOptions<
  CreateProductBundleMutation,
  CreateProductBundleMutationVariables
>;
export const UpdateProductBundleDocument = gql`
  mutation updateProductBundle($input: UpdateProductBundleInput!) {
    updateProductBundle(input: $input) {
      status
      node {
        ...Bundle
      }
      errors {
        field
        code
      }
    }
  }
  ${BundleFragmentDoc}
`;
export type UpdateProductBundleMutationFn = Apollo.MutationFunction<
  UpdateProductBundleMutation,
  UpdateProductBundleMutationVariables
>;

/**
 * __useUpdateProductBundleMutation__
 *
 * To run a mutation, you first call `useUpdateProductBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductBundleMutation, { data, loading, error }] = useUpdateProductBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductBundleMutation,
    UpdateProductBundleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductBundleMutation, UpdateProductBundleMutationVariables>(
    UpdateProductBundleDocument,
    options,
  );
}
export type UpdateProductBundleMutationHookResult = ReturnType<
  typeof useUpdateProductBundleMutation
>;
export type UpdateProductBundleMutationResult = Apollo.MutationResult<UpdateProductBundleMutation>;
export type UpdateProductBundleMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductBundleMutation,
  UpdateProductBundleMutationVariables
>;
export const DeleteProductBundleDocument = gql`
  mutation deleteProductBundle($input: DeleteProductBundleInput!) {
    deleteProductBundle(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteProductBundleMutationFn = Apollo.MutationFunction<
  DeleteProductBundleMutation,
  DeleteProductBundleMutationVariables
>;

/**
 * __useDeleteProductBundleMutation__
 *
 * To run a mutation, you first call `useDeleteProductBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductBundleMutation, { data, loading, error }] = useDeleteProductBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProductBundleMutation,
    DeleteProductBundleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProductBundleMutation, DeleteProductBundleMutationVariables>(
    DeleteProductBundleDocument,
    options,
  );
}
export type DeleteProductBundleMutationHookResult = ReturnType<
  typeof useDeleteProductBundleMutation
>;
export type DeleteProductBundleMutationResult = Apollo.MutationResult<DeleteProductBundleMutation>;
export type DeleteProductBundleMutationOptions = Apollo.BaseMutationOptions<
  DeleteProductBundleMutation,
  DeleteProductBundleMutationVariables
>;
export const GetProductBundlesDocument = gql`
  query getProductBundles(
    $filter: ProductBundlesFilterInput
    $skip: Int
    $limit: Int
    $of: ProductBundlesOrderBy
    $od: OrderDirection
  ) {
    getProductBundles(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...Bundle
      }
      count
    }
  }
  ${BundleFragmentDoc}
`;

/**
 * __useGetProductBundlesQuery__
 *
 * To run a query within a React component, call `useGetProductBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductBundlesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetProductBundlesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProductBundlesQuery, GetProductBundlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductBundlesQuery, GetProductBundlesQueryVariables>(
    GetProductBundlesDocument,
    options,
  );
}
export function useGetProductBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductBundlesQuery,
    GetProductBundlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductBundlesQuery, GetProductBundlesQueryVariables>(
    GetProductBundlesDocument,
    options,
  );
}
export type GetProductBundlesQueryHookResult = ReturnType<typeof useGetProductBundlesQuery>;
export type GetProductBundlesLazyQueryHookResult = ReturnType<typeof useGetProductBundlesLazyQuery>;
export type GetProductBundlesQueryResult = Apollo.QueryResult<
  GetProductBundlesQuery,
  GetProductBundlesQueryVariables
>;
export const GetProductBundleDocument = gql`
  query getProductBundle($_id: ID!) {
    getProductBundle(_id: $_id) {
      ...Bundle
    }
  }
  ${BundleFragmentDoc}
`;

/**
 * __useGetProductBundleQuery__
 *
 * To run a query within a React component, call `useGetProductBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductBundleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductBundleQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetProductBundleQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductBundleQuery, GetProductBundleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductBundleQuery, GetProductBundleQueryVariables>(
    GetProductBundleDocument,
    options,
  );
}
export function useGetProductBundleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductBundleQuery, GetProductBundleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductBundleQuery, GetProductBundleQueryVariables>(
    GetProductBundleDocument,
    options,
  );
}
export type GetProductBundleQueryHookResult = ReturnType<typeof useGetProductBundleQuery>;
export type GetProductBundleLazyQueryHookResult = ReturnType<typeof useGetProductBundleLazyQuery>;
export type GetProductBundleQueryResult = Apollo.QueryResult<
  GetProductBundleQuery,
  GetProductBundleQueryVariables
>;
export const CreateBundleCommissionDocument = gql`
  mutation createBundleCommission($input: CreateBundleCommissionInput!) {
    createBundleCommission(input: $input) {
      status
      node {
        ...BundleCommission
      }
      errors {
        field
        code
      }
    }
  }
  ${BundleCommissionFragmentDoc}
`;
export type CreateBundleCommissionMutationFn = Apollo.MutationFunction<
  CreateBundleCommissionMutation,
  CreateBundleCommissionMutationVariables
>;

/**
 * __useCreateBundleCommissionMutation__
 *
 * To run a mutation, you first call `useCreateBundleCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBundleCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBundleCommissionMutation, { data, loading, error }] = useCreateBundleCommissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBundleCommissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBundleCommissionMutation,
    CreateBundleCommissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBundleCommissionMutation,
    CreateBundleCommissionMutationVariables
  >(CreateBundleCommissionDocument, options);
}
export type CreateBundleCommissionMutationHookResult = ReturnType<
  typeof useCreateBundleCommissionMutation
>;
export type CreateBundleCommissionMutationResult =
  Apollo.MutationResult<CreateBundleCommissionMutation>;
export type CreateBundleCommissionMutationOptions = Apollo.BaseMutationOptions<
  CreateBundleCommissionMutation,
  CreateBundleCommissionMutationVariables
>;
export const UpdateBundleCommissionDocument = gql`
  mutation updateBundleCommission($input: UpdateBundleCommissionInput!) {
    updateBundleCommission(input: $input) {
      status
      node {
        ...BundleCommission
      }
      errors {
        field
        code
      }
    }
  }
  ${BundleCommissionFragmentDoc}
`;
export type UpdateBundleCommissionMutationFn = Apollo.MutationFunction<
  UpdateBundleCommissionMutation,
  UpdateBundleCommissionMutationVariables
>;

/**
 * __useUpdateBundleCommissionMutation__
 *
 * To run a mutation, you first call `useUpdateBundleCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBundleCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBundleCommissionMutation, { data, loading, error }] = useUpdateBundleCommissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBundleCommissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBundleCommissionMutation,
    UpdateBundleCommissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBundleCommissionMutation,
    UpdateBundleCommissionMutationVariables
  >(UpdateBundleCommissionDocument, options);
}
export type UpdateBundleCommissionMutationHookResult = ReturnType<
  typeof useUpdateBundleCommissionMutation
>;
export type UpdateBundleCommissionMutationResult =
  Apollo.MutationResult<UpdateBundleCommissionMutation>;
export type UpdateBundleCommissionMutationOptions = Apollo.BaseMutationOptions<
  UpdateBundleCommissionMutation,
  UpdateBundleCommissionMutationVariables
>;
export const DeleteBundleCommissionDocument = gql`
  mutation deleteBundleCommission($input: DeleteBundleCommissionInput!) {
    deleteBundleCommission(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteBundleCommissionMutationFn = Apollo.MutationFunction<
  DeleteBundleCommissionMutation,
  DeleteBundleCommissionMutationVariables
>;

/**
 * __useDeleteBundleCommissionMutation__
 *
 * To run a mutation, you first call `useDeleteBundleCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBundleCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBundleCommissionMutation, { data, loading, error }] = useDeleteBundleCommissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBundleCommissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBundleCommissionMutation,
    DeleteBundleCommissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBundleCommissionMutation,
    DeleteBundleCommissionMutationVariables
  >(DeleteBundleCommissionDocument, options);
}
export type DeleteBundleCommissionMutationHookResult = ReturnType<
  typeof useDeleteBundleCommissionMutation
>;
export type DeleteBundleCommissionMutationResult =
  Apollo.MutationResult<DeleteBundleCommissionMutation>;
export type DeleteBundleCommissionMutationOptions = Apollo.BaseMutationOptions<
  DeleteBundleCommissionMutation,
  DeleteBundleCommissionMutationVariables
>;
export const GetBundleCommissionsDocument = gql`
  query getBundleCommissions(
    $filter: BundleCommissionsFilterInput
    $skip: Int
    $limit: Int
    $of: BundleCommissionsOrderBy
    $od: OrderDirection
  ) {
    getBundleCommissions(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...BundleCommission
      }
      count
    }
  }
  ${BundleCommissionFragmentDoc}
`;

/**
 * __useGetBundleCommissionsQuery__
 *
 * To run a query within a React component, call `useGetBundleCommissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBundleCommissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBundleCommissionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetBundleCommissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBundleCommissionsQuery,
    GetBundleCommissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBundleCommissionsQuery, GetBundleCommissionsQueryVariables>(
    GetBundleCommissionsDocument,
    options,
  );
}
export function useGetBundleCommissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBundleCommissionsQuery,
    GetBundleCommissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBundleCommissionsQuery, GetBundleCommissionsQueryVariables>(
    GetBundleCommissionsDocument,
    options,
  );
}
export type GetBundleCommissionsQueryHookResult = ReturnType<typeof useGetBundleCommissionsQuery>;
export type GetBundleCommissionsLazyQueryHookResult = ReturnType<
  typeof useGetBundleCommissionsLazyQuery
>;
export type GetBundleCommissionsQueryResult = Apollo.QueryResult<
  GetBundleCommissionsQuery,
  GetBundleCommissionsQueryVariables
>;
export const GetBundleCommissionDocument = gql`
  query getBundleCommission($_id: ID!) {
    getBundleCommission(_id: $_id) {
      ...BundleCommission
    }
  }
  ${BundleCommissionFragmentDoc}
`;

/**
 * __useGetBundleCommissionQuery__
 *
 * To run a query within a React component, call `useGetBundleCommissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBundleCommissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBundleCommissionQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetBundleCommissionQuery(
  baseOptions: Apollo.QueryHookOptions<GetBundleCommissionQuery, GetBundleCommissionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBundleCommissionQuery, GetBundleCommissionQueryVariables>(
    GetBundleCommissionDocument,
    options,
  );
}
export function useGetBundleCommissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBundleCommissionQuery,
    GetBundleCommissionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBundleCommissionQuery, GetBundleCommissionQueryVariables>(
    GetBundleCommissionDocument,
    options,
  );
}
export type GetBundleCommissionQueryHookResult = ReturnType<typeof useGetBundleCommissionQuery>;
export type GetBundleCommissionLazyQueryHookResult = ReturnType<
  typeof useGetBundleCommissionLazyQuery
>;
export type GetBundleCommissionQueryResult = Apollo.QueryResult<
  GetBundleCommissionQuery,
  GetBundleCommissionQueryVariables
>;
export const CreateCommissionDocument = gql`
  mutation CreateCommission($input: CreateCommissionInput!) {
    createCommission(input: $input) {
      status
      node {
        ...Commission
      }
      errors {
        field
        code
      }
    }
  }
  ${CommissionFragmentDoc}
`;
export type CreateCommissionMutationFn = Apollo.MutationFunction<
  CreateCommissionMutation,
  CreateCommissionMutationVariables
>;

/**
 * __useCreateCommissionMutation__
 *
 * To run a mutation, you first call `useCreateCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommissionMutation, { data, loading, error }] = useCreateCommissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommissionMutation,
    CreateCommissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCommissionMutation, CreateCommissionMutationVariables>(
    CreateCommissionDocument,
    options,
  );
}
export type CreateCommissionMutationHookResult = ReturnType<typeof useCreateCommissionMutation>;
export type CreateCommissionMutationResult = Apollo.MutationResult<CreateCommissionMutation>;
export type CreateCommissionMutationOptions = Apollo.BaseMutationOptions<
  CreateCommissionMutation,
  CreateCommissionMutationVariables
>;
export const UpdateCommissionDocument = gql`
  mutation UpdateCommission($input: UpdateCommissionInput!) {
    updateCommission(input: $input) {
      status
      node {
        ...Commission
      }
      errors {
        field
        code
      }
    }
  }
  ${CommissionFragmentDoc}
`;
export type UpdateCommissionMutationFn = Apollo.MutationFunction<
  UpdateCommissionMutation,
  UpdateCommissionMutationVariables
>;

/**
 * __useUpdateCommissionMutation__
 *
 * To run a mutation, you first call `useUpdateCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommissionMutation, { data, loading, error }] = useUpdateCommissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommissionMutation,
    UpdateCommissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCommissionMutation, UpdateCommissionMutationVariables>(
    UpdateCommissionDocument,
    options,
  );
}
export type UpdateCommissionMutationHookResult = ReturnType<typeof useUpdateCommissionMutation>;
export type UpdateCommissionMutationResult = Apollo.MutationResult<UpdateCommissionMutation>;
export type UpdateCommissionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommissionMutation,
  UpdateCommissionMutationVariables
>;
export const DeleteCommissionDocument = gql`
  mutation DeleteCommission($input: DeleteCommissionInput!) {
    deleteCommission(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteCommissionMutationFn = Apollo.MutationFunction<
  DeleteCommissionMutation,
  DeleteCommissionMutationVariables
>;

/**
 * __useDeleteCommissionMutation__
 *
 * To run a mutation, you first call `useDeleteCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommissionMutation, { data, loading, error }] = useDeleteCommissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCommissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommissionMutation,
    DeleteCommissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCommissionMutation, DeleteCommissionMutationVariables>(
    DeleteCommissionDocument,
    options,
  );
}
export type DeleteCommissionMutationHookResult = ReturnType<typeof useDeleteCommissionMutation>;
export type DeleteCommissionMutationResult = Apollo.MutationResult<DeleteCommissionMutation>;
export type DeleteCommissionMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommissionMutation,
  DeleteCommissionMutationVariables
>;
export const GetCommissionsDocument = gql`
  query getCommissions(
    $filter: CommissionsFilterInput
    $skip: Int
    $limit: Int
    $of: CommissionsOrderBy
    $od: OrderDirection
  ) {
    getCommissions(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...Commission
      }
      count
    }
  }
  ${CommissionFragmentDoc}
`;

/**
 * __useGetCommissionsQuery__
 *
 * To run a query within a React component, call `useGetCommissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommissionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetCommissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCommissionsQuery, GetCommissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommissionsQuery, GetCommissionsQueryVariables>(
    GetCommissionsDocument,
    options,
  );
}
export function useGetCommissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommissionsQuery, GetCommissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommissionsQuery, GetCommissionsQueryVariables>(
    GetCommissionsDocument,
    options,
  );
}
export type GetCommissionsQueryHookResult = ReturnType<typeof useGetCommissionsQuery>;
export type GetCommissionsLazyQueryHookResult = ReturnType<typeof useGetCommissionsLazyQuery>;
export type GetCommissionsQueryResult = Apollo.QueryResult<
  GetCommissionsQuery,
  GetCommissionsQueryVariables
>;
export const GetCommissionDocument = gql`
  query getCommission($_id: ID!) {
    getCommission(_id: $_id) {
      ...Commission
    }
  }
  ${CommissionFragmentDoc}
`;

/**
 * __useGetCommissionQuery__
 *
 * To run a query within a React component, call `useGetCommissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommissionQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetCommissionQuery(
  baseOptions: Apollo.QueryHookOptions<GetCommissionQuery, GetCommissionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommissionQuery, GetCommissionQueryVariables>(
    GetCommissionDocument,
    options,
  );
}
export function useGetCommissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommissionQuery, GetCommissionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommissionQuery, GetCommissionQueryVariables>(
    GetCommissionDocument,
    options,
  );
}
export type GetCommissionQueryHookResult = ReturnType<typeof useGetCommissionQuery>;
export type GetCommissionLazyQueryHookResult = ReturnType<typeof useGetCommissionLazyQuery>;
export type GetCommissionQueryResult = Apollo.QueryResult<
  GetCommissionQuery,
  GetCommissionQueryVariables
>;
export const GetContractStatusHistoryDocument = gql`
  query getContractStatusHistory($orderId: ID!) {
    getContractStatusHistory(orderId: $orderId) {
      nodes {
        ...ContractStatusHistoryItem
      }
      count
    }
  }
  ${ContractStatusHistoryItemFragmentDoc}
`;

/**
 * __useGetContractStatusHistoryQuery__
 *
 * To run a query within a React component, call `useGetContractStatusHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractStatusHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractStatusHistoryQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetContractStatusHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractStatusHistoryQuery,
    GetContractStatusHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractStatusHistoryQuery, GetContractStatusHistoryQueryVariables>(
    GetContractStatusHistoryDocument,
    options,
  );
}
export function useGetContractStatusHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractStatusHistoryQuery,
    GetContractStatusHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContractStatusHistoryQuery, GetContractStatusHistoryQueryVariables>(
    GetContractStatusHistoryDocument,
    options,
  );
}
export type GetContractStatusHistoryQueryHookResult = ReturnType<
  typeof useGetContractStatusHistoryQuery
>;
export type GetContractStatusHistoryLazyQueryHookResult = ReturnType<
  typeof useGetContractStatusHistoryLazyQuery
>;
export type GetContractStatusHistoryQueryResult = Apollo.QueryResult<
  GetContractStatusHistoryQuery,
  GetContractStatusHistoryQueryVariables
>;
export const GetB2BContractStatusHistoryDocument = gql`
  query getB2BContractStatusHistory($orderId: ID!) {
    getB2BContractStatusHistory(orderId: $orderId) {
      nodes {
        ...B2BContractStatusHistoryItem
      }
      count
    }
  }
  ${B2BContractStatusHistoryItemFragmentDoc}
`;

/**
 * __useGetB2BContractStatusHistoryQuery__
 *
 * To run a query within a React component, call `useGetB2BContractStatusHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2BContractStatusHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2BContractStatusHistoryQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetB2BContractStatusHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetB2BContractStatusHistoryQuery,
    GetB2BContractStatusHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetB2BContractStatusHistoryQuery,
    GetB2BContractStatusHistoryQueryVariables
  >(GetB2BContractStatusHistoryDocument, options);
}
export function useGetB2BContractStatusHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetB2BContractStatusHistoryQuery,
    GetB2BContractStatusHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetB2BContractStatusHistoryQuery,
    GetB2BContractStatusHistoryQueryVariables
  >(GetB2BContractStatusHistoryDocument, options);
}
export type GetB2BContractStatusHistoryQueryHookResult = ReturnType<
  typeof useGetB2BContractStatusHistoryQuery
>;
export type GetB2BContractStatusHistoryLazyQueryHookResult = ReturnType<
  typeof useGetB2BContractStatusHistoryLazyQuery
>;
export type GetB2BContractStatusHistoryQueryResult = Apollo.QueryResult<
  GetB2BContractStatusHistoryQuery,
  GetB2BContractStatusHistoryQueryVariables
>;
export const GetCustomersForListDocument = gql`
  query getCustomersForList(
    $filter: GetCustomersFilterInput
    $skip: Int
    $limit: Int
    $of: CustomersOrderBy
    $od: OrderDirection
  ) {
    getCustomersForList: getCustomers(
      filter: $filter
      skip: $skip
      limit: $limit
      of: $of
      od: $od
    ) {
      nodes {
        _id
        fullName
        personId
        email
        phoneNumber
        creditCheckCode
      }
      count
    }
  }
`;

/**
 * __useGetCustomersForListQuery__
 *
 * To run a query within a React component, call `useGetCustomersForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersForListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersForListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetCustomersForListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomersForListQuery,
    GetCustomersForListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomersForListQuery, GetCustomersForListQueryVariables>(
    GetCustomersForListDocument,
    options,
  );
}
export function useGetCustomersForListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomersForListQuery,
    GetCustomersForListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomersForListQuery, GetCustomersForListQueryVariables>(
    GetCustomersForListDocument,
    options,
  );
}
export type GetCustomersForListQueryHookResult = ReturnType<typeof useGetCustomersForListQuery>;
export type GetCustomersForListLazyQueryHookResult = ReturnType<
  typeof useGetCustomersForListLazyQuery
>;
export type GetCustomersForListQueryResult = Apollo.QueryResult<
  GetCustomersForListQuery,
  GetCustomersForListQueryVariables
>;
export const GetCustomerDocument = gql`
  query getCustomer($_id: ID!, $limit: Int, $skip: Int) {
    getCustomer(_id: $_id) {
      notes(limit: $limit, skip: $skip) {
        nodes {
          ...Note
        }
        count
      }
      ...Customer
    }
  }
  ${NoteFragmentDoc}
  ${CustomerFragmentDoc}
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options,
  );
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<
  GetCustomerQuery,
  GetCustomerQueryVariables
>;
export const CreateProductDiscountDocument = gql`
  mutation CreateProductDiscount($input: CreateProductDiscountInput!) {
    createProductDiscount(input: $input) {
      status
      node {
        ...ProductDiscount
      }
      errors {
        field
        code
      }
    }
  }
  ${ProductDiscountFragmentDoc}
`;
export type CreateProductDiscountMutationFn = Apollo.MutationFunction<
  CreateProductDiscountMutation,
  CreateProductDiscountMutationVariables
>;

/**
 * __useCreateProductDiscountMutation__
 *
 * To run a mutation, you first call `useCreateProductDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductDiscountMutation, { data, loading, error }] = useCreateProductDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductDiscountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProductDiscountMutation,
    CreateProductDiscountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProductDiscountMutation, CreateProductDiscountMutationVariables>(
    CreateProductDiscountDocument,
    options,
  );
}
export type CreateProductDiscountMutationHookResult = ReturnType<
  typeof useCreateProductDiscountMutation
>;
export type CreateProductDiscountMutationResult =
  Apollo.MutationResult<CreateProductDiscountMutation>;
export type CreateProductDiscountMutationOptions = Apollo.BaseMutationOptions<
  CreateProductDiscountMutation,
  CreateProductDiscountMutationVariables
>;
export const UpdateProductDiscountDocument = gql`
  mutation UpdateProductDiscount($input: UpdateProductDiscountInput!) {
    updateProductDiscount(input: $input) {
      status
      node {
        ...ProductDiscount
      }
      errors {
        field
        code
      }
    }
  }
  ${ProductDiscountFragmentDoc}
`;
export type UpdateProductDiscountMutationFn = Apollo.MutationFunction<
  UpdateProductDiscountMutation,
  UpdateProductDiscountMutationVariables
>;

/**
 * __useUpdateProductDiscountMutation__
 *
 * To run a mutation, you first call `useUpdateProductDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductDiscountMutation, { data, loading, error }] = useUpdateProductDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductDiscountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductDiscountMutation,
    UpdateProductDiscountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductDiscountMutation, UpdateProductDiscountMutationVariables>(
    UpdateProductDiscountDocument,
    options,
  );
}
export type UpdateProductDiscountMutationHookResult = ReturnType<
  typeof useUpdateProductDiscountMutation
>;
export type UpdateProductDiscountMutationResult =
  Apollo.MutationResult<UpdateProductDiscountMutation>;
export type UpdateProductDiscountMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductDiscountMutation,
  UpdateProductDiscountMutationVariables
>;
export const DeleteProductDiscountDocument = gql`
  mutation DeleteProductDiscount($input: DeleteProductDiscountInput!) {
    deleteProductDiscount(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteProductDiscountMutationFn = Apollo.MutationFunction<
  DeleteProductDiscountMutation,
  DeleteProductDiscountMutationVariables
>;

/**
 * __useDeleteProductDiscountMutation__
 *
 * To run a mutation, you first call `useDeleteProductDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductDiscountMutation, { data, loading, error }] = useDeleteProductDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductDiscountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProductDiscountMutation,
    DeleteProductDiscountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProductDiscountMutation, DeleteProductDiscountMutationVariables>(
    DeleteProductDiscountDocument,
    options,
  );
}
export type DeleteProductDiscountMutationHookResult = ReturnType<
  typeof useDeleteProductDiscountMutation
>;
export type DeleteProductDiscountMutationResult =
  Apollo.MutationResult<DeleteProductDiscountMutation>;
export type DeleteProductDiscountMutationOptions = Apollo.BaseMutationOptions<
  DeleteProductDiscountMutation,
  DeleteProductDiscountMutationVariables
>;
export const GetProductDiscountsDocument = gql`
  query getProductDiscounts(
    $filter: ProductDiscountsFilterInput
    $skip: Int
    $limit: Int
    $of: ProductDiscountsOrderBy
    $od: OrderDirection
  ) {
    getProductDiscounts(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...ProductDiscount
      }
      count
    }
  }
  ${ProductDiscountFragmentDoc}
`;

/**
 * __useGetProductDiscountsQuery__
 *
 * To run a query within a React component, call `useGetProductDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductDiscountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetProductDiscountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductDiscountsQuery,
    GetProductDiscountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductDiscountsQuery, GetProductDiscountsQueryVariables>(
    GetProductDiscountsDocument,
    options,
  );
}
export function useGetProductDiscountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductDiscountsQuery,
    GetProductDiscountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductDiscountsQuery, GetProductDiscountsQueryVariables>(
    GetProductDiscountsDocument,
    options,
  );
}
export type GetProductDiscountsQueryHookResult = ReturnType<typeof useGetProductDiscountsQuery>;
export type GetProductDiscountsLazyQueryHookResult = ReturnType<
  typeof useGetProductDiscountsLazyQuery
>;
export type GetProductDiscountsQueryResult = Apollo.QueryResult<
  GetProductDiscountsQuery,
  GetProductDiscountsQueryVariables
>;
export const GetProductDiscountDocument = gql`
  query getProductDiscount($_id: ID!) {
    getProductDiscount(_id: $_id) {
      ...ProductDiscount
    }
  }
  ${ProductDiscountFragmentDoc}
`;

/**
 * __useGetProductDiscountQuery__
 *
 * To run a query within a React component, call `useGetProductDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductDiscountQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetProductDiscountQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductDiscountQuery, GetProductDiscountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductDiscountQuery, GetProductDiscountQueryVariables>(
    GetProductDiscountDocument,
    options,
  );
}
export function useGetProductDiscountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductDiscountQuery,
    GetProductDiscountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductDiscountQuery, GetProductDiscountQueryVariables>(
    GetProductDiscountDocument,
    options,
  );
}
export type GetProductDiscountQueryHookResult = ReturnType<typeof useGetProductDiscountQuery>;
export type GetProductDiscountLazyQueryHookResult = ReturnType<
  typeof useGetProductDiscountLazyQuery
>;
export type GetProductDiscountQueryResult = Apollo.QueryResult<
  GetProductDiscountQuery,
  GetProductDiscountQueryVariables
>;
export const GetExternalUsersDocument = gql`
  query getExternalUsers(
    $filter: GetExternalUsersFilterInput
    $skip: Int
    $limit: Int
    $of: ExternalUsersOrderBy
    $od: OrderDirection
  ) {
    getExternalUsers(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...ExternalUser
      }
      count
    }
  }
  ${ExternalUserFragmentDoc}
`;

/**
 * __useGetExternalUsersQuery__
 *
 * To run a query within a React component, call `useGetExternalUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetExternalUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExternalUsersQuery, GetExternalUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExternalUsersQuery, GetExternalUsersQueryVariables>(
    GetExternalUsersDocument,
    options,
  );
}
export function useGetExternalUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExternalUsersQuery, GetExternalUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExternalUsersQuery, GetExternalUsersQueryVariables>(
    GetExternalUsersDocument,
    options,
  );
}
export type GetExternalUsersQueryHookResult = ReturnType<typeof useGetExternalUsersQuery>;
export type GetExternalUsersLazyQueryHookResult = ReturnType<typeof useGetExternalUsersLazyQuery>;
export type GetExternalUsersQueryResult = Apollo.QueryResult<
  GetExternalUsersQuery,
  GetExternalUsersQueryVariables
>;
export const GetExternalUserDocument = gql`
  query getExternalUser($_id: ID!) {
    getExternalUser(_id: $_id) {
      ...ExternalUser
    }
  }
  ${ExternalUserFragmentDoc}
`;

/**
 * __useGetExternalUserQuery__
 *
 * To run a query within a React component, call `useGetExternalUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalUserQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetExternalUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetExternalUserQuery, GetExternalUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExternalUserQuery, GetExternalUserQueryVariables>(
    GetExternalUserDocument,
    options,
  );
}
export function useGetExternalUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExternalUserQuery, GetExternalUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExternalUserQuery, GetExternalUserQueryVariables>(
    GetExternalUserDocument,
    options,
  );
}
export type GetExternalUserQueryHookResult = ReturnType<typeof useGetExternalUserQuery>;
export type GetExternalUserLazyQueryHookResult = ReturnType<typeof useGetExternalUserLazyQuery>;
export type GetExternalUserQueryResult = Apollo.QueryResult<
  GetExternalUserQuery,
  GetExternalUserQueryVariables
>;
export const TerminateLicenseDocument = gql`
  mutation TerminateLicense($input: TerminateLicenseInput!) {
    terminateLicense(input: $input) {
      status
      node {
        ...License
      }
      errors {
        field
        code
      }
    }
  }
  ${LicenseFragmentDoc}
`;
export type TerminateLicenseMutationFn = Apollo.MutationFunction<
  TerminateLicenseMutation,
  TerminateLicenseMutationVariables
>;

/**
 * __useTerminateLicenseMutation__
 *
 * To run a mutation, you first call `useTerminateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateLicenseMutation, { data, loading, error }] = useTerminateLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminateLicenseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TerminateLicenseMutation,
    TerminateLicenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TerminateLicenseMutation, TerminateLicenseMutationVariables>(
    TerminateLicenseDocument,
    options,
  );
}
export type TerminateLicenseMutationHookResult = ReturnType<typeof useTerminateLicenseMutation>;
export type TerminateLicenseMutationResult = Apollo.MutationResult<TerminateLicenseMutation>;
export type TerminateLicenseMutationOptions = Apollo.BaseMutationOptions<
  TerminateLicenseMutation,
  TerminateLicenseMutationVariables
>;
export const GetLicensesForListDocument = gql`
  query getLicensesForList(
    $filter: LicensesFilterInput
    $skip: Int
    $limit: Int
    $of: LicensesOrderBy
    $od: OrderDirection
  ) {
    getLicensesForList: getLicenses(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        _id
        product {
          name
          canBeTerminated
        }
        productAddon {
          name
          canBeTerminated
        }
        status
        externalUser {
          phoneNumber
        }
        licenseStartDate
        licenseEndDate
        customer {
          fullName
        }
        externalUser {
          fullName
        }
        orderId
        financeSystemSyncStatus
        order {
          financeSystem
          financeSystemSyncStatus
        }
      }
      count
    }
  }
`;

/**
 * __useGetLicensesForListQuery__
 *
 * To run a query within a React component, call `useGetLicensesForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicensesForListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicensesForListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetLicensesForListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLicensesForListQuery, GetLicensesForListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLicensesForListQuery, GetLicensesForListQueryVariables>(
    GetLicensesForListDocument,
    options,
  );
}
export function useGetLicensesForListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLicensesForListQuery,
    GetLicensesForListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLicensesForListQuery, GetLicensesForListQueryVariables>(
    GetLicensesForListDocument,
    options,
  );
}
export type GetLicensesForListQueryHookResult = ReturnType<typeof useGetLicensesForListQuery>;
export type GetLicensesForListLazyQueryHookResult = ReturnType<
  typeof useGetLicensesForListLazyQuery
>;
export type GetLicensesForListQueryResult = Apollo.QueryResult<
  GetLicensesForListQuery,
  GetLicensesForListQueryVariables
>;
export const GetPurchasedLicensesDocument = gql`
  query getPurchasedLicenses($filter: GetPurchasedLicensesFilterInput!) {
    getPurchasedLicenses(filter: $filter) {
      activeLicenses {
        ...PurchasedLicense
      }
      inactiveLicenses {
        ...PurchasedLicense
      }
    }
  }
  ${PurchasedLicenseFragmentDoc}
`;

/**
 * __useGetPurchasedLicensesQuery__
 *
 * To run a query within a React component, call `useGetPurchasedLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchasedLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchasedLicensesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPurchasedLicensesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPurchasedLicensesQuery,
    GetPurchasedLicensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPurchasedLicensesQuery, GetPurchasedLicensesQueryVariables>(
    GetPurchasedLicensesDocument,
    options,
  );
}
export function useGetPurchasedLicensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchasedLicensesQuery,
    GetPurchasedLicensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPurchasedLicensesQuery, GetPurchasedLicensesQueryVariables>(
    GetPurchasedLicensesDocument,
    options,
  );
}
export type GetPurchasedLicensesQueryHookResult = ReturnType<typeof useGetPurchasedLicensesQuery>;
export type GetPurchasedLicensesLazyQueryHookResult = ReturnType<
  typeof useGetPurchasedLicensesLazyQuery
>;
export type GetPurchasedLicensesQueryResult = Apollo.QueryResult<
  GetPurchasedLicensesQuery,
  GetPurchasedLicensesQueryVariables
>;
export const MeDocument = gql`
  query me {
    me {
      ...TeamMember
    }
  }
  ${TeamMemberFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CreateNoteDocument = gql`
  mutation CreateNote($input: CreateNoteInput) {
    createNote(input: $input) {
      status
      node {
        ...Note
      }
      errors {
        field
        code
      }
    }
  }
  ${NoteFragmentDoc}
`;
export type CreateNoteMutationFn = Apollo.MutationFunction<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(
    CreateNoteDocument,
    options,
  );
}
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateNoteMutation,
  CreateNoteMutationVariables
>;
export const GetNotesDocument = gql`
  query getNotes($filter: GetNotesFilterInput!, $limit: Int) {
    getNotes(filter: $filter, limit: $limit) {
      nodes {
        ...Note
      }
      count
    }
  }
  ${NoteFragmentDoc}
`;

/**
 * __useGetNotesQuery__
 *
 * To run a query within a React component, call `useGetNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetNotesQuery(
  baseOptions: Apollo.QueryHookOptions<GetNotesQuery, GetNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotesQuery, GetNotesQueryVariables>(GetNotesDocument, options);
}
export function useGetNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotesQuery, GetNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotesQuery, GetNotesQueryVariables>(GetNotesDocument, options);
}
export type GetNotesQueryHookResult = ReturnType<typeof useGetNotesQuery>;
export type GetNotesLazyQueryHookResult = ReturnType<typeof useGetNotesLazyQuery>;
export type GetNotesQueryResult = Apollo.QueryResult<GetNotesQuery, GetNotesQueryVariables>;
export const GetOrderStatusHistoryDocument = gql`
  query getOrderStatusHistory($orderId: ID!) {
    getOrderStatusHistory(orderId: $orderId) {
      nodes {
        ...OrderStatusHistoryItem
      }
      count
    }
  }
  ${OrderStatusHistoryItemFragmentDoc}
`;

/**
 * __useGetOrderStatusHistoryQuery__
 *
 * To run a query within a React component, call `useGetOrderStatusHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderStatusHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderStatusHistoryQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderStatusHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderStatusHistoryQuery,
    GetOrderStatusHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderStatusHistoryQuery, GetOrderStatusHistoryQueryVariables>(
    GetOrderStatusHistoryDocument,
    options,
  );
}
export function useGetOrderStatusHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderStatusHistoryQuery,
    GetOrderStatusHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderStatusHistoryQuery, GetOrderStatusHistoryQueryVariables>(
    GetOrderStatusHistoryDocument,
    options,
  );
}
export type GetOrderStatusHistoryQueryHookResult = ReturnType<typeof useGetOrderStatusHistoryQuery>;
export type GetOrderStatusHistoryLazyQueryHookResult = ReturnType<
  typeof useGetOrderStatusHistoryLazyQuery
>;
export type GetOrderStatusHistoryQueryResult = Apollo.QueryResult<
  GetOrderStatusHistoryQuery,
  GetOrderStatusHistoryQueryVariables
>;
export const GetB2BOrderStatusHistoryDocument = gql`
  query getB2BOrderStatusHistory($orderId: ID!) {
    getB2BOrderStatusHistory(orderId: $orderId) {
      nodes {
        ...B2BOrderStatusHistoryItem
      }
      count
    }
  }
  ${B2BOrderStatusHistoryItemFragmentDoc}
`;

/**
 * __useGetB2BOrderStatusHistoryQuery__
 *
 * To run a query within a React component, call `useGetB2BOrderStatusHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2BOrderStatusHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2BOrderStatusHistoryQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetB2BOrderStatusHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetB2BOrderStatusHistoryQuery,
    GetB2BOrderStatusHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetB2BOrderStatusHistoryQuery, GetB2BOrderStatusHistoryQueryVariables>(
    GetB2BOrderStatusHistoryDocument,
    options,
  );
}
export function useGetB2BOrderStatusHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetB2BOrderStatusHistoryQuery,
    GetB2BOrderStatusHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetB2BOrderStatusHistoryQuery, GetB2BOrderStatusHistoryQueryVariables>(
    GetB2BOrderStatusHistoryDocument,
    options,
  );
}
export type GetB2BOrderStatusHistoryQueryHookResult = ReturnType<
  typeof useGetB2BOrderStatusHistoryQuery
>;
export type GetB2BOrderStatusHistoryLazyQueryHookResult = ReturnType<
  typeof useGetB2BOrderStatusHistoryLazyQuery
>;
export type GetB2BOrderStatusHistoryQueryResult = Apollo.QueryResult<
  GetB2BOrderStatusHistoryQuery,
  GetB2BOrderStatusHistoryQueryVariables
>;
export const CreateOrderDocument = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      status
      node {
        ...Order
      }
      errors {
        field
        code
      }
    }
  }
  ${OrderFragmentDoc}
`;
export type CreateOrderMutationFn = Apollo.MutationFunction<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(
    CreateOrderDocument,
    options,
  );
}
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;
export const CreateB2BOrderDocument = gql`
  mutation CreateB2BOrder($input: CreateB2BOrderInput!) {
    createB2BOrder(input: $input) {
      status
      node {
        ...B2BOrder
      }
      errors {
        field
        code
      }
    }
  }
  ${B2BOrderFragmentDoc}
`;
export type CreateB2BOrderMutationFn = Apollo.MutationFunction<
  CreateB2BOrderMutation,
  CreateB2BOrderMutationVariables
>;

/**
 * __useCreateB2BOrderMutation__
 *
 * To run a mutation, you first call `useCreateB2BOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateB2BOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createB2BOrderMutation, { data, loading, error }] = useCreateB2BOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateB2BOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateB2BOrderMutation, CreateB2BOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateB2BOrderMutation, CreateB2BOrderMutationVariables>(
    CreateB2BOrderDocument,
    options,
  );
}
export type CreateB2BOrderMutationHookResult = ReturnType<typeof useCreateB2BOrderMutation>;
export type CreateB2BOrderMutationResult = Apollo.MutationResult<CreateB2BOrderMutation>;
export type CreateB2BOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateB2BOrderMutation,
  CreateB2BOrderMutationVariables
>;
export const UpdateOrderDocument = gql`
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      status
      node {
        ...Order
      }
      errors {
        field
        code
      }
    }
  }
  ${OrderFragmentDoc}
`;
export type UpdateOrderMutationFn = Apollo.MutationFunction<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(
    UpdateOrderDocument,
    options,
  );
}
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;
export const UpdateB2BOrderDocument = gql`
  mutation UpdateB2BOrder($input: UpdateB2BOrderInput!) {
    updateB2BOrder(input: $input) {
      status
      node {
        ...B2BOrder
      }
      errors {
        field
        code
      }
    }
  }
  ${B2BOrderFragmentDoc}
`;
export type UpdateB2BOrderMutationFn = Apollo.MutationFunction<
  UpdateB2BOrderMutation,
  UpdateB2BOrderMutationVariables
>;

/**
 * __useUpdateB2BOrderMutation__
 *
 * To run a mutation, you first call `useUpdateB2BOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateB2BOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateB2BOrderMutation, { data, loading, error }] = useUpdateB2BOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateB2BOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateB2BOrderMutation, UpdateB2BOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateB2BOrderMutation, UpdateB2BOrderMutationVariables>(
    UpdateB2BOrderDocument,
    options,
  );
}
export type UpdateB2BOrderMutationHookResult = ReturnType<typeof useUpdateB2BOrderMutation>;
export type UpdateB2BOrderMutationResult = Apollo.MutationResult<UpdateB2BOrderMutation>;
export type UpdateB2BOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateB2BOrderMutation,
  UpdateB2BOrderMutationVariables
>;
export const ApproveOrderDocument = gql`
  mutation ApproveOrder($input: ApproveOrderInput!) {
    approveOrder(input: $input) {
      status
      node {
        ...Order
      }
      errors {
        field
        code
      }
    }
  }
  ${OrderFragmentDoc}
`;
export type ApproveOrderMutationFn = Apollo.MutationFunction<
  ApproveOrderMutation,
  ApproveOrderMutationVariables
>;

/**
 * __useApproveOrderMutation__
 *
 * To run a mutation, you first call `useApproveOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveOrderMutation, { data, loading, error }] = useApproveOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<ApproveOrderMutation, ApproveOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveOrderMutation, ApproveOrderMutationVariables>(
    ApproveOrderDocument,
    options,
  );
}
export type ApproveOrderMutationHookResult = ReturnType<typeof useApproveOrderMutation>;
export type ApproveOrderMutationResult = Apollo.MutationResult<ApproveOrderMutation>;
export type ApproveOrderMutationOptions = Apollo.BaseMutationOptions<
  ApproveOrderMutation,
  ApproveOrderMutationVariables
>;
export const ApproveB2BOrderDocument = gql`
  mutation ApproveB2BOrder($input: ApproveOrderInput!) {
    approveB2BOrder(input: $input) {
      status
      node {
        ...Order
      }
      errors {
        field
        code
      }
    }
  }
  ${OrderFragmentDoc}
`;
export type ApproveB2BOrderMutationFn = Apollo.MutationFunction<
  ApproveB2BOrderMutation,
  ApproveB2BOrderMutationVariables
>;

/**
 * __useApproveB2BOrderMutation__
 *
 * To run a mutation, you first call `useApproveB2BOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveB2BOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveB2BOrderMutation, { data, loading, error }] = useApproveB2BOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveB2BOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveB2BOrderMutation,
    ApproveB2BOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveB2BOrderMutation, ApproveB2BOrderMutationVariables>(
    ApproveB2BOrderDocument,
    options,
  );
}
export type ApproveB2BOrderMutationHookResult = ReturnType<typeof useApproveB2BOrderMutation>;
export type ApproveB2BOrderMutationResult = Apollo.MutationResult<ApproveB2BOrderMutation>;
export type ApproveB2BOrderMutationOptions = Apollo.BaseMutationOptions<
  ApproveB2BOrderMutation,
  ApproveB2BOrderMutationVariables
>;
export const RegretOrderDocument = gql`
  mutation RegretOrder($input: RegretOrderInput!) {
    regretOrder(input: $input) {
      status
      node {
        ...Order
      }
      errors {
        field
        code
      }
    }
  }
  ${OrderFragmentDoc}
`;
export type RegretOrderMutationFn = Apollo.MutationFunction<
  RegretOrderMutation,
  RegretOrderMutationVariables
>;

/**
 * __useRegretOrderMutation__
 *
 * To run a mutation, you first call `useRegretOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegretOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regretOrderMutation, { data, loading, error }] = useRegretOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegretOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<RegretOrderMutation, RegretOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegretOrderMutation, RegretOrderMutationVariables>(
    RegretOrderDocument,
    options,
  );
}
export type RegretOrderMutationHookResult = ReturnType<typeof useRegretOrderMutation>;
export type RegretOrderMutationResult = Apollo.MutationResult<RegretOrderMutation>;
export type RegretOrderMutationOptions = Apollo.BaseMutationOptions<
  RegretOrderMutation,
  RegretOrderMutationVariables
>;
export const DeleteOrderDocument = gql`
  mutation DeleteOrder($input: DeleteOrderInput!) {
    deleteOrder(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteOrderMutationFn = Apollo.MutationFunction<
  DeleteOrderMutation,
  DeleteOrderMutationVariables
>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(
    DeleteOrderDocument,
    options,
  );
}
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrderMutation,
  DeleteOrderMutationVariables
>;
export const DeleteB2BOrderDocument = gql`
  mutation DeleteB2BOrder($input: DeleteB2BOrderInput!) {
    deleteB2BOrder(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteB2BOrderMutationFn = Apollo.MutationFunction<
  DeleteB2BOrderMutation,
  DeleteB2BOrderMutationVariables
>;

/**
 * __useDeleteB2BOrderMutation__
 *
 * To run a mutation, you first call `useDeleteB2BOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteB2BOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteB2BOrderMutation, { data, loading, error }] = useDeleteB2BOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteB2BOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteB2BOrderMutation, DeleteB2BOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteB2BOrderMutation, DeleteB2BOrderMutationVariables>(
    DeleteB2BOrderDocument,
    options,
  );
}
export type DeleteB2BOrderMutationHookResult = ReturnType<typeof useDeleteB2BOrderMutation>;
export type DeleteB2BOrderMutationResult = Apollo.MutationResult<DeleteB2BOrderMutation>;
export type DeleteB2BOrderMutationOptions = Apollo.BaseMutationOptions<
  DeleteB2BOrderMutation,
  DeleteB2BOrderMutationVariables
>;
export const DeleteB2BUnsignedOrderDocument = gql`
  mutation DeleteB2BUnsignedOrder($input: DeleteB2BUnsignedOrderInput!) {
    deleteB2BUnsignedOrder(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteB2BUnsignedOrderMutationFn = Apollo.MutationFunction<
  DeleteB2BUnsignedOrderMutation,
  DeleteB2BUnsignedOrderMutationVariables
>;

/**
 * __useDeleteB2BUnsignedOrderMutation__
 *
 * To run a mutation, you first call `useDeleteB2BUnsignedOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteB2BUnsignedOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteB2BUnsignedOrderMutation, { data, loading, error }] = useDeleteB2BUnsignedOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteB2BUnsignedOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteB2BUnsignedOrderMutation,
    DeleteB2BUnsignedOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteB2BUnsignedOrderMutation,
    DeleteB2BUnsignedOrderMutationVariables
  >(DeleteB2BUnsignedOrderDocument, options);
}
export type DeleteB2BUnsignedOrderMutationHookResult = ReturnType<
  typeof useDeleteB2BUnsignedOrderMutation
>;
export type DeleteB2BUnsignedOrderMutationResult =
  Apollo.MutationResult<DeleteB2BUnsignedOrderMutation>;
export type DeleteB2BUnsignedOrderMutationOptions = Apollo.BaseMutationOptions<
  DeleteB2BUnsignedOrderMutation,
  DeleteB2BUnsignedOrderMutationVariables
>;
export const DeleteUnsignedOrderDocument = gql`
  mutation DeleteUnsignedOrder($input: DeleteUnsignedOrderInput!) {
    deleteUnsignedOrder(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteUnsignedOrderMutationFn = Apollo.MutationFunction<
  DeleteUnsignedOrderMutation,
  DeleteUnsignedOrderMutationVariables
>;

/**
 * __useDeleteUnsignedOrderMutation__
 *
 * To run a mutation, you first call `useDeleteUnsignedOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnsignedOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnsignedOrderMutation, { data, loading, error }] = useDeleteUnsignedOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUnsignedOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUnsignedOrderMutation,
    DeleteUnsignedOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUnsignedOrderMutation, DeleteUnsignedOrderMutationVariables>(
    DeleteUnsignedOrderDocument,
    options,
  );
}
export type DeleteUnsignedOrderMutationHookResult = ReturnType<
  typeof useDeleteUnsignedOrderMutation
>;
export type DeleteUnsignedOrderMutationResult = Apollo.MutationResult<DeleteUnsignedOrderMutation>;
export type DeleteUnsignedOrderMutationOptions = Apollo.BaseMutationOptions<
  DeleteUnsignedOrderMutation,
  DeleteUnsignedOrderMutationVariables
>;
export const UpdateShipmentOrderStatusDocument = gql`
  mutation updateShipmentOrderStatus($input: OrderShipmentStatusUpdateInput!) {
    orderShipmentStatusUpdate(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type UpdateShipmentOrderStatusMutationFn = Apollo.MutationFunction<
  UpdateShipmentOrderStatusMutation,
  UpdateShipmentOrderStatusMutationVariables
>;

/**
 * __useUpdateShipmentOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateShipmentOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipmentOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShipmentOrderStatusMutation, { data, loading, error }] = useUpdateShipmentOrderStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShipmentOrderStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateShipmentOrderStatusMutation,
    UpdateShipmentOrderStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateShipmentOrderStatusMutation,
    UpdateShipmentOrderStatusMutationVariables
  >(UpdateShipmentOrderStatusDocument, options);
}
export type UpdateShipmentOrderStatusMutationHookResult = ReturnType<
  typeof useUpdateShipmentOrderStatusMutation
>;
export type UpdateShipmentOrderStatusMutationResult =
  Apollo.MutationResult<UpdateShipmentOrderStatusMutation>;
export type UpdateShipmentOrderStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateShipmentOrderStatusMutation,
  UpdateShipmentOrderStatusMutationVariables
>;
export const UpdateB2BShipmentOrderStatusDocument = gql`
  mutation updateB2BShipmentOrderStatus($input: B2BOrderShipmentStatusUpdateInput!) {
    b2bOrderShipmentStatusUpdate(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type UpdateB2BShipmentOrderStatusMutationFn = Apollo.MutationFunction<
  UpdateB2BShipmentOrderStatusMutation,
  UpdateB2BShipmentOrderStatusMutationVariables
>;

/**
 * __useUpdateB2BShipmentOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateB2BShipmentOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateB2BShipmentOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateB2BShipmentOrderStatusMutation, { data, loading, error }] = useUpdateB2BShipmentOrderStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateB2BShipmentOrderStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateB2BShipmentOrderStatusMutation,
    UpdateB2BShipmentOrderStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateB2BShipmentOrderStatusMutation,
    UpdateB2BShipmentOrderStatusMutationVariables
  >(UpdateB2BShipmentOrderStatusDocument, options);
}
export type UpdateB2BShipmentOrderStatusMutationHookResult = ReturnType<
  typeof useUpdateB2BShipmentOrderStatusMutation
>;
export type UpdateB2BShipmentOrderStatusMutationResult =
  Apollo.MutationResult<UpdateB2BShipmentOrderStatusMutation>;
export type UpdateB2BShipmentOrderStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateB2BShipmentOrderStatusMutation,
  UpdateB2BShipmentOrderStatusMutationVariables
>;
export const SendOrderContractDocument = gql`
  mutation sendOrderContract($input: SendOrderContractInput!) {
    sendOrderContract(input: $input) {
      status
      node {
        ...Order
      }
      errors {
        field
        code
      }
    }
  }
  ${OrderFragmentDoc}
`;
export type SendOrderContractMutationFn = Apollo.MutationFunction<
  SendOrderContractMutation,
  SendOrderContractMutationVariables
>;

/**
 * __useSendOrderContractMutation__
 *
 * To run a mutation, you first call `useSendOrderContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOrderContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOrderContractMutation, { data, loading, error }] = useSendOrderContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOrderContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendOrderContractMutation,
    SendOrderContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendOrderContractMutation, SendOrderContractMutationVariables>(
    SendOrderContractDocument,
    options,
  );
}
export type SendOrderContractMutationHookResult = ReturnType<typeof useSendOrderContractMutation>;
export type SendOrderContractMutationResult = Apollo.MutationResult<SendOrderContractMutation>;
export type SendOrderContractMutationOptions = Apollo.BaseMutationOptions<
  SendOrderContractMutation,
  SendOrderContractMutationVariables
>;
export const GetOrdersForOrdersListDocument = gql`
  query getOrdersForOrdersList(
    $filter: GetOrdersFilterInput
    $skip: Int
    $limit: Int
    $of: OrdersOrderBy
    $od: OrderDirection
  ) {
    getOrdersForOrdersList: getOrders(
      filter: $filter
      skip: $skip
      limit: $limit
      of: $of
      od: $od
    ) {
      nodes {
        _id
        salesChannelId
        salesChannel {
          name
          status
          financeSystem
        }
        projectId
        customerId
        customer {
          _id
          personId
          fullName
        }
        isRegrettableDate
        isRegrettable
        assigneeId
        isCreditCheckReceived
        financeSystemSyncStatus
        financeSystem
        creditCheckCode
        scriveContractStatus
        scriveContractId
        contractStatus
        contractSignedDate
        fullName
        email
        phoneNumber
        personId
        status
        subtotal
        total
        discountPercent
        discountAmount
        fullTotalWithAddons
        fullSubtotalWithAddons
        createdAt
        contractSignedDate
        isDeleted
        isWinBack
        winBackFullTotal
        bundles {
          productBundle {
            name
          }
          items {
            productAddons {
              productAddon {
                name
              }
            }
          }
        }
        items {
          qty
          product {
            name
          }
          productAddons {
            productAddon {
              name
            }
          }
        }
        upgrades {
          productAddons {
            productAddon {
              name
            }
          }
        }
        assignee {
          ...TeamMember
        }
      }
      count
    }
  }
  ${TeamMemberFragmentDoc}
`;

/**
 * __useGetOrdersForOrdersListQuery__
 *
 * To run a query within a React component, call `useGetOrdersForOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersForOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersForOrdersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetOrdersForOrdersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrdersForOrdersListQuery,
    GetOrdersForOrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersForOrdersListQuery, GetOrdersForOrdersListQueryVariables>(
    GetOrdersForOrdersListDocument,
    options,
  );
}
export function useGetOrdersForOrdersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrdersForOrdersListQuery,
    GetOrdersForOrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersForOrdersListQuery, GetOrdersForOrdersListQueryVariables>(
    GetOrdersForOrdersListDocument,
    options,
  );
}
export type GetOrdersForOrdersListQueryHookResult = ReturnType<
  typeof useGetOrdersForOrdersListQuery
>;
export type GetOrdersForOrdersListLazyQueryHookResult = ReturnType<
  typeof useGetOrdersForOrdersListLazyQuery
>;
export type GetOrdersForOrdersListQueryResult = Apollo.QueryResult<
  GetOrdersForOrdersListQuery,
  GetOrdersForOrdersListQueryVariables
>;
export const GetB2BOrdersForOrdersListDocument = gql`
  query getB2BOrdersForOrdersList(
    $filter: GetB2BOrdersFilterInput
    $skip: Int
    $limit: Int
    $of: B2BOrdersOrderBy
    $od: OrderDirection
  ) {
    getB2BOrdersForOrdersList: getB2BOrders(
      filter: $filter
      skip: $skip
      limit: $limit
      of: $of
      od: $od
    ) {
      nodes {
        _id
        orgId
        companyName
        creditCheckCode
        items {
          productId
          monthlyPricePerAllLicenses
          duration
          discountAmount
        }
        total
        subtotal
        status
        scriveContractStatus
        scriveContractId
      }
      count
    }
  }
`;

/**
 * __useGetB2BOrdersForOrdersListQuery__
 *
 * To run a query within a React component, call `useGetB2BOrdersForOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2BOrdersForOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2BOrdersForOrdersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetB2BOrdersForOrdersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetB2BOrdersForOrdersListQuery,
    GetB2BOrdersForOrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetB2BOrdersForOrdersListQuery, GetB2BOrdersForOrdersListQueryVariables>(
    GetB2BOrdersForOrdersListDocument,
    options,
  );
}
export function useGetB2BOrdersForOrdersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetB2BOrdersForOrdersListQuery,
    GetB2BOrdersForOrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetB2BOrdersForOrdersListQuery,
    GetB2BOrdersForOrdersListQueryVariables
  >(GetB2BOrdersForOrdersListDocument, options);
}
export type GetB2BOrdersForOrdersListQueryHookResult = ReturnType<
  typeof useGetB2BOrdersForOrdersListQuery
>;
export type GetB2BOrdersForOrdersListLazyQueryHookResult = ReturnType<
  typeof useGetB2BOrdersForOrdersListLazyQuery
>;
export type GetB2BOrdersForOrdersListQueryResult = Apollo.QueryResult<
  GetB2BOrdersForOrdersListQuery,
  GetB2BOrdersForOrdersListQueryVariables
>;
export const GetShippingForListDocument = gql`
  query getShippingForList(
    $filter: GetOrdersFilterInput
    $skip: Int
    $limit: Int
    $of: OrdersOrderBy
    $od: OrderDirection
  ) {
    getShippingForList: getOrders(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        _id
        fullName
        customerAddress
        coAddress
        phoneNumber
        personId
        shippingStatus
      }
      count
    }
  }
`;

/**
 * __useGetShippingForListQuery__
 *
 * To run a query within a React component, call `useGetShippingForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShippingForListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShippingForListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetShippingForListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetShippingForListQuery, GetShippingForListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetShippingForListQuery, GetShippingForListQueryVariables>(
    GetShippingForListDocument,
    options,
  );
}
export function useGetShippingForListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShippingForListQuery,
    GetShippingForListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetShippingForListQuery, GetShippingForListQueryVariables>(
    GetShippingForListDocument,
    options,
  );
}
export type GetShippingForListQueryHookResult = ReturnType<typeof useGetShippingForListQuery>;
export type GetShippingForListLazyQueryHookResult = ReturnType<
  typeof useGetShippingForListLazyQuery
>;
export type GetShippingForListQueryResult = Apollo.QueryResult<
  GetShippingForListQuery,
  GetShippingForListQueryVariables
>;
export const GetB2BShippingForListDocument = gql`
  query getB2BShippingForList(
    $filter: GetB2BOrdersFilterInput
    $skip: Int
    $limit: Int
    $of: B2BOrdersOrderBy
    $od: OrderDirection
  ) {
    getB2BShippingForList: getB2BOrders(
      filter: $filter
      skip: $skip
      limit: $limit
      of: $of
      od: $od
    ) {
      nodes {
        _id
        companyName
        deliveryAddress
        coAddress
        phoneNumber
        orgId
        shippingStatus
      }
      count
    }
  }
`;

/**
 * __useGetB2BShippingForListQuery__
 *
 * To run a query within a React component, call `useGetB2BShippingForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2BShippingForListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2BShippingForListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetB2BShippingForListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetB2BShippingForListQuery,
    GetB2BShippingForListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetB2BShippingForListQuery, GetB2BShippingForListQueryVariables>(
    GetB2BShippingForListDocument,
    options,
  );
}
export function useGetB2BShippingForListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetB2BShippingForListQuery,
    GetB2BShippingForListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetB2BShippingForListQuery, GetB2BShippingForListQueryVariables>(
    GetB2BShippingForListDocument,
    options,
  );
}
export type GetB2BShippingForListQueryHookResult = ReturnType<typeof useGetB2BShippingForListQuery>;
export type GetB2BShippingForListLazyQueryHookResult = ReturnType<
  typeof useGetB2BShippingForListLazyQuery
>;
export type GetB2BShippingForListQueryResult = Apollo.QueryResult<
  GetB2BShippingForListQuery,
  GetB2BShippingForListQueryVariables
>;
export const GetOrderDocument = gql`
  query getOrder($_id: ID!) {
    getOrder(_id: $_id) {
      ...Order
    }
  }
  ${OrderFragmentDoc}
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
}
export function useGetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetB2BOrderDocument = gql`
  query getB2BOrder($_id: ID!) {
    getB2BOrder(_id: $_id) {
      ...B2BOrder
    }
  }
  ${B2BOrderFragmentDoc}
`;

/**
 * __useGetB2BOrderQuery__
 *
 * To run a query within a React component, call `useGetB2BOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2BOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2BOrderQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetB2BOrderQuery(
  baseOptions: Apollo.QueryHookOptions<GetB2BOrderQuery, GetB2BOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetB2BOrderQuery, GetB2BOrderQueryVariables>(GetB2BOrderDocument, options);
}
export function useGetB2BOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetB2BOrderQuery, GetB2BOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetB2BOrderQuery, GetB2BOrderQueryVariables>(
    GetB2BOrderDocument,
    options,
  );
}
export type GetB2BOrderQueryHookResult = ReturnType<typeof useGetB2BOrderQuery>;
export type GetB2BOrderLazyQueryHookResult = ReturnType<typeof useGetB2BOrderLazyQuery>;
export type GetB2BOrderQueryResult = Apollo.QueryResult<
  GetB2BOrderQuery,
  GetB2BOrderQueryVariables
>;
export const GetPersonDataDocument = gql`
  query getPersonData($personId: String!) {
    getPersonData(personId: $personId) {
      node {
        firstName
        lastName
        address
        zipCode
        coAddress
        allFields {
          name
          value
        }
      }
      errors {
        field
        code
      }
    }
  }
`;

/**
 * __useGetPersonDataQuery__
 *
 * To run a query within a React component, call `useGetPersonDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonDataQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetPersonDataQuery, GetPersonDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonDataQuery, GetPersonDataQueryVariables>(
    GetPersonDataDocument,
    options,
  );
}
export function useGetPersonDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPersonDataQuery, GetPersonDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonDataQuery, GetPersonDataQueryVariables>(
    GetPersonDataDocument,
    options,
  );
}
export type GetPersonDataQueryHookResult = ReturnType<typeof useGetPersonDataQuery>;
export type GetPersonDataLazyQueryHookResult = ReturnType<typeof useGetPersonDataLazyQuery>;
export type GetPersonDataQueryResult = Apollo.QueryResult<
  GetPersonDataQuery,
  GetPersonDataQueryVariables
>;
export const GetCompanyDataDocument = gql`
  query getCompanyData($orgId: String!) {
    getCompanyData(orgId: $orgId) {
      node {
        fullName
        orgId
        allFields {
          name
          value
        }
      }
      errors {
        field
        code
      }
    }
  }
`;

/**
 * __useGetCompanyDataQuery__
 *
 * To run a query within a React component, call `useGetCompanyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDataQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetCompanyDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyDataQuery, GetCompanyDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyDataQuery, GetCompanyDataQueryVariables>(
    GetCompanyDataDocument,
    options,
  );
}
export function useGetCompanyDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyDataQuery, GetCompanyDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyDataQuery, GetCompanyDataQueryVariables>(
    GetCompanyDataDocument,
    options,
  );
}
export type GetCompanyDataQueryHookResult = ReturnType<typeof useGetCompanyDataQuery>;
export type GetCompanyDataLazyQueryHookResult = ReturnType<typeof useGetCompanyDataLazyQuery>;
export type GetCompanyDataQueryResult = Apollo.QueryResult<
  GetCompanyDataQuery,
  GetCompanyDataQueryVariables
>;
export const GetPersonCreditCheckDocument = gql`
  query getPersonCreditCheck($personId: String!) {
    getPersonCreditCheck(personId: $personId) {
      check {
        casTemplate
        approvalCode
        approvalText
      }
      rejectionReasons
    }
  }
`;

/**
 * __useGetPersonCreditCheckQuery__
 *
 * To run a query within a React component, call `useGetPersonCreditCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonCreditCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonCreditCheckQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonCreditCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonCreditCheckQuery,
    GetPersonCreditCheckQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonCreditCheckQuery, GetPersonCreditCheckQueryVariables>(
    GetPersonCreditCheckDocument,
    options,
  );
}
export function useGetPersonCreditCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonCreditCheckQuery,
    GetPersonCreditCheckQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonCreditCheckQuery, GetPersonCreditCheckQueryVariables>(
    GetPersonCreditCheckDocument,
    options,
  );
}
export type GetPersonCreditCheckQueryHookResult = ReturnType<typeof useGetPersonCreditCheckQuery>;
export type GetPersonCreditCheckLazyQueryHookResult = ReturnType<
  typeof useGetPersonCreditCheckLazyQuery
>;
export type GetPersonCreditCheckQueryResult = Apollo.QueryResult<
  GetPersonCreditCheckQuery,
  GetPersonCreditCheckQueryVariables
>;
export const GetCompanyCreditCheckDocument = gql`
  query getCompanyCreditCheck($orgId: String!) {
    getCompanyCreditCheck(orgId: $orgId) {
      check {
        casTemplate
        approvalCode
        approvalText
      }
      rejectionReasons
    }
  }
`;

/**
 * __useGetCompanyCreditCheckQuery__
 *
 * To run a query within a React component, call `useGetCompanyCreditCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCreditCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCreditCheckQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetCompanyCreditCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyCreditCheckQuery,
    GetCompanyCreditCheckQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyCreditCheckQuery, GetCompanyCreditCheckQueryVariables>(
    GetCompanyCreditCheckDocument,
    options,
  );
}
export function useGetCompanyCreditCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyCreditCheckQuery,
    GetCompanyCreditCheckQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyCreditCheckQuery, GetCompanyCreditCheckQueryVariables>(
    GetCompanyCreditCheckDocument,
    options,
  );
}
export type GetCompanyCreditCheckQueryHookResult = ReturnType<typeof useGetCompanyCreditCheckQuery>;
export type GetCompanyCreditCheckLazyQueryHookResult = ReturnType<
  typeof useGetCompanyCreditCheckLazyQuery
>;
export type GetCompanyCreditCheckQueryResult = Apollo.QueryResult<
  GetCompanyCreditCheckQuery,
  GetCompanyCreditCheckQueryVariables
>;
export const GetInvoicesDocument = gql`
  query getInvoices($filter: GetInvoicesFilterInput!) {
    getInvoices(filter: $filter) {
      nodes {
        _id
        date
        status
        total
      }
      count
    }
  }
`;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
}
export function useGetInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(
    GetInvoicesDocument,
    options,
  );
}
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<
  GetInvoicesQuery,
  GetInvoicesQueryVariables
>;
export const GetOrderCalculationsDocument = gql`
  query getOrderCalculations($input: GetOrderCalculationsInput!) {
    getOrderCalculations(input: $input) {
      status
      node {
        ...OrderCalculation
      }
      errors {
        field
        code
      }
    }
  }
  ${OrderCalculationFragmentDoc}
`;

/**
 * __useGetOrderCalculationsQuery__
 *
 * To run a query within a React component, call `useGetOrderCalculationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderCalculationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderCalculationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrderCalculationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderCalculationsQuery,
    GetOrderCalculationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderCalculationsQuery, GetOrderCalculationsQueryVariables>(
    GetOrderCalculationsDocument,
    options,
  );
}
export function useGetOrderCalculationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderCalculationsQuery,
    GetOrderCalculationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderCalculationsQuery, GetOrderCalculationsQueryVariables>(
    GetOrderCalculationsDocument,
    options,
  );
}
export type GetOrderCalculationsQueryHookResult = ReturnType<typeof useGetOrderCalculationsQuery>;
export type GetOrderCalculationsLazyQueryHookResult = ReturnType<
  typeof useGetOrderCalculationsLazyQuery
>;
export type GetOrderCalculationsQueryResult = Apollo.QueryResult<
  GetOrderCalculationsQuery,
  GetOrderCalculationsQueryVariables
>;
export const CreatePartnerStoreDocument = gql`
  mutation CreatePartnerStore($input: CreatePartnerStoreInput!) {
    createPartnerStore(input: $input) {
      status
      node {
        ...PartnerStore
      }
      errors {
        field
        code
      }
    }
  }
  ${PartnerStoreFragmentDoc}
`;
export type CreatePartnerStoreMutationFn = Apollo.MutationFunction<
  CreatePartnerStoreMutation,
  CreatePartnerStoreMutationVariables
>;

/**
 * __useCreatePartnerStoreMutation__
 *
 * To run a mutation, you first call `useCreatePartnerStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerStoreMutation, { data, loading, error }] = useCreatePartnerStoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartnerStoreMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePartnerStoreMutation,
    CreatePartnerStoreMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePartnerStoreMutation, CreatePartnerStoreMutationVariables>(
    CreatePartnerStoreDocument,
    options,
  );
}
export type CreatePartnerStoreMutationHookResult = ReturnType<typeof useCreatePartnerStoreMutation>;
export type CreatePartnerStoreMutationResult = Apollo.MutationResult<CreatePartnerStoreMutation>;
export type CreatePartnerStoreMutationOptions = Apollo.BaseMutationOptions<
  CreatePartnerStoreMutation,
  CreatePartnerStoreMutationVariables
>;
export const UpdatePartnerStoreDocument = gql`
  mutation UpdatePartnerStore($input: UpdatePartnerStoreInput!) {
    updatePartnerStore(input: $input) {
      status
      node {
        ...PartnerStore
      }
      errors {
        field
        code
      }
    }
  }
  ${PartnerStoreFragmentDoc}
`;
export type UpdatePartnerStoreMutationFn = Apollo.MutationFunction<
  UpdatePartnerStoreMutation,
  UpdatePartnerStoreMutationVariables
>;

/**
 * __useUpdatePartnerStoreMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerStoreMutation, { data, loading, error }] = useUpdatePartnerStoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerStoreMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePartnerStoreMutation,
    UpdatePartnerStoreMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePartnerStoreMutation, UpdatePartnerStoreMutationVariables>(
    UpdatePartnerStoreDocument,
    options,
  );
}
export type UpdatePartnerStoreMutationHookResult = ReturnType<typeof useUpdatePartnerStoreMutation>;
export type UpdatePartnerStoreMutationResult = Apollo.MutationResult<UpdatePartnerStoreMutation>;
export type UpdatePartnerStoreMutationOptions = Apollo.BaseMutationOptions<
  UpdatePartnerStoreMutation,
  UpdatePartnerStoreMutationVariables
>;
export const DeletePartnerStoreDocument = gql`
  mutation DeletePartnerStore($input: DeletePartnerStoreInput!) {
    deletePartnerStore(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeletePartnerStoreMutationFn = Apollo.MutationFunction<
  DeletePartnerStoreMutation,
  DeletePartnerStoreMutationVariables
>;

/**
 * __useDeletePartnerStoreMutation__
 *
 * To run a mutation, you first call `useDeletePartnerStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerStoreMutation, { data, loading, error }] = useDeletePartnerStoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePartnerStoreMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePartnerStoreMutation,
    DeletePartnerStoreMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePartnerStoreMutation, DeletePartnerStoreMutationVariables>(
    DeletePartnerStoreDocument,
    options,
  );
}
export type DeletePartnerStoreMutationHookResult = ReturnType<typeof useDeletePartnerStoreMutation>;
export type DeletePartnerStoreMutationResult = Apollo.MutationResult<DeletePartnerStoreMutation>;
export type DeletePartnerStoreMutationOptions = Apollo.BaseMutationOptions<
  DeletePartnerStoreMutation,
  DeletePartnerStoreMutationVariables
>;
export const GetPartnerStoresDocument = gql`
  query getPartnerStores(
    $filter: PartnerStoresFilterInput
    $skip: Int
    $limit: Int
    $of: PartnerStoresOrderBy
    $od: OrderDirection
  ) {
    getPartnerStores(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...PartnerStore
      }
      count
    }
  }
  ${PartnerStoreFragmentDoc}
`;

/**
 * __useGetPartnerStoresQuery__
 *
 * To run a query within a React component, call `useGetPartnerStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerStoresQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetPartnerStoresQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPartnerStoresQuery, GetPartnerStoresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnerStoresQuery, GetPartnerStoresQueryVariables>(
    GetPartnerStoresDocument,
    options,
  );
}
export function useGetPartnerStoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerStoresQuery, GetPartnerStoresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartnerStoresQuery, GetPartnerStoresQueryVariables>(
    GetPartnerStoresDocument,
    options,
  );
}
export type GetPartnerStoresQueryHookResult = ReturnType<typeof useGetPartnerStoresQuery>;
export type GetPartnerStoresLazyQueryHookResult = ReturnType<typeof useGetPartnerStoresLazyQuery>;
export type GetPartnerStoresQueryResult = Apollo.QueryResult<
  GetPartnerStoresQuery,
  GetPartnerStoresQueryVariables
>;
export const GetPartnerStoreDocument = gql`
  query getPartnerStore($_id: ID!) {
    getPartnerStore(_id: $_id) {
      ...PartnerStore
    }
  }
  ${PartnerStoreFragmentDoc}
`;

/**
 * __useGetPartnerStoreQuery__
 *
 * To run a query within a React component, call `useGetPartnerStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerStoreQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetPartnerStoreQuery(
  baseOptions: Apollo.QueryHookOptions<GetPartnerStoreQuery, GetPartnerStoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnerStoreQuery, GetPartnerStoreQueryVariables>(
    GetPartnerStoreDocument,
    options,
  );
}
export function useGetPartnerStoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerStoreQuery, GetPartnerStoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartnerStoreQuery, GetPartnerStoreQueryVariables>(
    GetPartnerStoreDocument,
    options,
  );
}
export type GetPartnerStoreQueryHookResult = ReturnType<typeof useGetPartnerStoreQuery>;
export type GetPartnerStoreLazyQueryHookResult = ReturnType<typeof useGetPartnerStoreLazyQuery>;
export type GetPartnerStoreQueryResult = Apollo.QueryResult<
  GetPartnerStoreQuery,
  GetPartnerStoreQueryVariables
>;
export const GetPartnerStoresFilterDocument = gql`
  query getPartnerStoresFilter(
    $filter: PartnerStoresFilterInput
    $skip: Int
    $limit: Int
    $of: PartnerStoresOrderBy
    $od: OrderDirection
  ) {
    getPartnerStores(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...PartnerStoresFilter
      }
      count
    }
  }
  ${PartnerStoresFilterFragmentDoc}
`;

/**
 * __useGetPartnerStoresFilterQuery__
 *
 * To run a query within a React component, call `useGetPartnerStoresFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerStoresFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerStoresFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetPartnerStoresFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPartnerStoresFilterQuery,
    GetPartnerStoresFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPartnerStoresFilterQuery, GetPartnerStoresFilterQueryVariables>(
    GetPartnerStoresFilterDocument,
    options,
  );
}
export function useGetPartnerStoresFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartnerStoresFilterQuery,
    GetPartnerStoresFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPartnerStoresFilterQuery, GetPartnerStoresFilterQueryVariables>(
    GetPartnerStoresFilterDocument,
    options,
  );
}
export type GetPartnerStoresFilterQueryHookResult = ReturnType<
  typeof useGetPartnerStoresFilterQuery
>;
export type GetPartnerStoresFilterLazyQueryHookResult = ReturnType<
  typeof useGetPartnerStoresFilterLazyQuery
>;
export type GetPartnerStoresFilterQueryResult = Apollo.QueryResult<
  GetPartnerStoresFilterQuery,
  GetPartnerStoresFilterQueryVariables
>;
export const GetPermisionsListDocument = gql`
  query getPermisionsList {
    getPermisionsList {
      nodes {
        _id
        resource
      }
    }
  }
`;

/**
 * __useGetPermisionsListQuery__
 *
 * To run a query within a React component, call `useGetPermisionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermisionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermisionsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermisionsListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPermisionsListQuery, GetPermisionsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPermisionsListQuery, GetPermisionsListQueryVariables>(
    GetPermisionsListDocument,
    options,
  );
}
export function useGetPermisionsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermisionsListQuery,
    GetPermisionsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPermisionsListQuery, GetPermisionsListQueryVariables>(
    GetPermisionsListDocument,
    options,
  );
}
export type GetPermisionsListQueryHookResult = ReturnType<typeof useGetPermisionsListQuery>;
export type GetPermisionsListLazyQueryHookResult = ReturnType<typeof useGetPermisionsListLazyQuery>;
export type GetPermisionsListQueryResult = Apollo.QueryResult<
  GetPermisionsListQuery,
  GetPermisionsListQueryVariables
>;
export const CreateProductDocument = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      status
      node {
        ...Product
      }
      errors {
        field
        code
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type CreateProductMutationFn = Apollo.MutationFunction<
  CreateProductMutation,
  CreateProductMutationVariables
>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(
    CreateProductDocument,
    options,
  );
}
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<
  CreateProductMutation,
  CreateProductMutationVariables
>;
export const UpdateProductDocument = gql`
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      status
      node {
        ...Product
      }
      errors {
        field
        code
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type UpdateProductMutationFn = Apollo.MutationFunction<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(
    UpdateProductDocument,
    options,
  );
}
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;
export const DeleteProductDocument = gql`
  mutation DeleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteProductMutationFn = Apollo.MutationFunction<
  DeleteProductMutation,
  DeleteProductMutationVariables
>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(
    DeleteProductDocument,
    options,
  );
}
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<
  DeleteProductMutation,
  DeleteProductMutationVariables
>;
export const GetProductsDocument = gql`
  query getProducts(
    $filter: ProductsFilterInput
    $skip: Int
    $limit: Int
    $of: ProductsOrderBy
    $od: OrderDirection
  ) {
    getProducts(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...Product
      }
      count
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options,
  );
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<
  GetProductsQuery,
  GetProductsQueryVariables
>;
export const GetProductDocument = gql`
  query getProduct($_id: ID!) {
    getProduct(_id: $_id) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetProductQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
}
export function useGetProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options,
  );
}
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const GetProductUnitsDocument = gql`
  query getProductUnits {
    getProductUnits {
      nodes {
        _id
        title
      }
      count
    }
  }
`;

/**
 * __useGetProductUnitsQuery__
 *
 * To run a query within a React component, call `useGetProductUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductUnitsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProductUnitsQuery, GetProductUnitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductUnitsQuery, GetProductUnitsQueryVariables>(
    GetProductUnitsDocument,
    options,
  );
}
export function useGetProductUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductUnitsQuery, GetProductUnitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductUnitsQuery, GetProductUnitsQueryVariables>(
    GetProductUnitsDocument,
    options,
  );
}
export type GetProductUnitsQueryHookResult = ReturnType<typeof useGetProductUnitsQuery>;
export type GetProductUnitsLazyQueryHookResult = ReturnType<typeof useGetProductUnitsLazyQuery>;
export type GetProductUnitsQueryResult = Apollo.QueryResult<
  GetProductUnitsQuery,
  GetProductUnitsQueryVariables
>;
export const GetProductsFilterDocument = gql`
  query getProductsFilter(
    $filter: ProductsFilterInput
    $skip: Int
    $limit: Int
    $of: ProductsOrderBy
    $od: OrderDirection
  ) {
    getProducts(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...ProductsFilter
      }
      count
    }
  }
  ${ProductsFilterFragmentDoc}
`;

/**
 * __useGetProductsFilterQuery__
 *
 * To run a query within a React component, call `useGetProductsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetProductsFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProductsFilterQuery, GetProductsFilterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsFilterQuery, GetProductsFilterQueryVariables>(
    GetProductsFilterDocument,
    options,
  );
}
export function useGetProductsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsFilterQuery,
    GetProductsFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsFilterQuery, GetProductsFilterQueryVariables>(
    GetProductsFilterDocument,
    options,
  );
}
export type GetProductsFilterQueryHookResult = ReturnType<typeof useGetProductsFilterQuery>;
export type GetProductsFilterLazyQueryHookResult = ReturnType<typeof useGetProductsFilterLazyQuery>;
export type GetProductsFilterQueryResult = Apollo.QueryResult<
  GetProductsFilterQuery,
  GetProductsFilterQueryVariables
>;
export const CreateProjectDocument = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      status
      node {
        ...Project
      }
      errors {
        field
        code
      }
    }
  }
  ${ProjectFragmentDoc}
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(
    CreateProjectDocument,
    options,
  );
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      status
      node {
        ...Project
      }
      errors {
        field
        code
      }
    }
  }
  ${ProjectFragmentDoc}
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    UpdateProjectDocument,
    options,
  );
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation DeleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(
    DeleteProjectDocument,
    options,
  );
}
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const GetProjectsDocument = gql`
  query getProjects(
    $filter: ProjectsFilterInput
    $skip: Int
    $limit: Int
    $of: ProjectsOrderBy
    $od: OrderDirection
  ) {
    getProjects(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...Project
      }
      count
    }
  }
  ${ProjectFragmentDoc}
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
}
export function useGetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options,
  );
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<
  GetProjectsQuery,
  GetProjectsQueryVariables
>;
export const GetProjectDocument = gql`
  query getProject($_id: ID!) {
    getProject(_id: $_id) {
      ...Project
    }
  }
  ${ProjectFragmentDoc}
`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetProjectQuery(
  baseOptions: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
}
export function useGetProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options,
  );
}
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetProjectsFilterDocument = gql`
  query getProjectsFilter(
    $filter: ProjectsFilterInput
    $skip: Int
    $limit: Int
    $of: ProjectsOrderBy
    $od: OrderDirection
  ) {
    getProjects(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...ProjectsFilter
      }
      count
    }
  }
  ${ProjectsFilterFragmentDoc}
`;

/**
 * __useGetProjectsFilterQuery__
 *
 * To run a query within a React component, call `useGetProjectsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetProjectsFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProjectsFilterQuery, GetProjectsFilterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsFilterQuery, GetProjectsFilterQueryVariables>(
    GetProjectsFilterDocument,
    options,
  );
}
export function useGetProjectsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsFilterQuery,
    GetProjectsFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsFilterQuery, GetProjectsFilterQueryVariables>(
    GetProjectsFilterDocument,
    options,
  );
}
export type GetProjectsFilterQueryHookResult = ReturnType<typeof useGetProjectsFilterQuery>;
export type GetProjectsFilterLazyQueryHookResult = ReturnType<typeof useGetProjectsFilterLazyQuery>;
export type GetProjectsFilterQueryResult = Apollo.QueryResult<
  GetProjectsFilterQuery,
  GetProjectsFilterQueryVariables
>;
export const CreateRelationDocument = gql`
  mutation CreateRelation($input: CreateRelationInput!) {
    createRelation(input: $input) {
      status
      node {
        ...Relation
      }
      errors {
        field
        code
      }
    }
  }
  ${RelationFragmentDoc}
`;
export type CreateRelationMutationFn = Apollo.MutationFunction<
  CreateRelationMutation,
  CreateRelationMutationVariables
>;

/**
 * __useCreateRelationMutation__
 *
 * To run a mutation, you first call `useCreateRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRelationMutation, { data, loading, error }] = useCreateRelationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRelationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRelationMutation, CreateRelationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRelationMutation, CreateRelationMutationVariables>(
    CreateRelationDocument,
    options,
  );
}
export type CreateRelationMutationHookResult = ReturnType<typeof useCreateRelationMutation>;
export type CreateRelationMutationResult = Apollo.MutationResult<CreateRelationMutation>;
export type CreateRelationMutationOptions = Apollo.BaseMutationOptions<
  CreateRelationMutation,
  CreateRelationMutationVariables
>;
export const UpdateRelationDocument = gql`
  mutation UpdateRelation($input: UpdateRelationInput!) {
    updateRelation(input: $input) {
      status
      node {
        ...Relation
      }
      errors {
        field
        code
      }
    }
  }
  ${RelationFragmentDoc}
`;
export type UpdateRelationMutationFn = Apollo.MutationFunction<
  UpdateRelationMutation,
  UpdateRelationMutationVariables
>;

/**
 * __useUpdateRelationMutation__
 *
 * To run a mutation, you first call `useUpdateRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRelationMutation, { data, loading, error }] = useUpdateRelationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRelationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRelationMutation, UpdateRelationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRelationMutation, UpdateRelationMutationVariables>(
    UpdateRelationDocument,
    options,
  );
}
export type UpdateRelationMutationHookResult = ReturnType<typeof useUpdateRelationMutation>;
export type UpdateRelationMutationResult = Apollo.MutationResult<UpdateRelationMutation>;
export type UpdateRelationMutationOptions = Apollo.BaseMutationOptions<
  UpdateRelationMutation,
  UpdateRelationMutationVariables
>;
export const DeleteRelationDocument = gql`
  mutation DeleteRelation($input: DeleteRelationInput!) {
    deleteRelation(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteRelationMutationFn = Apollo.MutationFunction<
  DeleteRelationMutation,
  DeleteRelationMutationVariables
>;

/**
 * __useDeleteRelationMutation__
 *
 * To run a mutation, you first call `useDeleteRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRelationMutation, { data, loading, error }] = useDeleteRelationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRelationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRelationMutation, DeleteRelationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRelationMutation, DeleteRelationMutationVariables>(
    DeleteRelationDocument,
    options,
  );
}
export type DeleteRelationMutationHookResult = ReturnType<typeof useDeleteRelationMutation>;
export type DeleteRelationMutationResult = Apollo.MutationResult<DeleteRelationMutation>;
export type DeleteRelationMutationOptions = Apollo.BaseMutationOptions<
  DeleteRelationMutation,
  DeleteRelationMutationVariables
>;
export const GetRelationsDocument = gql`
  query getRelations(
    $filter: RelationsFilterInput
    $skip: Int
    $limit: Int
    $of: RelationsOrderBy
    $od: OrderDirection
  ) {
    getRelations(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...Relation
      }
      count
    }
  }
  ${RelationFragmentDoc}
`;

/**
 * __useGetRelationsQuery__
 *
 * To run a query within a React component, call `useGetRelationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetRelationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRelationsQuery, GetRelationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRelationsQuery, GetRelationsQueryVariables>(
    GetRelationsDocument,
    options,
  );
}
export function useGetRelationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRelationsQuery, GetRelationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRelationsQuery, GetRelationsQueryVariables>(
    GetRelationsDocument,
    options,
  );
}
export type GetRelationsQueryHookResult = ReturnType<typeof useGetRelationsQuery>;
export type GetRelationsLazyQueryHookResult = ReturnType<typeof useGetRelationsLazyQuery>;
export type GetRelationsQueryResult = Apollo.QueryResult<
  GetRelationsQuery,
  GetRelationsQueryVariables
>;
export const GetRelationDocument = gql`
  query getRelation($_id: ID!) {
    getRelation(_id: $_id) {
      ...Relation
    }
  }
  ${RelationFragmentDoc}
`;

/**
 * __useGetRelationQuery__
 *
 * To run a query within a React component, call `useGetRelationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelationQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetRelationQuery(
  baseOptions: Apollo.QueryHookOptions<GetRelationQuery, GetRelationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRelationQuery, GetRelationQueryVariables>(GetRelationDocument, options);
}
export function useGetRelationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRelationQuery, GetRelationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRelationQuery, GetRelationQueryVariables>(
    GetRelationDocument,
    options,
  );
}
export type GetRelationQueryHookResult = ReturnType<typeof useGetRelationQuery>;
export type GetRelationLazyQueryHookResult = ReturnType<typeof useGetRelationLazyQuery>;
export type GetRelationQueryResult = Apollo.QueryResult<
  GetRelationQuery,
  GetRelationQueryVariables
>;
export const CreateRoleDocument = gql`
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      node {
        ...Role
      }
      status
      errors {
        field
        code
      }
    }
  }
  ${RoleFragmentDoc}
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(
    CreateRoleDocument,
    options,
  );
}
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;
export const UpdateRoleDocument = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      node {
        ...Role
      }
      status
      errors {
        field
        code
      }
    }
  }
  ${RoleFragmentDoc}
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(
    UpdateRoleDocument,
    options,
  );
}
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;
export const UpdateRoleAddPermissionDocument = gql`
  mutation UpdateRoleAddPermission($input: UpdateRoleAddPermissionInput!) {
    updateRoleAddPermission(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type UpdateRoleAddPermissionMutationFn = Apollo.MutationFunction<
  UpdateRoleAddPermissionMutation,
  UpdateRoleAddPermissionMutationVariables
>;

/**
 * __useUpdateRoleAddPermissionMutation__
 *
 * To run a mutation, you first call `useUpdateRoleAddPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleAddPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleAddPermissionMutation, { data, loading, error }] = useUpdateRoleAddPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleAddPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleAddPermissionMutation,
    UpdateRoleAddPermissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRoleAddPermissionMutation,
    UpdateRoleAddPermissionMutationVariables
  >(UpdateRoleAddPermissionDocument, options);
}
export type UpdateRoleAddPermissionMutationHookResult = ReturnType<
  typeof useUpdateRoleAddPermissionMutation
>;
export type UpdateRoleAddPermissionMutationResult =
  Apollo.MutationResult<UpdateRoleAddPermissionMutation>;
export type UpdateRoleAddPermissionMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleAddPermissionMutation,
  UpdateRoleAddPermissionMutationVariables
>;
export const UpdateRoleRemovePermissionDocument = gql`
  mutation UpdateRoleRemovePermission($input: UpdateRoleRemovePermissionInput!) {
    updateRoleRemovePermission(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type UpdateRoleRemovePermissionMutationFn = Apollo.MutationFunction<
  UpdateRoleRemovePermissionMutation,
  UpdateRoleRemovePermissionMutationVariables
>;

/**
 * __useUpdateRoleRemovePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateRoleRemovePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleRemovePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleRemovePermissionMutation, { data, loading, error }] = useUpdateRoleRemovePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleRemovePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleRemovePermissionMutation,
    UpdateRoleRemovePermissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRoleRemovePermissionMutation,
    UpdateRoleRemovePermissionMutationVariables
  >(UpdateRoleRemovePermissionDocument, options);
}
export type UpdateRoleRemovePermissionMutationHookResult = ReturnType<
  typeof useUpdateRoleRemovePermissionMutation
>;
export type UpdateRoleRemovePermissionMutationResult =
  Apollo.MutationResult<UpdateRoleRemovePermissionMutation>;
export type UpdateRoleRemovePermissionMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleRemovePermissionMutation,
  UpdateRoleRemovePermissionMutationVariables
>;
export const DeleteRoleDocument = gql`
  mutation DeleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(
    DeleteRoleDocument,
    options,
  );
}
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;
export const GetRolesDocument = gql`
  query getRoles(
    $filter: RolesFilterInput
    $skip: Int
    $limit: Int
    $of: RolesOrderBy
    $od: OrderDirection
  ) {
    getRoles(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...Role
      }
      count
    }
  }
  ${RoleFragmentDoc}
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetRoleDocument = gql`
  query getRole($_id: ID!) {
    getRole(_id: $_id) {
      ...Role
    }
  }
  ${RoleFragmentDoc}
`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetRoleQuery(
  baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
}
export function useGetRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
}
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetRolesFilterDocument = gql`
  query getRolesFilter(
    $filter: RolesFilterInput
    $skip: Int
    $limit: Int
    $of: RolesOrderBy
    $od: OrderDirection
  ) {
    getRoles(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...RolesFilter
      }
      count
    }
  }
  ${RolesFilterFragmentDoc}
`;

/**
 * __useGetRolesFilterQuery__
 *
 * To run a query within a React component, call `useGetRolesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetRolesFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesFilterQuery, GetRolesFilterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesFilterQuery, GetRolesFilterQueryVariables>(
    GetRolesFilterDocument,
    options,
  );
}
export function useGetRolesFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRolesFilterQuery, GetRolesFilterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesFilterQuery, GetRolesFilterQueryVariables>(
    GetRolesFilterDocument,
    options,
  );
}
export type GetRolesFilterQueryHookResult = ReturnType<typeof useGetRolesFilterQuery>;
export type GetRolesFilterLazyQueryHookResult = ReturnType<typeof useGetRolesFilterLazyQuery>;
export type GetRolesFilterQueryResult = Apollo.QueryResult<
  GetRolesFilterQuery,
  GetRolesFilterQueryVariables
>;
export const CreateSalesChannelDocument = gql`
  mutation CreateSalesChannel($input: CreateSalesChannelInput!) {
    createSalesChannel(input: $input) {
      status
      node {
        ...SalesChannel
      }
      errors {
        field
        code
      }
    }
  }
  ${SalesChannelFragmentDoc}
`;
export type CreateSalesChannelMutationFn = Apollo.MutationFunction<
  CreateSalesChannelMutation,
  CreateSalesChannelMutationVariables
>;

/**
 * __useCreateSalesChannelMutation__
 *
 * To run a mutation, you first call `useCreateSalesChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalesChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalesChannelMutation, { data, loading, error }] = useCreateSalesChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalesChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSalesChannelMutation,
    CreateSalesChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSalesChannelMutation, CreateSalesChannelMutationVariables>(
    CreateSalesChannelDocument,
    options,
  );
}
export type CreateSalesChannelMutationHookResult = ReturnType<typeof useCreateSalesChannelMutation>;
export type CreateSalesChannelMutationResult = Apollo.MutationResult<CreateSalesChannelMutation>;
export type CreateSalesChannelMutationOptions = Apollo.BaseMutationOptions<
  CreateSalesChannelMutation,
  CreateSalesChannelMutationVariables
>;
export const UpdateSalesChannelDocument = gql`
  mutation UpdateSalesChannel($input: UpdateSalesChannelInput!) {
    updateSalesChannel(input: $input) {
      status
      node {
        ...SalesChannel
      }
      errors {
        field
        code
      }
    }
  }
  ${SalesChannelFragmentDoc}
`;
export type UpdateSalesChannelMutationFn = Apollo.MutationFunction<
  UpdateSalesChannelMutation,
  UpdateSalesChannelMutationVariables
>;

/**
 * __useUpdateSalesChannelMutation__
 *
 * To run a mutation, you first call `useUpdateSalesChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesChannelMutation, { data, loading, error }] = useUpdateSalesChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSalesChannelMutation,
    UpdateSalesChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSalesChannelMutation, UpdateSalesChannelMutationVariables>(
    UpdateSalesChannelDocument,
    options,
  );
}
export type UpdateSalesChannelMutationHookResult = ReturnType<typeof useUpdateSalesChannelMutation>;
export type UpdateSalesChannelMutationResult = Apollo.MutationResult<UpdateSalesChannelMutation>;
export type UpdateSalesChannelMutationOptions = Apollo.BaseMutationOptions<
  UpdateSalesChannelMutation,
  UpdateSalesChannelMutationVariables
>;
export const DeleteSalesChannelDocument = gql`
  mutation DeleteSalesChannel($input: DeleteSalesChannelInput!) {
    deleteSalesChannel(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteSalesChannelMutationFn = Apollo.MutationFunction<
  DeleteSalesChannelMutation,
  DeleteSalesChannelMutationVariables
>;

/**
 * __useDeleteSalesChannelMutation__
 *
 * To run a mutation, you first call `useDeleteSalesChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesChannelMutation, { data, loading, error }] = useDeleteSalesChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSalesChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSalesChannelMutation,
    DeleteSalesChannelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSalesChannelMutation, DeleteSalesChannelMutationVariables>(
    DeleteSalesChannelDocument,
    options,
  );
}
export type DeleteSalesChannelMutationHookResult = ReturnType<typeof useDeleteSalesChannelMutation>;
export type DeleteSalesChannelMutationResult = Apollo.MutationResult<DeleteSalesChannelMutation>;
export type DeleteSalesChannelMutationOptions = Apollo.BaseMutationOptions<
  DeleteSalesChannelMutation,
  DeleteSalesChannelMutationVariables
>;
export const GetSalesChannelsDocument = gql`
  query getSalesChannels(
    $filter: SalesChannelsFilterInput
    $skip: Int
    $limit: Int
    $of: SalesChannelsOrderBy
    $od: OrderDirection
  ) {
    getSalesChannels(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...SalesChannel
      }
      count
    }
  }
  ${SalesChannelFragmentDoc}
`;

/**
 * __useGetSalesChannelsQuery__
 *
 * To run a query within a React component, call `useGetSalesChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesChannelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetSalesChannelsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSalesChannelsQuery, GetSalesChannelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSalesChannelsQuery, GetSalesChannelsQueryVariables>(
    GetSalesChannelsDocument,
    options,
  );
}
export function useGetSalesChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSalesChannelsQuery, GetSalesChannelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSalesChannelsQuery, GetSalesChannelsQueryVariables>(
    GetSalesChannelsDocument,
    options,
  );
}
export type GetSalesChannelsQueryHookResult = ReturnType<typeof useGetSalesChannelsQuery>;
export type GetSalesChannelsLazyQueryHookResult = ReturnType<typeof useGetSalesChannelsLazyQuery>;
export type GetSalesChannelsQueryResult = Apollo.QueryResult<
  GetSalesChannelsQuery,
  GetSalesChannelsQueryVariables
>;
export const GetSalesChannelDocument = gql`
  query getSalesChannel($_id: ID!) {
    getSalesChannel(_id: $_id) {
      ...SalesChannel
    }
  }
  ${SalesChannelFragmentDoc}
`;

/**
 * __useGetSalesChannelQuery__
 *
 * To run a query within a React component, call `useGetSalesChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesChannelQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetSalesChannelQuery(
  baseOptions: Apollo.QueryHookOptions<GetSalesChannelQuery, GetSalesChannelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSalesChannelQuery, GetSalesChannelQueryVariables>(
    GetSalesChannelDocument,
    options,
  );
}
export function useGetSalesChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSalesChannelQuery, GetSalesChannelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSalesChannelQuery, GetSalesChannelQueryVariables>(
    GetSalesChannelDocument,
    options,
  );
}
export type GetSalesChannelQueryHookResult = ReturnType<typeof useGetSalesChannelQuery>;
export type GetSalesChannelLazyQueryHookResult = ReturnType<typeof useGetSalesChannelLazyQuery>;
export type GetSalesChannelQueryResult = Apollo.QueryResult<
  GetSalesChannelQuery,
  GetSalesChannelQueryVariables
>;
export const GetSalesChannelsFilterDocument = gql`
  query getSalesChannelsFilter(
    $filter: SalesChannelsFilterInput
    $skip: Int
    $limit: Int
    $of: SalesChannelsOrderBy
    $od: OrderDirection
  ) {
    getSalesChannels(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...SalesChannelsFilter
      }
      count
    }
  }
  ${SalesChannelsFilterFragmentDoc}
`;

/**
 * __useGetSalesChannelsFilterQuery__
 *
 * To run a query within a React component, call `useGetSalesChannelsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesChannelsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesChannelsFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetSalesChannelsFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSalesChannelsFilterQuery,
    GetSalesChannelsFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSalesChannelsFilterQuery, GetSalesChannelsFilterQueryVariables>(
    GetSalesChannelsFilterDocument,
    options,
  );
}
export function useGetSalesChannelsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalesChannelsFilterQuery,
    GetSalesChannelsFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSalesChannelsFilterQuery, GetSalesChannelsFilterQueryVariables>(
    GetSalesChannelsFilterDocument,
    options,
  );
}
export type GetSalesChannelsFilterQueryHookResult = ReturnType<
  typeof useGetSalesChannelsFilterQuery
>;
export type GetSalesChannelsFilterLazyQueryHookResult = ReturnType<
  typeof useGetSalesChannelsFilterLazyQuery
>;
export type GetSalesChannelsFilterQueryResult = Apollo.QueryResult<
  GetSalesChannelsFilterQuery,
  GetSalesChannelsFilterQueryVariables
>;
export const GetSalesHistoryDocument = gql`
  query getSalesHistory(
    $filter: GetOrdersFilterInput
    $skip: Int
    $limit: Int
    $of: OrdersOrderBy
    $od: OrderDirection
  ) {
    getOrders(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...SalesHistoryItem
      }
      count
    }
  }
  ${SalesHistoryItemFragmentDoc}
`;

/**
 * __useGetSalesHistoryQuery__
 *
 * To run a query within a React component, call `useGetSalesHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesHistoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetSalesHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSalesHistoryQuery, GetSalesHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSalesHistoryQuery, GetSalesHistoryQueryVariables>(
    GetSalesHistoryDocument,
    options,
  );
}
export function useGetSalesHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSalesHistoryQuery, GetSalesHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSalesHistoryQuery, GetSalesHistoryQueryVariables>(
    GetSalesHistoryDocument,
    options,
  );
}
export type GetSalesHistoryQueryHookResult = ReturnType<typeof useGetSalesHistoryQuery>;
export type GetSalesHistoryLazyQueryHookResult = ReturnType<typeof useGetSalesHistoryLazyQuery>;
export type GetSalesHistoryQueryResult = Apollo.QueryResult<
  GetSalesHistoryQuery,
  GetSalesHistoryQueryVariables
>;
export const GetSalesHistoryItemDocument = gql`
  query getSalesHistoryItem($_id: ID!) {
    getOrder(_id: $_id) {
      ...SalesHistoryItem
    }
  }
  ${SalesHistoryItemFragmentDoc}
`;

/**
 * __useGetSalesHistoryItemQuery__
 *
 * To run a query within a React component, call `useGetSalesHistoryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesHistoryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesHistoryItemQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetSalesHistoryItemQuery(
  baseOptions: Apollo.QueryHookOptions<GetSalesHistoryItemQuery, GetSalesHistoryItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSalesHistoryItemQuery, GetSalesHistoryItemQueryVariables>(
    GetSalesHistoryItemDocument,
    options,
  );
}
export function useGetSalesHistoryItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalesHistoryItemQuery,
    GetSalesHistoryItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSalesHistoryItemQuery, GetSalesHistoryItemQueryVariables>(
    GetSalesHistoryItemDocument,
    options,
  );
}
export type GetSalesHistoryItemQueryHookResult = ReturnType<typeof useGetSalesHistoryItemQuery>;
export type GetSalesHistoryItemLazyQueryHookResult = ReturnType<
  typeof useGetSalesHistoryItemLazyQuery
>;
export type GetSalesHistoryItemQueryResult = Apollo.QueryResult<
  GetSalesHistoryItemQuery,
  GetSalesHistoryItemQueryVariables
>;
export const GetSalesHistoryForListDocument = gql`
  query getSalesHistoryForList(
    $filter: GetOrdersFilterInput
    $skip: Int
    $limit: Int
    $of: OrdersOrderBy
    $od: OrderDirection
  ) {
    getSalesHistoryForList: getOrders(
      filter: $filter
      skip: $skip
      limit: $limit
      of: $of
      od: $od
    ) {
      nodes {
        _id
        bundles {
          productBundle {
            name
          }
          items {
            productAddons {
              productAddon {
                name
              }
            }
          }
        }
        items {
          qty
          product {
            name
          }
          productAddons {
            productAddon {
              name
            }
          }
        }
        upgrades {
          productAddons {
            productAddon {
              name
            }
          }
        }
        project {
          name
        }
        fullSubtotalWithAddons
        fullTotalWithAddons
        commissionGross
        commissionNet
        commissionRegret
        salesChannel {
          name
        }
        createdAt
        contractSignedDate
        assignee {
          fullName
        }
      }
      count
    }
  }
`;

/**
 * __useGetSalesHistoryForListQuery__
 *
 * To run a query within a React component, call `useGetSalesHistoryForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesHistoryForListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesHistoryForListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetSalesHistoryForListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSalesHistoryForListQuery,
    GetSalesHistoryForListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSalesHistoryForListQuery, GetSalesHistoryForListQueryVariables>(
    GetSalesHistoryForListDocument,
    options,
  );
}
export function useGetSalesHistoryForListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalesHistoryForListQuery,
    GetSalesHistoryForListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSalesHistoryForListQuery, GetSalesHistoryForListQueryVariables>(
    GetSalesHistoryForListDocument,
    options,
  );
}
export type GetSalesHistoryForListQueryHookResult = ReturnType<
  typeof useGetSalesHistoryForListQuery
>;
export type GetSalesHistoryForListLazyQueryHookResult = ReturnType<
  typeof useGetSalesHistoryForListLazyQuery
>;
export type GetSalesHistoryForListQueryResult = Apollo.QueryResult<
  GetSalesHistoryForListQuery,
  GetSalesHistoryForListQueryVariables
>;
export const GetSearchHistoryDocument = gql`
  query getSearchHistory(
    $filter: GetSearchHistoryFilterInput
    $skip: Int
    $limit: Int
    $of: SearchHistoryOrderBy
    $od: OrderDirection
  ) {
    getSearchHistory(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...SearchHistoryItem
      }
      count
    }
  }
  ${SearchHistoryItemFragmentDoc}
`;

/**
 * __useGetSearchHistoryQuery__
 *
 * To run a query within a React component, call `useGetSearchHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchHistoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetSearchHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSearchHistoryQuery, GetSearchHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSearchHistoryQuery, GetSearchHistoryQueryVariables>(
    GetSearchHistoryDocument,
    options,
  );
}
export function useGetSearchHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSearchHistoryQuery, GetSearchHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSearchHistoryQuery, GetSearchHistoryQueryVariables>(
    GetSearchHistoryDocument,
    options,
  );
}
export type GetSearchHistoryQueryHookResult = ReturnType<typeof useGetSearchHistoryQuery>;
export type GetSearchHistoryLazyQueryHookResult = ReturnType<typeof useGetSearchHistoryLazyQuery>;
export type GetSearchHistoryQueryResult = Apollo.QueryResult<
  GetSearchHistoryQuery,
  GetSearchHistoryQueryVariables
>;
export const GetDashboardSoSafeTotalStatsDocument = gql`
  query getDashboardSoSafeTotalStats {
    getDashboardSoSafeTotalStats {
      salesTotal
      avgRevenuePerCustomer
      customersTotal
      topSalesChannels {
        _id
        name
        customersTotal
      }
      regretsPercent
      buttonsTotal
      avgBtnsPerCustomer
    }
  }
`;

/**
 * __useGetDashboardSoSafeTotalStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardSoSafeTotalStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardSoSafeTotalStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardSoSafeTotalStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardSoSafeTotalStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardSoSafeTotalStatsQuery,
    GetDashboardSoSafeTotalStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardSoSafeTotalStatsQuery,
    GetDashboardSoSafeTotalStatsQueryVariables
  >(GetDashboardSoSafeTotalStatsDocument, options);
}
export function useGetDashboardSoSafeTotalStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardSoSafeTotalStatsQuery,
    GetDashboardSoSafeTotalStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardSoSafeTotalStatsQuery,
    GetDashboardSoSafeTotalStatsQueryVariables
  >(GetDashboardSoSafeTotalStatsDocument, options);
}
export type GetDashboardSoSafeTotalStatsQueryHookResult = ReturnType<
  typeof useGetDashboardSoSafeTotalStatsQuery
>;
export type GetDashboardSoSafeTotalStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardSoSafeTotalStatsLazyQuery
>;
export type GetDashboardSoSafeTotalStatsQueryResult = Apollo.QueryResult<
  GetDashboardSoSafeTotalStatsQuery,
  GetDashboardSoSafeTotalStatsQueryVariables
>;
export const GetDashboardSoSafeTodayStatsDocument = gql`
  query getDashboardSoSafeTodayStats {
    getDashboardSoSafeTodayStats {
      yesterday {
        salesValue
        buttonsCount
        salesCount
      }
      today {
        salesValue
        buttonsCount
        salesCount
        salesValueDiffPercent
        buttonsCountDiffPercent
        salesCountDiffPercent
      }
    }
  }
`;

/**
 * __useGetDashboardSoSafeTodayStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardSoSafeTodayStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardSoSafeTodayStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardSoSafeTodayStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardSoSafeTodayStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardSoSafeTodayStatsQuery,
    GetDashboardSoSafeTodayStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardSoSafeTodayStatsQuery,
    GetDashboardSoSafeTodayStatsQueryVariables
  >(GetDashboardSoSafeTodayStatsDocument, options);
}
export function useGetDashboardSoSafeTodayStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardSoSafeTodayStatsQuery,
    GetDashboardSoSafeTodayStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardSoSafeTodayStatsQuery,
    GetDashboardSoSafeTodayStatsQueryVariables
  >(GetDashboardSoSafeTodayStatsDocument, options);
}
export type GetDashboardSoSafeTodayStatsQueryHookResult = ReturnType<
  typeof useGetDashboardSoSafeTodayStatsQuery
>;
export type GetDashboardSoSafeTodayStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardSoSafeTodayStatsLazyQuery
>;
export type GetDashboardSoSafeTodayStatsQueryResult = Apollo.QueryResult<
  GetDashboardSoSafeTodayStatsQuery,
  GetDashboardSoSafeTodayStatsQueryVariables
>;
export const GetDashboardSoSafeTodayLatestSalesStatsDocument = gql`
  query getDashboardSoSafeTodayLatestSalesStats($timezone: String!, $limit: Int) {
    getDashboardSoSafeTodayLatestSalesStats(timezone: $timezone, limit: $limit) {
      salesDate
      fullName
      salesChannelName
      revenue
    }
  }
`;

/**
 * __useGetDashboardSoSafeTodayLatestSalesStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardSoSafeTodayLatestSalesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardSoSafeTodayLatestSalesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardSoSafeTodayLatestSalesStatsQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDashboardSoSafeTodayLatestSalesStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardSoSafeTodayLatestSalesStatsQuery,
    GetDashboardSoSafeTodayLatestSalesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardSoSafeTodayLatestSalesStatsQuery,
    GetDashboardSoSafeTodayLatestSalesStatsQueryVariables
  >(GetDashboardSoSafeTodayLatestSalesStatsDocument, options);
}
export function useGetDashboardSoSafeTodayLatestSalesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardSoSafeTodayLatestSalesStatsQuery,
    GetDashboardSoSafeTodayLatestSalesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardSoSafeTodayLatestSalesStatsQuery,
    GetDashboardSoSafeTodayLatestSalesStatsQueryVariables
  >(GetDashboardSoSafeTodayLatestSalesStatsDocument, options);
}
export type GetDashboardSoSafeTodayLatestSalesStatsQueryHookResult = ReturnType<
  typeof useGetDashboardSoSafeTodayLatestSalesStatsQuery
>;
export type GetDashboardSoSafeTodayLatestSalesStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardSoSafeTodayLatestSalesStatsLazyQuery
>;
export type GetDashboardSoSafeTodayLatestSalesStatsQueryResult = Apollo.QueryResult<
  GetDashboardSoSafeTodayLatestSalesStatsQuery,
  GetDashboardSoSafeTodayLatestSalesStatsQueryVariables
>;
export const GetDashboardSoSafeTodayTopSRsStatsDocument = gql`
  query getDashboardSoSafeTodayTopSRsStats($timezone: String!, $limit: Int) {
    getDashboardSoSafeTodayTopSRsStats(timezone: $timezone, limit: $limit) {
      fullName
      salesChannelName
      salesCount
      buttonsCount
    }
  }
`;

/**
 * __useGetDashboardSoSafeTodayTopSRsStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardSoSafeTodayTopSRsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardSoSafeTodayTopSRsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardSoSafeTodayTopSRsStatsQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDashboardSoSafeTodayTopSRsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardSoSafeTodayTopSRsStatsQuery,
    GetDashboardSoSafeTodayTopSRsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardSoSafeTodayTopSRsStatsQuery,
    GetDashboardSoSafeTodayTopSRsStatsQueryVariables
  >(GetDashboardSoSafeTodayTopSRsStatsDocument, options);
}
export function useGetDashboardSoSafeTodayTopSRsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardSoSafeTodayTopSRsStatsQuery,
    GetDashboardSoSafeTodayTopSRsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardSoSafeTodayTopSRsStatsQuery,
    GetDashboardSoSafeTodayTopSRsStatsQueryVariables
  >(GetDashboardSoSafeTodayTopSRsStatsDocument, options);
}
export type GetDashboardSoSafeTodayTopSRsStatsQueryHookResult = ReturnType<
  typeof useGetDashboardSoSafeTodayTopSRsStatsQuery
>;
export type GetDashboardSoSafeTodayTopSRsStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardSoSafeTodayTopSRsStatsLazyQuery
>;
export type GetDashboardSoSafeTodayTopSRsStatsQueryResult = Apollo.QueryResult<
  GetDashboardSoSafeTodayTopSRsStatsQuery,
  GetDashboardSoSafeTodayTopSRsStatsQueryVariables
>;
export const GetDashboardSoSafeTodayTopSalesChannelsStatsDocument = gql`
  query getDashboardSoSafeTodayTopSalesChannelsStats($timezone: String!, $limit: Int) {
    getDashboardSoSafeTodayTopSalesChannelsStats(timezone: $timezone, limit: $limit) {
      salesChannelName
      salesCount
      buttonsCount
    }
  }
`;

/**
 * __useGetDashboardSoSafeTodayTopSalesChannelsStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardSoSafeTodayTopSalesChannelsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardSoSafeTodayTopSalesChannelsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardSoSafeTodayTopSalesChannelsStatsQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDashboardSoSafeTodayTopSalesChannelsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardSoSafeTodayTopSalesChannelsStatsQuery,
    GetDashboardSoSafeTodayTopSalesChannelsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardSoSafeTodayTopSalesChannelsStatsQuery,
    GetDashboardSoSafeTodayTopSalesChannelsStatsQueryVariables
  >(GetDashboardSoSafeTodayTopSalesChannelsStatsDocument, options);
}
export function useGetDashboardSoSafeTodayTopSalesChannelsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardSoSafeTodayTopSalesChannelsStatsQuery,
    GetDashboardSoSafeTodayTopSalesChannelsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardSoSafeTodayTopSalesChannelsStatsQuery,
    GetDashboardSoSafeTodayTopSalesChannelsStatsQueryVariables
  >(GetDashboardSoSafeTodayTopSalesChannelsStatsDocument, options);
}
export type GetDashboardSoSafeTodayTopSalesChannelsStatsQueryHookResult = ReturnType<
  typeof useGetDashboardSoSafeTodayTopSalesChannelsStatsQuery
>;
export type GetDashboardSoSafeTodayTopSalesChannelsStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardSoSafeTodayTopSalesChannelsStatsLazyQuery
>;
export type GetDashboardSoSafeTodayTopSalesChannelsStatsQueryResult = Apollo.QueryResult<
  GetDashboardSoSafeTodayTopSalesChannelsStatsQuery,
  GetDashboardSoSafeTodayTopSalesChannelsStatsQueryVariables
>;
export const GetDashboardCustomersStatsDocument = gql`
  query getDashboardCustomersStats($filter: DashboardFilterInput) {
    getDashboardCustomersStats(filter: $filter) {
      totalCount
      bySalesChannel {
        salesChannelId
        salesChannelName
        count
      }
    }
  }
`;

/**
 * __useGetDashboardCustomersStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardCustomersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardCustomersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardCustomersStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDashboardCustomersStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardCustomersStatsQuery,
    GetDashboardCustomersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardCustomersStatsQuery, GetDashboardCustomersStatsQueryVariables>(
    GetDashboardCustomersStatsDocument,
    options,
  );
}
export function useGetDashboardCustomersStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardCustomersStatsQuery,
    GetDashboardCustomersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardCustomersStatsQuery,
    GetDashboardCustomersStatsQueryVariables
  >(GetDashboardCustomersStatsDocument, options);
}
export type GetDashboardCustomersStatsQueryHookResult = ReturnType<
  typeof useGetDashboardCustomersStatsQuery
>;
export type GetDashboardCustomersStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardCustomersStatsLazyQuery
>;
export type GetDashboardCustomersStatsQueryResult = Apollo.QueryResult<
  GetDashboardCustomersStatsQuery,
  GetDashboardCustomersStatsQueryVariables
>;
export const GetDashboardLicensesStatsDocument = gql`
  query getDashboardLicensesStats($filter: DashboardFilterInput) {
    getDashboardLicensesStats(filter: $filter) {
      totalCount
      totalOrdersCount
      bySalesChannel {
        salesChannelId
        salesChannelName
        count
        ordersCount
      }
    }
  }
`;

/**
 * __useGetDashboardLicensesStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardLicensesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardLicensesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardLicensesStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDashboardLicensesStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardLicensesStatsQuery,
    GetDashboardLicensesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardLicensesStatsQuery, GetDashboardLicensesStatsQueryVariables>(
    GetDashboardLicensesStatsDocument,
    options,
  );
}
export function useGetDashboardLicensesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardLicensesStatsQuery,
    GetDashboardLicensesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardLicensesStatsQuery,
    GetDashboardLicensesStatsQueryVariables
  >(GetDashboardLicensesStatsDocument, options);
}
export type GetDashboardLicensesStatsQueryHookResult = ReturnType<
  typeof useGetDashboardLicensesStatsQuery
>;
export type GetDashboardLicensesStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardLicensesStatsLazyQuery
>;
export type GetDashboardLicensesStatsQueryResult = Apollo.QueryResult<
  GetDashboardLicensesStatsQuery,
  GetDashboardLicensesStatsQueryVariables
>;
export const GetDashboardRegretsStatsDocument = gql`
  query getDashboardRegretsStats($filter: DashboardFilterInput) {
    getDashboardRegretsStats(filter: $filter) {
      totalCount
      totalRegrettedCount
      totalPercent
      bySalesChannel {
        salesChannelId
        salesChannelName
        count
        regrettedCount
        percent
      }
    }
  }
`;

/**
 * __useGetDashboardRegretsStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardRegretsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardRegretsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardRegretsStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDashboardRegretsStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardRegretsStatsQuery,
    GetDashboardRegretsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardRegretsStatsQuery, GetDashboardRegretsStatsQueryVariables>(
    GetDashboardRegretsStatsDocument,
    options,
  );
}
export function useGetDashboardRegretsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardRegretsStatsQuery,
    GetDashboardRegretsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDashboardRegretsStatsQuery, GetDashboardRegretsStatsQueryVariables>(
    GetDashboardRegretsStatsDocument,
    options,
  );
}
export type GetDashboardRegretsStatsQueryHookResult = ReturnType<
  typeof useGetDashboardRegretsStatsQuery
>;
export type GetDashboardRegretsStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardRegretsStatsLazyQuery
>;
export type GetDashboardRegretsStatsQueryResult = Apollo.QueryResult<
  GetDashboardRegretsStatsQuery,
  GetDashboardRegretsStatsQueryVariables
>;
export const GetDashboardOrdersStatsDocument = gql`
  query getDashboardOrdersStats($filter: DashboardFilterInput) {
    getDashboardOrdersStats(filter: $filter) {
      totalCount
      totalRevenue
      approvedCount
      regrettedCount
      submittedCount
    }
  }
`;

/**
 * __useGetDashboardOrdersStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardOrdersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardOrdersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardOrdersStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDashboardOrdersStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardOrdersStatsQuery,
    GetDashboardOrdersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardOrdersStatsQuery, GetDashboardOrdersStatsQueryVariables>(
    GetDashboardOrdersStatsDocument,
    options,
  );
}
export function useGetDashboardOrdersStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardOrdersStatsQuery,
    GetDashboardOrdersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDashboardOrdersStatsQuery, GetDashboardOrdersStatsQueryVariables>(
    GetDashboardOrdersStatsDocument,
    options,
  );
}
export type GetDashboardOrdersStatsQueryHookResult = ReturnType<
  typeof useGetDashboardOrdersStatsQuery
>;
export type GetDashboardOrdersStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardOrdersStatsLazyQuery
>;
export type GetDashboardOrdersStatsQueryResult = Apollo.QueryResult<
  GetDashboardOrdersStatsQuery,
  GetDashboardOrdersStatsQueryVariables
>;
export const GetDashboardCommissionsStatsDocument = gql`
  query getDashboardCommissionsStats($filter: DashboardFilterInput) {
    getDashboardCommissionsStats(filter: $filter) {
      totalRevenue
      guaranteedRevenue
      underRegretPeriodRevenue
      regrets {
        type
        revenue
        count
      }
    }
  }
`;

/**
 * __useGetDashboardCommissionsStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardCommissionsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardCommissionsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardCommissionsStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDashboardCommissionsStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardCommissionsStatsQuery,
    GetDashboardCommissionsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardCommissionsStatsQuery,
    GetDashboardCommissionsStatsQueryVariables
  >(GetDashboardCommissionsStatsDocument, options);
}
export function useGetDashboardCommissionsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardCommissionsStatsQuery,
    GetDashboardCommissionsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardCommissionsStatsQuery,
    GetDashboardCommissionsStatsQueryVariables
  >(GetDashboardCommissionsStatsDocument, options);
}
export type GetDashboardCommissionsStatsQueryHookResult = ReturnType<
  typeof useGetDashboardCommissionsStatsQuery
>;
export type GetDashboardCommissionsStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardCommissionsStatsLazyQuery
>;
export type GetDashboardCommissionsStatsQueryResult = Apollo.QueryResult<
  GetDashboardCommissionsStatsQuery,
  GetDashboardCommissionsStatsQueryVariables
>;
export const GetDashboardTopSalesManagersDocument = gql`
  query getDashboardTopSalesManagers($filter: DashboardFilterInput, $limit: Int) {
    getDashboardTopSalesManagers(filter: $filter, limit: $limit) {
      teamMemberId
      teamMember {
        fullName
        projects {
          projectId
          projectName
        }
      }
      count
      buttonsCount
      revenue
      position
      countDiff
    }
  }
`;

/**
 * __useGetDashboardTopSalesManagersQuery__
 *
 * To run a query within a React component, call `useGetDashboardTopSalesManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardTopSalesManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardTopSalesManagersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDashboardTopSalesManagersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardTopSalesManagersQuery,
    GetDashboardTopSalesManagersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardTopSalesManagersQuery,
    GetDashboardTopSalesManagersQueryVariables
  >(GetDashboardTopSalesManagersDocument, options);
}
export function useGetDashboardTopSalesManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardTopSalesManagersQuery,
    GetDashboardTopSalesManagersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardTopSalesManagersQuery,
    GetDashboardTopSalesManagersQueryVariables
  >(GetDashboardTopSalesManagersDocument, options);
}
export type GetDashboardTopSalesManagersQueryHookResult = ReturnType<
  typeof useGetDashboardTopSalesManagersQuery
>;
export type GetDashboardTopSalesManagersLazyQueryHookResult = ReturnType<
  typeof useGetDashboardTopSalesManagersLazyQuery
>;
export type GetDashboardTopSalesManagersQueryResult = Apollo.QueryResult<
  GetDashboardTopSalesManagersQuery,
  GetDashboardTopSalesManagersQueryVariables
>;
export const GetDashboardSalesStatsDocument = gql`
  query getDashboardSalesStats($filter: DashboardFilterInput) {
    getDashboardSalesStats(filter: $filter) {
      totalCount
      totalRevenue
      guaranteedCount
      guaranteedRevenue
      underRegretPeriodCount
      underRegretPeriodRevenue
      regrets {
        type
        count
        revenue
      }
    }
  }
`;

/**
 * __useGetDashboardSalesStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardSalesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardSalesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardSalesStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDashboardSalesStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardSalesStatsQuery,
    GetDashboardSalesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardSalesStatsQuery, GetDashboardSalesStatsQueryVariables>(
    GetDashboardSalesStatsDocument,
    options,
  );
}
export function useGetDashboardSalesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardSalesStatsQuery,
    GetDashboardSalesStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDashboardSalesStatsQuery, GetDashboardSalesStatsQueryVariables>(
    GetDashboardSalesStatsDocument,
    options,
  );
}
export type GetDashboardSalesStatsQueryHookResult = ReturnType<
  typeof useGetDashboardSalesStatsQuery
>;
export type GetDashboardSalesStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardSalesStatsLazyQuery
>;
export type GetDashboardSalesStatsQueryResult = Apollo.QueryResult<
  GetDashboardSalesStatsQuery,
  GetDashboardSalesStatsQueryVariables
>;
export const GetDashboardSalesGroupedByPeriodDocument = gql`
  query getDashboardSalesGroupedByPeriod($groupBy: GroupByPeriod!, $filter: DashboardFilterInput) {
    getDashboardSalesGroupedByPeriod(groupBy: $groupBy, filter: $filter) {
      totalCount
      totalRevenue
      totalCountGross
      guaranteedCount
      guaranteedRevenue
      underRegretPeriodCount
      underRegretPeriodRevenue
      totalRevenueGross
      regrets {
        type
        count
        revenue
      }
      periodFrom
      periodTo
    }
  }
`;

/**
 * __useGetDashboardSalesGroupedByPeriodQuery__
 *
 * To run a query within a React component, call `useGetDashboardSalesGroupedByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardSalesGroupedByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardSalesGroupedByPeriodQuery({
 *   variables: {
 *      groupBy: // value for 'groupBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDashboardSalesGroupedByPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDashboardSalesGroupedByPeriodQuery,
    GetDashboardSalesGroupedByPeriodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardSalesGroupedByPeriodQuery,
    GetDashboardSalesGroupedByPeriodQueryVariables
  >(GetDashboardSalesGroupedByPeriodDocument, options);
}
export function useGetDashboardSalesGroupedByPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardSalesGroupedByPeriodQuery,
    GetDashboardSalesGroupedByPeriodQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardSalesGroupedByPeriodQuery,
    GetDashboardSalesGroupedByPeriodQueryVariables
  >(GetDashboardSalesGroupedByPeriodDocument, options);
}
export type GetDashboardSalesGroupedByPeriodQueryHookResult = ReturnType<
  typeof useGetDashboardSalesGroupedByPeriodQuery
>;
export type GetDashboardSalesGroupedByPeriodLazyQueryHookResult = ReturnType<
  typeof useGetDashboardSalesGroupedByPeriodLazyQuery
>;
export type GetDashboardSalesGroupedByPeriodQueryResult = Apollo.QueryResult<
  GetDashboardSalesGroupedByPeriodQuery,
  GetDashboardSalesGroupedByPeriodQueryVariables
>;
export const GetDashboardTopProductAndBundleStatsDocument = gql`
  query getDashboardTopProductAndBundleStats($filter: DashboardFilterInput, $limit: Int) {
    getDashboardTopProductAndBundleStats(filter: $filter, limit: $limit) {
      type
      product {
        name
      }
      bundle {
        name
      }
      signedCount
      regrettedCount
    }
  }
`;

/**
 * __useGetDashboardTopProductAndBundleStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardTopProductAndBundleStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardTopProductAndBundleStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardTopProductAndBundleStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDashboardTopProductAndBundleStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardTopProductAndBundleStatsQuery,
    GetDashboardTopProductAndBundleStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardTopProductAndBundleStatsQuery,
    GetDashboardTopProductAndBundleStatsQueryVariables
  >(GetDashboardTopProductAndBundleStatsDocument, options);
}
export function useGetDashboardTopProductAndBundleStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardTopProductAndBundleStatsQuery,
    GetDashboardTopProductAndBundleStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardTopProductAndBundleStatsQuery,
    GetDashboardTopProductAndBundleStatsQueryVariables
  >(GetDashboardTopProductAndBundleStatsDocument, options);
}
export type GetDashboardTopProductAndBundleStatsQueryHookResult = ReturnType<
  typeof useGetDashboardTopProductAndBundleStatsQuery
>;
export type GetDashboardTopProductAndBundleStatsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardTopProductAndBundleStatsLazyQuery
>;
export type GetDashboardTopProductAndBundleStatsQueryResult = Apollo.QueryResult<
  GetDashboardTopProductAndBundleStatsQuery,
  GetDashboardTopProductAndBundleStatsQueryVariables
>;
export const GetDashboardOrdersListDocument = gql`
  query getDashboardOrdersList(
    $filter: GetOrdersFilterInput
    $skip: Int
    $limit: Int
    $of: OrdersOrderBy
    $od: OrderDirection
  ) {
    getDashboardOrdersList: getOrders(
      filter: $filter
      skip: $skip
      limit: $limit
      of: $of
      od: $od
    ) {
      nodes {
        _id
        fullName
        fullTotalWithAddons
        status
        createdAt
      }
      count
    }
  }
`;

/**
 * __useGetDashboardOrdersListQuery__
 *
 * To run a query within a React component, call `useGetDashboardOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardOrdersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetDashboardOrdersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardOrdersListQuery,
    GetDashboardOrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardOrdersListQuery, GetDashboardOrdersListQueryVariables>(
    GetDashboardOrdersListDocument,
    options,
  );
}
export function useGetDashboardOrdersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardOrdersListQuery,
    GetDashboardOrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDashboardOrdersListQuery, GetDashboardOrdersListQueryVariables>(
    GetDashboardOrdersListDocument,
    options,
  );
}
export type GetDashboardOrdersListQueryHookResult = ReturnType<
  typeof useGetDashboardOrdersListQuery
>;
export type GetDashboardOrdersListLazyQueryHookResult = ReturnType<
  typeof useGetDashboardOrdersListLazyQuery
>;
export type GetDashboardOrdersListQueryResult = Apollo.QueryResult<
  GetDashboardOrdersListQuery,
  GetDashboardOrdersListQueryVariables
>;
export const GetDashboardStatsDocument = gql`
  query getDashboardStats(
    $filter: DashboardFilterInput
    $topSalesManagersLimit: Int
    $topProductAndBundleLimit: Int
    $groupBy: GroupByPeriod!
    $isPermittedViewOrders: Boolean!
    $isPermittedViewCustomers: Boolean!
  ) {
    customerStats: getDashboardCustomersStats(filter: $filter)
      @include(if: $isPermittedViewCustomers) {
      totalCount
      bySalesChannel {
        salesChannelId
        salesChannelName
        count
      }
    }
    licensesStats: getDashboardLicensesStats(filter: $filter)
      @include(if: $isPermittedViewCustomers) {
      totalCount
      totalOrdersCount
      bySalesChannel {
        salesChannelId
        salesChannelName
        count
        ordersCount
      }
    }
    regretsStats: getDashboardRegretsStats(filter: $filter)
      @include(if: $isPermittedViewCustomers) {
      totalCount
      totalRegrettedCount
      totalPercent
      bySalesChannel {
        salesChannelId
        salesChannelName
        count
        regrettedCount
        percent
      }
    }
    orderStats: getDashboardOrdersStats(filter: $filter) @include(if: $isPermittedViewOrders) {
      totalCount
      totalRevenue
      approvedCount
      regrettedCount
      submittedCount
    }
    topProductAndBundleStats: getDashboardTopProductAndBundleStats(
      filter: $filter
      limit: $topProductAndBundleLimit
    ) @include(if: $isPermittedViewOrders) {
      type
      product {
        name
      }
      bundle {
        name
      }
      signedCount
      regrettedCount
    }
    allCommissionsStats: getDashboardCommissionsStats(filter: $filter)
      @include(if: $isPermittedViewOrders) {
      totalRevenue
      guaranteedRevenue
      underRegretPeriodRevenue
      regrets {
        type
        revenue
      }
    }
    salesStats: getDashboardSalesStats(filter: $filter) @include(if: $isPermittedViewOrders) {
      totalCount
      totalRevenue
      guaranteedCount
      guaranteedRevenue
      underRegretPeriodCount
      underRegretPeriodRevenue
      regrets {
        type
        count
        revenue
      }
    }
    salesGroupedByPeriod: getDashboardSalesGroupedByPeriod(groupBy: $groupBy, filter: $filter)
      @include(if: $isPermittedViewOrders) {
      totalCount
      totalRevenue
      totalCountGross
      totalRevenueGross
      guaranteedCount
      guaranteedRevenue
      underRegretPeriodRevenue
      underRegretPeriodCount
      regrets {
        type
        count
        revenue
      }
      periodFrom
      periodTo
    }
    topSalesManagers: getDashboardTopSalesManagers(filter: $filter, limit: $topSalesManagersLimit) {
      teamMemberId
      teamMember {
        fullName
        projects {
          projectId
          projectName
        }
      }
      count
      buttonsCount
      revenue
      position
      countDiff
    }
  }
`;

/**
 * __useGetDashboardStatsQuery__
 *
 * To run a query within a React component, call `useGetDashboardStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      topSalesManagersLimit: // value for 'topSalesManagersLimit'
 *      topProductAndBundleLimit: // value for 'topProductAndBundleLimit'
 *      groupBy: // value for 'groupBy'
 *      isPermittedViewOrders: // value for 'isPermittedViewOrders'
 *      isPermittedViewCustomers: // value for 'isPermittedViewCustomers'
 *   },
 * });
 */
export function useGetDashboardStatsQuery(
  baseOptions: Apollo.QueryHookOptions<GetDashboardStatsQuery, GetDashboardStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardStatsQuery, GetDashboardStatsQueryVariables>(
    GetDashboardStatsDocument,
    options,
  );
}
export function useGetDashboardStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardStatsQuery,
    GetDashboardStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDashboardStatsQuery, GetDashboardStatsQueryVariables>(
    GetDashboardStatsDocument,
    options,
  );
}
export type GetDashboardStatsQueryHookResult = ReturnType<typeof useGetDashboardStatsQuery>;
export type GetDashboardStatsLazyQueryHookResult = ReturnType<typeof useGetDashboardStatsLazyQuery>;
export type GetDashboardStatsQueryResult = Apollo.QueryResult<
  GetDashboardStatsQuery,
  GetDashboardStatsQueryVariables
>;
export const CreateTeamDocument = gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      status
      node {
        ...Team
      }
      errors {
        field
        code
      }
    }
  }
  ${TeamFragmentDoc}
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(
    CreateTeamDocument,
    options,
  );
}
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;
export const UpdateTeamDocument = gql`
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      status
      node {
        ...Team
      }
      errors {
        field
        code
      }
    }
  }
  ${TeamFragmentDoc}
`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    options,
  );
}
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;
export const DeleteTeamDocument = gql`
  mutation DeleteTeam($input: DeleteTeamInput!) {
    deleteTeam(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteTeamMutationFn = Apollo.MutationFunction<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(
    DeleteTeamDocument,
    options,
  );
}
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;
export const GetTeamsDocument = gql`
  query getTeams(
    $filter: TeamsFilterInput
    $skip: Int
    $limit: Int
    $of: TeamsOrderBy
    $od: OrderDirection
  ) {
    getTeams(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...Team
      }
      count
    }
  }
  ${TeamFragmentDoc}
`;

/**
 * __useGetTeamsQuery__
 *
 * To run a query within a React component, call `useGetTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeamsQuery, GetTeamsQueryVariables>(GetTeamsDocument, options);
}
export function useGetTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeamsQuery, GetTeamsQueryVariables>(GetTeamsDocument, options);
}
export type GetTeamsQueryHookResult = ReturnType<typeof useGetTeamsQuery>;
export type GetTeamsLazyQueryHookResult = ReturnType<typeof useGetTeamsLazyQuery>;
export type GetTeamsQueryResult = Apollo.QueryResult<GetTeamsQuery, GetTeamsQueryVariables>;
export const GetTeamDocument = gql`
  query getTeam($_id: ID!) {
    getTeam(_id: $_id) {
      ...Team
    }
  }
  ${TeamFragmentDoc}
`;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetTeamQuery(
  baseOptions: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
}
export function useGetTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
}
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;
export const CreateTeamMemberDocument = gql`
  mutation CreateTeamMember($input: CreateTeamMemberInput!) {
    createTeamMember(input: $input) {
      status
      node {
        ...TeamMember
      }
      errors {
        field
        code
      }
    }
  }
  ${TeamMemberFragmentDoc}
`;
export type CreateTeamMemberMutationFn = Apollo.MutationFunction<
  CreateTeamMemberMutation,
  CreateTeamMemberMutationVariables
>;

/**
 * __useCreateTeamMemberMutation__
 *
 * To run a mutation, you first call `useCreateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMemberMutation, { data, loading, error }] = useCreateTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeamMemberMutation,
    CreateTeamMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>(
    CreateTeamMemberDocument,
    options,
  );
}
export type CreateTeamMemberMutationHookResult = ReturnType<typeof useCreateTeamMemberMutation>;
export type CreateTeamMemberMutationResult = Apollo.MutationResult<CreateTeamMemberMutation>;
export type CreateTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMemberMutation,
  CreateTeamMemberMutationVariables
>;
export const UpdateTeamMemberDocument = gql`
  mutation UpdateTeamMember($input: UpdateTeamMemberInput!) {
    updateTeamMember(input: $input) {
      status
      node {
        ...TeamMember
      }
      errors {
        field
        code
      }
    }
  }
  ${TeamMemberFragmentDoc}
`;
export type UpdateTeamMemberMutationFn = Apollo.MutationFunction<
  UpdateTeamMemberMutation,
  UpdateTeamMemberMutationVariables
>;

/**
 * __useUpdateTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberMutation, { data, loading, error }] = useUpdateTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamMemberMutation,
    UpdateTeamMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>(
    UpdateTeamMemberDocument,
    options,
  );
}
export type UpdateTeamMemberMutationHookResult = ReturnType<typeof useUpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationResult = Apollo.MutationResult<UpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMemberMutation,
  UpdateTeamMemberMutationVariables
>;
export const DeleteTeamMemberDocument = gql`
  mutation DeleteTeamMember($input: DeleteTeamMemberInput!) {
    deleteTeamMember(input: $input) {
      status
      errors {
        field
        code
      }
    }
  }
`;
export type DeleteTeamMemberMutationFn = Apollo.MutationFunction<
  DeleteTeamMemberMutation,
  DeleteTeamMemberMutationVariables
>;

/**
 * __useDeleteTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMemberMutation, { data, loading, error }] = useDeleteTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTeamMemberMutation,
    DeleteTeamMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>(
    DeleteTeamMemberDocument,
    options,
  );
}
export type DeleteTeamMemberMutationHookResult = ReturnType<typeof useDeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationResult = Apollo.MutationResult<DeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteTeamMemberMutation,
  DeleteTeamMemberMutationVariables
>;
export const GetTeamMembersDocument = gql`
  query getTeamMembers(
    $filter: TeamMembersFilterInput
    $skip: Int
    $limit: Int
    $of: TeamMembersOrderBy
    $od: OrderDirection
  ) {
    getTeamMembers(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...TeamMember
      }
      count
    }
  }
  ${TeamMemberFragmentDoc}
`;

/**
 * __useGetTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMembersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetTeamMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(
    GetTeamMembersDocument,
    options,
  );
}
export function useGetTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(
    GetTeamMembersDocument,
    options,
  );
}
export type GetTeamMembersQueryHookResult = ReturnType<typeof useGetTeamMembersQuery>;
export type GetTeamMembersLazyQueryHookResult = ReturnType<typeof useGetTeamMembersLazyQuery>;
export type GetTeamMembersQueryResult = Apollo.QueryResult<
  GetTeamMembersQuery,
  GetTeamMembersQueryVariables
>;
export const GetTeamMemberDocument = gql`
  query getTeamMember($_id: ID!) {
    getTeamMember(_id: $_id) {
      ...TeamMember
    }
  }
  ${TeamMemberFragmentDoc}
`;

/**
 * __useGetTeamMemberQuery__
 *
 * To run a query within a React component, call `useGetTeamMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMemberQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetTeamMemberQuery(
  baseOptions: Apollo.QueryHookOptions<GetTeamMemberQuery, GetTeamMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeamMemberQuery, GetTeamMemberQueryVariables>(
    GetTeamMemberDocument,
    options,
  );
}
export function useGetTeamMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMemberQuery, GetTeamMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeamMemberQuery, GetTeamMemberQueryVariables>(
    GetTeamMemberDocument,
    options,
  );
}
export type GetTeamMemberQueryHookResult = ReturnType<typeof useGetTeamMemberQuery>;
export type GetTeamMemberLazyQueryHookResult = ReturnType<typeof useGetTeamMemberLazyQuery>;
export type GetTeamMemberQueryResult = Apollo.QueryResult<
  GetTeamMemberQuery,
  GetTeamMemberQueryVariables
>;
export const GetTeamMembersFilterDocument = gql`
  query getTeamMembersFilter(
    $filter: TeamMembersFilterInput
    $skip: Int
    $limit: Int
    $of: TeamMembersOrderBy
    $od: OrderDirection
  ) {
    getTeamMembers(filter: $filter, skip: $skip, limit: $limit, of: $of, od: $od) {
      nodes {
        ...TeamMembersFilter
      }
      count
    }
  }
  ${TeamMembersFilterFragmentDoc}
`;

/**
 * __useGetTeamMembersFilterQuery__
 *
 * To run a query within a React component, call `useGetTeamMembersFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMembersFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMembersFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      of: // value for 'of'
 *      od: // value for 'od'
 *   },
 * });
 */
export function useGetTeamMembersFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTeamMembersFilterQuery,
    GetTeamMembersFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeamMembersFilterQuery, GetTeamMembersFilterQueryVariables>(
    GetTeamMembersFilterDocument,
    options,
  );
}
export function useGetTeamMembersFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamMembersFilterQuery,
    GetTeamMembersFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeamMembersFilterQuery, GetTeamMembersFilterQueryVariables>(
    GetTeamMembersFilterDocument,
    options,
  );
}
export type GetTeamMembersFilterQueryHookResult = ReturnType<typeof useGetTeamMembersFilterQuery>;
export type GetTeamMembersFilterLazyQueryHookResult = ReturnType<
  typeof useGetTeamMembersFilterLazyQuery
>;
export type GetTeamMembersFilterQueryResult = Apollo.QueryResult<
  GetTeamMembersFilterQuery,
  GetTeamMembersFilterQueryVariables
>;
export const CreateWinBackDocument = gql`
  mutation CreateWinBack($input: CreateWinBackInput!) {
    createWinBack(input: $input) {
      status
      node {
        ...Order
      }
      errors {
        field
        code
      }
    }
  }
  ${OrderFragmentDoc}
`;
export type CreateWinBackMutationFn = Apollo.MutationFunction<
  CreateWinBackMutation,
  CreateWinBackMutationVariables
>;

/**
 * __useCreateWinBackMutation__
 *
 * To run a mutation, you first call `useCreateWinBackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWinBackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWinBackMutation, { data, loading, error }] = useCreateWinBackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWinBackMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWinBackMutation, CreateWinBackMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWinBackMutation, CreateWinBackMutationVariables>(
    CreateWinBackDocument,
    options,
  );
}
export type CreateWinBackMutationHookResult = ReturnType<typeof useCreateWinBackMutation>;
export type CreateWinBackMutationResult = Apollo.MutationResult<CreateWinBackMutation>;
export type CreateWinBackMutationOptions = Apollo.BaseMutationOptions<
  CreateWinBackMutation,
  CreateWinBackMutationVariables
>;
export const UpdateWinBackDocument = gql`
  mutation UpdateWinBack($input: UpdateWinBackInput!) {
    updateWinBack(input: $input) {
      status
      node {
        ...Order
      }
      errors {
        field
        code
      }
    }
  }
  ${OrderFragmentDoc}
`;
export type UpdateWinBackMutationFn = Apollo.MutationFunction<
  UpdateWinBackMutation,
  UpdateWinBackMutationVariables
>;

/**
 * __useUpdateWinBackMutation__
 *
 * To run a mutation, you first call `useUpdateWinBackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWinBackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWinBackMutation, { data, loading, error }] = useUpdateWinBackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWinBackMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWinBackMutation, UpdateWinBackMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWinBackMutation, UpdateWinBackMutationVariables>(
    UpdateWinBackDocument,
    options,
  );
}
export type UpdateWinBackMutationHookResult = ReturnType<typeof useUpdateWinBackMutation>;
export type UpdateWinBackMutationResult = Apollo.MutationResult<UpdateWinBackMutation>;
export type UpdateWinBackMutationOptions = Apollo.BaseMutationOptions<
  UpdateWinBackMutation,
  UpdateWinBackMutationVariables
>;
