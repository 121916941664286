import { intl } from 'contexts';
import { CustomerType, Notification } from 'components';

import { api } from './interceptors';

const downloadShippingOrder = async (
  orderIds: string[],
  shipmentNeeded: boolean,
  customerType = CustomerType.B2C
) => {
  try {
    const uri =
      customerType === CustomerType.B2B
        ? '/app/orders/b2b-shipment/zip'
        : '/app/orders/shipment/zip';
    const response = await api.post(uri, { orderIds, shipmentNeeded });
    const link = document.createElement('a');
    link.href = response.data;
    document.body.appendChild(link);
    link.click();
    return response;
  } catch (error) {
    Notification({
      type: 'error',
      title: intl.formatMessage({ id: 'notification.error' }),
      message: intl.formatMessage({ id: 'notification.orderStatus' }),
    });
    throw error;
  }
};

export { downloadShippingOrder };
