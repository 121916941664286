import moment, { Moment } from 'moment';

export const DATE_FORMAT_DD_MMM_YYYY = 'DD MMM YYYY';

export const defaultDateFormat = (date: Date) => moment(date).format('DD/MM/YYYY');

export const defaultDateTimeFormat = (date: Date) => moment(date).format('DD/MM/YYYY, HH:mm');

export const formatDate = (date: Date, format: string) =>
  moment(date).isValid() ? moment(date).format(format) : '';

export const transformUnitStart = (date: Moment, unitOfTime: moment.unitOfTime.StartOf) =>
  date.clone().utc().startOf(unitOfTime);

export const transformUnitEnd = (date: Moment, unitOfTime: moment.unitOfTime.StartOf) =>
  date.clone().utc().endOf(unitOfTime);

export const transformDateStart = (date: Moment) => transformUnitStart(date, 'date');
export const transformDateEnd = (date: Moment) => transformUnitEnd(date, 'date');

export const transformWeekStart = (date: Moment) => transformUnitStart(date, 'isoWeek');
export const transformWeekEnd = (date: Moment) => transformUnitEnd(date, 'isoWeek');

export const transformMonthStart = (date: Moment) => transformUnitStart(date, 'month');
export const transformMonthEnd = (date: Moment) => transformUnitEnd(date, 'month');

export const transformQuarterStart = (date: Moment) => transformUnitStart(date, 'quarter');
export const transformQuarterEnd = (date: Moment) => transformUnitEnd(date, 'quarter');

export const transformYearStart = (date: Moment) => transformUnitStart(date, 'year');
export const transformYearEnd = (date: Moment) => transformUnitEnd(date, 'year');

export const transformDateRange = (startDate: Moment, endDate?: Moment) => {
  if (!endDate) {
    return [transformDateStart(startDate), transformDateEnd(startDate)];
  }

  return [transformDateStart(startDate), transformDateEnd(endDate)];
};

export const transformWeekRange = (startDate: Moment, endDate?: Moment) => {
  if (!endDate) {
    return [transformWeekStart(startDate), transformWeekEnd(startDate)];
  }

  return [transformWeekStart(startDate), transformWeekEnd(endDate)];
};

export const transformMonthRange = (startDate: Moment, endDate?: Moment) => {
  if (!endDate) {
    return [transformMonthStart(startDate), transformMonthEnd(startDate)];
  }

  return [transformMonthStart(startDate), transformMonthEnd(endDate)];
};

export const transformQuarterRange = (startDate: Moment, endDate?: Moment) => {
  if (!endDate) {
    return [transformQuarterStart(startDate), transformQuarterEnd(startDate)];
  }

  return [transformQuarterStart(startDate), transformQuarterEnd(endDate)];
};

export const transformYearRange = (startDate: Moment, endDate?: Moment) => {
  if (!endDate) {
    return [transformYearStart(startDate), transformYearEnd(startDate)];
  }

  return [transformYearStart(startDate), transformYearEnd(endDate)];
};

export const daysRemaining = (startDate: Date, endDate: Date) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const resDiff = end.diff(start, 'days');
  if (resDiff < 0) {
    return 0;
  }
  return resDiff;
};
