import moment, { Moment } from 'moment';
import { GroupByPeriod } from '../api';
import { transformMonthRange } from './momentFormats';

const currentMonth = transformMonthRange(moment());

const initialFilterValues = {
  localFilterByTeam: null,
  localFilterByUser: null,
  localFilterByRole: null,
  localFilterByProject: null,
  localFilterByChannel: null,
  localPeriod: 'today',
  localDateRange: currentMonth,
  localGroupedBy: GroupByPeriod.Hour,
};

export const getLocalDashboardFilters = () => {
  const localDashboardFilters = localStorage.getItem('dashboardFilters');

  const parsedLocalDashboardFilters = localDashboardFilters
    ? JSON.parse(localDashboardFilters)
    : null;

  if (parsedLocalDashboardFilters) {
    const { localDateRange } = parsedLocalDashboardFilters;
    if (localDateRange) {
      const transformDateRange = [moment(localDateRange[0]).utc(), moment(localDateRange[1]).utc()];

      return {
        ...parsedLocalDashboardFilters,
        localDateRange: transformDateRange,
      };
    }
  }

  return initialFilterValues;
};

export const setLocalFilterByTeam = (teamId?: string | null) => {
  const localDashboardFilters = getLocalDashboardFilters();

  localStorage.setItem(
    'dashboardFilters',
    JSON.stringify({ ...localDashboardFilters, localFilterByTeam: teamId }),
  );
};

export const setLocalFilterByUser = (userId?: string | null) => {
  const localDashboardFilters = getLocalDashboardFilters();

  localStorage.setItem(
    'dashboardFilters',
    JSON.stringify({ ...localDashboardFilters, localFilterByUser: userId }),
  );
};

export const setLocalFilterByRole = (roleId?: string | null) => {
  const localDashboardFilters = getLocalDashboardFilters();

  localStorage.setItem(
    'dashboardFilters',
    JSON.stringify({ ...localDashboardFilters, localFilterByRole: roleId }),
  );
};

export const setLocalFilterByProject = (projectId?: string | null) => {
  const localDashboardFilters = getLocalDashboardFilters();

  localStorage.setItem(
    'dashboardFilters',
    JSON.stringify({
      ...localDashboardFilters,
      localFilterByProject: projectId,
    }),
  );
};

export const setLocalFilterByChannel = (channelId?: string | null) => {
  const localDashboardFilters = getLocalDashboardFilters();

  localStorage.setItem(
    'dashboardFilters',
    JSON.stringify({
      ...localDashboardFilters,
      localFilterByChannel: channelId,
    }),
  );
};

export const setLocalDateRange = (dateRange: Moment[] | null) => {
  const localDashboardFilters = getLocalDashboardFilters();
  if (dateRange) {
    localStorage.setItem(
      'dashboardFilters',
      JSON.stringify({ ...localDashboardFilters, localDateRange: dateRange }),
    );
  } else {
    const dateRangeResult = [null, null];
    localStorage.setItem(
      'dashboardFilters',
      JSON.stringify({
        ...localDashboardFilters,
        localDateRange: dateRangeResult,
      }),
    );
  }
};

export const setLocalGroupedBy = (groupedBy: GroupByPeriod) => {
  const localDashboardFilters = getLocalDashboardFilters();

  localStorage.setItem(
    'dashboardFilters',
    JSON.stringify({ ...localDashboardFilters, localGroupedBy: groupedBy }),
  );
};

export const setLocalPeriod = (period: string) => {
  const localDashboardFilters = getLocalDashboardFilters();

  localStorage.setItem(
    'dashboardFilters',
    JSON.stringify({ ...localDashboardFilters, localPeriod: period }),
  );
};

export const resetDashboardFilters = () => {
  localStorage.setItem('dashboardFilters', JSON.stringify(initialFilterValues));
};
