import { MapNestedRecord, NestedRecord } from 'types';

export function getLinks<T extends NestedRecord = NestedRecord>(
  paths: Partial<T>,
  prefix = '',
): MapNestedRecord<T> {
  const result: any = {};

  for (const [key, value] of Object.entries(paths)) {
    let newPrefix = prefix;

    if (paths.index === '/') {
      newPrefix = '';
    } else if (key !== 'index') {
      newPrefix = `${prefix}/${paths.index}`;
    }

    const newValue = value === '/' ? value : `${newPrefix}/${value}`;
    if (typeof value === 'object') {
      result[key] = getLinks(value, newPrefix);
    }
    if (typeof value === 'string') {
      result[key] = newValue;
    }
  }

  return result;
}
