import React, { useEffect } from 'react';
// import { Prompt } from 'react-router-dom';

interface RouterPromptPropsI {
  children: React.ReactNode;
  when?: boolean;
  message: string;
}

const RouterPrompt = ({ when, message, children }: RouterPromptPropsI) => {
  const showAlertMessage = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
  };

  // Browser default alert on reload/close page
  useEffect(() => {
    if (when) {
      window.addEventListener('beforeunload', showAlertMessage);
    }
    return () => window.removeEventListener('beforeunload', showAlertMessage);
  }, [when]);

  return (
    <>
      {/* TODO: Need to implement this logic for v6 */}
      {/* <Prompt when={when} message={message} /> */}
      {children}
    </>
  );
};

export default RouterPrompt;
