import classNames from 'classnames';
import { calculatePercent } from 'utils';

import './statisticBar.less';

interface StatisticBarPropsI {
  className?: string;
  values: {
    color: 'green' | 'yellow' | 'red' | 'gray' | string;
    value: number;
  }[];
  total: number;
}

const StatisticBar = ({ className, values, total }: StatisticBarPropsI) => {
  const calculatePercents = values.reduce((accum: any[], item) => {
    if (total === 0 || item.value === 0) {
      return accum;
    }

    return [...accum, { ...item, percent: calculatePercent(item.value, total) }];
  }, []);

  return (
    <div className={classNames('statistic-bar', className)}>
      {calculatePercents.length ? (
        calculatePercents.map(({ color, percent }) => (
          <div
            key={color}
            className={classNames('statistic-bar__item', `statistic-bar__item--${color}`)}
            style={{ width: percent }}
          />
        ))
      ) : (
        <div className="statistic-bar__item statistic-bar__item--gray" style={{ width: '100%' }} />
      )}
    </div>
  );
};

export default StatisticBar;
