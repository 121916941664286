import React, { useCallback, useState } from 'react';
import {
  RoleFragment,
  TeamFragment,
  ProductFragment,
  RbacResourceName,
  TeamMemberFragment,
  SalesChannelFragment,
  ProductDiscountStatus,
  ProductDiscountFragment,
  useDeleteProductDiscountMutation,
  useUpdateProductDiscountMutation,
} from 'api';
import {
  Table,
  Status,
  Column,
  ItemsPopover,
  EditDiscountModal,
} from 'components';
import {
  DotsIcon,
  EditIcon,
  TrashIcon,
  ProjectSettingsIcon,
} from 'assets/icons';
import { useIntl } from 'react-intl';
import { usePermission } from 'hooks';
import { discountRound } from 'utils';
import { useErrorsContext } from 'contexts';

import './discountsTable.less';

interface DiscountsTablePropsI {
  limit: number;
  total?: number;
  loading: boolean;
  currentPage: number;
  refetch: () => void;
  showProduct?: boolean;
  teams: TeamFragment[];
  roles: RoleFragment[];
  teamMembers: TeamMemberFragment[];
  fetchMore: (page: number) => void;
  discounts: ProductDiscountFragment[];
  salesChannels: SalesChannelFragment[];
  onChange: (pagination: any, filters: any, sorter: any) => void;
}

const DiscountsTable = ({
  limit,
  total,
  teams,
  roles,
  loading,
  refetch,
  onChange,
  fetchMore,
  discounts,
  currentPage,
  teamMembers,
  showProduct,
  salesChannels,
}: DiscountsTablePropsI) => {
  const [editDiscountVisible, setEditDiscountVisible] = useState(false);
  const [editDiscountInfo, setEditDiscountInfo] =
    useState<ProductDiscountFragment>();

  const { setErrors } = useErrorsContext();

  const { formatMessage } = useIntl();

  const { isPermitted: isPermittedManaging } = usePermission(
    RbacResourceName.DiscountManaging
  );

  const [updateDiscount] = useUpdateProductDiscountMutation({
    onCompleted({ updateProductDiscount }) {
      const { status, errors } = updateProductDiscount || {};
      const success = status === 'SUCCESS';
      if (success) {
        return refetch();
      }

      setErrors(errors);
    },
  });

  const [deleteDiscount] = useDeleteProductDiscountMutation({
    onCompleted({ deleteProductDiscount }) {
      const { status, errors } = deleteProductDiscount || {};
      const success = status === 'SUCCESS';

      if (success) {
        return refetch();
      }

      setErrors(errors);
    },
  });

  const handleMenuClick = useCallback(
    (
      value: string,

      _id?: string,
      productDiscount?: ProductDiscountFragment
    ) => {
      if (value === 'edit') {
        toggleEditDiscountVisible(productDiscount);
      }

      if (_id && value === 'toggle') {
        updateDiscount({
          variables: {
            input: {
              _id,
              status:
                productDiscount?.status === 'ACTIVE'
                  ? ProductDiscountStatus.Inactive
                  : ProductDiscountStatus.Active,
            },
          },
        });
      }

      if (_id && value === 'delete') {
        deleteDiscount({ variables: { input: { _id } } });
      }
    },
    [deleteDiscount, updateDiscount]
  );

  const toggleEditDiscountVisible = (
    productDiscount?: ProductDiscountFragment
  ) => {
    if (productDiscount) {
      setEditDiscountInfo(productDiscount);
    }

    setEditDiscountVisible((prev) => !prev);
  };

  const popoverContent = ({ status }: { status: ProductDiscountStatus }) => [
    {
      title:
        status === 'ACTIVE'
          ? formatMessage({ id: 'button.Deactivate' })
          : formatMessage({ id: 'button.Activate' }),
      value: 'toggle',
      icon: (
        <ProjectSettingsIcon className="discounts-table__icon discounts-table__icon--gray" />
      ),
    },
    {
      title: formatMessage({ id: 'button.Edit' }),
      value: 'edit',
      icon: (
        <EditIcon className="discounts-table__icon discounts-table__icon--gray" />
      ),
    },
    {
      title: formatMessage({ id: 'button.Delete' }),
      value: 'delete',
      icon: <TrashIcon className="discounts-table__icon" />,
    },
  ];

  return (
    <div className="discounts-table">
      <Table
        dataSource={discounts}
        currentPage={currentPage}
        total={total}
        limit={limit}
        fetchMore={fetchMore}
        rowKey="_id"
        onChange={onChange}
        loading={loading}
      >
        {showProduct && (
          <Column
            title={formatMessage({ id: 'Product' })}
            dataIndex="product"
            render={(product: ProductFragment) => product?.name}
          />
        )}
        <Column
          title={formatMessage({ id: 'Quantity' })}
          dataIndex="qty"
          render={(qty: string) => `x${qty}`}
          sorter
        />
        <Column
          title={formatMessage({ id: 'Team' })}
          dataIndex="team"
          render={(team: TeamFragment) => team?.name || '-'}
        />
        <Column
          title={formatMessage({ id: 'Role' })}
          dataIndex="role"
          render={(role: RoleFragment) => role?.name || '-'}
        />
        <Column
          title={
            <span className="discounts-table__title">
              {formatMessage({ id: 'User' })}
            </span>
          }
          dataIndex="teamMember"
          className="discounts-table__colored"
          render={(teamMember: TeamMemberFragment) =>
            teamMember?.fullName || '-'
          }
        />
        <Column
          title={formatMessage({ id: 'Sales channel' })}
          dataIndex="salesChannel"
          render={(salesChannel: SalesChannelFragment) =>
            salesChannel?.name || '-'
          }
        />
        <Column
          title={formatMessage({ id: 'Percent' })}
          dataIndex="discount"
          render={(discount: number) => `~${discountRound(discount)}%`}
          sorter
        />
        <Column
          title={formatMessage({ id: 'Author' })}
          dataIndex="author"
          render={(author: TeamMemberFragment) => author?.fullName}
        />
        <Column
          title={formatMessage({ id: 'Status' })}
          dataIndex="status"
          render={(status: string) => <Status status={status} />}
        />
        {isPermittedManaging && (
          <Column
            title={formatMessage({ id: 'Actions' })}
            key="actions"
            className="discounts-table__actions"
            render={(
              _: undefined,
              productDiscount: ProductDiscountFragment
            ) => {
              const discountId = productDiscount?._id;
              const content = popoverContent(productDiscount);

              return (
                <ItemsPopover
                  content={content}
                  onItemClick={(value: string) =>
                    handleMenuClick(value, discountId, productDiscount)
                  }
                >
                  <DotsIcon className="discounts-table__menu" />
                </ItemsPopover>
              );
            }}
          />
        )}
      </Table>

      <EditDiscountModal
        teams={teams}
        roles={roles}
        refetch={refetch}
        teamMembers={teamMembers}
        visible={editDiscountVisible}
        salesChannels={salesChannels}
        productDiscount={editDiscountInfo}
        onCancel={toggleEditDiscountVisible}
        setUpdatedDiscount={(data: ProductDiscountFragment) => setEditDiscountInfo(data)}
      />
    </div>
  );
};

export default DiscountsTable;
