import React, { useEffect, useState } from 'react';
import {
  RoleFragment,
  TeamFragment,
  TeamMemberFragment,
  SalesChannelFragment,
  ProductDiscountStatus,
  ProductDiscountFragment,
  useUpdateProductDiscountMutation,
} from 'api';
import { Modal, Form } from 'antd';
import { useIntl } from 'react-intl';
import { useErrorsContext } from 'contexts';
import { Title, DiscountForm } from 'components';

const { useForm } = Form;

interface EditDiscountModalPropsI {
  visible?: boolean;
  refetch: () => void;
  onCancel: () => void;
  teams: TeamFragment[];
  roles: RoleFragment[];
  teamMembers: TeamMemberFragment[];
  salesChannels: SalesChannelFragment[];
  productDiscount?: ProductDiscountFragment;
  setUpdatedDiscount?: (data: ProductDiscountFragment) => void;
}

interface EditDiscountFormValuesI {
  qty?: number;
  discount?: number;
  teamId?: string | null;
  roleId?: string | null;
  teamMemberId?: string | null;
  salesChannelId?: string | null;
}

const initialData = {
  qty: 0,
  teamId: '',
  roleId: '',
  product: '',
  _id: '',
  discount: 0,
  teamMemberId: '',
  salesChannelId: '',
};

const EditDiscountModal = ({
  teams,
  roles,
  visible,
  refetch,
  onCancel,
  teamMembers,
  salesChannels,
  productDiscount,
  setUpdatedDiscount,
}: EditDiscountModalPropsI) => {
  const [form] = useForm();
  const { setErrors } = useErrorsContext();
  const { formatMessage } = useIntl();
  const [data, setData] = useState(initialData);

  const [updateDiscount, { loading }] = useUpdateProductDiscountMutation({
    onCompleted({ updateProductDiscount }) {
      const { status, errors } = updateProductDiscount || {};
      const success = status === 'SUCCESS';
      if (success) {
        refetch();
        if (setUpdatedDiscount && updateProductDiscount?.node) {
          setUpdatedDiscount(updateProductDiscount.node);
        }
        return onCancel();
      }

      setErrors(errors);
    },
  });

  const { product, _id = '' } = productDiscount || {};

  useEffect(() => {
    const { qty, teamId, roleId, discount, teamMemberId, salesChannelId } =
      productDiscount || {};

    const res = () => {
      if (teamMemberId) {
        return {
          ...data,
          discountType: 'byUser',
          qty,
          discount,
          teamMemberId,
        };
      }

      if (roleId) {
        return {
          ...data,
          discountType: 'byRole',
          qty,
          discount,
          roleId,
          salesChannelId,
        };
      }

      if (teamId) {
        return {
          ...data,
          discountType: 'byTeam',
          qty,
          discount,
          teamId,
          salesChannelId,
        };
      }

      return {
        ...data,
        discountType: 'byQty',
        qty,
        discount,
        salesChannelId,
      };
    };
    setData(res as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDiscount]);

  const handleSubmit = ({
    qty,
    discount,
    teamId = null,
    roleId = null,
    teamMemberId = null,
    salesChannelId = null,
  }: EditDiscountFormValuesI) => {
    updateDiscount({
      variables: {
        input: {
          _id,
          qty,
          teamId,
          roleId,
          discount,
          teamMemberId,
          salesChannelId,
          status: ProductDiscountStatus.Active,
        },
      },
    });
  };

  return (
    <Modal
      className="edit-discount-modal"
      width={420}
      open={visible}
      title={
        <Title size="middle">{formatMessage({ id: 'Edit discount' })}</Title>
      }
      okText={formatMessage({ id: 'button.Save' })}
      cancelText={formatMessage({ id: 'button.Cancel' })}
      onCancel={onCancel}
      onOk={() => form.validateFields().then(handleSubmit)}
      confirmLoading={loading}
      destroyOnClose
    >
      <DiscountForm
        form={form}
        teams={teams}
        roles={roles}
        product={product}
        teamMembers={teamMembers}
        salesChannels={salesChannels}
        initialValues={data}
      />
    </Modal>
  );
};

export default EditDiscountModal;
