const dispatchCustomEvent = (
  type: string,
  canBubble: boolean = true,
  cancelable: boolean = true,
  detail?: any
) => {
  const event = document.createEvent('customevent') as CustomEvent;
  event.initCustomEvent(type, canBubble, cancelable, detail);
  window.dispatchEvent(event);
};

export { dispatchCustomEvent };
