import classnames from 'classnames';
import React, { useState } from 'react';
import { Logo, LogoText } from 'assets/images';
import { RoundedArrowIcon } from 'assets/icons';
import { Navigation } from 'components';
import { Layout } from 'antd';

const { Sider } = Layout;

function AppSider() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="home__sider-wrapper">
      <div
        className={classnames('trigger', {
          collapsed,
        })}
        onClick={() => setCollapsed(!collapsed)}
      >
        <RoundedArrowIcon />
      </div>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className={classnames('home__sider', {
          collapsed,
        })}
        theme="light"
        trigger={null}
        collapsedWidth={64}
      >
        <div
          className={classnames('home__logo', {
            collapsed,
          })}
        >
          <Logo />
          {!collapsed && <LogoText />}
        </div>
        <Navigation collapsed={collapsed} />
      </Sider>
    </div>
  );
}

export default AppSider;
