import { FormattedMessage } from 'react-intl';

const getPostponeOptions = (productFreeMonths?: number) =>
  productFreeMonths
    ? Array.from({ length: productFreeMonths + 1 }, (_, i) => ({
        value: i,
        label: <FormattedMessage id="monthDuration" values={{ duration: i }} />,
      }))
    : [
        {
          value: 0,
          label: <FormattedMessage id="monthDuration" values={{ duration: 0 }} />,
        },
      ];

const getProductBundlePostponeOptions = (product: any) => {
  const minProductsFreeMonths = product?.products.reduce(
    (minValue: number, { product: productBundleProduct }: { product: { freeMonths: number } }) =>
      minValue < productBundleProduct.freeMonths ? minValue : productBundleProduct.freeMonths,
    12,
  );

  return getPostponeOptions(minProductsFreeMonths);
};

export { getPostponeOptions, getProductBundlePostponeOptions };
