import React, { ReactNode, ChangeEvent } from 'react';
import { Input, Space } from 'antd';
import classNames from 'classnames';
import { Select } from 'components';
import { useIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';

import './searchWithParams.less';

interface SearchWithParamsPropsI {
  className?: string;
  defaultValue: any;
  value?: any;
  onSearch: (value: string) => void;
  onSelect: (value: any, option: any) => void;
  searchOptions: { value: any; label: string | ReactNode }[];
  optionValue?: string | undefined;
}

const SearchWithParams = ({
  onSelect,
  onSearch,
  className,
  defaultValue,
  value,
  searchOptions,
  optionValue,
}: SearchWithParamsPropsI) => {
  const { formatMessage } = useIntl();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (onSearch) {
      onSearch(e.target.value);
    }
  };

  return (
    <Space.Compact className={classNames('search-with-params', className)}>
      <Select
        className="search-with-params__select"
        size="large"
        onChange={onSelect}
        options={searchOptions}
        defaultValue={defaultValue}
        value={optionValue}
      />
      <Input
        value={value || undefined}
        className="search-with-params__input"
        size="large"
        placeholder={formatMessage({ id: 'Search' })}
        onChange={handleSearch}
        suffix={<SearchOutlined className="search-with-params__icon" />}
      />
    </Space.Compact>
  );
};

export default SearchWithParams;
