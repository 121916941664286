import { FormattedMessage } from 'react-intl';
import { defaultFormatPhoneNumber } from 'utils';
import { CreditStatus } from 'components';

export const columns = [
  {
    title: <FormattedMessage id="ID" />,
    dataIndex: '_id',
  },
  {
    title: <FormattedMessage id="Full name" />,
    dataIndex: 'fullName',
    sorter: true,
  },
  {
    title: <FormattedMessage id="Personnummer" />,
    className: 'no-wrap',
    dataIndex: 'personId',
  },
  {
    title: <FormattedMessage id="Email" />,
    dataIndex: 'email',
  },
  {
    title: <FormattedMessage id="Phone number" />,
    className: 'no-wrap',
    dataIndex: 'phoneNumber',
    render: (phoneNumber: string) => defaultFormatPhoneNumber(phoneNumber),
  },
  {
    title: <FormattedMessage id="Credit check" />,
    dataIndex: 'creditCheckCode',
    render: (creditCheckCode: number) => <CreditStatus statusCode={creditCheckCode} />,
  },
];
