import { useIntl } from 'react-intl';

import './creditStatus.less';

const CreditStatus = ({ statusCode }: { statusCode?: number | null }) => {
  const { formatMessage } = useIntl();

  if (statusCode === 1) {
    return (
      <span className="credit-status credit-status--approved">
        {formatMessage({ id: 'Approved' })}
      </span>
    );
  }

  if (statusCode === 2) {
    return (
      <span className="credit-status credit-status--rejected">
        {formatMessage({ id: 'Rejected' })}
      </span>
    );
  }

  return <span className="credit-status">{formatMessage({ id: 'Not required' })}</span>;
};

export default CreditStatus;
