import React, { useState } from 'react';
import { Form, Radio } from 'antd';
import { useIntl } from 'react-intl';

import { CheckIcon } from 'assets/icons';
import { callFunction } from 'utils';

import './radioGroup.less';

const { Item: FormItem } = Form;

interface RadioGroupPropsI {
  formItemName: string;
  formItemClassName?: string;
  formItemInitialValue?: string | number;
  label: string;
  options: { value: string | number; label: any }[];
  onChange?: (value: any) => void;
}

const RadioGroup = ({
  formItemName,
  formItemClassName,
  formItemInitialValue,
  label,
  options,
  onChange,
}: RadioGroupPropsI) => {
  const { formatMessage } = useIntl();
  const [value, setValue] = useState<string | number | undefined>(
    formItemInitialValue
  );

  const onChangeRadio = (event: any) => {
    setValue(event.target.value);
    callFunction(onChange, event.target.value);
  };

  return (
    <FormItem
      name={formItemName}
      className={`radio-group ${formItemClassName}`}
      label={label}
      rules={[{ required: true, message: <div className='error-message-wraper'>{formatMessage({
        id: 'Please select Invoicing frequency',
      })}</div> }]}
    >
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        onChange={onChangeRadio}
      >
        {options.map(({ value: optionValue, label }) => (
          <Radio.Button key={optionValue} value={optionValue}>
            {value === optionValue && (
              <CheckIcon className="radio-group__icon-check" />
            )}
            {label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </FormItem>
  );
};

export default RadioGroup;
