import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

import './multiSelect.less';

const MultiSelect = (props: SelectProps<any>) => (
  <Select
    className="multi-select"
    optionFilterProp="label"
    getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
    {...props}
  />
);

export default MultiSelect;
