import React, { ComponentType } from 'react';
import { ErrorBoundary } from 'components';
import { usePermission } from 'hooks';
import { Navigate } from 'react-router-dom';
import { Links } from 'settings';

interface ProtectedRoutePropsI<T> {
  action: string | string[];
  component: ComponentType<Partial<T>>;
  componentProps?: T;
}

function ProtectedRoute<PropsTypes>({
  component: Component,
  action,
  componentProps,
}: ProtectedRoutePropsI<PropsTypes>) {
  const { isPermitted } = usePermission(action);

  if (!Component) {
    return null;
  }

  if (!isPermitted) {
    return (
      <Navigate
        to={{
          pathname: Links.dashboard.index,
        }}
      />
    );
  }

  return (
    <ErrorBoundary>
      <Component {...componentProps} />
    </ErrorBoundary>
  );
}

export default ProtectedRoute;
