import { useState } from 'react';

const useSort = () => {
  const [sortBy, setSortBy] = useState<any>();
  const [orderBy, setOrderBy] = useState<any>(null);

  const handleOrder = (order?: 'ascend' | 'descend') => {
    if (order === 'ascend') {
      return 'ASC';
    }

    if (order === 'descend') {
      return 'DESC';
    }

    return null;
  };

  const setSort = (pagination: any, filters: any, sorter: any) => {
    const { order, field } = sorter;
    const ordered = handleOrder(order);
    setOrderBy(ordered);

    if (ordered) {
      return setSortBy(field);
    }

    return setSortBy(null);
  };

  return { sortBy, orderBy, setSort };
};

export { useSort };
