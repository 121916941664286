import { DeleteTimer } from 'assets/icons';
import moment from 'moment';
import './deletePeriodBadge.less';

const DeletePeriodBadge = ({ dateString }: { dateString: string | null | undefined }) => {
  const contractIsSignedDuration = moment.duration(
    moment(moment(dateString).add(3, 'days')).diff(moment()),
  );
  const days = dateString ? contractIsSignedDuration.days() : 0;
  const hours = dateString ? contractIsSignedDuration.hours() : 0;

  return (
    <>
      {days <= 3 && hours <= 24 && days >= 0 && hours > 0 && (
        <div className="delete-period-badge">
          <DeleteTimer />
          <span>
            {days} days {hours} hours
          </span>
        </div>
      )}
    </>
  );
};

export default DeletePeriodBadge;
