import { Tag } from 'components';
import { useIntl } from 'react-intl';
import { OrderContractStatus } from 'api';
import { CloseRedIcon } from 'assets/icons';

const ContractStatusTag = ({ status }: { status?: string }) => {
  const { formatMessage } = useIntl();

  if (status === OrderContractStatus.Unsigned) {
    return <Tag color="warning">{formatMessage({ id: 'Unsigned' })}</Tag>;
  }

  if (status === OrderContractStatus.Signed) {
    return <Tag color="success">{formatMessage({ id: 'Sale' })}</Tag>;
  }

  return (
    <Tag color="error">
      <CloseRedIcon />
    </Tag>
  );
};

export default ContractStatusTag;
