import { useState } from 'react';
import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { transformDateStart } from 'utils';

const useDateRangeFilter = (resetPagination?: () => void) => {
  const [startDate, setStartDate] = useState<Moment | null | undefined>();
  const [endDate, setEndDate] = useState<Moment | null | undefined>();

  const reset = () => (resetPagination ? resetPagination() : null);

  const setDateRange = (dates: Moment[] | RangeValue<Moment> | null | undefined) => {
    if (!dates) {
      setStartDate(undefined);
      setEndDate(undefined);
      return reset();
    }

    const [startDateFromRange, endDateFromRange] = dates;

    if (startDateFromRange && endDateFromRange) {
      setStartDate(transformDateStart(startDateFromRange));
      setEndDate(transformDateStart(endDateFromRange).endOf('day'));
    }
    return reset();
  };
  return { startDate, endDate, setDateRange };
};

export { useDateRangeFilter };
