import React, { ReactNode, useState } from 'react';
import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import './itemsPopover.less';

const ItemsPopover = ({
  children,
  content,
  trigger = 'click',
  onItemClick,
  ...rest
}: ItemsPopoverPropsI) => {
  const [visiblePopover, setVisiblePopover] = useState(false);
  return (
    <Popover
      arrow
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      trigger={trigger}
      open={visiblePopover}
      onOpenChange={setVisiblePopover}
      content={
        <div className="items-popover">
          {content.map((item) => (
            <div
              key={item.value}
              className="popover-item"
              onClick={() => {
                onItemClick(item.value);
                setVisiblePopover(false);
              }}
            >
              {item.icon}
              <p className="popover-item__title">{item.title}</p>
            </div>
          ))}
        </div>
      }
      {...rest}
    >
      {children}
    </Popover>
  );
};

interface ItemsPopoverPropsI {
  placement?: TooltipPlacement;
  content: { title: string | ReactNode; value: string; icon: ReactNode }[];
  onItemClick: (value: string) => void;
  children: ReactNode;
  trigger?: 'focus' | 'hover' | 'click';
}

export default ItemsPopover;
