import { getLinks } from 'utils/getLinks';

export const Paths = {
  index: '/',
  dashboard: {
    index: 'dashboard',
  },
  users: {
    index: 'users',
    details: 'view/:id',
    create: 'create',
    edit: 'edit/:id',
  },
  teams: {
    index: 'teams',
    details: 'view/:id',
    viewRole: ':teamId/role/:roleId',
  },
  myTeam: {
    index: 'my-team',
    viewRole: ':teamId/role/:roleId',
  },
  projects: {
    index: 'projects',
    create: 'create',
    details: 'view/:id',
    edit: 'edit/:id',
    settings: 'settings/:id',
  },
  products: {
    index: 'products',
    create: 'create',
    details: 'view/:id',
    edit: 'edit/:id',
    settings: 'settings/:id',
  },
  bundles: {
    index: 'bundles',
    details: 'view/:id',
  },
  addons: {
    index: 'addons',
    details: 'view/:id',
  },
  customers: {
    index: 'customers',
    details: 'view/:id',
  },
  orders: {
    index: 'orders',
    create: 'create',
    details: 'view/:id',
    winback: ':id/winback',
    b2bView: 'b2b-view/:id',
  },
  shipping: {
    index: 'shipping',
  },
  licenses: {
    index: 'licenses',
  },
  soSafe: {
    index: 'soSafe',
    details: 'view/:id',
  },
  partnerStores: {
    index: 'partner-stores',
    create: 'create',
    details: 'details/:id',
    edit: 'edit',
  },
  batches: {
    index: 'batches',
  },
} as const;

export type PathsType = typeof Paths;

export const Links = getLinks(Paths);
