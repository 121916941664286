import React, { useCallback, useState } from 'react';
import {
  RoleFragment,
  TeamFragment,
  ProductFragment,
  RbacResourceName,
  CommissionStatus,
  CommissionFragment,
  TeamMemberFragment,
  SalesChannelFragment,
  useDeleteCommissionMutation,
  useUpdateCommissionMutation,
} from 'api';
import {
  Table,
  Status,
  Column,
  ItemsPopover,
  EditCommissionModal,
} from 'components';
import {
  DotsIcon,
  EditIcon,
  TrashIcon,
  ProjectSettingsIcon,
} from 'assets/icons';
import { useIntl } from 'react-intl';
import { usePermission } from 'hooks';
import { useErrorsContext } from 'contexts';

import './commissionsTable.less';

interface CommissionsTablePropsI {
  limit: number;
  total?: number;
  loading: boolean;
  currentPage: number;
  refetch: () => void;
  showProduct?: boolean;
  teams: TeamFragment[];
  roles: RoleFragment[];
  teamMembers: TeamMemberFragment[];
  fetchMore: (page: number) => void;
  commissions: CommissionFragment[];
  salesChannels: SalesChannelFragment[];
  onChange: (pagination: any, filters: any, sorter: any) => void;
}

const CommissionsTable = ({
  limit,
  total,
  teams,
  roles,
  loading,
  refetch,
  onChange,
  fetchMore,
  commissions,
  currentPage,
  teamMembers,
  showProduct,
  salesChannels,
}: CommissionsTablePropsI) => {
  const [editCommissionVisible, setEditCommissionVisible] = useState(false);
  const [editCommissionInfo, setEditCommissionInfo] =
    useState<CommissionFragment>();

  const { setErrors } = useErrorsContext();

  const { formatMessage } = useIntl();

  const { isPermitted: isPermittedManaging } = usePermission(
    RbacResourceName.CommissionManaging
  );

  const [updateCommission] = useUpdateCommissionMutation({
    onCompleted({ updateCommission }) {
      const { status, errors } = updateCommission || {};
      const success = status === 'SUCCESS';

      if (success) {
        return refetch();
      }

      setErrors(errors);
    },
  });

  const [deleteCommission] = useDeleteCommissionMutation({
    onCompleted({ deleteCommission }) {
      const { status, errors } = deleteCommission;
      const success = status === 'SUCCESS';

      if (success) {
        return refetch();
      }

      setErrors(errors);
    },
  });

  const handleMenuClick = useCallback(
    (value: string, _id?: string, commission?: CommissionFragment) => {
      if (value === 'edit') {
        toggleEditCommissionVisible(commission);
      }

      if (_id && value === 'toggle') {
        updateCommission({
          variables: {
            input: {
              _id,
              status:
                commission?.status === 'ACTIVE'
                  ? CommissionStatus.Inactive
                  : CommissionStatus.Active,
            },
          },
        });
      }

      if (_id && value === 'delete') {
        deleteCommission({ variables: { input: { _id } } });
      }
    },
    [deleteCommission, updateCommission]
  );

  const toggleEditCommissionVisible = (commission?: CommissionFragment) => {
    if (commission) {
      setEditCommissionInfo(commission);
    }

    setEditCommissionVisible((prev) => !prev);
  };

  const popoverContent = ({ status }: { status: CommissionStatus }) => [
    {
      title:
        status === 'ACTIVE'
          ? formatMessage({ id: 'button.Deactivate' })
          : formatMessage({ id: 'button.Activate' }),
      value: 'toggle',
      icon: (
        <ProjectSettingsIcon className="commissions-table__icon commissions-table__icon--gray" />
      ),
    },
    {
      title: formatMessage({ id: 'button.Edit' }),
      value: 'edit',
      icon: (
        <EditIcon className="commissions-table__icon commissions-table__icon--gray" />
      ),
    },
    {
      title: formatMessage({ id: 'button.Delete' }),
      value: 'delete',
      icon: <TrashIcon className="commissions-table__icon" />,
    },
  ];

  return (
    <div className="commissions-table">
      <Table
        dataSource={commissions}
        currentPage={currentPage}
        total={total}
        limit={limit}
        fetchMore={fetchMore}
        rowKey="_id"
        onChange={onChange}
        loading={loading}
      >
        {showProduct && (
          <Column
            title={formatMessage({ id: 'Product' })}
            dataIndex="product"
            render={(product: ProductFragment) => product?.name}
          />
        )}
        <Column
          title={formatMessage({ id: 'Quantity' })}
          dataIndex="qty"
          render={(qty: string) => (qty ? `x${qty}` : '-')}
          sorter
        />
        <Column
          title={formatMessage({ id: 'Team' })}
          dataIndex="team"
          render={(team: TeamFragment) => team?.name || '-'}
        />
        <Column
          title={formatMessage({ id: 'Role' })}
          dataIndex="role"
          render={(role: RoleFragment) => role?.name || '-'}
        />
        <Column
          title={
            <span className="commissions-table__title">
              {formatMessage({ id: 'User' })}
            </span>
          }
          dataIndex="teamMember"
          className="commissions-table__colored"
          render={(teamMember: TeamMemberFragment) =>
            teamMember?.fullName || '-'
          }
        />
        <Column
          title={formatMessage({ id: 'Sales channel' })}
          dataIndex="salesChannel"
          render={(salesChannel: SalesChannelFragment) =>
            salesChannel?.name || '-'
          }
        />
        <Column
          title={formatMessage({ id: 'Size' })}
          dataIndex="size"
          render={(size: string) => `${size} kr`}
          sorter
        />
        <Column
          title={formatMessage({ id: 'Author' })}
          dataIndex="author"
          render={(author: TeamMemberFragment) => author?.fullName}
        />
        <Column
          title={formatMessage({ id: 'Status' })}
          dataIndex="status"
          render={(status: string) => <Status status={status} />}
        />
        {isPermittedManaging && (
          <Column
            title={formatMessage({ id: 'Actions' })}
            key="actions"
            className="commissions-table__actions"
            render={(_: undefined, commission: any) => {
              const commissionId = commission?._id;
              const content = popoverContent(commission);

              return (
                <ItemsPopover
                  content={content}
                  onItemClick={(value: string) =>
                    handleMenuClick(value, commissionId, commission)
                  }
                >
                  <DotsIcon className="commissions-table__menu" />
                </ItemsPopover>
              );
            }}
          />
        )}
      </Table>

      {editCommissionVisible && (
        <EditCommissionModal
          teams={teams}
          roles={roles}
          refetch={refetch}
          teamMembers={teamMembers}
          salesChannels={salesChannels}
          visible={editCommissionVisible}
          commission={editCommissionInfo}
          onCancel={toggleEditCommissionVisible}
        />
      )}
    </div>
  );
};

export default CommissionsTable;
