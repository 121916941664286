import { Component } from 'react';
import { logOut } from 'utils';
import * as Sentry from '@sentry/react';
import { Card, Button, Layout } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CloseCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import './errorBoundary.less';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo)
  componentDidCatch(error: any, errorInfo: any): void {
    const invalidToken = /Invalid token/gi;
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      this.reload();
    } else if (invalidToken.test(error.message)) {
      logOut();
    } else {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  reload = () => window.location.reload();

  render() {
    const { hasError } = this.state;
    const { children }: any = this.props;

    if (hasError) {
      return (
        <Layout className="error-boundary">
          <Card
            className="error-boundary__card"
            headStyle={{ background: '#ff4d4f', textAlign: 'center' }}
            title={<CloseCircleOutlined className="error-boundary__icon" />}
            bordered={false}
            actions={[
              <Button
                key="refresh"
                type="primary"
                icon={<ReloadOutlined />}
                onClick={this.reload}
                danger
              >
                <FormattedMessage id="button.Refresh">
                  {(txt) => <span>{txt}</span>}
                </FormattedMessage>
              </Button>,
            ]}
          >
            <h1 className="error-boundary__title">
              <FormattedMessage id="Warning" />
            </h1>
            <p className="error-boundary__text">
              <FormattedMessage id="text.errorBoundarySubtitle" />
            </p>
            <p className="error-boundary__text">
              <FormattedMessage id="text.errorBoundaryText" />
            </p>
          </Card>
        </Layout>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
