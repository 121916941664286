import React, { ReactNode, memo } from 'react';
import { Switch } from 'antd';
import classNames from 'classnames';
import './itemSwitcher.less';

const ItemSwitcher = ({ text, onChange, icon, type, disabled, ...rest }: ItemSwitcherPropsI) => (
  <div className="item-switcher">
    <p
      className={classNames('item-switcher__text', {
        'item-switcher__text--secondary': type === 'secondary',
      })}
    >
      {text}
    </p>
    <div
      className={classNames('switcher-block', {
        'switcher-block--secondary': type === 'secondary',
      })}
    >
      <Switch
        disabled={disabled}
        onChange={(checked: boolean) => onChange(checked, text)}
        {...rest}
      />
      {icon}
    </div>
  </div>
);

interface ItemSwitcherPropsI {
  text: string;
  onChange: (checked: boolean, text: string) => void;
  checked?: boolean;
  icon?: ReactNode;
  type?: 'secondary';
  size?: 'small' | 'default';
  loading?: boolean;
  disabled?: boolean;
}

export default memo(ItemSwitcher);
