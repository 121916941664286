const ROUTES = {
  ORDERS: '/orders',
  ORDERS_VIEW: '/orders/view',
  PRODUCTS_VIEW: '/products/view',
  PRODUCTS_LIST: '/products',
};

export const ordersIdViewRoute = (id: string) => `${ROUTES.ORDERS_VIEW}/${id}`;
export const productsIdViewRoute = (id: string | undefined) =>
  id ? `${ROUTES.PRODUCTS_VIEW}/${id}` : ROUTES.PRODUCTS_LIST;
