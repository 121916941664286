const getLanguage = () => {
  const language = localStorage.getItem('react-intl');

  // if no language in cache set local language
  if (!language) {
    localStorage.setItem('react-intl', navigator.language);
    return navigator.language;
  }

  return language;
};

export { getLanguage };
