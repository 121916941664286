import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { getToken, fetchRefreshToken, logOut } from 'utils';

const getFreshTokens = async () => {
  const response = await fetchRefreshToken().then((response) =>
    response.json()
  );
  if (response?.data?.refreshToken?.status === 'SUCCESS') {
    localStorage.setItem(
      'auth',
      JSON.stringify(response?.data?.refreshToken?.tokens)
    );
    return 'SUCCESS';
  }
  logOut();
};

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});

api.interceptors.request.use(
  async (conf) => {
    const config = { ...conf };
    const token = getToken();
    if (token) {
      const { exp }: any = jwtDecode(token);
      // check if accessToken invalid
      if (Date.now() > exp * 1000) {
        // get new tokens
        const result = await getFreshTokens();
        if (result === 'SUCCESS') {
          // new accessToken
          const newToken = getToken();
          config.headers.Authorization = `Bearer ${newToken}`;
          return config;
        }
      } else {
        // token still valid
        config.headers.Authorization = `Bearer ${token}`;
      }
    } else {
      logOut();
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      config,
      response: { statusText },
    } = error;

    if (statusText === 'Unauthorized') {
      const refreshToken = getToken('refresh');
      if (refreshToken) {
        const handleFetchTokens = async () => {
          const result = await getFreshTokens();
          if (result === 'SUCCESS') {
            // if user Unauthorized (token's time has expired) refetch the same request that was failed with new accessToken
            config._retry = true;
            // new accessToken
            config.headers.Authorization = `Bearer ${getToken()}`;
            return axios(config);
          }
        };
        handleFetchTokens();
      } else {
        logOut();
      }
    }
    return Promise.reject(error);
  }
);

export { api, getFreshTokens };
