import { FormattedMessage } from 'react-intl';
import { defaultDateFormat, defaultFormatPhoneNumber } from 'utils';

export const columns = [
  {
    title: <FormattedMessage id="Store name" />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: <FormattedMessage id="Address" />,
    dataIndex: 'address',
    className: 'partner-stores-table__middle',
  },
  {
    title: <FormattedMessage id="Contact person" />,
    dataIndex: 'contactPerson',
  },
  {
    title: <FormattedMessage id="Phone number" />,
    className: 'no-wrap',
    dataIndex: 'phoneNumber',
    render: (phoneNumber: string) => defaultFormatPhoneNumber(phoneNumber),
  },
  {
    title: <FormattedMessage id="Email" />,
    dataIndex: 'email',
  },
  {
    title: <FormattedMessage id="Licenses" />,
    dataIndex: 'totalLicenseCount',
    className: 'partner-stores-table__bold',
    render: (
      totalLicenseCount: string,
      { activatedLicenseCount }: { activatedLicenseCount: string },
    ) => `${activatedLicenseCount}/${totalLicenseCount}`,
    sorter: true,
  },
  {
    title: <FormattedMessage id="Date added" />,
    dataIndex: 'createdAt',
    render: (createdAt: Date) => defaultDateFormat(createdAt),
    sorter: true,
  },
];
