import classnames from 'classnames';
import { useB2COrdersFilterContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Switch from '../Switch/Switch';

import './customerTypeFilter.less';

export enum CustomerType {
  B2B = 'B2B',
  B2C = 'B2C',
}

interface CustomerTypeFilterProps {
  className?: string;
  onChange: (customerType: CustomerType) => void;
  customerType?: CustomerType;
}

export const CustomerTypeFilter = ({ className, onChange }: CustomerTypeFilterProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { orderType } = useB2COrdersFilterContext();

  const [currentCustomerType, setCurrentCustomerType] = useState(orderType);

  const handleSwitchChange = (value: boolean) => {
    if (value) {
      setCurrentCustomerType(CustomerType.B2B);
      onChange(CustomerType.B2B);
    } else {
      setCurrentCustomerType(CustomerType.B2C);
      onChange(CustomerType.B2C);
    }

    navigate(pathname);
  };

  useEffect(() => {
    setCurrentCustomerType(orderType);
  }, [orderType]);

  return (
    <div
      className={classnames('customer-type-filter', {
        [`${className}`]: className,
        isB2b: currentCustomerType === CustomerType.B2B,
      })}
    >
      <span
        className={classnames({
          'customer-type-filter__label': true,
          'customer-type-filter--active': currentCustomerType === CustomerType.B2C,
        })}
        onClick={() => handleSwitchChange(false)}
      >
        B2C
      </span>
      <Switch isChecked={currentCustomerType === CustomerType.B2B} onChange={handleSwitchChange} />
      <span
        className={classnames({
          'customer-type-filter__label': true,
          'customer-type-filter--active': currentCustomerType === CustomerType.B2B,
        })}
        onClick={() => handleSwitchChange(true)}
      >
        B2B
      </span>
    </div>
  );
};
