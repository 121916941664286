import { Button } from 'antd';
import { useIntl } from 'react-intl';

import './savePanelColumn.less';

interface SavePanelColumnPropsI {
  cancelText?: string;
  submitText?: string;
  loading?: boolean;
  onCancel?: () => void;
  submitDisabled?: boolean;
}

const SavePanelColumn = ({
  cancelText,
  submitText,
  submitDisabled,
  loading,
  onCancel,
}: SavePanelColumnPropsI) => {
  const { formatMessage } = useIntl();

  return (
    <div className="save-panel-column">
      <Button
        className="save-panel-column__button"
        type="primary"
        htmlType="submit"
        size="large"
        loading={loading}
        disabled={submitDisabled}
      >
        {submitText || formatMessage({ id: 'button.Continue' })}
      </Button>
      <Button
        className="save-panel-column__button"
        type="link"
        size="large"
        onClick={onCancel}
        disabled={loading}
      >
        {cancelText || formatMessage({ id: 'button.Back' })}
      </Button>
    </div>
  );
};

export default SavePanelColumn;
