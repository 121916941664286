interface CheckArgI {
  rules?: string[];
  action: string | string[];
}

const checkPermission = ({ rules = [], action }: CheckArgI) => {
  // No permissions
  if (rules.length === 0) {
    return false;
  }

  if (typeof action === 'string') {
    return rules.includes(action);
  }

  return rules.reduce((isPermitted, permission) => {
    if (isPermitted) {
      return isPermitted;
    }

    return action.includes(permission);
  }, false);
};

export { checkPermission };
