import { CustomerType } from 'components';

const PERSON_DASH_SYMBOL_INDEX = 8;
const PERSON_SYMBOLS_COUNT = 12;
export const PERSON_SYMBOLS_COUNT_WITH_DASH = 13;

const ORG_DASH_SYMBOL_INDEX = 6;
const ORG_SYMBOLS_COUNT = 10;
export const ORG_SYMBOLS_COUNT_WITH_DASH = 11;

const getDashSymbolIndex = (customerType: CustomerType) =>
  customerType === CustomerType.B2C
    ? PERSON_DASH_SYMBOL_INDEX
    : ORG_DASH_SYMBOL_INDEX;

const getSymbolsCount = (customerType: CustomerType) =>
  customerType === CustomerType.B2C ? PERSON_SYMBOLS_COUNT : ORG_SYMBOLS_COUNT;

const getSymbolsCountWithDash = (customerType: CustomerType) =>
  customerType === CustomerType.B2C
    ? PERSON_SYMBOLS_COUNT_WITH_DASH
    : ORG_SYMBOLS_COUNT_WITH_DASH;

export const addDashToValue = (
  value: string,
  customerType = CustomerType.B2C
) =>
  `${value.slice(0, getDashSymbolIndex(customerType))}-${value.slice(
    getDashSymbolIndex(customerType)
  )}`;

export const inputPersonnumerFormat = (
  inputValue: string,
  setErrors: () => void,
  clearErrors: () => void,
  customerType = CustomerType.B2C
) => {
  let newValue = inputValue.trim().replace(/[^0-9]/g, '');

  // Add dash symbol if no one was entered
  if (
    // countDash.length === 0 &&
    newValue.length > getDashSymbolIndex(customerType)
  ) {
    newValue = addDashToValue(newValue, customerType);
  }

  if (newValue.length >= getSymbolsCount(customerType)) {
    // For copy-paste string
    [!(newValue.charAt(getDashSymbolIndex(customerType)) === '-')]
      .filter(Boolean)
      .forEach(() => {
        newValue = addDashToValue(newValue);
      });

    if (newValue.length > getSymbolsCount(customerType)) {
      if (newValue.length > getSymbolsCountWithDash(customerType)) {
        newValue = newValue.slice(0, getSymbolsCountWithDash(customerType));
      }

      const formatRegex =
        customerType === CustomerType.B2C
          ? /^(19|20)(\d{6})-(\d{4})$/
          : /^(\d{6})-(\d{4})$/;
      const isPatterError = !formatRegex.test(newValue);

      if (isPatterError) {
        setErrors();
      } else {
        clearErrors();
      }
    }
  }

  return newValue;
};
