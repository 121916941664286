import React, { ReactNode } from 'react';
import { usePermission } from 'hooks';

interface PermissionPropsI {
  action: string | string[];
  children?: ReactNode;
  fallback?: ReactNode;
}

const Permission = ({ action, children, fallback }: PermissionPropsI) => {
  const { isPermitted } = usePermission(action);

  return isPermitted ? <>{children}</> : <>{fallback}</>;
};

export default Permission;
