import { intl } from 'contexts';
import { Notification } from 'components';

import { api } from './interceptors';

const downloadContract = async (orderId?: string, isB2BContract = false) => {
  try {
    const response = await api.get(
      `/app/contracts/${isB2BContract ? 'b2b/' : ''}pdf/${orderId}`,
      {
        responseType: 'blob',
      }
    );
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute(
      'download',
      `order_${isB2BContract ? 'b2b' : 'b2c'}_${orderId}_contract.pdf`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    Notification({
      type: 'error',
      title: intl.formatMessage({ id: 'notification.error' }),
      message: intl.formatMessage({ id: 'notification.orderStatus' }),
    });
    throw error;
  }
};

export { downloadContract };
