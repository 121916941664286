import { useMemo } from 'react';
import { checkPermission } from 'utils';
import { useUserContext } from 'contexts';

const usePermission = (action: string | string[]) => {
  const { permissionsList } = useUserContext();

  const isPermitted = useMemo(
    () =>
      checkPermission({
        rules: permissionsList,
        action,
      }),
    [action, permissionsList],
  );

  return {
    isPermitted,
  };
};

export { usePermission };
