import { useState, ReactNode } from 'react';
import { cleanSearchResult } from 'utils';
import { useDebounce } from './useDebounce';

const useSearchBy = (defaultSearchBy: any, resetPagination?: () => void) => {
  const [valueToSearch, setValueToSearch] = useState<string>();
  const [searchBy, setSelectedSearchBy] = useState(defaultSearchBy);
  const searchResult = useDebounce(valueToSearch);
  const searchValue = cleanSearchResult(searchBy, searchResult);

  const reset = () => (resetPagination ? resetPagination() : null);

  const setSearchValue = (value: string) => {
    reset();
    setValueToSearch(value);
  };

  const setSearchBy = (value: string | ReactNode) => {
    if (!valueToSearch) {
      return setSelectedSearchBy(value);
    }

    reset();
    return setSelectedSearchBy(value);
  };

  return { searchValue, setSearchValue, searchBy, setSearchBy };
};

export { useSearchBy };
