import React, { useMemo } from 'react';
import { StatisticBar, StatisticNumbers } from 'components';

import './statisticInfo.less';

interface StatisticInfoPropsI {
  withCounts?: boolean;
  total: number;
  regertQaRevenue?: number;
  regertCustomerRevenue?: number;
  regertCustomerArpRevenue?: number;
  guarantedRevenue?: number;
  underRegretPerionRevenue?: number;
  guaranteed: number;
  regrettedByQa: number;
  regrettedTotal: number;
  underRegretPeriod: number;
  regrettedByCustomer: number;
  regrettedByCustomerArp: number;
}

const StatisticInfo = ({
  withCounts,
  total,
  regertQaRevenue,
  regertCustomerRevenue,
  regertCustomerArpRevenue,
  guarantedRevenue,
  underRegretPerionRevenue,
  guaranteed,
  regrettedByQa,
  regrettedTotal,
  underRegretPeriod,
  regrettedByCustomer,
  regrettedByCustomerArp,
}: StatisticInfoPropsI) => {
  const statisticBarValues = useMemo(
    () => [
      { color: 'green', value: guaranteed },
      { color: 'yellow', value: underRegretPeriod },
      {
        color: 'red',
        value: regrettedByCustomer + regrettedByQa + regrettedByCustomerArp,
      },
    ],
    [
      guaranteed,
      regrettedByCustomer,
      regrettedByQa,
      regrettedByCustomerArp,
      underRegretPeriod,
    ]
  );

  return (
    <div className="statistic-info">
      <StatisticBar
        className="statistic-info__bar"
        values={statisticBarValues}
        total={total}
      />
      <StatisticNumbers
        withCounts={withCounts}
        regertQaRevenueData={regertQaRevenue}
        regertCustomerRevenueData={regertCustomerRevenue}
        regertCustomerArpRevenueData={regertCustomerArpRevenue}
        guarantedRevenue={guarantedRevenue}
        underRegretPerionRevenue={underRegretPerionRevenue}
        total={total}
        guaranteed={guaranteed}
        regretted={regrettedTotal}
        regrettedByQA={regrettedByQa}
        underRegretPeriod={underRegretPeriod}
        regrettedByCustomer={regrettedByCustomer}
        regrettedByCustomerArp={regrettedByCustomerArp}
      />
    </div>
  );
};

export default StatisticInfo;
