import React, { useState, useContext, useCallback, createContext } from 'react';
import moment from 'moment';
import { getLanguage } from 'utils';
import { ConfigProvider } from 'antd';
import English from 'locales/en.json';
import Swedish from 'locales/sv.json';
import en from 'antd/locale/en_GB';
import sv from 'antd/locale/sv_SE';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

moment.locale('en');

const initialValues = {
  changeLanguage: (lang: string) => {},
};

const translateContext = createContext(initialValues);

const { Provider } = translateContext;

const cache = createIntlCache();
const cachedLanguage = getLanguage();

const messages = {
  en: English,
  sv: Swedish,
} as any;

// eslint-disable-next-line import/no-mutable-exports
let intl = createIntl(
  {
    locale: cachedLanguage,
    messages: messages[cachedLanguage] || English,
    onError(e) {
      // eslint-disable-next-line no-console
      console.log('Please check your translation files', e.message);
    },
  },
  cache
);

const TranslateProvider = (props: any) => {
  const [antLanguage, setAntLanguage] = useState(en);
  const [local, setLocal] = useState(cachedLanguage);

  const changeLanguage = useCallback((lang: string) => {
    if (/sv/i.test(`${lang}`)) {
      intl = createIntl({ locale: 'sv', messages: Swedish }, cache);
      setAntLanguage(sv);
      setLocal('sv');
      moment.locale('sv');
      localStorage.setItem('react-intl', 'sv');
      return;
    }

    intl = createIntl({ locale: 'en', messages: English }, cache);
    setAntLanguage(en);
    setLocal('en');
    moment.locale('en');
    localStorage.setItem('react-intl', 'en');
  }, []);

  const validateMessages = {
    required: intl.formatMessage({ id: 'validate.required' }),
    whitespace: intl.formatMessage({ id: 'validate.whitespace' }),
    types: {
      email: intl.formatMessage({ id: 'validate.email' }),
    },
    pattern: {
      mismatch: intl.formatMessage({ id: 'validate.mismatch' }),
    },
  };

  return (
    <ConfigProvider locale={antLanguage} form={{ validateMessages }}>
      <Provider value={{ changeLanguage }}>
        <RawIntlProvider value={intl} key={local} {...props} />
      </Provider>
    </ConfigProvider>
  );
};

const useTranslateContext = () => useContext(translateContext);

export { TranslateProvider, translateContext, useTranslateContext, intl };
