import { Tag } from 'components';
import { FormattedMessage } from 'react-intl';
import { defaultFormatPhoneNumber } from 'utils';
import { TeamMemberProject } from 'api';

export const columns = [
  {
    title: <FormattedMessage id="ID" />,
    dataIndex: '_id',
  },
  {
    title: <FormattedMessage id="Name" />,
    dataIndex: 'fullName',
    sorter: true,
  },
  {
    title: <FormattedMessage id="Email" />,
    dataIndex: 'email',
  },
  {
    title: <FormattedMessage id="Phone number" />,
    className: 'no-wrap',
    dataIndex: 'phoneNumber',
    render: (phoneNumber: string) => defaultFormatPhoneNumber(phoneNumber),
  },
  {
    title: <FormattedMessage id="Personnummer" />,
    className: 'no-wrap',
    dataIndex: 'personId',
  },
  {
    title: <FormattedMessage id="Project" />,
    dataIndex: 'projects',
    render: (projects: TeamMemberProject[]) =>
      projects.map(({ projectName }) => projectName).join(', '),
  },
  {
    title: <FormattedMessage id="Role" />,
    dataIndex: 'role',
    render: ({ name }: { name: string }) => name,
  },
  {
    title: <FormattedMessage id="Status" />,
    dataIndex: 'status',
    render: (status: string) => {
      if (status === 'ACTIVE') {
        return (
          <Tag color="primary">
            <FormattedMessage id="Active" />
          </Tag>
        );
      }

      if (status === 'INVITED') {
        return (
          <Tag>
            <FormattedMessage id="Invited" />
          </Tag>
        );
      }

      return (
        <Tag color="warning">
          <FormattedMessage id="Inactive" />
        </Tag>
      );
    },
  },
];
