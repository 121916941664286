import { useState } from 'react';
import { Moment } from 'moment';
import { transformDateEnd, transformDateStart } from 'utils';

const useDateFilter = (resetPagination?: () => void) => {
  const [startDate, setStartDate] = useState<Moment | null>();
  const [endDate, setEndDate] = useState<Moment | null>();

  const reset = () => (resetPagination ? resetPagination() : null);

  const setFilterByDate = (date: Moment | null) => {
    if (!date) {
      setStartDate(null);
      setEndDate(null);
      return reset();
    }

    setStartDate(transformDateStart(date));
    setEndDate(transformDateEnd(date));
    return reset();
  };

  return { startDate, endDate, setFilterByDate };
};

export { useDateFilter };
