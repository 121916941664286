import { CustomerType } from 'components';
import { isEqual } from 'lodash';
import moment, { Moment } from 'moment';
import { GroupByPeriod } from '../api';

const ifFiltersTouched = (localB2CFilters: any) => {
  let count = 0;
  for (const key in localB2CFilters) {
    if (localB2CFilters[key] && localB2CFilters[key].isTouched) {
      count += 1;
    }
  }
  const localB2COrdersFilters = getLocalB2COrdersFilters();
  if (count > 0) {
    localStorage.setItem(
      'b2cOrdersFilters',
      JSON.stringify({
        ...localB2COrdersFilters,
        isFilterTouched: true,
      })
    );
  } else {
    localStorage.setItem(
      'b2cOrdersFilters',
      JSON.stringify({
        ...localB2COrdersFilters,
        isFilterTouched: false,
      })
    );
  }
};

const initialFilters = {
  orderType: CustomerType.B2C,
  localProductIdIn: { value: undefined, isTouched: false },
  localProjectIdIn: { value: undefined, isTouched: false },
  localContractStatusIn: { value: undefined, isTouched: false },
  localOrderStatusIn: { value: undefined, isTouched: false },
  localFinanceSystemEq: { value: undefined, isTouched: false },
  localCreditCheckCodeEq: { value: undefined, isTouched: false },
  localSalesChannelIdIn: { value: undefined, isTouched: false },
  localBundleIdIn: { value: undefined, isTouched: false },
  localAddonIdIn: { value: undefined, isTouched: false },
  localSearchBy: { value: 'customerLike', isTouched: false },
  localSearchValue: { value: undefined, isTouched: false },
  localSaleStatusEq: { value: undefined, isTouched: false },
  localRecordsLimit: { value: 10, isTouched: false },
  localPickerDateType: { value: 'salesAt', isTouched: false },
  localPeriod: { value: 'allTime', isTouched: false },
  localDateRange: { value: [null, null], isTouched: false },
  localGroupedBy: GroupByPeriod.Day,
  isFilterTouched: false,
  isLocalCollapseOpen: false,
};

export const getLocalB2COrdersFilters = () => {
  const localB2COrdersFilters: any = localStorage.getItem('b2cOrdersFilters');

  const parsedLocalB2CFilters = localB2COrdersFilters
    ? JSON.parse(localB2COrdersFilters)
    : null;

  if (parsedLocalB2CFilters) {
    const { localDateRange } = localB2COrdersFilters;
    if (localDateRange) {
      const transformDateRange = [
        moment(localDateRange[0]).utc(),
        moment(localDateRange[1]).utc(),
      ];
      return {
        ...parsedLocalB2CFilters,
        localDateRange: transformDateRange,
      };
    }
    return parsedLocalB2CFilters;
  }

  return initialFilters;
};

export const resetB2CLocalFilters = () => {
  localStorage.setItem('b2cOrdersFilters', JSON.stringify(initialFilters));
};

export const filtersCollapseTogle = (value?: boolean) => {
  const localB2COrdersFilters = getLocalB2COrdersFilters();
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2COrdersFilters,
      isLocalCollapseOpen: value,
    })
  );
};

export const setLocalB2CFilterByOrdersType = (
  orderTypeInput?: string | null
) => {
  const localB2COrdersFilters = getLocalB2COrdersFilters();
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2COrdersFilters,
      orderType: orderTypeInput,
    })
  );
};

// with is touched check
export const setLocalB2CFilterByProject = (projectId?: string | null) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localProjectIdIn.value;

  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localProjectIdIn: {
        value: projectId,
        isTouched: projectId !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2CFilterByProduct = (productId?: string | null) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localProjectIdIn.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localProductIdIn: {
        value: productId,
        isTouched: productId !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2CFilterByContractStatus = (
  contractStatusIn?: string | null
) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localContractStatusIn.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localContractStatusIn: {
        value: contractStatusIn,
        isTouched: contractStatusIn !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2CFilterByOrderStatus = (
  orderStatusIn?: string | null
) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localOrderStatusIn.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localOrderStatusIn: {
        value: orderStatusIn,
        isTouched: orderStatusIn !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2CFilterByInvoicing = (
  financeSystemEq?: string | null
) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localFinanceSystemEq.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localFinanceSystemEq: {
        value: financeSystemEq,
        isTouched: financeSystemEq !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2CFilterByCreditCheck = (
  creditCheckCodeEq?: string | null
) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localCreditCheckCodeEq.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localCreditCheckCodeEq: {
        value: creditCheckCodeEq,
        isTouched: creditCheckCodeEq !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2CFilterBySaleStatus = (saleStatusEq?: string | null) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localSaleStatusEq.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localSaleStatusEq: {
        value: saleStatusEq,
        isTouched: saleStatusEq !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2CFilterBySalesChannel = (
  salesChannelIdIn?: string | null
) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localSalesChannelIdIn.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localSalesChannelIdIn: {
        value: salesChannelIdIn,
        isTouched: salesChannelIdIn !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2CFilterByBundle = (bundleIdIn?: string | null) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localBundleIdIn.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localBundleIdIn: {
        value: bundleIdIn,
        isTouched: bundleIdIn !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2CFilterByAddon = (addonIdIn?: string | null) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localAddonIdIn.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localAddonIdIn: {
        value: addonIdIn,
        isTouched: addonIdIn !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2CFilterSearchName = (searchBy?: string | null) => {
  if (searchBy) {
    const localB2CFilters = getLocalB2COrdersFilters();
    const initialFilterValue = initialFilters.localSearchBy.value;
    localStorage.setItem(
      'b2cOrdersFilters',
      JSON.stringify({
        ...localB2CFilters,
        localSearchBy: {
          value: searchBy,
          isTouched: searchBy !== initialFilterValue,
        },
      })
    );
  }
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2CFilterSearchValue = (searchValue?: string | null) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localSearchValue.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localSearchValue: {
        value: searchValue && searchValue.length > 0 ? searchValue : undefined,
        isTouched: !!(
          searchValue &&
          searchValue !== initialFilterValue &&
          searchValue.length > 0
        ),
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2COrdersDateRange = (
  dateRange: Moment[] | null[] | undefined
) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localDateRange.value;
  if (dateRange && dateRange !== localB2CFilters.localDateRange) {
    localStorage.setItem(
      'b2cOrdersFilters',
      JSON.stringify({
        ...localB2CFilters,
        localDateRange: {
          value: dateRange,
          isTouched: !isEqual(dateRange, initialFilterValue),
        },
      })
    );
  } else {
    const dateRangeResult = [null, null];
    localStorage.setItem(
      'b2cOrdersFilters',
      JSON.stringify({
        ...localB2CFilters,
        localDateRange: {
          value: dateRangeResult,
          isTouched: !isEqual(dateRangeResult, initialFilterValue),
        },
      })
    );
  }
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2COrdersGroupedBy = (groupedBy: GroupByPeriod) => {
  const localB2CFilters = getLocalB2COrdersFilters();

  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localGroupedBy: groupedBy,
    })
  );
};

export const setLocalB2COrdersPeriod = (period: string | undefined) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localPeriod.value;
  if (localB2CFilters.localPeriod !== period) {
    localStorage.setItem(
      'b2cOrdersFilters',
      JSON.stringify({
        ...localB2CFilters,
        localPeriod: {
          value: period,
          isTouched: period !== initialFilterValue,
        },
      })
    );
  }
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};
export const setLocalB2COrdersPickerDateType = (
  dateType: string | undefined
) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localPickerDateType.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localPickerDateType: {
        value: dateType,
        isTouched: dateType !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};

export const setLocalB2COrdersRecordsLimit = (limit: number | undefined) => {
  const localB2CFilters = getLocalB2COrdersFilters();
  const initialFilterValue = initialFilters.localRecordsLimit.value;
  localStorage.setItem(
    'b2cOrdersFilters',
    JSON.stringify({
      ...localB2CFilters,
      localRecordsLimit: {
        value: limit,
        isTouched: limit !== initialFilterValue,
      },
    })
  );
  const updatedFilters = getLocalB2COrdersFilters();
  ifFiltersTouched(updatedFilters);
};
