import { useIntl } from 'react-intl';

import './status.less';

const Status = ({ status }: { status?: string }) => {
  const { formatMessage } = useIntl();

  return status === 'ACTIVE' ? (
    <span className="status status--active">{formatMessage({ id: 'Active' })}</span>
  ) : (
    <span className="status status--inactive">{formatMessage({ id: 'Inactive' })}</span>
  );
};

export default Status;
